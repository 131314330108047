import React from 'react';
import { useTranslation } from 'react-i18next';
import ChangePasswordForm from '../containers/ChangePasswordForm';
import { Modal } from 'react-bootstrap';
import { Button } from './Custom';

const ChangeUserPasswordModal = ({ showModal, onCloseModal }) => {
  const { t } = useTranslation();

  const handleClose = ev => {
    if (ev) {
      ev.preventDefault();
    }
    onCloseModal();
  };

  return (
    <div>
      <Modal id="changePasswordDialogModal" size="lg" animation="true" show={showModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <h2>{t('DASHBOARD_USER_CHANGE_PASSWORD')}</h2>
            <Button as="a" className="a-button float-right" onClick={handleClose}>
              <i className="material-icons">close</i>
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body" id="modal-body">
          <div className="width-100">
            <div className="row">
              <div className="col-12">
                {' '}
                <ChangePasswordForm onChangeSuccess={onCloseModal} />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ChangeUserPasswordModal;
