import { EXPERTS_GET_REQUEST, EXPERTS_GET_SUCCESS, EXPERTS_GET_FAILURE, EXPERTS_ADD_USER, REGISTER_EXPERT_REQUEST, REGISTER_EXPERT_FAILURE, EXPERTS_UPDATE_LIST } from '../constants/actionTypes';
import agent from '../agent';
import { setToastSuccessMessage, setToastErrorMessage } from './common';

export const getExpertsRequest = () => ({ type: EXPERTS_GET_REQUEST });

export const getExpertsSuccess = payload => ({
  type: EXPERTS_GET_SUCCESS,
  payload
});
export const getExpertsFailure = error => ({ type: EXPERTS_GET_FAILURE, error });

export const getExperts = () => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(getExpertsRequest());

    try {
      const paginatedResponse = await agent.ExpertService.getExperts();
      const payload = paginatedResponse.result.listings
      dispatch(getExpertsSuccess(payload));
    } catch (error) {
      dispatch(getExpertsFailure(error));
    }
  };
};

export const registerExpertRequest = () => ({ type: REGISTER_EXPERT_REQUEST });

export const addExpertUser = newUser => ({ type: EXPERTS_ADD_USER, payload: newUser });
export const updateExpertUserList = experts => ({ type: EXPERTS_UPDATE_LIST, payload: experts });

export const registerExpertFailure = error => ({ type: REGISTER_EXPERT_FAILURE, error });

export const registerExpert = (newExpertUser) => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(registerExpertRequest());
    try {
      const { email, password } = newExpertUser;
      const payload = await agent.AuthService.registerExpert(email, password);
      const { user } = payload;
      dispatch(addExpertUser(user));
      setToastSuccessMessage('Successfully registered new user!');
      return payload;
    } catch (error) {
      dispatch(registerExpertFailure(error));
      setToastErrorMessage(`Could not regisster new user: ${JSON.stringify(error)}`);
    }
  };
};
