import React from 'react';
import { useTranslation } from 'react-i18next';
import CONSTANTS from '../constants/constants';
import IconTransportSwitcher from './IconTransportSwitcher';
import IconAccommodationSwitcher from './IconAccommodationSwitcher';
import IconAttractionSwitcher from './IconAttractionSwitcher';
import { CustomTooltip } from './Custom';

const TripEventKindIconSwitcher = ({ tripEvent, icon, tooltip, text }) => {
  const { t } = useTranslation();

  switch (tripEvent.kind) {
    case CONSTANTS.TRIP_EVENT_KINDS.DIRECTIONS:
      return <IconTransportSwitcher iconKey={tripEvent.meansOfTransportation} icon={icon} tooltip={tooltip} text={text}></IconTransportSwitcher>;
    case CONSTANTS.TRIP_EVENT_KINDS.ACCOMMODATION:
      return <IconAccommodationSwitcher iconKey={tripEvent.accommodationType} icon={icon} tooltip={tooltip} text={text}></IconAccommodationSwitcher>;
    case CONSTANTS.TRIP_EVENT_KINDS.ATTRACTION:
      return <IconAttractionSwitcher iconKey={tripEvent.eventType} icon={icon} tooltip={tooltip} text={text}></IconAttractionSwitcher>;
    case CONSTANTS.TRIP_EVENT_KINDS.ESTABLISHMENT:
    default:
      return (
        <CustomTooltip text="ESTABLISHMENT" isKey={true}>
          <div className="expert-icon-container">
            <i className="fa fa-map-marker" title={t('ESTABLISHMENT')} aria-hidden="true"></i>
          </div>
        </CustomTooltip>
      );
  }
};

export default TripEventKindIconSwitcher;
