import React from 'react';
import { getAllCurrencies } from '../utils/currencyUtils';

const SelectCurrencyBox = ({ currency, disable, onCurrencyChange }) => {
  const allCurencies = getAllCurrencies();

  const changeCurrency = ev => {
    onCurrencyChange(ev.target.value);
  };

  const currencyTypes = Object.keys(allCurencies).map(currency => {
    const currencyType = {};
    currencyType.value = currency;
    currencyType.label = `${currency} (${allCurencies[currency].symbol})`;
    return currencyType;
  });

  return (
    <select className="form-control" value={currency} onChange={changeCurrency} disabled={disable}>
      {currencyTypes.map((currencyType, index) => (
        <option key={index} value={currencyType.value}>
          {currencyType.label}
        </option>
      ))}
    </select>
  );
};

export default SelectCurrencyBox;
