import React, { useState } from 'react';
import InProgress from '../components/InProgress';
import { connect } from 'react-redux';
import { changeUserPassword } from '../actions/users';
import { useTranslation } from 'react-i18next';
import CONSTANTS from '../constants/constants';
import { useForm } from 'react-hook-form';

const ChangePasswordForm = ({ currentUser, inProgress, changeUserPassword, onChangeSuccess }) => {
  const { t } = useTranslation();

  const userId = currentUser ? currentUser._id : null;

  const { register, errors, handleSubmit } = useForm({ mode: 'onBlur' });

  const [errorMessage, setErrorMessage] = useState('');

  const isPasswordFormValid = changePasswordForm => {
    const { currentPassword, newPassword, newPasswordConfirm } = changePasswordForm;

    if (!userId) {
      setErrorMessage(t('USER_UPDATE_CREDENTIALS_VALIDATION_MISSING_USERID_ERROR'));
      return false;
    }
    if (!currentPassword || !newPassword || !newPasswordConfirm) {
      setErrorMessage(t('USER_UPDATE_CREDENTIALS_VALIDATION_ALL_FIELDS_ERROR'));
      return false;
    }
    if (newPassword !== newPasswordConfirm) {
      setErrorMessage(t('USER_UPDATE_CREDENTIALS_VALIDATION_MISMATCH_PASSWORD_ERROR'));
      return false;
    }
    return true;
  };

  const changePassword = async formValues => {
    if (!isPasswordFormValid(formValues)) {
      return;
    }

    const { currentPassword, newPassword } = formValues;

    const errorMsg = await changeUserPassword(userId, currentPassword, newPassword);

    if (!!errorMsg) {
      setErrorMessage(`${t('USER_UPDATE_CREDENTIALS_ERROR_PREFIX')} ${errorMsg}`);
      return;
    }

    onChangeSuccess();
  };

  return (
    <>
      <form name="changePasswordForm" className="bold-form form-horizontal" onSubmit={handleSubmit(changePassword)}>
        <fieldset>
          <div className="row">
            <label htmlFor="currentPassword" className="col-form-label col-sm-4">
              {t('DASHBOARD_USER_CURRENT_PASSWORD')}
            </label>
            <div className="col-sm-6">
              <input
                type="password"
                className="form-control textbox"
                placeholder={t('DASHBOARD_USER_CURRENT_PASSWORD_PLACEHOLDER')}
                name="currentPassword"
                ref={register({ required: t('REQUIRED_FIELD') })}
              />
              <div>
                <span className="error small">{errors.currentPassword && errors.currentPassword.message}</span>
              </div>
            </div>
          </div>
          <br />

          <div className="row">
            <label htmlFor="newPassword" className="col-form-label col-sm-4">
              {t('DASHBOARD_USER_NEW_PASSWORD')}
            </label>
            <div className="col-sm-6">
              <input
                type="password"
                className="form-control textbox"
                placeholder={t('DASHBOARD_USER_NEW_PASSWORD_PLACEHOLDER')}
                name="newPassword"
                ref={register({
                  required: t('REQUIRED_FIELD'),
                  pattern: {
                    value: CONSTANTS.PASSWORD_REGEX,
                    message: t('PASSWORD_ACCEPTANCE_CRITERIA')
                  }
                })}
              />
              <div>
                <span className="error small">{errors.newPassword && errors.newPassword.message}</span>
              </div>
            </div>
          </div>
          <br />

          <div className="row">
            <label htmlFor="newPasswordConfirm" className="col-form-label col-sm-4">
              {t('DASHBOARD_USER_NEW_PASSWORD_CONFIRMATION')}
            </label>
            <div className="col-sm-6">
              <input
                type="password"
                className="form-control textbox"
                placeholder={t('DASHBOARD_USER_NEW_PASSWORD_CONFIRMATION_PLACEHOLDER')}
                name="newPasswordConfirm"
                ref={register({
                  required: t('REQUIRED_FIELD'),
                  pattern: {
                    value: CONSTANTS.PASSWORD_REGEX,
                    message: t('PASSWORD_ACCEPTANCE_CRITERIA')
                  }
                })}
              />
              <div>
                <span className="error small">{errors.newPasswordConfirm && errors.newPasswordConfirm.message}</span>
              </div>
            </div>
          </div>
        </fieldset>
        <p className="callback-error-message">{errorMessage}</p>
        <button type="submit" className="btn btn-primary" disabled={inProgress}>
          {t('BUTTON_SAVE')}
        </button>
      </form>
      <InProgress inProgress={inProgress} />
    </>
  );
};

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  inProgress: state.users.inProgress
});

const mapDispatchToProps = dispatch => ({
  changeUserPassword: (userId, currentPassword, newPassword) => dispatch(changeUserPassword(userId, currentPassword, newPassword))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm);
