import agent from './agent';
import { APP_LOAD, LOGIN_SUCCESS, REGISTER_SUCCESS, LOGOUT_SUCCESS } from './constants/actionTypes';
import LocalStorage from './LocalStorage';
import { showLoginDialogAction } from './actions/modals';

const setCurrentSessionTimeout = timeInterval => {
  console.debug(`***Remaining time for session: ${timeInterval / 1000} seconds`);

  const currentSessionTimeout = window.setTimeout(() => {
    showLoginDialogAction();
    LocalStorage.clear();
  }, timeInterval);

  agent.setCurrentSessionTimeout(currentSessionTimeout);
};

/* Cancels existing session timeout from previous login if exists*/
const cancelCurrentSessionTimeout = () => {
  const currentSessionTimeout = agent.getCurrentSessionTimeout();

  if (!currentSessionTimeout) {
    return;
  }
  window.clearTimeout(currentSessionTimeout);
  agent.setCurrentSessionTimeout(null);
};

const createCurrentSessionTimeout = (expirationTime = 0) => {
  const now = new Date().getTime();
  const remainingTimeSession = expirationTime - now;
  if (remainingTimeSession <= 0) {
    return;
  }
  setCurrentSessionTimeout(remainingTimeSession);
};

const initializeSessionTimeout = expirationTime => {
  cancelCurrentSessionTimeout();
  createCurrentSessionTimeout(expirationTime);
};

export const localStorageMiddleware = store => next => action => {
  if (action.type === APP_LOAD) {
    const expirationTime = LocalStorage.getExpirationTime();
    initializeSessionTimeout(expirationTime);
  } else if (action.type === REGISTER_SUCCESS || action.type === LOGIN_SUCCESS) {
    if (!action.error) {
      const { token, user, expirationTime } = action.payload;
      LocalStorage.setAll(token, user, expirationTime);
      initializeSessionTimeout(expirationTime);
      agent.set(token, user._id);
    }
  } else if (action.type === LOGOUT_SUCCESS) {
    LocalStorage.clear();
    agent.set(null, null);
    cancelCurrentSessionTimeout();
  }

  next(action);
};

export const logger = store => next => action => {
  console.log(`dispatching : ${action.type}`, action);
  let result = next(action);
  console.log('next state', store.getState());
  return result;
};

export const crashReporter = store => next => action => {
  try {
    return next(action);
  } catch (err) {
    console.error('Caught an exception!', err);
    agent.AppService.logClientError(`Redux exception: ${err.message}`);
    throw err;
  }
};
