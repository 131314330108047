import React from 'react';
import { useTranslation } from 'react-i18next';

const TripTitleSubtitle = ({ children, title, subtitle, isEdition }) => {
  const { t } = useTranslation();

  if (!title) {
    return null;
  }
  return (
    <div className="row title-subtitle">
      <div className="col-12 col-md-9">
        <h3 className="title">
          {isEdition ? <b>[{t('TRIP_PLAN_EDIT_TITLE_PREFIX')}]</b> : null} {title} {children}
        </h3>
        <h4 className="subtitle">{subtitle}</h4>
      </div>
      <div className="col-12 col-md-3"></div>
    </div>
  );
};

export default TripTitleSubtitle;
