import {
  LOCATION_RESET,
  UPDATE_CURRENT_LOCATION_FIELD,
  LOCATIONS_GET_REQUEST,
  LOCATIONS_GET_SUCCESS,
  LOCATIONS_GET_FAILURE,
  LOCATION_POST_REQUEST,
  LOCATION_POST_SUCCESS,
  LOCATION_POST_FAILURE,
  LOCATION_DELETE_REQUEST,
  LOCATION_DELETE_SUCCESS,
  LOCATION_DELETE_FAILURE,
  LOCATION_GET_REQUEST,
  LOCATION_GET_SUCCESS,
  LOCATION_GET_FAILURE,
  LOCATION_PUT_REQUEST,
  LOCATION_PUT_SUCCESS,
  LOCATION_PUT_FAILURE
} from '../constants/actionTypes';

import agent from '../agent';
import i18next from 'i18next';
import { setToastSuccessMessage, setToastErrorMessage } from './common';

export const resetCurrentLocation = () => ({ type: LOCATION_RESET });
export const updateCurrentLocationField = (name, value) => ({ type: UPDATE_CURRENT_LOCATION_FIELD, key: name, value });

export const getLocationsRequest = () => ({ type: LOCATIONS_GET_REQUEST });

export const getLocationsSuccess = payload => ({
  type: LOCATIONS_GET_SUCCESS,
  payload
});
export const getLocationsFailure = errorMsg => ({ type: LOCATIONS_GET_FAILURE, error: { message: errorMsg } });

export const getLocations = () => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(getLocationsRequest());

    const errorMsg = i18next.t('LOCATION_LIST_RETRIEVAL_ERROR');

    try {
      let paginatedResult = await agent.LocationsService.getLocations();  //paginated
      const locations = paginatedResult.result.listings

      // this is necessary because the $scope.destinationSearchCode filter requires an empty string (null is not breaks the filtering)
      locations = (locations || []).map(location => {
        if (location.destinationCode === null) {
          location.destinationCode = '';
        }
        return location;
      });

      const sortedLocations = (locations || []).sort((a, b) => new Date(b.name) - new Date(a.name));

      dispatch(getLocationsSuccess(sortedLocations));
      return sortedLocations;
    } catch (error) {
      console.log(`Error when retrieving Locations list: ${JSON.stringify(error)}`);
      dispatch(getLocationsFailure(errorMsg));
    }
  };
};

export const deleteLocationRequest = () => ({ type: LOCATION_DELETE_REQUEST });

export const deleteLocationSuccess = (message, locationId) => ({
  type: LOCATION_DELETE_SUCCESS,
  message,
  locationId
});

export const deleteLocationFailure = errorMsg => ({ type: LOCATION_DELETE_FAILURE, error: { message: errorMsg } });

export const deleteLocation = locationId => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(deleteLocationRequest());

    try {
      await agent.LocationsService.deleteLocation(locationId);
      const deletionSuccessMessage = i18next.t('LOCATION_DELETION_SUCCESS');
      dispatch(deleteLocationSuccess(deletionSuccessMessage, locationId));
      dispatch(setToastSuccessMessage(deletionSuccessMessage, `Location successfully deleted: ${locationId}`));
    } catch (error) {
      const errorMsg = `${i18next.t('LOCATION_DELETION_ERROR_PREFIX')} : ${error.message}`;
      dispatch(deleteLocationFailure(errorMsg));
      dispatch(setToastErrorMessage(errorMsg, `Error when deleting a Location: ${JSON.stringify(error)}`));
    }
  };
};

export const postLocationRequest = () => ({ type: LOCATION_POST_REQUEST });

export const postLocationSuccess = (message, payload) => ({
  type: LOCATION_POST_SUCCESS,
  message,
  payload
});

export const postLocationFailure = errorMsg => ({ type: LOCATION_POST_FAILURE, error: { message: errorMsg } });

export const createLocation = location => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(postLocationRequest());

    try {
      const newLocation = await agent.LocationsService.postLocation(location);
      dispatch(postLocationSuccess(i18next.t('LOCATION_CREATION_SUCCESS'), newLocation));
      dispatch(setToastSuccessMessage(i18next.t('LOCATION_CREATION_SUCCESS'), `'Location successfully created: ${newLocation._id}`));
      return newLocation;
    } catch (error) {
      const errorMsg = `${i18next.t('LOCATION_CREATION_ERROR_PREFIX')} : ${error.message}`;
      dispatch(postLocationFailure(errorMsg));
      dispatch(setToastErrorMessage(errorMsg, `Error when creating a Location: ${JSON.stringify(error)}`));
      return null;
    }
  };
};

export const getLocationRequest = () => ({ type: LOCATION_GET_REQUEST });

export const getLocationSuccess = payload => ({
  type: LOCATION_GET_SUCCESS,
  payload
});
export const getLocationFailure = errorMsg => ({ type: LOCATION_GET_FAILURE, error: { message: errorMsg } });

export const getLocation = locationId => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(getLocationRequest());

    try {
      const location = await agent.LocationsService.getLocation(locationId);
      dispatch(getLocationSuccess(location));
      return location;
    } catch (error) {
      const errorMsg = `${i18next.t('LOCATION_RETRIEVAL_ERROR')} : ${error.message}`;
      dispatch(getLocationFailure(errorMsg));
      dispatch(setToastErrorMessage(errorMsg, `Error when retrieving a Location: ${JSON.stringify(error)}`));
      return null;
    }
  };
};

export const putLocationRequest = () => ({ type: LOCATION_PUT_REQUEST });

export const putLocationSuccess = (message, payload) => ({
  type: LOCATION_PUT_SUCCESS,
  message,
  payload
});
export const putLocationFailure = errorMsg => ({ type: LOCATION_PUT_FAILURE, error: { message: errorMsg } });

export const saveLocation = (locationId, location) => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(putLocationRequest());

    try {
      const updatedLocation = await agent.LocationsService.updateLocation(locationId, location);
      dispatch(putLocationSuccess(i18next.t('LOCATION_SAVE_SUCCESS'), updatedLocation));
      dispatch(setToastSuccessMessage(i18next.t('LOCATION_SAVE_SUCCESS'), `Location successfully saved: ${locationId}`));
      return updatedLocation;
    } catch (error) {
      const errorMsg = `${i18next.t('LOCATION_UPDATE_ERROR_PREFIX')} : ${error.message}`;
      dispatch(putLocationFailure(errorMsg));
      dispatch(setToastErrorMessage(errorMsg, `Error when updating a Location: ${JSON.stringify(error)}`));
      return null;
    }
  };
};
