import {
  UPDATE_TRIP_PLAN_REQUEST,
  UPDATE_TRIP_PLAN_SUCCESS,
  UPDATE_TRIP_PLAN_FAILURE,
  UPDATE_TRIP_PLAN_DAY_SUCCESS,
  TRIP_PLAN_RESET,
  UPDATE_TRIP_PLAN_CURRENT_DAY,
  UPDATE_TRIP_PLAN_CURRENT_EVENT
} from '../constants/actionTypes';

import agent from '../agent';
import i18next from 'i18next';
import { setToastSuccessMessage, setToastErrorMessage } from './common';
import { getDirectionEvent, updateDirectionEvent } from '../utils/tripUtils';
import { reorder, deepCopy } from '../utils/common';
import { applyCorrectionToTripDayEventsLocal, moveEventFromOneDayToAnother, removeEventAndApplyCorrection } from '../utils/tripPlanUtils';

export const updateTripPlanRequest = () => ({ type: UPDATE_TRIP_PLAN_REQUEST });

export const resetTripPlanPayload = () => ({
  type: TRIP_PLAN_RESET
});

export const updateTripPlanCurrentDayId = dayId => ({
  type: UPDATE_TRIP_PLAN_CURRENT_DAY,
  dayId
});

export const updateTripPlanCurrentEventId = eventId => ({
  type: UPDATE_TRIP_PLAN_CURRENT_EVENT,
  eventId
});

export const updateTripPlanPayload = (payload, message = '') => ({
  type: UPDATE_TRIP_PLAN_SUCCESS,
  message,
  payload
});

export const updateTripPlanDaySuccess = (message, dayId, payload) => ({
  type: UPDATE_TRIP_PLAN_DAY_SUCCESS,
  message,
  dayId,
  payload
});

export const updateTripPlanFailure = errorMsg => ({ type: UPDATE_TRIP_PLAN_FAILURE, error: { message: errorMsg } });

export const deleteDay = (tripId, dayId, isTemplate) => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(updateTripPlanRequest());

    try {
      await agent.TripPlansService.deleteTripPlanDay(tripId, dayId, isTemplate);
      const { currentTripPlan } = getState().tripPlans;
      const { days } = currentTripPlan;
      const updatedDays = days.filter(day => day._id !== dayId);
      const deletionSuccessMessage = i18next.t('TRIP_PLAN_DELETE_DAY_SUCCESS');
      dispatch(updateTripPlanPayload({ ...currentTripPlan, days: updatedDays }, deletionSuccessMessage));
      dispatch(setToastSuccessMessage(deletionSuccessMessage, `Trip Day successfully deleted: ${tripId}`));
      return true;
    } catch (error) {
      const errorMsg = `${i18next.t('TRIP_PLAN_DELETE_DAY_ERROR')} : ${error.message}`;
      dispatch(updateTripPlanFailure(error));
      dispatch(setToastErrorMessage(errorMsg, `Error when deleting a Trip Day: ${JSON.stringify(error)}`));
      return false;
    }
  };
};

export const addDay = (tripId, isTemplate, queryEvents) => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(updateTripPlanRequest());

    const tripPlan = getState().tripPlans.currentTripPlan;
    try {
      const newDay = {
        events: queryEvents || []
      };

      const addedDay = await agent.TripPlansService.addTripPlanDay(tripId, newDay, isTemplate);

      if (!addedDay) {
        throw new Error('Day could not be added: null');
      }
      const addSuccessMessage = i18next.t('TRIP_PLAN_ADD_DAY_SUCCESS');
      const { days } = tripPlan;
      const updatedTripPlan = { ...tripPlan, days: [...days, addedDay] };
      dispatch(updateTripPlanPayload(updatedTripPlan, addSuccessMessage));
      dispatch(setToastSuccessMessage(addSuccessMessage, `Trip Day successfully added: ${tripId}`));
      return addedDay;
    } catch (error) {
      const errorMsg = `${i18next.t('TRIP_PLAN_ADD_DAY_ERROR')} : ${error.message}`;
      dispatch(updateTripPlanFailure(error));
      dispatch(setToastErrorMessage(errorMsg, `Error when adding a Trip Day: ${JSON.stringify(error)}`));
      return null;
    }
  };
};

export const saveDay = (tripId, dayId, day, isTemplate) => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(updateTripPlanRequest());

    try {
      const updatedDay = await agent.TripPlansService.updateTripPlanDay(tripId, dayId, day, isTemplate);

      if (updatedDay._id !== dayId) {
        throw new Error(`Something went wrong when updating new Day. Ids don't match: server id is ${updatedDay._id}  and current id is ${dayId}`);
      }

      const updateSuccessMessage = i18next.t('TRIP_PLAN_DAY_UPDATE_SUCCESS');
      dispatch(updateTripPlanDaySuccess(updateSuccessMessage, dayId, updatedDay));
      dispatch(setToastSuccessMessage(updateSuccessMessage, `Trip Day successfully updated: ${tripId}`));
    } catch (error) {
      const errorMsg = `${i18next.t('TRIP_PLAN_DAY_UPDATE_ERROR_PREFIX')} : ${error.message}`;
      dispatch(updateTripPlanFailure(error));
      dispatch(setToastErrorMessage(errorMsg, `Error when updating a Trip Day: ${JSON.stringify(error)}`));
    }
  };
};

export const moveDay = (tripId, dayId, positionFrom, positionTo, isTemplate) => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(updateTripPlanRequest());

    const tripPlan = getState().tripPlans.currentTripPlan;
    const { days } = tripPlan;
    const originalDays = deepCopy(days);
    try {
      const reorderedDays = reorder(days, positionFrom, positionTo);
      const updatedTripPlan = { ...tripPlan, days: reorderedDays };
      dispatch(updateTripPlanPayload(updatedTripPlan, i18next.t('TRIP_PLAN_DAY_UPDATE_SUCCESS')));
      await agent.TripPlansService.moveTripPlanDay(tripId, dayId, positionFrom, positionTo, isTemplate);
      return true;
    } catch (error) {
      // in case of error, restores the day events before this operation
      dispatch(updateTripPlanPayload({ ...tripPlan, days: originalDays }, ''));

      const errorMsg = `${i18next.t('TRIP_PLAN_DAY_MOVE_ERROR')} : ${error.message}`;
      dispatch(updateTripPlanFailure(error));
      dispatch(setToastErrorMessage(errorMsg, `Error when moving Trip Plan Day. Cause:  ${JSON.stringify(error)}`));
      return false;
    }
  };
};

/* On updating an event to a trip, checks whether there are previous nad next directions events.
     In this case, also updates them according to the new event coordinates */
export const updateEventInTripDayPlan = (day, tripId, dayId, queryEvent, isTemplate) => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(updateTripPlanRequest());

    try {
      const { events } = day;
      const dayEvents = [...events]; // copy of the day events array

      const { _id: tripEventId } = queryEvent;
      const eventIndex = dayEvents.findIndex(event => event._id === tripEventId);

      if (eventIndex === -1) {
        dispatch(setToastErrorMessage('Could not find event with this index'));
        return;
      }

      let previousDirectionEvent, nextDirectionEvent;
      if (eventIndex > 0) {
        previousDirectionEvent = dayEvents[eventIndex - 1];
        updateDirectionEvent(queryEvent, previousDirectionEvent, true);
        const newPreviousDirectionEvent = await agent.TripPlansService.updateTripPlanDayEvent(tripId, dayId, previousDirectionEvent, isTemplate);
        dayEvents[eventIndex - 1] = newPreviousDirectionEvent;
      }

      if (eventIndex < dayEvents.length - 1 && eventIndex >= 0) {
        nextDirectionEvent = dayEvents[eventIndex + 1];
        updateDirectionEvent(queryEvent, nextDirectionEvent, false);
        const newNextDirectionEvent = await agent.TripPlansService.updateTripPlanDayEvent(tripId, dayId, nextDirectionEvent, isTemplate);
        dayEvents[eventIndex + 1] = newNextDirectionEvent;
      }

      const newEvent = await agent.TripPlansService.updateTripPlanDayEvent(tripId, dayId, queryEvent, isTemplate);
      dayEvents[eventIndex] = newEvent;

      const updateEventSuccessMessage = i18next.t('TRIP_PLAN_DAY_UPDATE_EVENT_SUCCESS');
      dispatch(updateTripPlanDaySuccess(updateEventSuccessMessage, dayId, { ...day, events: dayEvents }));
      dispatch(setToastSuccessMessage(updateEventSuccessMessage));
      return true;
    } catch (error) {
      const errorMsg = `${i18next.t('TRIP_PLAN_DAY_ADD_NEW_EVENT_ERROR')} : ${error.message}`;
      dispatch(updateTripPlanFailure(error));
      dispatch(setToastErrorMessage(errorMsg, `Error when adding a Trip Day Event: ${JSON.stringify(error)}`));
      return false;
    }
  };
};

/* On adding a new event to a trip, checks whether there are two consecutive non-directions events.
     In this case, inserts a new Direction Event between them */
export const addEventToTripDayPlan = (day, tripId, dayId, queryEvent, isTemplate) => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(updateTripPlanRequest());

    const { events: originalEvents } = day; // keep original events for rollback
    try {
      const dayEvents = [...originalEvents]; // copy of the day events array
      const queryEvents = [];

      // direction event not added when the day is empty and the newEvent is going to be the first added
      if (dayEvents.length !== 0) {
        const lastEvent = dayEvents[dayEvents.length - 1];
        const directionEvent = await getDirectionEvent(lastEvent, queryEvent);
        queryEvents.push(directionEvent);
      }
      queryEvents.push(queryEvent);

      const addEventSuccessMessage = i18next.t('TRIP_PLAN_DAY_ADD_NEW_EVENT_SUCCESS');
      let updatedDayEvents = [...dayEvents, ...queryEvents];
      dispatch(updateTripPlanDaySuccess(addEventSuccessMessage, dayId, { ...day, events: updatedDayEvents }));
      dispatch(setToastSuccessMessage(addEventSuccessMessage));

      const updatedTripPlanDay = await agent.TripPlansService.appendEvents(tripId, dayId, isTemplate, queryEvents);
      updatedDayEvents = updatedTripPlanDay.events;
      // updates Trip Plan Day, now with the newly created events ids obtained in thes erver response
      dispatch(updateTripPlanDaySuccess(addEventSuccessMessage, dayId, { ...day, events: updatedDayEvents }));
      return true;
    } catch (error) {
      const errorMsg = `${i18next.t('TRIP_PLAN_DAY_ADD_NEW_EVENT_ERROR')} : ${error.message}`;
      dispatch(updateTripPlanFailure(error));
      // in case of error, restores the day events before this operation
      dispatch(updateTripPlanDaySuccess('', dayId, { ...day, events: originalEvents }));
      dispatch(setToastErrorMessage(errorMsg, `Error when adding a Trip Day Event: ${JSON.stringify(error)}`));
      return false;
    }
  };
};

export const updateSingleEventInTripDayPlan = (tripId, dayId, queryEvent, isTemplate) => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(updateTripPlanRequest());

    try {
      if (!queryEvent || !queryEvent._id) {
        throw new Error('Event to be updated should have an id');
      }
      const savedEvent = await agent.TripPlansService.updateTripPlanDayEvent(tripId, dayId, queryEvent, isTemplate);

      const tripPlan = getState().tripPlans.currentTripPlan;
      const updatedDays = [...tripPlan.days];
      const dayIndex = updatedDays.map(day => day._id).indexOf(dayId);
      const updatedDay = updatedDays[dayIndex];
      const eventIndex = updatedDay.events.map(event => event._id).indexOf(savedEvent._id);
      updatedDay.events[eventIndex] = savedEvent;

      const updateSuccessMessage = i18next.t(!queryEvent._id ? 'TRIP_PLAN_DAY_ADD_NEW_EVENT_SUCCESS' : 'TRIP_PLAN_DAY_UPDATE_EVENT_SUCCESS');
      dispatch(updateTripPlanDaySuccess(updateSuccessMessage, dayId, updatedDay));
      dispatch(setToastSuccessMessage(updateSuccessMessage, `Trip Event successfully saved: ${tripId}`));
      return savedEvent;
    } catch (error) {
      const errorMsg = `${i18next.t('TRIP_PLAN_DAY_ADD_NEW_EVENT_ERROR')} : ${error.message}`;
      dispatch(updateTripPlanFailure(error));
      dispatch(setToastErrorMessage(errorMsg, `Error when updating a Trip Day Event: ${JSON.stringify(error)}`));
      return null;
    }
  };
};

export const moveEvent = (tripId, dayIdFrom, positionFrom, dayIdTo, positionTo, isTemplate) => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    const originalTripPlan = getState().tripPlans.currentTripPlan;
    try {
      let updatedDays = deepCopy(originalTripPlan.days);
      moveEventFromOneDayToAnother(updatedDays, dayIdFrom, positionFrom, dayIdTo, positionTo);

      const [dayFrom] = updatedDays.filter(day => day._id === dayIdFrom);
      let updatedDayFromEvents = await applyCorrectionToTripDayEventsLocal(dayFrom.events);
      const dayFromIndex = updatedDays.findIndex(day => day._id === dayIdFrom);
      updatedDays[dayFromIndex] = { ...dayFrom, events: updatedDayFromEvents };

      let updatedDayToEvents, dayToIndex;
      if (dayIdFrom !== dayIdTo) {
        const [dayTo] = updatedDays.filter(day => day._id === dayIdTo);
        updatedDayToEvents = await applyCorrectionToTripDayEventsLocal(dayTo.events);
        dayToIndex = updatedDays.findIndex(day => day._id === dayIdTo);
        updatedDays[dayToIndex] = { ...dayTo, events: updatedDayToEvents };
      }

      let updatedTripPlan = { ...originalTripPlan, days: updatedDays };
      dispatch(updateTripPlanPayload(updatedTripPlan, 'Moved Trip Day Event Locally.'));
      dispatch(updateTripPlanRequest());

      const updatedDayFrom = await agent.TripPlansService.batchEventUpdate(tripId, dayIdFrom, isTemplate, updatedDayFromEvents);
      updatedDays[dayFromIndex] = updatedDayFrom;

      let updatedDayTo;
      if (dayIdFrom !== dayIdTo) {
        updatedDayTo = await agent.TripPlansService.batchEventUpdate(tripId, dayIdTo, isTemplate, updatedDayToEvents);
        updatedDays[dayToIndex] = updatedDayTo;
      }

      updatedTripPlan = { ...originalTripPlan, days: updatedDays };
      dispatch(updateTripPlanPayload(updatedTripPlan, 'Successfully moved Trip Day Event after server update'));
    } catch (error) {
      // in case of error, restores the day events before this operation
      dispatch(updateTripPlanPayload(originalTripPlan, 'There was an error moving a Trip Day Event'));

      const errorMsg = `${i18next.t('TRIP_PLAN_DAY_MOVE_ERROR')} : ${error.message}`;
      dispatch(updateTripPlanFailure(error));
      dispatch(setToastErrorMessage(errorMsg, `Error when moving Trip Plan Event. Cause: ${JSON.stringify(error.stack)}`));
    }
  };
};

export const applyCorrectionToTripDayEvents = (tripId, dayId, isTemplate) => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(updateTripPlanRequest());

    try {
      const updatedDay = await agent.TripPlansService.applyCorrectionToTripDayEvents(tripId, dayId, isTemplate);
      dispatch(updateTripPlanPayload(undefined, 'Successfully applied correction to Trip Plan'));
      return updatedDay;
    } catch (error) {
      const errorMsg = `${i18next.t('TRIP_PLAN_DAY_MOVE_ERROR')} : ${error.message}`;
      dispatch(updateTripPlanFailure(error));
      dispatch(setToastErrorMessage(errorMsg, `Error when applying correction to Trip Plan. Cause: ${JSON.stringify(error)}`));
      return false;
    }
  };
};

export const deleteEvent = (tripId, dayId, eventId, isTemplate) => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(updateTripPlanRequest());

    try {
      const deletionSuccessMessage = i18next.t('TRIP_PLAN_DAY_DELETE_EVENT_SUCCESS');

      const { currentTripPlan } = getState().tripPlans;
      const updatedDay = await removeEventAndApplyCorrection(currentTripPlan, dayId, eventId);
      dispatch(updateTripPlanDaySuccess(deletionSuccessMessage, dayId, updatedDay));
      dispatch(setToastSuccessMessage(deletionSuccessMessage, `Trip Day Event successfully deleted: ${tripId}`));
      const updatedDayEvents = updatedDay.events;
      const updatedDayCb = await agent.TripPlansService.batchEventUpdate(tripId, dayId, isTemplate, updatedDayEvents);

      if (updatedDayCb._id !== dayId) {
        throw new Error(`Something went wrong when updating new Day. Ids don't match: server id is ${updatedDayCb._id}  and current id is ${dayId}`);
      }

      return true;
    } catch (error) {
      const errorMsg = `${i18next.t('TRIP_PLAN_DAY_DELETE_EVENT_ERROR')} : ${error.message}`;
      dispatch(updateTripPlanFailure(error));
      dispatch(setToastErrorMessage(errorMsg, `Error when deleting a Trip Day Event: ${JSON.stringify(error)}`));
      return false;
    }
  };
};

export const saveTripPlan = (tripId, tripPlan) => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(updateTripPlanRequest());

    try {
      const updatedTripPlan = await agent.TripsService.saveTripPlan(tripId, tripPlan);
      dispatch(updateTripPlanPayload(updatedTripPlan, i18next.t('TRIP_RESUME_UPDATE_SUCCESS')));
      dispatch(setToastSuccessMessage(i18next.t('TRIP_RESUME_UPDATE_SUCCESS'), `Trip Plan successfully saved: ${tripId}`));
    } catch (error) {
      const errorMsg = `${i18next.t('TRIP_RESUME_UPDATE_ERROR_PREFIX')} : ${error.message}`;
      dispatch(updateTripPlanFailure(errorMsg));
      dispatch(setToastErrorMessage(errorMsg, `Error when updating a Trip Plan: ${JSON.stringify(error)}`));
    }
  };
};
