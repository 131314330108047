import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectTripStatusBox from './SelectTripStatusBox';

const TripListFilter = ({ onChangeFilter }) => {
  const { t } = useTranslation();

  const [status, setStatus] = useState('');

  const onStatusChange = statusType => {
    const updatedFilter = { status: statusType };
    setStatus(statusType);
    onChangeFilter(updatedFilter);
  };

  return (
    <>
      <div className="ml-4 display-inline-flex">
        <label className="font-bold">{t('TRIP_RESUME_TRIP_STATUS')}: </label>
        <SelectTripStatusBox tripStatus={status} hasDefault={true} onStatusChange={onStatusChange} required />
      </div>
    </>
  );
};

export default TripListFilter;
