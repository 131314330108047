const CONSTANTS = {
  API_BASE: '/api',
  PASSWORD_REGEX: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d$@$!%*#?&]{8,30}$/,
  PASSWORD_MINLENGTH: 8,
  PASSWORD_MAXLENGTH: 30,
  ENGLISH_LANGUAGE: 'en',
  PORTUGUESE_LANGUAGE: 'pt',
  USER_MODEL_KIND: {
    REGULAR: 'RegularUser',
    EXPERT: 'ExpertUser'
  },
  TRIP_TYPE: {
    SOLO: 'SOLO',
    COUPLE: 'COUPLE',
    FAMILY: 'FAMILY',
    FRIENDS: 'FRIENDS'
  },
  DASHBOARD_TAB_NAMES: {
    NOTIFICATIONS: 'dashboard_notifications',
    TRIP_PLANS: 'dashboard_trip-plans',
    MANAGE_TRIPS: 'manage-trips',
    MANAGE_LEADS: 'leads',
    REGISTER_EXPERT: 'register_expert',
    MESSAGES: 'dashboard_messages',
    PROFILE: 'dashboard_profile',
    TRIP_TEMPLATES: 'dashboard_trip-templates',
    USER_PAYMENTS: 'dashboard_user_payments'
  },
  TRIP_BUDGET: {
    LOW: 'LOW',
    MEDIUM: 'MEDIUM',
    HIGH: 'HIGH'
  },
  TRIP_SERVICES_CONFIG: {
    ITINERARY: {
      MODEL_KEY: 'itinerary',
      SERVICE_NAME_LABEL_KEY: 'EXPERT_CARD_SERVICE_ITINERARY',
      ICON_NAME: 'icon ive-itinerary',
      SERVICE_DESCRIPTION_LABEL_KEY: 'TRIP_REQUEST_SERVICES_ITINERARY',
      PER_DAY: true
    },
    RESTAURANTS: {
      MODEL_KEY: 'restaurants',
      SERVICE_NAME_LABEL_KEY: 'EXPERT_CARD_SERVICE_RESTAURANTS',
      ICON_NAME: 'fa fa-cutlery',
      SERVICE_DESCRIPTION_LABEL_KEY: 'TRIP_REQUEST_SERVICES_RESTAURANTS',
      PER_DAY: true
    },
    FLIGHT: {
      MODEL_KEY: 'flight',
      SERVICE_NAME_LABEL_KEY: 'EXPERT_CARD_SERVICE_TRANSPORTATION',
      ICON_NAME: 'fa fa-plane',
      SERVICE_DESCRIPTION_LABEL_KEY: 'TRIP_REQUEST_SERVICES_TRANSPORTATION',
      PER_DAY: false
    },
    ACCOMMODATION: {
      MODEL_KEY: 'accommodation',
      SERVICE_NAME_LABEL_KEY: 'EXPERT_CARD_SERVICE_ACOMMODATION',
      ICON_NAME: 'fa fa-bed',
      SERVICE_DESCRIPTION_LABEL_KEY: 'TRIP_REQUEST_SERVICES_ACOMMODATION',
      PER_DAY: false
    }
  },
  TRIP_STATUS: {
    TRIP_REQUEST: 'TRIP_REQUEST',
    TRIP_REQUEST_CANCELLED: 'TRIP_REQUEST_CANCELLED',
    TRIP_REQUEST_REJECTED: 'TRIP_REQUEST_REJECTED',
    WAITING_PAYMENT: 'WAITING_PAYMENT',
    PAYMENT_CANCELLED: 'PAYMENT_CANCELLED',
    TRIP_PROCESSING: 'TRIP_PROCESSING',
    TRIP_VALIDATED: 'TRIP_VALIDATED',
    TRIP_REVIEW: 'TRIP_REVIEW'
  },
  TRIP_STATUS_STATES: {
    TRIP_PROCESSING_WORK_IN_PROGRESS: 'TRIP_PROCESSING_WORK_IN_PROGRESS',
    TRIP_PROCESSING_WORK_DONE: 'TRIP_PROCESSING_WORK_DONE'
  },
  TRIP_EVENT_KINDS: {
    ATTRACTION: 'TripPlanAttractionEvent',
    DIRECTIONS: 'TripPlanDirectionEvent',
    ACCOMMODATION: 'TripPlanAccommodationEvent',
    ESTABLISHMENT: 'TripPlanEstablishmentEvent'
  },
  DEFAULT_DIRECTION: 'WALKING',
  DEFAULT_CURRENCY_CODE: 'EUR',
  SEARCH_PLACES_BOX_ID: 'pac-input',
  DEFAULT_MAP_DESTINATION: {
    NAME: 'United States',
    CENTER: [37.09024, -95.71289100000001],
    CODE: 'US',
    ZOOM: 3
  },
  DRAGGABLE_TYPES: {
    TRIP_EVENT: 'TRIP_EVENT',
    TRIP_DAY: 'TRIP_DAY'
  },
  USER_ROLES: {
    ALL: '*',
    ADMIN: 'ADMIN_ROLE',
    EXPERT: 'EXPERT_ROLE',
    REGULAR: 'REGULAR_ROLE'
  },
  ACCOMMODATION_TYPES: ['HOTEL', 'HOSTEL', 'APPARTMENT', 'STUDIO', 'CAMPING', 'BED_BREAKFAST', 'CHALET', 'OTHER_ACCOMMODATION'],
  ACCOMMODATION_ACTION_TYPES_OBJECT: {
    STAY: 'STAY', CHECKIN: 'CHECKIN', CHECKOUT: 'CHECKOUT'
  },
  ATTRACTION_TYPES_OBJECT: {
    MUSEUM: 'MUSEUM',
    PARK: 'PARK',
    MONUMENT: 'MONUMENT',
    RESTAURANT: 'RESTAURANT',
    NIGHT_CLUB: 'NIGHT_CLUB',
    BAR: 'BAR',
    ZOO: 'ZOO',
    OTHER: 'OTHER'
  },
  DIRECTIONS_TYPES_OBJECT: {
    AIRPLANE: 'AIRPLANE',
    BICYCLE: 'BICYCLE',
    WALKING: 'WALKING',
    CAR: 'CAR',
    UBER: 'UBER',
    TAXI: 'TAXI',
    FERRY: 'FERRY',
    SUBWAY: 'SUBWAY',
    BUS: 'BUS',
    TRAIN: 'TRAIN',
    SHUTTLE: 'SHUTTLE',
    OTHER_TRANSPORTATION: 'OTHER_TRANSPORTATION'
  },
  CHART_COLOR_SET: ['#4286f4', '#f44141', '#f4ee41', '#41f455', '#41f4e8', '#4146f4', '#6d41f4', '#f44170'],
  DESTINATION_TYPES_OBJECT: {
    CITY: "CITY",
    STATE: "STATE",
    REGION: "REGION",
    COUNTRY: "COUNTRY",
    SPECIAL: "SPECIAL",
  }
};

CONSTANTS.DIRECTIONS_TYPES = Object.values(CONSTANTS.DIRECTIONS_TYPES_OBJECT);
CONSTANTS.ATTRACTION_TYPES = Object.values(CONSTANTS.ATTRACTION_TYPES_OBJECT);
CONSTANTS.ACCOMMODATION_ACTION_TYPES = Object.values(CONSTANTS.ACCOMMODATION_ACTION_TYPES_OBJECT);
CONSTANTS.DESTINATION_TYPES = Object.values(CONSTANTS.DESTINATION_TYPES_OBJECT);

CONSTANTS.TRIP_BUDGET_ENUM = Object.values(CONSTANTS.TRIP_BUDGET);
CONSTANTS.TRIP_TYPE_ENUM = Object.values(CONSTANTS.TRIP_TYPE);
CONSTANTS.TRIP_STATUS_ENUM = Object.values(CONSTANTS.TRIP_STATUS);
CONSTANTS.TRIP_STATUS_STATE_ENUM = Object.values(CONSTANTS.TRIP_STATUS_STATES);
CONSTANTS.DASHBOARD_TAB_NAMES_ENUM = Object.values(CONSTANTS.DASHBOARD_TAB_NAMES);

CONSTANTS.GOOGLE_MAPS_TO_DIRECTIONS_MAP = {
  DRIVING: CONSTANTS.DIRECTIONS_TYPES_OBJECT.CAR, // (Default) indicates standard driving directions using the road network.
  BICYCLING: CONSTANTS.DIRECTIONS_TYPES_OBJECT.BICYCLE, //requests bicycling directions via bicycle paths & preferred streets.
  TRANSIT: CONSTANTS.DIRECTIONS_TYPES_OBJECT.BUS, // requests directions via public transit routes.
  WALKING: CONSTANTS.DIRECTIONS_TYPES_OBJECT.WALKING // requests walking directions via pedestrian paths & sidewalks.
}

CONSTANTS.DEFAULT_LANGUAGE = CONSTANTS.ENGLISH_LANGUAGE;
CONSTANTS.APP_LANGUAGES = [CONSTANTS.ENGLISH_LANGUAGE, CONSTANTS.PORTUGUESE_LANGUAGE];

CONSTANTS.LOCALE_CONFIGS = {
  [CONSTANTS.ENGLISH_LANGUAGE]: {
    dateFormat: 'MM/dd/yyyy',
    momentFormat: 'MM/DD/YYYY'
  },
  [CONSTANTS.PORTUGUESE_LANGUAGE]: {
    dateFormat: 'dd/MM/yyyy',
    momentFormat: 'DD/MM/YYYY'
  }
};

const MINUTE = 60 * 1000; // 60s = 1 minute
const HOUR = 60 * MINUTE; // 60 * 1 minute = 1 hour
const DAY = 24 * HOUR; // 24 * 1 hour = 1 day
const TIME_STEP = 15 * MINUTE;

CONSTANTS.TIME = {
  MINUTE,
  HOUR,
  DAY,
  TIME_STEP
};

export default CONSTANTS;
