import React from 'react';
import { connect } from 'react-redux';

const AlertEnvironment = ({ environment }) => {

  if (!environment) {
    return null;
  }

  const { name, url, serverUrl } = environment;

  return (
    <div className="alert alert-danger" role="alert">
      Current environment: <span className="font-weight-bold">{name}</span> - URL <span className="badge badge-danger">{url}</span> - Server <span className="badge badge-danger">{serverUrl}</span>
    </div>
  );
};

const mapStateToProps = state => ({
  environment: state.common.environment,
});

export default connect(mapStateToProps, null)(AlertEnvironment);
