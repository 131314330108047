import CONSTANTS from '../constants/constants';
import agent from '../agent';

/********************************PLACES API********************************************* */

export const getParsedAddress = address_components => {
  let address;
  let addressNumber;
  let addressRoute;

  (address_components || []).forEach(function (address_component) {
    if (address_component.types.indexOf('street_number') !== -1) {
      addressNumber = address_component.long_name;
    }
    if (address_component.types.indexOf('route') !== -1) {
      addressRoute = address_component.long_name;
    }
  });
  address = '';
  address += addressNumber ? addressNumber + ', ' : '';
  address += addressRoute ? addressRoute : '';

  return address;
};

/* Parses a place object from Google API into an obejct with the following properties:
   {
    id,
    name,
    latitude,
    longitude,
    zipCode,
    address
   };
*/
export const parsePlace = place => {
  const parsedPlace = {
    id: place.id,
    name: place.name,
    latitude: place.geometry.location.lat(),
    longitude: place.geometry.location.lng()
  };

  (place.address_components || []).forEach(function (address_component) {
    if (address_component.types.indexOf('postal_code') !== -1) {
      parsedPlace.zipCode = address_component.long_name;
    }
  });
  // parsedPlace.address = getParsedAddress(place.address_components);
  parsedPlace.address = place.formatted_address;

  return parsedPlace;
};

export const initAutocompleteSearchPlacesBox = (inputElement, maps, callback) => {
  // Create the search box and link it to the UI element.
  const searchBox = new maps.places.SearchBox(inputElement);

  // Listen for the event fired when the user selects a prediction and retrieve
  // more details for that place.
  searchBox.addListener('places_changed', function () {
    const places = searchBox.getPlaces();

    if (places.length === 0) {
      return;
    }
    if (places.length > 1) {
      console.warn('Multiple search results but only the first one is being considered.');
    }
    // only most relevant (first) match form search is considered
    const place = places[0];

    if (!place.geometry) {
      console.log('Returned place contains no geometry');
      return;
    }

    const parsedPlace = parsePlace(place);
    callback(parsedPlace);
  });
};

/******************************** END PLACES API********************************************* */

/********************************MAPS API********************************************* */

export const zoomOnDestinationSearchResult = (maps, map, destinationAddressSearchQuery) => {
  const geocoder = new maps.Geocoder();
  let latitude;
  let longitude;

  geocoder.geocode(
    {
      address: destinationAddressSearchQuery
    },
    function (results, status) {
      if (status !== 'OK') {
        console.log(`Search returned result ${status} Map could not be centered for destination ${destinationAddressSearchQuery}`);
        return;
      }

      const place = results[0];
      console.log('Search returned result ' + JSON.stringify(place.geometry.location));
      latitude = place.geometry.location.lat();
      longitude = place.geometry.location.lng();
      const mapCenter = [];
      mapCenter.push(latitude);
      mapCenter.push(longitude);
      if (place.geometry.viewport && map) {
        map.fitBounds(place.geometry.viewport);
      }
    }
  );
};

// Return map bounds based on list of places
const getMapBounds = (maps, markers) => {
  const bounds = new maps.LatLngBounds();

  for (var key in markers) {
    const pos = markers[key].getPosition();
    // console.log(`Marker Key ${key}: ${JSON.stringify(pos)}`);
    bounds.extend(pos);
  }

  return bounds;
};

// Re-center map when resizing the window
const bindResizeListener = (map, maps, bounds) => {
  maps.event.addDomListenerOnce(map, 'idle', () => {
    maps.event.addDomListener(window, 'resize', () => {
      map.fitBounds(bounds);
    });
  });
};

const setZoomForOneMarker = (markers, map) => {
  if (markers.length === 1) {
    let newZoom;
    newZoom = map.getZoom() - 8;
    newZoom = newZoom < 0 ? 0 : newZoom;
    map.setZoom(newZoom);
  }
};

export const zoomToIncludeMarkers = (maps, map, markers = []) => {
  if (markers.length === 0) {
    return;
  }
  // Get bounds by our places
  const bounds = getMapBounds(maps, markers);
  // Fit map to bounds
  map.fitBounds(bounds);
  // Bind the resize listener
  bindResizeListener(map, maps, bounds);

  // special zoom if only one marker
  setZoomForOneMarker(markers, map);
};
/********************************ENDS MAPS API********************************************* */

/********************************DIRECTIONS API********************************************* */
const DIRECTIONS_RENDERER_PROPS = {
  suppressMarkers: true,
  draggable: false
};

export const getNewDirectionsDisplay = maps => {
  return new maps.DirectionsRenderer(DIRECTIONS_RENDERER_PROPS);
};

export const clearAllDirections = directionsDisplay => {
  if (!(directionsDisplay && directionsDisplay.setDirections)) {
    return;
  }
  directionsDisplay.setDirections({ routes: [] });
};

export const updateDirectionsDisplay = (map, maps, directionsDisplay) => {
  // initializes directions display if it does not exist
  if (directionsDisplay) {
    directionsDisplay.setMap(null); // cleans current directions overlay if it exists
  }

  const newDirectionsDisplay = getNewDirectionsDisplay(maps);
  newDirectionsDisplay.setMap(map);
  return newDirectionsDisplay;
};

/* Dispatch data to service so it can calculate and render route on map*/
export const calculateRoute = (directionsService, directionsDisplay, origin, destination, waypoints, travelMode) => {
  directionsService.route(
    {
      origin,
      destination,
      waypoints,
      travelMode
    },
    function (response, status) {
      if (status === 'OK') {
        directionsDisplay.setDirections(response);
      } else {
        console.error('Directions request failed due to ' + status);
      }
    }
  );
};

/* Guidelines to Follow: https://developers.google.com/maps/documentation/directions/overview and https://developers.google.com/maps/documentation/javascript/directions */
export const calculateDirectionRoute = async (origin, destination) => {
  let directions;
  try {
    //origin=41.43206,-81.38992
    const { latitude: origLat, longitude: origLon } = origin;
    const { latitude: destLat, longitude: destLon } = destination;
    const originQuery = `${origLat},${origLon}`;
    const destinationQuery = `${destLat},${destLon}`;
    directions = await agent.MapsService.getDirections(originQuery, destinationQuery);
  } catch (error) {
    console.log(`${JSON.stringify(error, null, 2)}`);
    directions = null;
  }

  return directions;
};

export const calculateGoogleDirectionsServiceRoute = async (directionsService, origin, destination, travelMode) => {
  return new Promise((resolve, reject) => {
    directionsService.route(
      {
        origin,
        destination,
        travelMode
      },
      function (response, status) {
        if (status === 'OK') {
          return resolve(response);
        }
        console.error('Directions request failed due to ' + status);
        reject(status);
      }
    );
  });
};

/* Initialize Directions points*/
export const initializeWayPoints = (maps, directionsDisplay, positions) => {
  const directionsService = new maps.DirectionsService();

  const mappedPositions = positions.map(position => {
    return {
      lat: position.latitude,
      lng: position.longitude
    };
  });

  const destination = mappedPositions.pop();
  const [origin, ...middlePositions] = mappedPositions;

  const wayPoints = middlePositions.map(position => ({
    location: position
  }));

  calculateRoute(directionsService, directionsDisplay, origin, destination, wayPoints, CONSTANTS.DEFAULT_DIRECTION);
};
/********************************END DIRECTIONS API********************************************* */
