import React from 'react';
import { useTranslation } from 'react-i18next';
import CONSTANTS from '../constants/constants';
import IconAttractionSwitcher from './IconAttractionSwitcher';
import IconTransportSwitcher from './IconTransportSwitcher';
import IconAccommodationSwitcher from './IconAccommodationSwitcher';
import { CustomTooltip } from './Custom';

const TripEventTitle = ({ event }) => {
  const { t } = useTranslation();

  switch (event.kind) {
    case CONSTANTS.TRIP_EVENT_KINDS.ATTRACTION:
      return (
        <>
          <IconAttractionSwitcher iconKey={event.eventType} icon={true} tooltip={true} text={false}></IconAttractionSwitcher> {event.name || ''}
        </>
      );
    case CONSTANTS.TRIP_EVENT_KINDS.DIRECTIONS:
      return (
        <>
          {t('TOOL_DAY_VIEW_DIRECTIONS')} - <IconTransportSwitcher iconKey={event.meansOfTransportation} icon={true} tooltip={true} text={true} />
        </>
      );
    case CONSTANTS.TRIP_EVENT_KINDS.ACCOMMODATION:
      return (
        <>
          <IconAccommodationSwitcher iconKey={event.accommodationType} icon={true} tooltip={true} text={true} /> - {event.name || ''}
        </>
      );
    case CONSTANTS.TRIP_EVENT_KINDS.ESTABLISHMENT:
      return (
        <>
          <CustomTooltip text="ESTABLISHMENT" isKey={true}>
            <i className="fa fa-map-marker" title={t('ESTABLISHMENT')} aria-hidden="true"></i>
          </CustomTooltip>
          {event.establishmentType || '-'} - {event.name || '-'}
        </>
      );

    default:
      return <>-</>;
  }
};

export default TripEventTitle;
