import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import NavigationService from '../services/navigation';
import { getTripTemplate, deleteTripTemplate } from '../actions/tripTemplates';
import InProgress from '../components/InProgress';
import TripCanvasBoard from '../containers/TripCanvasBoard';

const TripTemplateViewPage = ({ currentTripTemplate, deleteTripTemplate, getTripTemplate, inProgress }) => {
  const { t } = useTranslation();
  let { tripTemplateId } = useParams();

  // Wrap with useCallback to avoid change on every render. It returns a memoized version of the callback that only changes if one of the provided dependencies change.
  const fetchTripTemplate = useCallback(async () => {
    await getTripTemplate(tripTemplateId);
  }, [tripTemplateId, getTripTemplate]);

  useEffect(() => {
    fetchTripTemplate();
  }, [fetchTripTemplate]);

  const goToTripTemplateList = event => {
    event.stopPropagation();
    NavigationService.goToTripTemplateList();
  };

  const goToTripTemplateEdition = tripTemplateId => event => {
    event.stopPropagation();
    NavigationService.goToTripTemplateEdition(tripTemplateId);
  };

  const onDeleteTripTemplate = tripTemplateId => event => {
    event.stopPropagation();
    const confirmWindow = window.confirm(t('TRIP_TEMPLATE_CONFIRM_DELETION'));
    if (confirmWindow === false) {
      return;
    }
    deleteTripTemplate(tripTemplateId);
    NavigationService.goToTripTemplateList(tripTemplateId);
  };

  const renderTripPlan = (isExpertUser, tripTemplate) => {
    if (!tripTemplate) {
      return;
    }

    return <TripCanvasBoard trip={tripTemplate} isExpertUser={isExpertUser} view={true} isTripTemplate={true} />;
  };

  const tripTemplateDaysLength = currentTripTemplate.tripPlan && currentTripTemplate.tripPlan.days ? currentTripTemplate.tripPlan.days.length : null;

  return (
    <div className="container special padding-10 trip-plan-edit-container">
      <div className="board-wrapper">
        <div className="board-main-content">
          <div className="row padding-10 trip-canvas-top">
            <h2 className="col-6">
              {currentTripTemplate.name || 'Trip Template'} : {tripTemplateDaysLength || '-'} {t('DASHBOARD_TEMPLATE_DAYS')}
            </h2>
            <div className="col-6">
              <button type="button" className="btn btn-primary mr-2" onClick={goToTripTemplateEdition(tripTemplateId)}>
                {t('BUTTON_EDIT')}
              </button>
              <button type="button" className="btn btn-primary mr-2" onClick={onDeleteTripTemplate(tripTemplateId)}>
                {t('BUTTON_DELETE_TEMPLATE')}
              </button>
              <button type="button" className="btn btn-primary" onClick={goToTripTemplateList}>
                {t('BUTTON_BACK')}
              </button>
              <InProgress inProgress={inProgress} />
            </div>
          </div>
          <div className="trip-canvas-wrapper">
            {inProgress ? (
              <div className="load-overlay show">
                <div className="text">
                  <i className="fa fa-spinner fa-spin spin-size"></i>
                </div>
              </div>
            ) : (
              <div className="trip-canvas view width-100">{renderTripPlan(true, currentTripTemplate)}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  currentTripTemplate: state.tripTemplates.currentTripTemplate,
  inProgress: state.tripTemplates.inProgress
});

const mapDispatchToProps = dispatch => ({
  getTripTemplate: tripTemplateId => dispatch(getTripTemplate(tripTemplateId, true)),
  deleteTripTemplate: tripTemplateId => dispatch(deleteTripTemplate(tripTemplateId))
});

export default connect(mapStateToProps, mapDispatchToProps)(TripTemplateViewPage);
