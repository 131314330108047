import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { updateTripRequestField } from '../actions/common';
import ExpertCard from '../components/ExpertCard';
import AlertMessage from '../components/AlertMessage';
import InProgress from '../components/InProgress';
import TripInfo from '../containers/TripInfo';
import { formatCurrency } from '../utils/currencyUtils';
import { useTranslation } from 'react-i18next';
import NavigationService from '../services/navigation';
import { validateEmail } from '../utils/common';
import { validateTripRequestFormParams, calculateFullPriceInCents } from '../utils/tripUtils';
import { postTripRequest, postTripRequestFailure } from '../actions/tripRequest';
import { getUserFullName } from '../utils/userUtils';

const TripRequestConfirmationNotAuthenticatedFields = ({ tripRequest, currentUser, isFormSubmitted, onChangeTripRequestField }) => {
  const { t } = useTranslation();

  const changeName = ev => {
    const notAuthenticatedUser = {
      name: ev.target.value,
      email: tripRequest.notAuthenticatedUser.email
    };
    onChangeTripRequestField('notAuthenticatedUser', notAuthenticatedUser);
  };
  const changeEmail = ev => {
    const notAuthenticatedUser = {
      name: tripRequest.notAuthenticatedUser.name,
      email: ev.target.value
    };
    onChangeTripRequestField('notAuthenticatedUser', notAuthenticatedUser);
  };

  if (currentUser) {
    return null;
  }

  return (
    <div className="row">
      <fieldset className="form-group col-12 col-lg-9 no-padding">
        <div className="row">
          <div className={`form-group col-12 col-md-6`}>
            <label htmlFor="name">{t('TRIP_REQUEST_NAME')}:</label>
            <input
              type="text"
              className={`form-control ${isFormSubmitted && !tripRequest.notAuthenticatedUser.name ? 'is-invalid' : ''}`}
              value={tripRequest.notAuthenticatedUser.name}
              onChange={changeName}
              required
            ></input>
            <div className={isFormSubmitted ? 'invalid-feedback' : 'd-none'}>
              <div className={tripRequest.notAuthenticatedUser.name ? 'd-none' : ''}>
                {t('TRIP_REQUEST_NOT_AUTHENTICATED_VALIDATION_MISSING_USERNAME')}
              </div>
            </div>
          </div>
          <div className={`form-group col-12 col-md-6`}>
            <label htmlFor="uEmail">E-mail:</label>
            <input
              type="email"
              className={`form-control ${isFormSubmitted && !validateEmail(tripRequest.notAuthenticatedUser.email) ? 'is-invalid' : ''}`}
              value={tripRequest.notAuthenticatedUser.email}
              onChange={changeEmail}
              required
            ></input>
            <div className={isFormSubmitted ? 'invalid-feedback' : 'd-none'}>
              <span className={validateEmail(tripRequest.notAuthenticatedUser.email) ? 'd-none' : ''}>
                {t('TRIP_REQUEST_NOT_AUTHENTICATED_VALIDATION_MISSING_EMAIL')} {t('HEADER_MENU_OR')}{' '}
                {t('TRIP_REQUEST_NOT_AUTHENTICATED_VALIDATION_INVALID_EMAIL')}
              </span>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

const TripRequestConfirmationPage = ({
  tripRequest,
  currentUser,
  onChangeTripRequestField,
  onPostTripRequest,
  onPostTripRequestFailure,
  message,
  inProgress,
  error
}) => {
  const { t } = useTranslation();

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useEffect(() => {
    // Before seeing this view, the user must select an Expert for a Trip Request
    if (!tripRequest.expert) {
      NavigationService.goToTripRequestPickExpert();
      return;
    }
  }, [tripRequest.expert]);

  useEffect(() => {
    const fullTripPrice = calculateFullPriceInCents(tripRequest.numberOfDays, tripRequest.expert, tripRequest.services, tripRequest.destinations);
    onChangeTripRequestField('fullPrice', fullTripPrice);
  }, [onChangeTripRequestField, tripRequest.numberOfDays, tripRequest.expert, tripRequest.services, tripRequest.destinations]);

  const goToPickExpertStep = () => {
    NavigationService.goToTripRequestPickExpert();
  };

  const expertDisplayName = getUserFullName(tripRequest.expert, '-');

  const changeMessage = ev => {
    onChangeTripRequestField('message', ev.target.value);
  };

  const sendTripRequest = ev => {
    ev.preventDefault();
    setIsFormSubmitted(true);

    // validates not authenticated user params first
    const defaultErrorMessage = 'TRIP_REQUEST_FORM_SUBMISSION_VALIDATION_ERROR';
    const { notAuthenticatedUser } = tripRequest;
    if (!currentUser && !(notAuthenticatedUser.name && validateEmail(notAuthenticatedUser.email))) {
      onPostTripRequestFailure(defaultErrorMessage);
      return;
    }

    const errorMessage = validateTripRequestFormParams(tripRequest);

    if (errorMessage) {
      const composedErrorMessage = `${t(defaultErrorMessage)} : ${t(errorMessage)}`;
      onPostTripRequestFailure(composedErrorMessage);
      return;
    }

    onPostTripRequest(!!currentUser, tripRequest);
  };

  return (
    <div className="container">
      <form name="tripRequestForm" noValidate>
        <div className="row">
          <div className="col-12 col-lg-8">
            <TripInfo tripRequest={tripRequest} isConfirmation={true}></TripInfo>
          </div>
          {/*<!--Selected Expert Card --> */}
          <div className="col-12 col-lg-4">
            {tripRequest.expert ? (
              <ExpertCard expert={tripRequest.expert} services={tripRequest.services}>
                <div className="row font-bold">
                  <p className="col-7 pl-3">TOTAL</p>
                  <p className="col-5">{formatCurrency(tripRequest.expert.servicesCurrencyCode, tripRequest.fullPrice)}</p>
                </div>
              </ExpertCard>
            ) : null}
          </div>
        </div>
        <TripRequestConfirmationNotAuthenticatedFields
          currentUser={currentUser}
          tripRequest={tripRequest}
          isFormSubmitted={isFormSubmitted}
          onChangeTripRequestField={onChangeTripRequestField}
        />
        <div className="row">
          <fieldset className="form-group col-xs-12 col-md-9">
            <label htmlFor="comment">
              {t('TRIP_REQUEST_MESSAGE')} {expertDisplayName}:
            </label>
            <textarea className="form-control" rows="5" name="comment" value={tripRequest.message} onChange={changeMessage}></textarea>
          </fieldset>
        </div>

        <div className="row trip-request-bottom">
          <div className="col-xs-12 col-md-9">
            <InProgress inProgress={inProgress} />
            <button type="submit" className="btn btn-primary float-right" onClick={sendTripRequest}>
              {t('BUTTON_SEND_REQUEST')}
            </button>
            <button type="button" className="btn btn-primary float-right mr-1" onClick={goToPickExpertStep}>
              {t('BUTTON_BACK')}
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-md-9">
            <AlertMessage messageType="success" message={message} />
            <AlertMessage messageType="danger" message={error ? error.message : null} />
          </div>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  tripRequest: state.common.tripRequest,
  message: state.tripRequest.message,
  inProgress: state.tripRequest.inProgress,
  error: state.tripRequest.error
});

const mapDispatchToProps = dispatch => ({
  onChangeTripRequestField: (field, value) => dispatch(updateTripRequestField(field, value)),
  onPostTripRequest: (isUserAuthenticated, tripRequest) => dispatch(postTripRequest(isUserAuthenticated, tripRequest)),
  onPostTripRequestFailure: errMessage => dispatch(postTripRequestFailure({ message: errMessage }))
});

export default connect(mapStateToProps, mapDispatchToProps)(TripRequestConfirmationPage);
