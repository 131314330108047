import React from 'react';
import { connect } from 'react-redux';
import ConversationsList from '../containers/ConversationsList';
import ConversationMessagesContainer from '../containers/ConversationMessagesContainer';
import { isExpert } from '../utils/userUtils';

const MessagesPage = ({ currentUser }) => {
  const isExpertUser = isExpert(currentUser);

  return (
    <div className="container padding-10">
      <div className="row">
        <div className="col-md-4 fadein-container">
          <ConversationsList isExpertUser={isExpertUser} />
        </div>
        <div className="col-md-8">
          <ConversationMessagesContainer isExpertUser={isExpertUser} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  currentUser: state.common.currentUser
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MessagesPage);
