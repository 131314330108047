export default {
  clear: () => {
    window.localStorage.clear();
  },
  getToken: () => {
    const token = window.localStorage.getItem('jwt');
    return token;
  },
  getExpirationTime: () => {
    const expirationTime = window.localStorage.getItem('expirationTime');
    return expirationTime;
  },
  getCurrentUser: () => {
    try {
      const userStr = window.localStorage.getItem('user');
      return JSON.parse(userStr);
    } catch (error) {
      console.log(`Error when parsing existing local storage user info: ${error.message}`);
      window.localStorage.setItem('user', null);
      return null;
    }
  },
  getCurrentEnvironment: () => {
    try {
      const environmentStr = window.localStorage.getItem('environment');
      return JSON.parse(environmentStr);
    } catch (error) {
      console.error(`Error when parsing existing local storage environment info: ${error.message}`);
      window.localStorage.setItem('environment', null);
      return null;
    }
  },
  isSessionExpired: function () {
    const expirationTime = this.getExpirationTime();
    const now = new Date().getTime();
    const remainingTimeSession = expirationTime - now;
    if (remainingTimeSession <= 0) {
      return true;
    }
    return false;
  },
  setAll: function (token, user, expirationTime) {
    this.setToken(token);
    this.setCurrentUser(user);
    this.setExpirationTime(expirationTime);
  },
  setToken: token => {
    window.localStorage.setItem('jwt', token);
  },
  setCurrentUser: user => {
    const userStr = JSON.stringify(user);
    window.localStorage.setItem('user', userStr);
  },
  setExpirationTime: expirationTime => {
    window.localStorage.setItem('expirationTime', expirationTime);
  },
  setEnvironment: environment => {
    const environmentStr = JSON.stringify(environment);
    window.localStorage.setItem('environment', environmentStr);
  },
};
