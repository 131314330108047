import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import TripDayPlanner from '../containers/TripDayPlanner';
import { getTripTemplate } from '../actions/tripTemplates';
import { getTripResume } from '../actions/trips';
import { updateTripPlanCurrentDayId, updateTripPlanCurrentEventId } from '../actions/tripPlans';

const TripDayViewPage = ({ tripResume, tripTemplate, getTripResume, getTripTemplate, updateTripPlanCurrentDayId, updateTripPlanCurrentEventId }) => {
  let { tripId } = useParams();
  let { tripTemplateId } = useParams();
  let { dayId } = useParams();
  let { eventId } = useParams();
  const isTripTemplate = !!tripTemplateId;

  useEffect(() => {
    updateTripPlanCurrentDayId(dayId);
  }, [dayId, updateTripPlanCurrentDayId]);

  useEffect(() => {
    updateTripPlanCurrentEventId(eventId);
  }, [eventId, updateTripPlanCurrentEventId]);

  const onLoad = async (trip, fetchTripFn) => {
    if (trip && trip.tripPlan && trip.tripPlan.days) {
      return trip;
    }

    await fetchTripFn();
  };

  useEffect(() => {
    const fetchTripTemplate = async () => {
      await getTripTemplate(tripTemplateId);
    };

    if (isTripTemplate) {
      onLoad(tripTemplate, fetchTripTemplate);
    }
  }, [tripTemplate, tripTemplateId, isTripTemplate, getTripTemplate]);

  useEffect(() => {
    const fetchTripResume = async () => {
      await getTripResume(tripId);
    };

    if (!isTripTemplate) {
      onLoad(tripResume, fetchTripResume);
    }
  }, [tripResume, tripId, isTripTemplate, getTripResume]);

  const trip = isTripTemplate ? tripTemplate : tripResume;

  return (
    <div id="day-view-container" className="container special padding-10">
      <TripDayPlanner trip={trip} dayId={dayId} eventId={eventId} isTripTemplate={isTripTemplate} />
    </div>
  );
};

const mapStateToProps = state => ({
  tripTemplate: state.tripTemplates.currentTripTemplate,
  tripResume: state.trips.currentTripResume
});

const mapDispatchToProps = dispatch => ({
  getTripTemplate: tripTemplateId => dispatch(getTripTemplate(tripTemplateId, true)),
  getTripResume: tripResumeId => dispatch(getTripResume(tripResumeId)),
  updateTripPlanCurrentDayId: dayId => dispatch(updateTripPlanCurrentDayId(dayId)),
  updateTripPlanCurrentEventId: eventId => dispatch(updateTripPlanCurrentEventId(eventId))
});

export default connect(mapStateToProps, mapDispatchToProps)(TripDayViewPage);
