import React from 'react';
import { useTranslation } from 'react-i18next';
import TripInfoButtons from '../components/TripInfoButtons';
import { connect } from 'react-redux';
import { updateTripRequestField } from '../actions/common';
import ServicesCheckboxes from '../components/ServicesCheckboxes';
import { calculateFullPriceInCents, isSomeServiceChosen } from '../utils/tripUtils';
// import CustomDatePicker from '../components/CustomDatePicker';
import AutocompleteDestinationsInput from '../components/AutocompleteDestinationsInput';
import CONSTANTS from '../constants/constants';

const TripInfo = ({ tripRequest, isConfirmation, onChangeTripRequestField }) => {
  const { t } = useTranslation();

  const onChangeServices = (tripRequestServices = {}) => {
    // Business rule: if no service is selected, selects basic itinerary service, because at least one service must be selected
    const noServiceEnabled = !isSomeServiceChosen(tripRequestServices);
    if (noServiceEnabled) {
      tripRequestServices[CONSTANTS.TRIP_SERVICES_CONFIG.ITINERARY.MODEL_KEY] = true;
    }

    onChangeTripRequestField('services', tripRequestServices);

    const fullTripPrice = calculateFullPriceInCents(tripRequest.numberOfDays, tripRequest.expert, tripRequestServices, tripRequest.destinations);
    onChangeTripRequestField('fullPrice', fullTripPrice);
  };

  const changeNumberOfDays = ev => {
    const numberOfDays = ev.target.valueAsNumber;
    onChangeTripRequestField('numberOfDays', numberOfDays);

    const fullTripPrice = calculateFullPriceInCents(numberOfDays, tripRequest.expert, tripRequest.services, tripRequest.destinations);
    onChangeTripRequestField('fullPrice', fullTripPrice);
  };

  /*const onChangeStartDate = date => {
    onChangeTripRequestField('startDate', date);
  };*/
  const changeStartDate = ev => {
    onChangeTripRequestField('startDate', ev.target.value);
  };

  const onChangeDestination = destinations => {
    onChangeTripRequestField('destinations', destinations);
  };

  return (
    <>
      <div className="pt-2" />
      <div className="row trip-request-title">
        <h4>
          <i className="fa fa-plane"></i>
          {isConfirmation ? t('TRIP_REQUEST_CONFIRMATION_TITLE') : t('TRIP_INFO_TITLE')}
        </h4>
      </div>
      <br />
      <div className="row">
        <fieldset className="form-group col-12">
          <div className="row">
            <div className="col-lg-6">{t('TRIP_INFO_DESTINATION')}</div>
            <div className="col-lg-6">
              <AutocompleteDestinationsInput maxItems={6} values={tripRequest.destinations} onChangeValues={onChangeDestination} />
            </div>
          </div>
        </fieldset>
      </div>

      <div className="row">
        <fieldset className="form-group col-12">
          <div className="row">
            <div className="col-lg-6">{t('TRIP_INFO_START_DATE')}</div>
            <div className="col-lg-6">
              <input
                type="date"
                className="form-control"
                value={tripRequest.startDate}
                min={Date.now()}
                max={new Date(2030, 12, 31)}
                onChange={changeStartDate}
              />
            </div>
          </div>
        </fieldset>
      </div>
      {/*<div className="row d-none">
        <fieldset className="form-group col-12">
          <div className="row">
            <div className="col-lg-6">{t('TRIP_INFO_START_DATE')}</div>
            <div className="col-lg-6">
              <CustomDatePicker date={tripRequest.startDate} changeDate={onChangeStartDate} />
            </div>
          </div>
        </fieldset>
      </div>*/}

      <div className="row">
        <fieldset className="form-group col-12">
          <div className="row">
            <div className="col-lg-6">{t('TRIP_INFO_TRIP_DAYS')}</div>
            <div className="col-lg-6">
              <input type="number" id="daysNumber" className="form-control" value={tripRequest.numberOfDays} onChange={changeNumberOfDays} />
            </div>
          </div>
        </fieldset>
      </div>

      <div className="row trip-request-title">
        <h4>
          <i className="fa fa-sliders"></i>
          {t('TRIP_INFO_SERVICES')}
        </h4>
      </div>
      <div className="row">
        <div className="col-12 services-check-font">
          <ServicesCheckboxes tripRequestServices={tripRequest.services} onChangeServices={onChangeServices} />
        </div>
      </div>

      <div className="row trip-request-title">
        <h4>
          <i className="fa fa-vcard-o"></i>
          {t('TRIP_INFO_TRIP_PROFILE')}
        </h4>
      </div>

      <br />
      <TripInfoButtons
        objInfo={tripRequest}
        isConfirmation={isConfirmation}
        preferencesKey="preferences"
        onChangeTripInfoField={onChangeTripRequestField}
      />
    </>
  );
};

const mapStateToProps = state => ({
  tripRequest: state.common.tripRequest
});

const mapDispatchToProps = dispatch => ({
  onChangeTripRequestField: (field, value) => dispatch(updateTripRequestField(field, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(TripInfo);
