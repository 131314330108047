import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import NavigationService from '../services/navigation';
import { getTripTemplate, saveTripTemplate, updateCurrentTripTemplateField, putTripTemplateSuccess } from '../actions/tripTemplates';
import { addDay, updateTripPlanPayload } from '../actions/tripPlans';
import InProgress from '../components/InProgress';
import TripCanvasBoard from '../containers/TripCanvasBoard';

const TripTemplateEditPage = ({
  currentTripTemplate,
  saveTripTemplate,
  updateCurrentTripTemplateField,
  getTripTemplate,
  inProgress,
  inProgressTripPlan,
  addDay
}) => {
  const { t } = useTranslation();
  let { tripTemplateId } = useParams();

  // Wrap with useCallback to avoid change on every render. It returns a memoized version of the callback that only changes if one of the provided dependencies change.
  const fetchTripTemplate = useCallback(async () => {
    await getTripTemplate(tripTemplateId);
  }, [tripTemplateId, getTripTemplate]);

  useEffect(() => {
    fetchTripTemplate();
  }, [fetchTripTemplate]);

  const goToTripTemplateView = event => {
    event.stopPropagation();
    NavigationService.goToTripTemplateView(tripTemplateId);
  };

  const onSaveTripTemplate = event => {
    event.stopPropagation();
    saveTripTemplate(currentTripTemplate);
  };

  const onFieldUpdate = event => {
    const name = event.target.value;
    updateCurrentTripTemplateField('name', name);
  };

  const onAddDay = async () => {
    await addDay(tripTemplateId, true); // action should display error message if any
  };

  const renderTripPlan = tripTemplate => {
    if (!tripTemplate) {
      return;
    }
    return <TripCanvasBoard trip={tripTemplate} view={false} isExpertUser={true} isTripTemplate={true} />;
  };

  return (
    <div className="container special padding-10 trip-plan-edit-container">
      <div className="trip-template-name">
        <div className="form-group">
          <label forhtml="templatename" className="font-weight-bold mr-2">
            {t('TRIP_PLAN_EDIT_NAME')}
          </label>
          <input
            type="text"
            className="form-control mr-2"
            placeholder={t('TRIP_PLAN_EDIT_NAME_PLACEHOLDER')}
            name="templatename"
            onChange={onFieldUpdate}
            value={currentTripTemplate.name}
            style={{ width: '50%', display: 'inline-block' }}
          />
          <button type="button" className="btn btn-primary mr-2" onClick={onSaveTripTemplate}>
            {t('BUTTON_SAVE')}
          </button>
          <button type="button" className="btn btn-primary mr-2" onClick={onAddDay}>
            {t('BUTTON_NEW_DAY')}
          </button>
          <button type="button" className="btn btn-primary" onClick={goToTripTemplateView}>
            {t('BUTTON_VIEW_TRIP')}
          </button>
          <InProgress inProgress={inProgress} />
        </div>
      </div>
      <div className="trip-canvas-wrapper">
        {inProgressTripPlan ? (
          <div className="load-overlay show">
            <div className="text">
              <i className="fa fa-spinner fa-spin spin-size"></i>
            </div>
          </div>
        ) : (
          <div className="trip-canvas width-100">{renderTripPlan(currentTripTemplate)}</div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  currentTripTemplate: state.tripTemplates.currentTripTemplate,
  inProgress: state.tripTemplates.inProgress,
  tripPlan: state.tripPlans.currentTripPlan,
  inProgressTripPlan: state.tripTemplates.inProgress
});

const mapDispatchToProps = dispatch => ({
  getTripTemplate: tripTemplateId => dispatch(getTripTemplate(tripTemplateId, true)),
  saveTripTemplate: tripTemplate => dispatch(saveTripTemplate(tripTemplate._id, tripTemplate)),
  updateCurrentTripTemplateField: (name, value) => dispatch(updateCurrentTripTemplateField(name, value)),
  putTripTemplateSuccess: (message, updatedTripTemplate) => dispatch(putTripTemplateSuccess(message, updatedTripTemplate)),
  addDay: (tripId, isTemplate) => dispatch(addDay(tripId, isTemplate)),
  updateTripPlanPayload: tripPlan => dispatch(updateTripPlanPayload(tripPlan))
});

export default connect(mapStateToProps, mapDispatchToProps)(TripTemplateEditPage);
