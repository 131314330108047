import LocalStorage from "../LocalStorage";

const common = {
  goToHash: name => {
    window.location.hash(name);
    console.log(name);
  }
};

export const generateTimestampId = () => new Date().getTime();

export const isPositiveInteger = number => {
  if (typeof number !== 'number') {
    return false;
  }
  return parseInt(number, 10) === number && number > 0;
};

export const deepCopy = obj => {
  const objCopy = JSON.parse(JSON.stringify(obj)); // deep copy of object
  return objCopy;
};

export const parseObject = function (obj) {
  try {
    return JSON.parse(obj);
  } catch (e) {
    return;
  }
}

export const isEmptyObject = (obj = {}) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getTripStatusTranslationString = tripStatus => {
  return tripStatus ? `STATUS_${tripStatus}` : '';
};

export const reloadPage = () => {
  window.location.reload();
};

export const updateHash = hash => {
  window.location.hash = hash;
};

export const openInNewWindow = url => {
  const params = []
  const token = LocalStorage.getToken();
  params.push(`token=${token}`); //jwt token
  const expirationTime = LocalStorage.getExpirationTime();
  params.push(`expirationTime=${expirationTime}`);
  const user = LocalStorage.getCurrentUser();
  params.push(`user=${JSON.stringify(user)}`);
  const allParams = params.join('&');

  const win = window.open(`${url}?${allParams}`, '_blank');
  win.focus();
};

export const copyArrayItems = (array, newArray) => {
  const { length: arrayLength } = array;
  const { length: newArrayLength } = newArray;

  Array.prototype.splice.apply(array, [0, newArrayLength].concat(newArray));
  if (arrayLength > newArrayLength) {
    array.splice(newArrayLength, arrayLength - newArrayLength);
  }
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed); // in this case, splice call returns a list with one object

  return result;
};

export const transferArrayElement = (arrSource, arrTarget, fromIndex, toIndex) => {
  const movedElement = arrSource.splice(fromIndex, 1)[0];
  arrTarget.splice(toIndex, 0, movedElement); // in this case, splice call returns a list with one object
};

export default common;
