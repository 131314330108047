import React, { useState, useEffect } from 'react';
import ShowError from '../components/ShowError';
import InProgress from '../components/InProgress';
import { connect } from 'react-redux';
import { updateCurrentLocationField, deleteLocation, createLocation, saveLocation } from '../actions/locations';
import { useTranslation } from 'react-i18next';
import AutocompleteDestinationsInput from '../components/AutocompleteDestinationsInput';
import CONSTANTS from '../constants/constants';
import SelectLocationBox from '../components/SelectLocationBox';
import SelectAccommodationBox from '../components/SelectAccommodationBox';
import { isEmptyObject } from '../utils/common';
import { Button } from '../components/Custom';
import { setToastErrorMessage } from '../actions/common';
import FileUploader from '../components/FileUploader';

const LocationForm = ({
  currentLocation,
  error,
  inProgress,
  updateCurrentLocationField,
  deleteLocation,
  createLocation,
  saveLocation,
  isDuplicateLocation,
  setToastErrorMessage
}) => {
  const { t } = useTranslation();
  const [destinationSearchObject, setDestinationSearchObject] = useState([]);

  useEffect(() => {
    const destinationSearchObject = [];
    if (currentLocation.destinationCode && currentLocation.destinationName) {
      const destinationSearch = {
        code: currentLocation.destinationCode,
        name: currentLocation.destinationName
      };
      destinationSearchObject.push(destinationSearch);
    }
    setDestinationSearchObject(destinationSearchObject);
  }, [currentLocation]);

  const onChangeDestination = (destinationSearchObject = []) => {
    const destinationSearch = destinationSearchObject[0] || {};
    setDestinationSearchObject(destinationSearchObject);
    updateCurrentLocationField('destinationCode', destinationSearch.code); // resets destinationCode
    updateCurrentLocationField('destinationName', destinationSearch.name); // resets destinationName
  };

  const getLocationKind = location => {
    if (isEmptyObject(location)) {
      return CONSTANTS.TRIP_EVENT_KINDS.ATTRACTION;
    }
    if (CONSTANTS.ACCOMMODATION_TYPES.indexOf(location.locationType) !== -1) {
      return CONSTANTS.TRIP_EVENT_KINDS.ACCOMMODATION;
    } else if (CONSTANTS.ATTRACTION_TYPES.indexOf(location.locationType) !== -1) {
      return CONSTANTS.TRIP_EVENT_KINDS.ATTRACTION;
    } else {
      return CONSTANTS.TRIP_EVENT_KINDS.ESTABLISHMENT; // establishment event type can be any string
    }
  };

  const initialLocationKind = getLocationKind(currentLocation);
  const [locationKind, setLocationKind] = useState(initialLocationKind);

  const onSaveLocation = (locationId, location) => ev => {
    ev.preventDefault();
    if (!validateLocationPayload(location)) {
      return;
    }
    saveLocation(locationId, location);
  };
  const onDeleteLocation = locationId => ev => {
    ev.preventDefault();
    deleteLocation(locationId);
  };
  const onCreateLocation = location => ev => {
    ev.preventDefault();
    if (!validateLocationPayload(location)) {
      return;
    }
    createLocation(location);
  };
  const updateField = field => ev => {
    ev.preventDefault();
    const { value } = ev.target;
    updateCurrentLocationField(field, value);
  };

  const onSelectUpdate = field => value => {
    updateCurrentLocationField(field, value);
  };

  const onChangeLocationKind = ev => {
    const { value } = ev.target;
    setLocationKind(value);
    updateCurrentLocationField('locationType', ''); // resets locationType
  };

  const deleteImage = ev => {
    ev.preventDefault();
    updateCurrentLocationField('imageUrl', '');
  };

  const onSaveLocationImage = imageUrl => {
    updateCurrentLocationField('imageUrl', imageUrl);
  };

  const validateLocationPayload = location => {
    if (!location.name) {
      setToastErrorMessage(t('LOCATION_VALIDATION_FORM_NAME_MISSING'));
      return false;
    }
    if (!location.locationType) {
      setToastErrorMessage(t('LOCATION_VALIDATION_FORM_TYPE_MISSING'));
      return false;
    }

    if (!location.isDefault && isDuplicateLocation(location)) {
      const duplicateConfirmation = window.confirm(t('MY_LOCATIONS_DUPLICATE_LOCATION_CONFIRM'));
      if (duplicateConfirmation === false) {
        return false;
      }
    }
    return true;
  };

  const destinationName =
    destinationSearchObject && destinationSearchObject[0] && destinationSearchObject[0].code ? destinationSearchObject[0].name : null;

  const eventTypes = [
    {
      value: CONSTANTS.TRIP_EVENT_KINDS.ATTRACTION,
      label: 'Attraction'
    },
    {
      value: CONSTANTS.TRIP_EVENT_KINDS.ESTABLISHMENT,
      label: 'Establishment'
    },
    {
      value: CONSTANTS.TRIP_EVENT_KINDS.ACCOMMODATION,
      label: 'Accommodation'
    }
  ];

  const selectBoxSwitcher = location => {
    switch (locationKind) {
      case CONSTANTS.TRIP_EVENT_KINDS.ATTRACTION:
        return (
          <SelectLocationBox locationType={location.locationType} hasDefault={true} onLocationChange={onSelectUpdate('locationType')} required />
        );
      case CONSTANTS.TRIP_EVENT_KINDS.ACCOMMODATION:
        return (
          <SelectAccommodationBox
            accommodationType={location.locationType}
            hasDefault={true}
            onLocationChange={onSelectUpdate('locationType')}
            required
          />
        );
      case CONSTANTS.TRIP_EVENT_KINDS.ESTABLISHMENT:
      default:
        return (
          <input
            type="text"
            className="form-control textbox"
            placeholder="Enter Type"
            name="locationType"
            value={location.locationType}
            onChange={updateField('locationType')}
            required
          />
        );
    }
  };

  return (
    <>
      <fieldset className="form-group col-12">
        <div className="row">
          <div className="col-12 form2">
            <label htmlFor="address" className="col-form-label col-12">
              {t('MY_LOCATIONS_ADDRESS')} {currentLocation.address || t('MY_LOCATIONS_ADDRESS_PLACEHOLDER')}
            </label>
          </div>
          <div className="col-12 form2">
            <label htmlFor="destinationSearchObject" className="col-form-label col-12">
              {t('MY_LOCATIONS_DESTNATION_2')} {destinationName ? destinationName : t('MY_LOCATIONS_DESTINATION_PLACEHOLDER')}
            </label>
            <div className="">
              <div className="filter width-100 destination">
                <AutocompleteDestinationsInput
                  maxItems={1}
                  values={destinationSearchObject}
                  placeholder={t('SEARCH_DESTINATION_PLACEHOLDER')}
                  onChangeValues={onChangeDestination}
                  className="width-100"
                />
              </div>
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset className="form-group col-12">
        <div className="row form2">
          <div className="col-6">
            <label htmlFor="etype" className="col-form-label">
              {t('MY_LOCATIONS_CATEGOTY')}{' '}
            </label>
            <select className="form-control" value={locationKind} onChange={onChangeLocationKind} disabled={currentLocation._id} required>
              {eventTypes.map((eventType, index) => (
                <option key={index} value={eventType.value}>
                  {eventType.label}
                </option>
              ))}
            </select>
          </div>
          <div className="col-6">
            <label htmlFor="eventType" className="col-form-label">
              {t('MY_LOCATIONS_TYPE')}{' '}
            </label>
            {selectBoxSwitcher(currentLocation)}
          </div>
        </div>
      </fieldset>

      <fieldset className="form-group col-12">
        <div className="row form2">
          <label htmlFor="name" className="col-form-label col-sm-4">
            {t('MY_LOCATIONS_NAME')}{' '}
          </label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control textbox"
              placeholder={t('MY_LOCATIONS_NAME_PLACEHOLDER')}
              name="name"
              value={currentLocation.name}
              onChange={updateField('name')}
              required
            />
          </div>
        </div>
      </fieldset>

      {locationKind === CONSTANTS.TRIP_EVENT_KINDS.ATTRACTION ? (
        <fieldset className="form-group col-12">
          <div className="row form2">
            <label htmlFor="description" className="col-form-label  col-sm-4">
              {t('MY_LOCATIONS_DESCRIPTION')}{' '}
            </label>
            <div className="col-sm-12">
              <textarea
                className="form-control"
                rows="5"
                placeholder={t('MY_LOCATIONS_DESCRIPTION_PLACEHOLDER')}
                value={currentLocation.description}
                onChange={updateField('description')}
              ></textarea>
            </div>
          </div>
        </fieldset>
      ) : null}

      <fieldset className="form-group col-12">
        <div className="row form2">
          <label htmlFor="tips" className="col-form-label col-sm-4">
            {t('MY_LOCATIONS_TIPS')}{' '}
          </label>
          <div className="col-sm-12">
            <textarea
              className="form-control"
              rows="5"
              placeholder={t('MY_LOCATIONS_TIPS_PLACEHOLDER')}
              value={currentLocation.tips}
              onChange={updateField('tips')}
            ></textarea>
          </div>
        </div>
      </fieldset>

      <fieldset className="form-group col-12">
        <div className="row form">
          <div id="my-locations-image-container-wrapper" className="image-container-wrapper">
            <div className="image-container">
              <Button as="a" className="a-button close-button" onClick={deleteImage}>
                <i className="fa fa-times fa-1halfx" aria-hidden="true"></i>
              </Button>
              <img className="location-img" src={currentLocation.imageUrl || '/images/default-image-bk.png'} alt="Location" />
            </div>
            <div className="file-uploader-container">
              <FileUploader initialFileUrl={currentLocation.imageUrl} folder="location" hideText={false} onFileURLUpdate={onSaveLocationImage} />
            </div>
          </div>
        </div>
      </fieldset>

      <div className="row form">
        {currentLocation && currentLocation._id ? (
          <>
            <button type="button" className="btn btn-primary mr-2" onClick={onSaveLocation(currentLocation._id, currentLocation)}>
              {t('BUTTON_UPDATE_MY_LOCATION')}
            </button>
            <button type="button" className="btn btn-primary" onClick={onDeleteLocation(currentLocation._id)}>
              {t('BUTTON_DELETE_MY_LOCATION')}
            </button>
          </>
        ) : (
          <button type="button" className="btn btn-primary" onClick={onCreateLocation(currentLocation)}>
            {t('BUTTON_SAVE_MY_LOCATION')}
          </button>
        )}
      </div>

      <ShowError error={error} />
      <InProgress inProgress={inProgress} />
    </>
  );
};

const mapStateToProps = state => ({
  currentLocation: state.locations.currentLocation,
  inProgress: state.locations.inProgress,
  error: state.locations.error
});

const mapDispatchToProps = dispatch => ({
  updateCurrentLocationField: (name, value) => dispatch(updateCurrentLocationField(name, value)),
  createLocation: location => dispatch(createLocation(location)),
  saveLocation: (locationId, location) => dispatch(saveLocation(locationId, location)),
  deleteLocation: locationId => dispatch(deleteLocation(locationId)),
  setToastErrorMessage: errorMsg => dispatch(setToastErrorMessage(errorMsg))
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationForm);
