import React from 'react';

import MapCanvas from './MapCanvas';

const MapCanvasWrapper = ({ children, positions, searchPlace, selectedMarkerId, destinationSearchName, onMapsLoaded }) => {
  return (
    <>
      <div id="map_canvas-wrapper">
        <MapCanvas
          positions={positions}
          searchPlace={searchPlace}
          selectedMarkerId={selectedMarkerId}
          destinationSearchName={destinationSearchName}
          onMapsLoaded={onMapsLoaded}
        >
          {children}
        </MapCanvas>
      </div>
    </>
  );
};

export default MapCanvasWrapper;
