import React from 'react';
import { useTranslation } from 'react-i18next';
import TooltipIcon from './TooltipIcon';

const IconAccommodationItem = ({ iconKey, keyToMatch, iconClass, textKey, icon, tooltip, text, big }) => {
  const { t } = useTranslation();

  if (iconKey !== keyToMatch) {
    return null;
  }
  const bigClass = big ? 'fa-2x circle-icon' : '';

  return (
    <div className="expert-icon-container">
      <TooltipIcon iconClass={`${bigClass} ${iconClass}`} textKey={textKey} icon={icon} tooltip={tooltip} big={big} />
      {text ? t(textKey) : ''}
    </div>
  );
};

const IconAccommodationSwitcher = ({ iconKey = 'OTHER', icon, tooltip, text, big }) => {
  return (
    <>
      <IconAccommodationItem
        iconKey={iconKey}
        keyToMatch="HOTEL"
        iconClass="fa fa-bed"
        textKey="TRIP_EVENT__ACCOMMODATION_TYPE_HOTEL"
        big={big}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />

      <IconAccommodationItem
        iconKey={iconKey}
        keyToMatch="HOSTEL"
        iconClass="fa fa-bed"
        textKey="TRIP_EVENT__ACCOMMODATION_TYPE_HOSTEL"
        big={big}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />

      <IconAccommodationItem
        iconKey={iconKey}
        keyToMatch="APPARTMENT"
        iconClass="fa fa-bed"
        textKey="TRIP_EVENT__ACCOMMODATION_TYPE_APPARTMENT"
        big={big}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />

      <IconAccommodationItem
        iconKey={iconKey}
        keyToMatch="STUDIO"
        iconClass="fa fa-bed"
        textKey="TRIP_EVENT__ACCOMMODATION_TYPE_STUDIO"
        big={big}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />

      <IconAccommodationItem
        iconKey={iconKey}
        keyToMatch="CAMPING"
        iconClass="fa fa-bed"
        textKey="TRIP_EVENT__ACCOMMODATION_TYPE_CAMPING"
        big={big}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />

      <IconAccommodationItem
        iconKey={iconKey}
        keyToMatch="BED_BREAKFAST"
        iconClass="fa fa-bed"
        textKey="TRIP_EVENT__ACCOMMODATION_TYPE_BED_BREAKFAST"
        big={big}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />

      <IconAccommodationItem
        iconKey={iconKey}
        keyToMatch="CHALET"
        iconClass="fa fa-bed"
        textKey="TRIP_EVENT__ACCOMMODATTRIP_EVENT__ACCOMMODATION_TYPE_CHALETION_TYPE_HOTEL"
        big={big}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />
    </>
  );
};

export default IconAccommodationSwitcher;
