import React from 'react';

const RatingStars = ({ rating }) => {
  const starClassname = (level, rating) => {
    let starRating = 'fa star-color ';

    starRating += level < rating ? 'fa-star' : 'fa-star-o';

    return starRating;
  };

  return (
    <>
      <i className={starClassname(0, rating)} aria-hidden="true"></i>
      <i className={starClassname(1, rating)} aria-hidden="true"></i>
      <i className={starClassname(2, rating)} aria-hidden="true"></i>
      <i className={starClassname(3, rating)} aria-hidden="true"></i>
      <i className={starClassname(4, rating)} aria-hidden="true"></i>
    </>
  );
};

export default RatingStars;
