import React from 'react';

const PrivacyPolicyPage = () => {
  return (
    <div className="container padding-10">
      <div className="row">
        <h1>Política de Privacidade</h1>

        <p className="c8">
          <span className="c9 c10">&nbsp;</span>
        </p>
        <p className="c3">
          <span>A </span>
          <span>VOYAGEZY</span>
          <span>
            , de acordo com a legisla&ccedil;&atilde;o vigente sobre a prote&ccedil;&atilde;o de dados pessoais, informa aos usu&aacute;rios de sua
            plataforma web, sobre a Pol&iacute;tica de Privacidade que ser&aacute; aplicada no processamento de dados pessoais que os usu&aacute;rios
            fornecer&atilde;o voluntariamente ao acessar o website
          </span>
          <span>
            <a className="c6" href="https://www.google.com/url?q=http://www.voyagezy.com/&amp;sa=D&amp;ust=1555968484969000">
              &nbsp;
            </a>
          </span>
          <span className="c12">
            <a className="c6" href="https://www.google.com/url?q=http://www.voyagezy.com/&amp;sa=D&amp;ust=1555968484969000">
              www.voyagezy.com
            </a>
          </span>
          <span className="c1">.</span>
        </p>
        <p className="c2">
          <span className="c1">&nbsp;</span>
        </p>
        <p className="c3">
          <span>A </span>
          <span>VOYAGEZY</span>
          <span className="c1">
            &nbsp;afirma seu compromisso de cumprir a legisla&ccedil;&atilde;o vigente em todos os momentos em termos de prote&ccedil;&atilde;o de
            dados, especificamente com a Lei 15/1999, de 13 de dezembro, sobre Prote&ccedil;&atilde;o de Dados Pessoais; Decreto 1720/2007 de 21 de
            Dezembro, que aprova o Regulamento para o desenvolvimento da Lei 15/1999; Regulamento (UE) 2016/679 do Parlamento Europeu e do Conselho,
            de 27 de abril de 2016, e demais legisla&ccedil;&atilde;o aplic&aacute;vel. Os usu&aacute;rios s&atilde;o informados de que os dados
            pessoais fornecidos no site ser&atilde;o processados &#8203;&#8203;automaticamente e se tornar&atilde;o parte dos arquivos de propriedade
            da VOYAGEZY.
          </span>
        </p>
        <p className="c3">
          <span className="c1">
            De acordo com a legisla&ccedil;&atilde;o aplic&aacute;vel, &eacute; detalhada a Pol&iacute;tica de Privacidade que a VOYAGEZY utiliza no
            tratamento dos dados pessoais dos usu&aacute;rios que se cadastram no site.
          </span>
        </p>
        <p className="c2">
          <span className="c1">&nbsp;</span>
        </p>
        <p className="c2">
          <span className="font-weight-bold">Qual &eacute; a legitima&ccedil;&atilde;o para o tratamento de seus dados?</span>
        </p>
        <p className="c3">
          <span className="c1">
            A base legal para o processamento dos dados &eacute; o contato por qualquer uma das formas dispon&iacute;veis no site (formul&aacute;rios
            ou contato por e-mail), a execu&ccedil;&atilde;o do registro do usu&aacute;rio no site da VOYAGEZY e o uso subsequente do site pelo
            usu&aacute;rio.
          </span>
        </p>
        <p className="c2">
          <span className="c1">&nbsp;</span>
        </p>
        <p className="c2">
          <span className="font-weight-bold">Quais informa&ccedil;&otilde;es pessoais coletamos das pessoas que visitam nosso site?</span>
        </p>
        <p className="c2">
          <span className="c1">
            Ao fazer o pedido ou registrar-se em nosso site, voc&ecirc; pode ser solicitado a inserir seu nome, endere&ccedil;o de e-mail,
            endere&ccedil;o postal, n&uacute;mero de telefone, perfis sociais, fotos de perfil, informa&ccedil;&otilde;es de cart&atilde;o de
            cr&eacute;dito ou outras informa&ccedil;&otilde;es pessoais.
          </span>
        </p>
        <p className="c2">
          <span className="c1">&nbsp;</span>
        </p>
        <p className="c2">
          <span className="c1">
            N&oacute;s coletamos informa&ccedil;&otilde;es sobre voc&ecirc; quando voc&ecirc; se registrar em nosso site, faz um pedido, responde uma
            pesquisa, preenche um formul&aacute;rio, usa o chat ao vivo, insere informa&ccedil;&otilde;es em nosso site ou envia coment&aacute;rios
            sobre nossos produtos ou servi&ccedil;os.
          </span>
        </p>
        <p className="c2">
          <span className="c1">&nbsp;</span>
        </p>
        <p className="c2">
          <span className="font-weight-bold">Como usamos sua informa&ccedil;&atilde;o?</span>
        </p>
        <p className="c2">
          <span className="c1">Podemos usar as informa&ccedil;&otilde;es que coletamos das seguintes maneiras:</span>
        </p>
        <p className="c2 c5">
          <span>&middot;</span>
          <span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
          <span className="c1">
            Para personalizar sua experi&ecirc;ncia e nos permitir fornecer o tipo de conte&uacute;do e ofertas de produtos em que voc&ecirc;
            est&aacute; mais interessado.
          </span>
        </p>
        <p className="c2 c5">
          <span>&middot;</span>
          <span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
          <span className="c1">Melhorar o nosso site para melhor atend&ecirc;-lo.</span>
        </p>
        <p className="c2 c5">
          <span>&middot;</span>
          <span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
          <span className="c1">
            Para nos permitir melhor atend&ecirc;-lo ao responder &agrave;s suas solicita&ccedil;&otilde;es de atendimento ao cliente.
          </span>
        </p>
        <p className="c2 c5">
          <span>&middot;</span>
          <span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
          <span className="c1">Administrar um concurso, promo&ccedil;&atilde;o, pesquisa ou outro recurso do site.</span>
        </p>
        <p className="c2 c5">
          <span>&middot;</span>
          <span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
          <span className="c1">Processar rapidamente suas transa&ccedil;&otilde;es.</span>
        </p>
        <p className="c2 c5">
          <span>&middot;</span>
          <span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
          <span className="c1">Solicitar classifica&ccedil;&otilde;es e revis&otilde;es de servi&ccedil;os ou produtos</span>
        </p>
        <p className="c2 c5">
          <span>&middot;</span>
          <span className="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
          <span className="c1">Para acompanh&aacute;-los ap&oacute;s a correspond&ecirc;ncia (chat ao vivo, e-mail ou telefone)</span>
        </p>
        <p className="c2">
          <span className="c1">&nbsp;</span>
        </p>
        <p className="c2">
          <span className="font-weight-bold">Por quanto tempo manteremos seus dados pessoais?</span>
        </p>
        <p className="c2">
          <span className="c1">
            Os dados pessoais fornecidos ser&atilde;o mantidos at&eacute; que sejam solicitados pelo usu&aacute;rio por um per&iacute;odo de 24 meses
            a partir da &uacute;ltima intera&ccedil;&atilde;o.
          </span>
        </p>
        <p className="c2">
          <span className="c1">&nbsp;</span>
        </p>
        <p className="c2">
          <span className="font-weight-bold">Compartilhamos os dados pessoais que coletamos?</span>
        </p>
        <p className="c2">
          <span className="c1">
            Os dados pessoais que coletamos ser&atilde;o usados exclusivamente para atingir o objeto definido na contrata&ccedil;&atilde;o do
            servi&ccedil;o. A VOYAGEZY n&atilde;o transferir&aacute; seus dados para outras empresas, exceto para determinados servi&ccedil;os do
            nosso site e sempre com o consentimento pr&eacute;vio do usu&aacute;rio. Em todos os momentos, o &uacute;nico destinat&aacute;rio das
            informa&ccedil;&otilde;es contidas em seus dados pessoais ser&aacute; a VOYAGEZY, exceto que voc&ecirc;, voluntariamente, deseje a
            divulga&ccedil;&atilde;o de tais informa&ccedil;&otilde;es pessoais atrav&eacute;s do site, a fim de ser capaz de fornecer determinados
            servi&ccedil;os, relacionadas &agrave;s atividades deste site.
          </span>
        </p>
        <p className="c2">
          <span className="c1">&nbsp;</span>
        </p>
        <p className="c2">
          <span className="font-weight-bold">Quais s&atilde;o os direitos do usu&aacute;rio?</span>
        </p>
        <p className="c2">
          <span className="c1">
            A VOYAGEZY fornece ao usu&aacute;rio acesso a uma grande quantidade de informa&ccedil;&otilde;es sobre sua conta e suas
            opera&ccedil;&otilde;es no site, para que o usu&aacute;rio possa ver e, em certos casos, atualizar essas informa&ccedil;&otilde;es.
          </span>
        </p>
        <p className="c2">
          <span className="c1">
            Qualquer usu&aacute;rio pode acessar seu perfil e complet&aacute;-lo e/ou edit&aacute;-lo conforme sua conveni&ecirc;ncia. As
            informa&ccedil;&otilde;es e dados fornecidos pelo usu&aacute;rio estar&atilde;o sempre dispon&iacute;veis na conta do usu&aacute;rio e
            podem ser modificados pelo usu&aacute;rio por meio da op&ccedil;&atilde;o de editar perfil.
          </span>
        </p>
        <p className="c2">
          <span className="c1">
            O utilizador pode exercer os seus direitos de acesso, cancelamento e oposi&ccedil;&atilde;o, tendo em conta que o exerc&iacute;cio destes
            direitos &eacute; pessoal, ser&aacute; necess&aacute;rio que o utilizador prove a sua identidade. O exerc&iacute;cio destes direitos deve
            ser feito por escrito e assinado pelo titular dos dados, indicando o seu endere&ccedil;o, anexando uma c&oacute;pia do seu Documento
            Nacional de Identidade (DNI) ou outro documento de apoio, endere&ccedil;ado ao email info@voyagezy.com.
          </span>
        </p>
        <p className="c2">
          <span className="c1">
            Como indicado acima, se o usu&aacute;rio deseja parar de receber e-mails da VOYAGEZY, ele pode cancelar suas inscri&ccedil;&otilde;es
            pressionando o link &quot;Cancelar a assinatura&quot;, no rodap&eacute; dos nossos e-mails.
          </span>
        </p>
        <p className="c2">
          <span className="c1">&nbsp;</span>
        </p>
        <p className="c2">
          <span className="font-weight-bold">Como protegemos os seus dados?</span>
        </p>
        <p className="c2">
          <span className="c1">
            A VOYAGEZY tomou as medidas necess&aacute;rias para manter o n&iacute;vel de seguran&ccedil;a exigido, de acordo com a natureza dos dados
            pessoais tratados e as circunst&acirc;ncias do tratamento, a fim de evitar, tanto quanto poss&iacute;vel, a altera&ccedil;&atilde;o,
            perda, processamento ou acesso n&atilde;o autorizado. Como mencionado acima, os dados pessoais fornecidos n&atilde;o ser&atilde;o
            transferidos a terceiros sem autoriza&ccedil;&atilde;o pr&eacute;via do usu&aacute;rio.
          </span>
        </p>
        <p className="c2">
          <span className="c1">&nbsp;</span>
        </p>
        <p className="c2">
          <span className="font-weight-bold">N&oacute;s usamos &#39;cookies&#39;?</span>
        </p>
        <p className="c2">
          <span className="c1">
            N&atilde;o. Cookies s&atilde;o pequenos arquivos que um site ou seu provedor de servi&ccedil;os transfere para o disco r&iacute;gido do
            seu computador atrav&eacute;s do navegador (se voc&ecirc; permitir) permitindo aos sistemas do site ou do provedor de servi&ccedil;os
            reconhecerem seu navegador e registrarem certas informa&ccedil;&otilde;es.
          </span>
        </p>
        <p className="c2">
          <span className="c1">&nbsp;</span>
        </p>
        <p className="c2">
          <span className="font-weight-bold">Divulga&ccedil;&atilde;o de terceiros</span>
        </p>
        <p className="c3">
          <span className="c1">
            N&oacute;s n&atilde;o vendemos, comercializamos ou transferimos para terceiros as informa&ccedil;&otilde;es pessoais, a menos que
            solicitado e aprovado previamente pelos usu&aacute;rios. Isso n&atilde;o inclui parceiros de hospedagem do site e outras partes que nos
            auxiliam na opera&ccedil;&atilde;o de nosso site, na condu&ccedil;&atilde;o de nossos neg&oacute;cios ou na presta&ccedil;&atilde;o de
            servi&ccedil;os a nossos usu&aacute;rios, desde que essas partes concordem em manter essas informa&ccedil;&otilde;es confidenciais.
            Tamb&eacute;m podemos liberar informa&ccedil;&otilde;es quando elas forem apropriadas para cumprir a lei, aplicar as pol&iacute;ticas do
            nosso site ou proteger os direitos, propriedades ou seguran&ccedil;a de outros.
          </span>
        </p>
        <p className="c3">
          <span className="c1">
            No entanto, informa&ccedil;&otilde;es de visitantes n&atilde;o pessoalmente identific&aacute;veis podem ser fornecidas a terceiros para
            marketing, publicidade ou outros usos.
          </span>
        </p>
        <p className="c2">
          <span className="c1">&nbsp;</span>
        </p>
        <p className="c2">
          <span className="font-weight-bold">Links de terceiros</span>
        </p>
        <p className="c2">
          <span className="c1">
            Ocasionalmente, a nosso crit&eacute;rio, podemos incluir ou oferecer produtos ou servi&ccedil;os de terceiros em nosso site. Esses sites
            de terceiros t&ecirc;m pol&iacute;ticas de privacidade separadas e independentes. Portanto, n&atilde;o temos responsabilidade nem
            obriga&ccedil;&atilde;o pelo conte&uacute;do e atividades desses sites vinculados.
          </span>
        </p>
        <p className="c2">
          <span className="font-weight-bold">&nbsp;</span>
        </p>
        <p className="c2 c4">
          <span className="c1"></span>
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
