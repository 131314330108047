import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import CONSTANTS from '../constants/constants';
import { Tabs, Tab } from 'react-bootstrap';
import MessagesPage from './MessagesPage';
import ProfileShortEditionPage from './ProfileShortEditionPage';
import PaymentsListPage from './PaymentsListPage';
import TripListPage from './TripListPage';
import { updateHash } from '../utils/common';
import RegisterExpertUserPage from './RegisterExpertUserPage';
import ManageTripsPage from './ManageTripsPage';
import ManageTripsRequestsPage from './ManageTripsRequestsPage';
const DEFAULT_TAB_NAME = CONSTANTS.DASHBOARD_TAB_NAMES.MANAGE_TRIPS;

const DashboardPage = ({ currentUser }) => {
  const { t } = useTranslation();
  const location = useLocation();

  // Wrap with useCallback to avoid change on every render. It returns a memoized version of the callback that only changes if one of the provided dependencies change.
  const getTabNameFromHash = useCallback(() => {
    const tabNameHash = location.hash ? location.hash.slice(1) : null; // removes the '#' character
    let tabName = DEFAULT_TAB_NAME;

    if (tabNameHash && CONSTANTS.DASHBOARD_TAB_NAMES_ENUM.indexOf(tabNameHash) !== -1) {
      tabName = tabNameHash;
    }
    console.log('Dashboard rerendered: ' + tabNameHash);
    return tabName;
  }, [location]);

  const [key, setKey] = useState('');

  useEffect(() => {
    const tabName = getTabNameFromHash();
    setKey(tabName);
  }, [getTabNameFromHash]);

  const onTabChange = k => {
    setKey(k);
    updateHash(k);
  };

  const tabData = [
    {
      heading: 'Manage Trips',
      route: CONSTANTS.DASHBOARD_TAB_NAMES.MANAGE_TRIPS,
      content: ManageTripsPage,
      display: true
    },
    {
      heading: 'Leads',
      route: CONSTANTS.DASHBOARD_TAB_NAMES.MANAGE_LEADS,
      content: ManageTripsRequestsPage,
      display: true
    },
    {
      heading: 'DASHBOARD_TAB_REGISTER_EXPERT',
      route: CONSTANTS.DASHBOARD_TAB_NAMES.REGISTER_EXPERT,
      content: RegisterExpertUserPage,
      display: true
    },
    {
      heading: 'DASHBOARD_TAB_TITLE_MESSAGES',
      route: CONSTANTS.DASHBOARD_TAB_NAMES.MESSAGES,
      content: MessagesPage,
      display: true
    },
    {
      heading: 'DASHBOARD_TAB_TITLE_TRIP_PLANS_EXPERT',
      route: CONSTANTS.DASHBOARD_TAB_NAMES.TRIP_PLANS,
      content: TripListPage,
      display: true
    },
    {
      heading: 'DASHBOARD_TAB_TITLE_USER_PAYMENTS',
      route: CONSTANTS.DASHBOARD_TAB_NAMES.USER_PAYMENTS,
      content: PaymentsListPage,
      display: true
    },
    {
      heading: 'DASHBOARD_TAB_TITLE_PERSONAL_INFO',
      route: CONSTANTS.DASHBOARD_TAB_NAMES.PROFILE,
      content: ProfileShortEditionPage,
      display: true
    },
  ];

  return (
    <div className="container padding-10">
      <div className="width-100 padding-10">
        <Tabs id="dashboard-tab" activeKey={key} onSelect={onTabChange}>
          {tabData.map((tab, index) => {
            if (!tab.display) {
              return null;
            }
            return (
              <Tab key={index} eventKey={tab.route} title={t(tab.heading)}>
                {key === tab.route ? <tab.content /> : null /* only render the active tab */}
              </Tab>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
