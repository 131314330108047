import agent from '../agent';
import NavigationService from '../services/navigation';
import {
  UPDATE_FIELD_AUTH,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  REGISTER_PAGE_UNLOADED,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  LOGIN_PAGE_LOADED,
  LOGIN_PAGE_UNLOADED,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_PAGE_UNLOADED,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_PAGE_UNLOADED
} from '../constants/actionTypes';
import i18next from 'i18next';
import CONSTANTS from '../constants/constants';
import { hideLoginDialogAction } from './modals';
import { reloadPage } from '../utils/common';

export const updateAuthField = (name, value) => ({ type: UPDATE_FIELD_AUTH, key: name, value });

export const registerPageUnload = () => ({ type: REGISTER_PAGE_UNLOADED });

export const postRegister = () => ({ type: REGISTER_REQUEST });
export const postRegisterSuccess = payload => ({
  type: REGISTER_SUCCESS,
  payload
});
export const postRegisterFailure = error => ({ type: REGISTER_FAILURE, error });

/* throws Validatoin Error */
export const validatePassword = (password, confirmPassword) => {
  // tests validity of the password
  const passwordRegex = RegExp(CONSTANTS.PASSWORD_REGEX);
  if (passwordRegex.test(password) === false) {
    const errorMsg = i18next.t('PASSWORD_ACCEPTANCE_CRITERIA_ERROR');
    throw new Error(errorMsg);
  }

  if (password !== confirmPassword) {
    const errorMsg = i18next.t('REGISTER_VALIDATION_MISMATCH_PASSWORD_ERROR');
    throw new Error(errorMsg);
  }
};

export const registerRequest = (email, password, confirmPassword) => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(postRegister());

    try {
      validatePassword(password, confirmPassword);

      const payload = await agent.AuthService.register(email, password);

      if (payload.success !== true) {
        throw new Error(payload.message);
      }

      // Patch while the register query response does not return the token
      loginActions(dispatch, email, password);
      //dispatch(postRegisterSuccess(payload));
    } catch (error) {
      dispatch(postRegisterFailure(error));
    }
  };
};

export const loginPageUnload = () => ({ type: LOGIN_PAGE_UNLOADED });
export const loginPageLoad = () => ({ type: LOGIN_PAGE_LOADED });

export const postLogin = error => ({ type: LOGIN_REQUEST, error });

export const postLoginSuccess = payload => ({
  type: LOGIN_SUCCESS,
  payload
});
export const postLoginFailure = error => ({ type: LOGIN_FAILURE, error });

export const loginRequest = (email, password, isDialogModal) => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    loginActions(dispatch, email, password, isDialogModal);
  };
};

export const loginActions = async (dispatch, email, password, isDialogModal) => {
  dispatch(postLogin());

  try {
    const payload = await agent.AuthService.login(email, password);
    const { user, state } = payload

    // failure
    if (state !== 'success') {
      throw new Error(payload.message);
    }

    // we only allow admin users to authenticate in this app
    if (!user || !user.isAdmin) {
      throw new Error("This application is only available for Admin Users");
    }

    dispatch(postLoginSuccess(payload));

    // stay on the same page and close modal if request comes from a login modal
    if (isDialogModal) {
      hideLoginDialogAction();
      reloadPage();
      return;
    }
  } catch (error) {
    dispatch(postLoginFailure(error));
  }
};

export const postLogout = () => ({
  type: LOGOUT_REQUEST
});

export const postLogoutSuccess = payload => ({
  type: LOGOUT_SUCCESS,
  payload
});

export const postLogoutFailure = error => ({
  type: LOGOUT_FAILURE,
  error
});

export const logoutRequest = () => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(postLogout());

    try {
      const payload = await agent.AuthService.signout();

      dispatch(postLogoutSuccess(payload));
    } catch (error) {
      console.error(`Logout failed on server: ${error.message}`);
      dispatch(postLogoutFailure(error));
    }
  };
};

export const forgotPasswordPageUnload = () => ({ type: FORGOT_PASSWORD_PAGE_UNLOADED });

export const postForgotPassword = error => ({ type: FORGOT_PASSWORD_REQUEST, error });

export const postForgotPasswordSuccess = payload => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload
});
export const postForgotPasswordFailure = error => ({ type: FORGOT_PASSWORD_FAILURE, error });

export const forgotPasswordRequest = email => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(postForgotPassword());

    try {
      if (!email) {
        const errorMsg = i18next.t('FORGOT_PASSWORD_VALIDATION_MISSING_EMAIL_ERROR');
        throw new Error(errorMsg);
      }

      const payload = await agent.AuthService.forgotPassword(email);

      dispatch(postForgotPasswordSuccess(payload));
    } catch (error) {
      dispatch(postForgotPasswordFailure(error));
    }
  };
};

export const resetPasswordPageUnload = () => ({ type: RESET_PASSWORD_PAGE_UNLOADED });

export const postResetPassword = error => ({ type: RESET_PASSWORD_REQUEST, error });

export const postResetPasswordSuccess = payload => ({
  type: RESET_PASSWORD_SUCCESS,
  payload
});
export const postResetPasswordFailure = error => ({ type: RESET_PASSWORD_FAILURE, error });

export const resetPasswordRequest = (token, password, confirmPassword) => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(postResetPassword());

    try {
      validatePassword(password, confirmPassword);

      const payload = await agent.AuthService.resetPassword(token, password);

      const clientPayload = {
        message: ''
      };

      if (payload.message) {
        console.log(`Server message: ${payload.message}`);
        clientPayload.message = i18next.t('USER_UPDATE_CREDENTIALS_SUCCESS');
      }

      dispatch(postResetPasswordSuccess(clientPayload));
      NavigationService.goToLogin();
    } catch (error) {
      dispatch(postResetPasswordFailure(error));
    }
  };
};
