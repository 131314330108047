import { resetNewEvent, extractTripEventFromId } from '../utils/tripUtils';

// Selector
export function getTripPlanEvent(tripPlan, dayId, eventId) {
  let tripEvent = extractTripEventFromId(tripPlan, dayId, eventId);
  if (!tripEvent) {
    const day = getTripPlanDayWithIndex(tripPlan, dayId);
    const newEvent = resetNewEvent(day);
    return newEvent;
  }
  return tripEvent;
}

// Selector
export function getTripPlanDayWithIndex(tripPlan, dayId) {
  if (!tripPlan || !tripPlan.days) {
    return null;
  }

  const { days } = tripPlan;
  const daysWithIndex = days.map((day, index) => ({ ...day, index }));
  const [day] = daysWithIndex.filter(day => day._id === dayId);
  return day;
}
