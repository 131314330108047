import React from 'react';

const NotAuthorized = () => {
  return (
    <div className="container padding-10">
      <div className="row">
        <h1>You are Not Authorized to see this page.</h1>
      </div>
    </div>
  );
};

export default NotAuthorized;
