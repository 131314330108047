import {
  TRIP_TEMPLATE_RESET,
  UPDATE_CURRENT_TRIP_TEMPLATE_FIELD,
  TRIP_TEMPLATES_GET_REQUEST,
  TRIP_TEMPLATES_GET_SUCCESS,
  TRIP_TEMPLATES_GET_FAILURE,
  TRIP_TEMPLATE_POST_REQUEST,
  TRIP_TEMPLATE_POST_SUCCESS,
  TRIP_TEMPLATE_POST_FAILURE,
  TRIP_TEMPLATE_DELETE_REQUEST,
  TRIP_TEMPLATE_DELETE_SUCCESS,
  TRIP_TEMPLATE_DELETE_FAILURE,
  TRIP_TEMPLATE_GET_REQUEST,
  TRIP_TEMPLATE_GET_SUCCESS,
  TRIP_TEMPLATE_GET_FAILURE,
  TRIP_TEMPLATE_PUT_REQUEST,
  TRIP_TEMPLATE_PUT_SUCCESS,
  TRIP_TEMPLATE_PUT_FAILURE
} from '../constants/actionTypes';

const defaultState = {
  tripTemplates: [],
  message: '',
  currentTripTemplate: {
    name: '',
    days: []
  },
  inProgress: false,
  error: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case TRIP_TEMPLATE_RESET:
      return {
        ...state,
        currentTripTemplate: state.currentTripTemplate
      };
    case UPDATE_CURRENT_TRIP_TEMPLATE_FIELD:
      const updatedCurrentTripTemplate = { ...state.currentTripTemplate, [action.key]: action.value };
      return {
        ...state,
        currentTripTemplate: updatedCurrentTripTemplate
      };

    case TRIP_TEMPLATES_GET_REQUEST:
    case TRIP_TEMPLATE_POST_REQUEST:
    case TRIP_TEMPLATE_DELETE_REQUEST:
    case TRIP_TEMPLATE_GET_REQUEST:
    case TRIP_TEMPLATE_PUT_REQUEST:
      return { ...state, inProgress: true };
    case TRIP_TEMPLATES_GET_SUCCESS:
      return {
        ...state,
        inProgress: false,
        error: null,
        currentTripTemplate: defaultState.currentTripTemplate,
        tripTemplates: action.payload ? action.payload : null
      };
    case TRIP_TEMPLATE_GET_SUCCESS:
    case TRIP_TEMPLATE_POST_SUCCESS:
    case TRIP_TEMPLATE_PUT_SUCCESS:
      return {
        ...state,
        inProgress: false,
        error: null,
        message: action.message ? action.message : null,
        currentTripTemplate: action.payload ? action.payload : null
      };

    case TRIP_TEMPLATE_DELETE_SUCCESS:
      const filteredTripTemplates = state.tripTemplates.filter(el => el._id !== action.tripTemplateId);

      return {
        ...state,
        inProgress: false,
        error: null,
        message: action.message ? action.message : null,
        tripTemplates: filteredTripTemplates
      };

    case TRIP_TEMPLATES_GET_FAILURE:
    case TRIP_TEMPLATE_POST_FAILURE:
    case TRIP_TEMPLATE_DELETE_FAILURE:
    case TRIP_TEMPLATE_GET_FAILURE:
    case TRIP_TEMPLATE_PUT_FAILURE:
      return {
        ...state,
        inProgress: false,
        message: [],
        error: action.error ? action.error : null
      };
    default:
      return state;
  }
};
