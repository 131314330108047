export const getDefaultDestination = () => {
  return {
    country_code: "",
    search_code: "",
    imageUrl: "",
    videoUrl: "",
    name: {
      en: "",
      pt: ""
    },
    title: {
      en: "",
      pt: ""
    },
    country: {
      en: "",
      pt: ""
    },
    description: {
      en: "",
      pt: ""
    }
  };
}
