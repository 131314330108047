import React from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, Card, Button } from 'react-bootstrap';

const questionSet = [
  {
    id: 'faq-section-1',
    title: 'FAQ_SECTION_1_TITLE',
    header: 'FAQ_ABOUT_VOYAGEZY',
    image: 'images/faq/section-1.png',
    questions: [
      {
        question: 'FAQ_Q1',
        answer: 'FAQ_A1'
      },
      {
        question: 'FAQ_Q2',
        answer: 'FAQ_A2'
      },
      {
        question: 'FAQ_Q3',
        answer: 'FAQ_A3'
      },
      {
        question: 'FAQ_Q4',
        answer: 'FAQ_A4'
      },
      {
        question: 'FAQ_Q5',
        answer: 'FAQ_A5',
        items: [
          {
            strong: 'TRIP_REQUEST_SERVICES_ITINERARY',
            normal: 'FAQ_A5_1'
          },
          {
            strong: 'TRIP_REQUEST_SERVICES_RESTAURANTS',
            normal: 'FAQ_A5_2'
          },
          {
            strong: 'TRIP_REQUEST_SERVICES_TRANSPORTATION',
            normal: 'FAQ_A5_3'
          },
          {
            strong: 'TRIP_REQUEST_SERVICES_ACOMMODATION',
            normal: 'FAQ_A5_4'
          }
        ]
      }
    ]
  },
  {
    id: 'faq-section-2',
    title: 'FAQ_SECTION_2_TITLE',
    header: 'FAQ_PLANNERS',
    image: 'images/faq/section-2.png',
    questions: [
      {
        question: 'FAQ_Q6',
        answer: 'FAQ_A6'
      },
      {
        question: 'FAQ_Q7',
        answer: 'FAQ_A7'
      },
      {
        question: 'FAQ_Q8',
        answer: 'FAQ_A8'
      },
      {
        question: 'FAQ_Q9',
        answer: 'FAQ_A9'
      },
      {
        question: 'FAQ_Q10',
        items: [
          {
            strong: 'FAQ_A10_1S',
            normal: 'FAQ_A10_1'
          },
          {
            strong: 'FAQ_A10_2S',
            normal: 'FAQ_A10_2'
          },
          {
            strong: 'FAQ_A10_3S',
            normal: 'FAQ_A10_3'
          },
          {
            strong: 'FAQ_A10_4S',
            normal: 'FAQ_A10_4'
          },
          {
            strong: 'FAQ_A10_5S',
            normal: 'FAQ_A10_5'
          }
        ]
      }
    ]
  },
  {
    id: 'faq-section-3',
    title: 'FAQ_SECTION_3_TITLE',
    header: 'FAQ_TRAVELERS',
    image: 'images/faq/section-3.png',
    questions: [
      {
        question: 'FAQ_Q11',
        answer: 'FAQ_A11'
      },
      {
        question: 'FAQ_Q12',
        answer: 'FAQ_A12'
      },
      {
        question: 'FAQ_Q13',
        answer: 'FAQ_A13'
      },
      {
        question: 'FAQ_Q14',
        answer: 'FAQ_A14'
      },
      {
        question: 'FAQ_Q15',
        answer: 'FAQ_A15'
      },
      {
        question: 'FAQ_Q16',
        items: [
          {
            strong: 'FAQ_A16_1S',
            normal: 'FAQ_A16_1'
          },
          {
            strong: 'FAQ_A16_2S',
            normal: 'FAQ_A16_2'
          },
          {
            strong: 'FAQ_A16_3S',
            normal: 'FAQ_A16_3'
          },
          {
            strong: 'FAQ_A16_4S',
            normal: 'FAQ_A16_4'
          }
        ]
      }
    ]
  }
];

const FaqAccordion = ({ questionSet }) => {
  const { t } = useTranslation();

  return (
    <div>
      {questionSet.map((section, index) => {
        return (
          <div key={index} id={section.id}>
            <h4>{t(section.title)}</h4>
            <Accordion key={index} className="panel-default">
              {section.questions.map((element, index) => {
                return (
                  <Card key={index} className="panel-default">
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey={index}>
                        {t(element.question)}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={index}>
                      <Card.Body>
                        <div>
                          <p>{t(element.answer)}</p>
                        </div>
                        {(element.items || []).map((item, index) => {
                          return (
                            <div key={index}>
                              <p>
                                <strong>{t(item.strong)}</strong>
                                {t(item.normal)}
                              </p>
                            </div>
                          );
                        })}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                );
              })}
            </Accordion>
            <hr />
          </div>
        );
      })}
    </div>
  );
};

const FaqPage = () => {
  const { t } = useTranslation();

  const renderHeaderLink = ({ id, image, header }) => {
    return (
      <div className="col-4 ">
        <a href={`#${id}`}>
          <img src={image} alt={header} width="250px" height="250px" />
        </a>
        <h4>{t(header)}</h4>
      </div>
    );
  };

  return (
    <div className="container padding-10">
      <h1> {t('FAQ_TITLE')}</h1>
      <h4> {t('FAQ_SUBTITLE')}</h4>
      <div className="row text-center margin-20">{questionSet.map(questionSetItem => renderHeaderLink(questionSetItem))}</div>
      <div className="container padding-10">
        <FaqAccordion questionSet={questionSet} />
      </div>
      <h4> {t('FAQ_FOOTER')}</h4>
    </div>
  );
};

export default FaqPage;
