import { SUBMIT_FEEDBACK_REQUEST, SUBMIT_FEEDBACK_FAILURE, SUBMIT_FEEDBACK_SUCCESS } from '../constants/actionTypes';

const defaultState = {
  message: '',
  inProgress: false,
  error: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SUBMIT_FEEDBACK_REQUEST:
      return { ...state, inProgress: true };
    case SUBMIT_FEEDBACK_SUCCESS:
      return {
        ...state,
        inProgress: false,
        error: null,
        message: action.payload ? action.payload : null
      };
    case SUBMIT_FEEDBACK_FAILURE:
      return {
        ...state,
        inProgress: false,
        message: '',
        error: action.error ? action.error : null
      };
    default:
      return state;
  }
};
