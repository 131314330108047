import React from 'react';
import { useTranslation } from 'react-i18next';
import TooltipIcon from './TooltipIcon';

const IconBudgetItem = ({ iconKey, keyToMatch, iconClass, textKey, icon, tooltip, text }) => {
  const { t } = useTranslation();

  if (iconKey !== keyToMatch) {
    return null;
  }

  return (
    <div className="expert-icon-container">
      <TooltipIcon iconClass={`${iconClass} expert-specialist-icon`} textKey={textKey} icon={icon} tooltip={tooltip} />
      {text ? t(textKey) : ''}
    </div>
  );
};

const IconBudgetSwitcher = ({ iconKey, icon, tooltip, text }) => {
  return (
    <>
      <IconBudgetItem
        iconKey={iconKey}
        keyToMatch="LOW"
        iconClass="icon ive-economic"
        textKey="TRIP_BUDGET_LOW_COST"
        icon={icon}
        tooltip={tooltip}
        text={text}
      />

      <IconBudgetItem
        iconKey={iconKey}
        keyToMatch="MEDIUM"
        iconClass="icon ive-regular"
        textKey="TRIP_BUDGET_BALANCED"
        icon={icon}
        tooltip={tooltip}
        text={text}
      />

      <IconBudgetItem
        iconKey={iconKey}
        keyToMatch="HIGH"
        iconClass="icon ive-lux"
        textKey="TRIP_BUDGET_LUXURY"
        icon={icon}
        tooltip={tooltip}
        text={text}
      />
    </>
  );
};

export default IconBudgetSwitcher;
