import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { makeTripPayment } from '../actions/trips';
import InProgress from '../components/InProgress';
import CONSTANTS from '../constants/constants';
import { formatCurrency } from '../utils/currencyUtils';
import StripeCheckout from '../components/StripeCheckout';
import { isAfterPaymentStatus } from '../utils/tripUtils';

const PaymentCheckout = ({ currentTripResume, tripId, status, payment, currentUser, makeTripPayment, paymentMessage, inProgress }) => {
  const { t } = useTranslation();

  const makePayment = () => {
    const { value, currencyCode } = payment;
    makeTripPayment(tripId, value, currencyCode);
  };

  return (
    <>
      <div className="col-12">
        <h1>
          {t('LABEL_TOTAL')} : {formatCurrency(payment.currencyCode, payment.value)}
        </h1>
      </div>
      <div className="col-12">
        <button
          type="submit"
          className="btn btn-primary margin-10 mr-2 d-none"
          onClick={makePayment}
          disabled={status !== CONSTANTS.TRIP_STATUS.WAITING_PAYMENT}
        >
          {t('BUTTON_PAY')}
        </button>
        <InProgress inProgress={inProgress} />
        <hr />
        <StripeCheckout tripId={tripId} payment={payment} tripResume={currentTripResume} />
      </div>
      <div className="col-12">
        {isAfterPaymentStatus(currentTripResume.status) ? <p className="success">{t('TRIP_RESUME_PAYMENT_SUCCESS')}</p> : null}
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  currentTripResume: state.trips.currentTripResume,
  currentUser: state.common.currentUser,
  inProgress: state.trips.inProgress,
  error: state.trips.error,
  paymentMessage: state.trips.message
});

const mapDispatchToProps = dispatch => ({
  makeTripPayment: (tripResumeId, value, currencyCode) => dispatch(makeTripPayment(tripResumeId, value, currencyCode))
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentCheckout);
