import React from 'react';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
// For more info, check react-bootstrap Dropdowns reference
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

export default CustomToggle;
