import {
  UPDATE_TRIP_PLAN_REQUEST,
  UPDATE_TRIP_PLAN_SUCCESS,
  UPDATE_TRIP_PLAN_FAILURE,
  UPDATE_TRIP_PLAN_DAY_SUCCESS,
  TRIP_PLAN_RESET,
  UPDATE_TRIP_PLAN_CURRENT_DAY,
  UPDATE_TRIP_PLAN_CURRENT_EVENT
} from '../constants/actionTypes';

const defaultState = {
  message: '',
  inProgress: false,
  error: null,
  currentTripPlan: {},
  currentTripPlanDayId: '',
  currentTripPlanEventId: ''
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case TRIP_PLAN_RESET:
      return {
        ...state,
        currentTripPlan: defaultState.currentTripPlan
      };
    case UPDATE_TRIP_PLAN_REQUEST:
      return { ...state, inProgress: true };
    case UPDATE_TRIP_PLAN_CURRENT_DAY:
      return {
        ...state,
        currentTripPlanDayId: action.dayId ? action.dayId : defaultState.currentTripPlanDayId
      };
    case UPDATE_TRIP_PLAN_CURRENT_EVENT:
      return {
        ...state,
        currentTripPlanEventId: action.eventId ? action.eventId : defaultState.currentTripPlanEventId
      };
    case UPDATE_TRIP_PLAN_SUCCESS:
      return {
        ...state,
        inProgress: false,
        error: null,
        message: action.message ? action.message : defaultState.message,
        currentTripPlan: action.payload ? action.payload : state.currentTripPlan
      };
    case UPDATE_TRIP_PLAN_DAY_SUCCESS:
      const updateDay = action.payload;
      const dayId = action.dayId;

      const tripPlan = state.currentTripPlan;
      const updatedDays = [...tripPlan.days];
      const dayFromIndex = updatedDays.map(day => day._id).indexOf(dayId);
      updatedDays[dayFromIndex] = updateDay;
      const updatedTripPlan = { ...tripPlan, days: updatedDays };

      return {
        ...state,
        inProgress: false,
        error: null,
        message: action.message ? action.message : defaultState.message,
        currentTripPlan: updatedTripPlan
      };
    case UPDATE_TRIP_PLAN_FAILURE:
      return {
        ...state,
        inProgress: false,
        message: defaultState.message,
        error: action.error ? action.error : null
      };
    default:
      return state;
  }
};
