import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InProgress from './InProgress';
import FileUploadService from '../services/aws-file-upload';

const Preview = ({ show, fileUrl }) => {
  if (!show) {
    return null;
  }

  return (
    <div>
      <h3>Preview</h3>
      <img className="image-uploader-preview" src={fileUrl || '/images/default-user-bk.png'} alt="Avatar preview" />
    </div>
  );
};

const FileUploader = ({ initialFileUrl, folder, showPreview, hideText, onFileURLUpdate }) => {
  const { t } = useTranslation();

  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const initUpload = file => {
    FileUploadService.authenticateAndUploadFile(file, folder, setLoading, setSuccess, setMessage, onFileURLUpdate);
  };

  /*
       Function called when file input updated. If there is a file selected, then
       start upload procedure by asking for a signed request from the app.
      */
  const onFileChange = ev => {
    const file = ev.target.files[0];
    if (file == null) {
      return alert('No file selected.');
    }
    initUpload(file);
  };

  return (
    <div className="width-100">
      <div>
        <input type="file" id="file-input" onChange={onFileChange} className={hideText ? 'hide-text' : ''} />
        <InProgress inProgress={loading} />
        <p id="message" className={success ? 'success' : 'error'}>
          {message}{' '}
        </p>
        <p id="status">{t('INPUT_FILE_INSTRUCTION_SELECT_FILE')}</p>
      </div>

      <Preview show={showPreview} fileUrl={initialFileUrl} />
    </div>
  );
};

export default FileUploader;
