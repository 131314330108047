import React, { useState, useEffect } from 'react';
import ShowError from '../components/ShowError';
import InProgress from '../components/InProgress';
import { connect } from 'react-redux';
import { updateAuthField, resetPasswordRequest, resetPasswordPageUnload } from '../actions/auth';
import { useTranslation } from 'react-i18next';
import CONSTANTS from '../constants/constants';
import { useParams } from 'react-router';

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  onChangePassword: value => dispatch(updateAuthField('password', value)),
  onChangeConfirmPassword: value => dispatch(updateAuthField('confirmPassword', value)),
  onSubmit: (token, password, confirmPassword) => {
    dispatch(resetPasswordRequest(token, password, confirmPassword));
  },
  onUnload: () => dispatch(resetPasswordPageUnload())
});

const ResetPasswordPage = ({ auth, onChangePassword, onChangeConfirmPassword, onSubmit, onUnload }) => {
  const { password, confirmPassword, error, inProgress, message } = auth;
  const { t } = useTranslation();
  const [pwdError, setPwdError] = useState('');
  let { token } = useParams();

  const changePassword = ev => onChangePassword(ev.target.value);
  const changeConfirmPassword = ev => onChangeConfirmPassword(ev.target.value);
  const submitForm = (token, password, confirmPassword) => ev => {
    ev.preventDefault();
    onSubmit(token, password, confirmPassword);
  };
  const onBlurPassword = ev => {
    const pwdValue = ev.target.value;
    // tests validity of the password
    const passwordRegex = RegExp(CONSTANTS.PASSWORD_REGEX);
    if (passwordRegex.test(pwdValue) === false) {
      const msg = t('PASSWORD_ACCEPTANCE_CRITERIA');
      setPwdError(msg);
      return;
    }
    setPwdError('');
  };

  useEffect(() => {
    return () => onUnload();
  }, [onUnload]);

  return (
    <div className="container padding-10">
      <div className="row">
        <div className="col-md-6 offset-md-3 col-xs-12">
          <h1 className="text-xs-center">{t('RESET_PASSWORD_NEW')}</h1>
          <ShowError error={error} />
          <p className="text-warning">{message}</p>
          <form onSubmit={submitForm(token, password, confirmPassword)} className="form-auth">
            <fieldset>
              <fieldset className="form-group">
                <input
                  className="form-control form-control-lg"
                  type="password"
                  placeholder={t('REGISTER_PLACEHOLDER_PASSWORD')}
                  value={password}
                  onChange={changePassword}
                  onBlur={onBlurPassword}
                />
                <div>
                  <span className="error small">{pwdError}</span>
                </div>
              </fieldset>

              <fieldset className="form-group">
                <input
                  className="form-control form-control-lg"
                  type="password"
                  placeholder={t('REGISTER_PLACEHOLDER_CONFIRM_PASSWORD')}
                  value={confirmPassword}
                  onChange={changeConfirmPassword}
                />
              </fieldset>
              <button className="btn btn-lg btn-primary pull-xs-right" type="submit" disabled={inProgress}>
                {t('BUTTON_CHANGE_PASSWORD')}
              </button>
              <InProgress inProgress={inProgress} />
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
