import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getTrips } from '../actions/trips';
import { deleteTripResume } from '../actions/trips';
import { Button } from '../components/Custom';
import InProgress from '../components/InProgress';
import { getTripStatusTranslationString, openInNewWindow } from '../utils/common';

const ManageTripsPage = ({ allTrips, inProgress, getTrips, deleteTripResume, environmentUrl }) => {
  const { t } = useTranslation();

  // Wrap with useCallback to avoid change on every render. It returns a memoized version of the callback that only changes if one of the provided dependencies change.
  const fetchAllTrips = useCallback(async () => {
    await getTrips();
  }, [getTrips]);

  useEffect(() => {
    fetchAllTrips();
  }, [fetchAllTrips]);

  const goToTrip = tripId => ev => {
    const url = `${environmentUrl}/trips/${tripId}`
    openInNewWindow(url);
  };

  const deleteTrip = async tripId => {
    const confirmWindow = window.confirm('Are you sure?');
    if (confirmWindow === false) {
      return;
    }
    const isDeleted = await deleteTripResume(tripId);
    if (isDeleted) {
      console.log(`Trip succefully deleted: ${tripId}`);
      return;
    }
    console.log(`Trip could not be deleted: ${tripId}`);
  };

  return (
    <div className="container padding-10">
      <div className="row fadein-container">
        <div>
          <h2>Manage Trips</h2>
        </div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>{t('DASHBOARD_REGISTER_EXPERT_ID')}</th>
              <th>Title</th>
              <th>Expert User</th>
              <th>Regular User</th>
              <th>Status</th>
              <th>{t('DASHBOARD_REGISTER_EXPERT_ACTIONS')}</th>
            </tr>
          </thead>
          <tbody>
            {(allTrips || []).map((tripResume, index) => {
              const { _id, expertUser, regularUser, title, status } = tripResume;
              return (
                <tr key={index}>
                  <td>
                    <span>{_id} </span>
                    <span>
                      <Button as="a" className="a-button" onClick={goToTrip(_id)}>
                        <i className="fa fa-external-link"></i>
                      </Button>
                    </span>
                  </td>
                  <td>{title}</td>
                  <td>{expertUser.email}</td>
                  <td>{regularUser.email}</td>
                  <td>{t(getTripStatusTranslationString(status))}</td>
                  <td>
                    <input type="submit" value="Delete" className="btn btn-primary  mr-2" onClick={ev => deleteTrip(tripResume._id)} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <InProgress inProgress={inProgress} />
      {!inProgress && allTrips.length === 0 ? <div>{t('TRIP_RESUME_LIST_RETRIVAL_ERROR')}</div> : null}
    </div>
  );
};

const mapStateToProps = state => ({
  allTrips: state.trips.tripResumes,
  inProgress: state.trips.inProgress,
  environmentUrl: state.common.environment ? state.common.environment.url : '',
});

const mapDispatchToProps = dispatch => ({
  getTrips: () => dispatch(getTrips()),
  deleteTripResume: (tripId) => dispatch(deleteTripResume(tripId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageTripsPage);
