import React from 'react';
import { useTranslation } from 'react-i18next';
import NavigationService from '../services/navigation';
import { Button, CustomTooltip } from './Custom';
import TripStatusAction from '../containers/TripStatusAction';
import { getTripStatusTranslationString, openInNewWindow } from '../utils/common';
import { formatCurrency } from '../utils/currencyUtils';
import TripStatusTimeline from './TripStatusTimeline';
import CONSTANTS from '../constants/constants';
import IconGroupButton from './IconGroupButton';
import Skeleton from 'react-loading-skeleton';

const TripRequestServiceIcons = ({ tripRequestServices }) => {
  if (!tripRequestServices) {
    return null;
  }

  const serviceConfigs = Object.keys(CONSTANTS.TRIP_SERVICES_CONFIG).map(serviceConfigKey => CONSTANTS.TRIP_SERVICES_CONFIG[serviceConfigKey]);

  return (
    <div className="btn-group btn-group-sm service-icons" aria-label="Trip Service Group">
      {serviceConfigs.map((serviceConfig, index) => {
        const isServiceActive = tripRequestServices[serviceConfig.MODEL_KEY];
        const labelKey = serviceConfig.SERVICE_NAME_LABEL_KEY;
        return <IconGroupButton key={index} index={index} isActive={isServiceActive} label={labelKey} iconClass={serviceConfig.ICON_NAME} />;
      })}
    </div>
  );
};

const TripListItemInfo = ({ isExpert, trip }) => {
  const { t } = useTranslation();
  const { status, tripRequest, expertUserRead, payment, tripProcessingStatusState } = trip;
  const isNew = expertUserRead === false;

  // if there is no trip request, a trip has not been loaded
  if (!tripRequest) {
    return (
      <>
        <div className="avatar-container">
          <Skeleton circle={true} height={50} width={50} />
        </div>
        <div className="left-text-content small">
          <Skeleton count={3} />
        </div>
      </>
    );
  }

  const { numberOfDays = 0, destinations = [] } = tripRequest;

  const tripDestinations = destinations
    .map(destination => {
      if (!destination.name) {
        console.log('Destination should have a name field. Found this object instead: ' + JSON.stringify(destination));
        return null;
      }
      return destination.name;
    })
    .join(', ');

  const tripUser = trip && (trip.expertUser || trip.regularUser) ? (!isExpert ? trip.expertUser : trip.regularUser) : {};

  return (
    <>
      <CustomTooltip text={tripUser.email || '-'}>
        <div className="avatar-container">
          <Button as="a" className="a-button float-left">
            <img src={tripUser.imageUrl || '/images/default-user-bk.png'} alt={tripUser.email} className="photo rounded-circle" />
          </Button>
          <p className="avatar-name ellipsis-text">{tripUser.name || '-'}</p>
          {isExpert && isNew ? <span className="badge badge-primary">{t('TRIP_RESUME_NEW_ALERT_LABEL')}</span> : null}
        </div>
      </CustomTooltip>
      <div className="left-text-content small">
        <span>{tripDestinations}</span>
        <br />
        <span>
          {t('TRIP_RESUME_DURATION')}: {numberOfDays || '-'} {t('TRIP_RESUME_DURATION_DAYS_SUFFIX')}
        </span>
        <br />
        <span>
          {t('TRIP_RESUME_STATUS')}: {t(getTripStatusTranslationString(status))}{' '}
          {status === CONSTANTS.TRIP_STATUS.TRIP_PROCESSING && tripProcessingStatusState ? <span>({t(tripProcessingStatusState)})</span> : null}
        </span>
        <br />

        {!isExpert && payment && payment.date ? (
          <>
            <span>
              {t('TRIP_RESUME_PAID_VALUE')}: {formatCurrency(payment.currencyCode, payment.value)}
            </span>
            <br />
          </>
        ) : null}

        <TripStatusAction tripId={trip._id} tripStatus={status} isExpert={isExpert} />
      </div>
    </>
  );
};

const TripListItem = ({ isExpert, trip, environmentUrl }) => {
  const { t } = useTranslation();

  const { status, tripRequest } = trip;

  let tripRequestServices = [];
  if (tripRequest) {
    tripRequestServices = tripRequest.services;
  }

  const goToTrip = tripId => ev => {
    const url = `${environmentUrl}/trips/${tripId}`
    openInNewWindow(url);
  };

  const goToMessages = event => {
    event.stopPropagation();
    NavigationService.goToDashboard('dashboard_messages');
  };

  return (
    <div className="row collection-item cursor-pointer" onClick={goToTrip(trip._id)}>
      <div className="col-12 col-md-3">
        <TripListItemInfo isExpert={isExpert} trip={trip} />
      </div>
      <div className="col-12 col-md-6">
        {status ? <TripStatusTimeline tripStatus={status} trip={trip}></TripStatusTimeline> : <Skeleton height={100} />}
      </div>
      <div className=" col-12 col-md-3 collection-item-body text-center">
        {status ? (
          <>
            <div className="col-12">
              <TripRequestServiceIcons tripRequestServices={tripRequestServices} />
            </div>
            <div className="buttons">
              <button type="button" className="btn btn-primary" onClick={goToMessages}>
                {t('BUTTON_MESSAGES')}
              </button>
            </div>
          </>
        ) : (
          <Skeleton height={100} />
        )}
      </div>
    </div>
  );
};

export default TripListItem;
