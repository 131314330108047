import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { saveUser, saveUserProfileImage, getUser, updateCurrentUserField } from '../actions/users';
import { Button } from '../components/Custom';
import ChangeUserPasswordModal from '../components/ChangeUserPasswordModal';

const ProfileShortEditionPage = ({ currentUser, user, saveUser, getUser, updateCurrentUserField, saveUserProfileImage }) => {
  const { t } = useTranslation();

  const userId = currentUser ? currentUser._id : null;
  const currentUserEmail = currentUser ? currentUser.email : null;

  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  // Wrap with useCallback to avoid change on every render. It returns a memoized version of the callback that only changes if one of the provided dependencies change.
  const fetchUser = useCallback(async () => {
    await getUser(userId);
  }, [userId, getUser]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const onSaveUser = user => async event => {
    event.preventDefault();
    await saveUser(user);
  };

  const openChangePassworModal = event => {
    event.preventDefault();
    setShowChangePasswordModal(true);
  };

  const onUpdateUserField = field => ev => {
    const newValue = ev.target.value;
    updateCurrentUserField(field, newValue);
  };

  const onCloseChangePasswordModal = () => {
    setShowChangePasswordModal(false);
  };

  return (
    <>
      <div className="container padding-10">
        <div className="row">
          <div className="col-12">
            <form id="profile-form" className="bold-form" onSubmit={onSaveUser(user)}>
              <h4 className="title">{t('DASHBOARD_USER_INFO')}</h4>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="firstname">{t('DASHBOARD_USER_FIRST_NAME')}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter First Name"
                      name="firstname"
                      value={user.firstname}
                      onChange={onUpdateUserField('firstname')}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="lastname">{t('DASHBOARD_USER_LAST_NAME')}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Last Name"
                      name="lastname"
                      value={user.lastname}
                      onChange={onUpdateUserField('lastname')}
                    />
                  </div>
                </div>
              </div>
              <hr />
              <h4 className="title">{t('DASHBOARD_USER_CONTACT_INFO')}</h4>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="telephone">{t('DASHBOARD_USER_MOBILE')}</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="(+XX) XX 99999-9999"
                      name="telephone"
                      value={user.telephone}
                      onChange={onUpdateUserField('telephone')}
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-12">
                  <h4>{t('DASHBOARD_USER_SECURITY')}</h4>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-6">
                      <span>{t('DASHBOARD_USER_EMAIL')}</span>
                    </div>
                    <div className="col-6">{currentUserEmail}</div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-6">
                      <span>{t('DASHBOARD_USER_PASSWORD')}</span>
                    </div>
                    <div className="col-6">
                      •••••••••••{' '}
                      <Button as="a" className="a-button" onClick={openChangePassworModal}>
                        <i className="fa fa-pencil" aria-hidden="true"></i>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="row">
          <div className="col-12 padding-10">
            <button type="submit" form="profile-form" className="btn btn-primary margin-10">
              {t('BUTTON_SAVE')}
            </button>
          </div>
        </div>
      </div>
      <ChangeUserPasswordModal showModal={showChangePasswordModal} onCloseModal={onCloseChangePasswordModal} />
    </>
  );
};

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  user: state.users.currentUser
});

const mapDispatchToProps = dispatch => ({
  saveUser: user => dispatch(saveUser(user)),
  getUser: userId => dispatch(getUser(userId)),
  saveUserProfileImage: (userId, imageUrl) => dispatch(saveUserProfileImage(userId, imageUrl)),
  updateCurrentUserField: (field, value) => dispatch(updateCurrentUserField(field, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileShortEditionPage);
