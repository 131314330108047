import {
  POST_MESSAGE_REQUEST,
  POST_MESSAGE_FAILURE,
  POST_MESSAGE_SUCCESS,
  GET_CONVERSATION_REQUEST,
  GET_CONVERSATION_FAILURE,
  GET_CONVERSATION_SUCCESS,
  GET_CONVERSATIONS_REQUEST,
  GET_CONVERSATIONS_FAILURE,
  GET_CONVERSATIONS_SUCCESS
} from '../constants/actionTypes';

const defaultState = {
  conversations: [],
  currentConversation: {},
  inProgress: false,
  error: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case POST_MESSAGE_REQUEST:
    case GET_CONVERSATION_REQUEST:
    case GET_CONVERSATIONS_REQUEST:
      return { ...state, inProgress: true };
    case POST_MESSAGE_SUCCESS:
      return {
        ...state,
        inProgress: false,
        error: null
      };
    case POST_MESSAGE_FAILURE:
      return {
        ...state,
        inProgress: false,
        error: action.error ? action.error : null
      };

    case GET_CONVERSATION_SUCCESS:
      return {
        ...state,
        inProgress: false,
        error: null,
        currentConversation: action.payload ? action.payload : null
      };
    case GET_CONVERSATION_FAILURE:
      return {
        ...state,
        inProgress: false,
        currentConversation: defaultState.currentConversation,
        error: action.error ? action.error : null
      };

    case GET_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        inProgress: false,
        error: null,
        messages: defaultState.messages,
        conversations: action.payload ? action.payload : null
      };
    case GET_CONVERSATIONS_FAILURE:
      return {
        ...state,
        inProgress: false,
        messages: defaultState.messages,
        conversations: defaultState.conversations,
        error: action.error ? action.error : null
      };

    default:
      return state;
  }
};
