import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TripEvent from '../components/TripEvent';
import { formatDateCustom } from '../utils/dateUtils';
import { deleteDay, addDay } from '../actions/tripPlans';
import CONSTANTS from '../constants/constants';
import NavigationService from '../services/navigation';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { updateTripPlanCurrentEventId } from '../actions/tripPlans';
import CustomToggle from '../components/CustomToggle';
import { Dropdown } from 'react-bootstrap';
import { deepCopy } from '../utils/common';

const DraggableTripEvent = ({ index, tripEvent, isSelectedEvent, dayId, onSelectEvent, emitTripEventPickEvent, isDragDisabled }) => {
  // all direction events dragging are disabled by default
  const isDragDisabledCondition = isDragDisabled || tripEvent.kind === CONSTANTS.TRIP_EVENT_KINDS.DIRECTIONS;
  const draggableId = tripEvent._id || `temp-draggable-id-${index}`; // fallback value since the draggable id and a Trip Event Id can be undefined at this point
  return (
    <Draggable draggableId={draggableId} index={index} isDragDisabled={isDragDisabledCondition} type={CONSTANTS.DRAGGABLE_TYPES.TRIP_EVENT}>
      {provided => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <TripEvent
            index={index}
            tripEvent={tripEvent}
            mapIndex={tripEvent.mapIndex}
            isSelected={isSelectedEvent}
            onSelectEvent={onSelectEvent}
            actionFn={emitTripEventPickEvent(dayId, tripEvent)}
          ></TripEvent>
        </div>
      )}
    </Draggable>
  );
};

const TripDayDroppableArea = ({ dayId, selectedEventId, dayEvents, onSelectEvent, emitTripEventPickEvent, isDragAndDropDisabled, view }) => {
  return (
    <Droppable droppableId={dayId} isDropDisabled={isDragAndDropDisabled} type={CONSTANTS.DRAGGABLE_TYPES.TRIP_EVENT}>
      {provided => (
        <div className="row-wrapper trip-canvas-drag-box trip-canvas-scrollbar" ref={provided.innerRef} {...provided.droppableProps}>
          {dayEvents.map((tripEvent, index) => {
            if (tripEvent.hidden && view) {
              return null;
            }

            return (
              <DraggableTripEvent
                tripEvent={tripEvent}
                key={tripEvent._id || index}
                index={index}
                dayId={dayId}
                isSelectedEvent={tripEvent._id === selectedEventId}
                onSelectEvent={onSelectEvent}
                emitTripEventPickEvent={emitTripEventPickEvent}
                isDragDisabled={isDragAndDropDisabled}
              ></DraggableTripEvent>
            );
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const TripDayCanvas = ({
  view,
  tripId,
  dayIndex,
  day,
  startDate,
  isTripTemplate,
  dayViewActionFn,
  inProgress,
  currentTripPlanEventId,
  deleteDay,
  duplicateDay,
  isDragAndDropDisabled,
  updateTripPlanCurrentEventId
}) => {
  const { t } = useTranslation();
  const ONE_DAY = CONSTANTS.TIME.DAY;
  const [dayEvents, setDayEvents] = useState([]);

  useEffect(() => {
    // copy day events to avoid flickering on drag end
    const copyDayEvents = deepCopy(day.events || []);
    setDayEvents(copyDayEvents);
  }, [day]);

  const dateTime = new Date(startDate).getTime() + dayIndex * ONE_DAY;
  const dayHeader = `${t('TRIP_DAY_CANVAS_DAY')} ${dayIndex + 1} ${dateTime ? '- ' + formatDateCustom(dateTime, 'DD/MM ddd') : ''} `;

  const emitTripDayPickEvent = dayId => ev => {
    ev.preventDefault();
    if (dayViewActionFn) {
      dayViewActionFn(dayId); // this function overrides the default behavior of this event
      return;
    }

    NavigationService.goToTripDayView(tripId, dayId, null, isTripTemplate);
  };
  const emitTripEventPickEvent = (dayId, tripEvent) => ev => {
    ev.preventDefault();
    if (dayViewActionFn) {
      dayViewActionFn(dayId, tripEvent); // this function overrides the default behavior of this event
      return;
    }
    NavigationService.goToTripDayView(tripId, dayId, tripEvent._id, isTripTemplate);
  };

  const onSelectEvent = tripEvent => ev => {
    ev.preventDefault();
    updateTripPlanCurrentEventId(tripEvent._id);
  };

  const onDeleteDay = async () => {
    const dayId = day._id;
    await deleteDay(tripId, dayId, isTripTemplate); // action should display error message if any
  };

  const onDuplicateDay = async () => {
    const { events: dayEvents } = day;
    await duplicateDay(tripId, isTripTemplate, dayEvents);
  };

  return (
    <div className="trip-canvas-column-wrapper fadein-container">
      <div className="trip-canvas-column trip-canvas-scrollbar">
        <div className="column-handle">
          {dayHeader}
          <div className="menu">
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" drop="down">
                <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  eventKey="1"
                  onClick={emitTripDayPickEvent(day._id)}
                  title={t('BUTTON_DAY_SUMMARY')}
                  className="trip-canvas-link a-button"
                >
                  <i className="fa fa-align-justify" aria-hidden="true"></i> {t('BUTTON_DAY_SUMMARY')}
                </Dropdown.Item>

                {!view ? (
                  <>
                    <Dropdown.Item eventKey="2" onClick={onDeleteDay} className="trip-canvas-link a-button">
                      <i className="fa fa-trash" aria-hidden="true"></i> {t('BUTTON_DELETE_DAY')}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="3" onClick={onDuplicateDay} className="trip-canvas-link a-button">
                      <i className="fa fa-copy" aria-hidden="true"></i> {t('BUTTON_DUPLICATE_DAY')}
                    </Dropdown.Item>
                  </>
                ) : null}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        {inProgress ? (
          <div className="load-overlay show">
            <div className="text">
              <i className="fa fa-spinner fa-spin spin-size"></i>
            </div>
          </div>
        ) : null}

        <TripDayDroppableArea
          dayId={day._id}
          selectedEventId={currentTripPlanEventId}
          dayEvents={dayEvents}
          onSelectEvent={onSelectEvent}
          emitTripEventPickEvent={emitTripEventPickEvent}
          isDragAndDropDisabled={isDragAndDropDisabled}
          view={view}
        ></TripDayDroppableArea>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  inProgress: state.tripPlans.inProgress,
  currentTripPlanEventId: state.tripPlans.currentTripPlanEventId
});
const mapDispatchToProps = dispatch => ({
  deleteDay: (tripId, dayId, isTemplate) => dispatch(deleteDay(tripId, dayId, isTemplate)),
  duplicateDay: (tripId, isTemplate, queryEvents) => dispatch(addDay(tripId, isTemplate, queryEvents)),
  updateTripPlanCurrentEventId: eventId => dispatch(updateTripPlanCurrentEventId(eventId))
});

export default connect(mapStateToProps, mapDispatchToProps)(TripDayCanvas);
