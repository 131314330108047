import React from 'react';
import { Link } from 'react-router-dom';
import HeaderMenu from '../containers/HeaderMenu';

const Header = ({ }) => {

  return (
    <nav className="navbar fixed-top navbar-light navbar-default">
      <div className="container">
        <Link to="/" className="navbar-brand">
          <img alt="Voyagezy Logo" src="/images/logo.png" className="voyagezy-logo" />
        </Link>
        <div className="menu">
          <HeaderMenu />
        </div>
      </div>
    </nav>
  );
};

export default Header;
