import {
  DESTINATION_RESET,
  UPDATE_CURRENT_DESTINATION_FIELD,
  DESTINATIONS_GET_REQUEST,
  DESTINATIONS_GET_SUCCESS,
  DESTINATIONS_GET_FAILURE,
  DESTINATION_POST_REQUEST,
  DESTINATION_POST_SUCCESS,
  DESTINATION_POST_FAILURE,
  DESTINATION_DELETE_REQUEST,
  DESTINATION_DELETE_SUCCESS,
  DESTINATION_DELETE_FAILURE,
  DESTINATION_GET_REQUEST,
  DESTINATION_GET_SUCCESS,
  DESTINATION_GET_FAILURE,
  DESTINATION_PUT_REQUEST,
  DESTINATION_PUT_SUCCESS,
  DESTINATION_PUT_FAILURE
} from '../constants/actionTypes';

import agent from '../agent';
import i18next from 'i18next';
import { setToastSuccessMessage, setToastErrorMessage } from './common';
import { getDefaultDestination } from '../utils/destinationUtils';

export const resetCurrentDestination = () => ({ type: DESTINATION_RESET });
export const updateCurrentDestinationField = (name, value, language) => ({ type: UPDATE_CURRENT_DESTINATION_FIELD, key: name, value, language });

export const getDestinationsRequest = () => ({ type: DESTINATIONS_GET_REQUEST });

export const getDestinationsSuccess = payload => ({
  type: DESTINATIONS_GET_SUCCESS,
  payload
});
export const getDestinationsFailure = errorMsg => ({ type: DESTINATIONS_GET_FAILURE, error: { message: errorMsg } });

export const getDestinations = () => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(getDestinationsRequest());

    const errorMsg = i18next.t('DESTINATION_LIST_RETRIEVAL_ERROR');

    try {
      const paginatedResult = await agent.DestinationsService.getDestinations(); //paginated result
      const destinations = paginatedResult.result.listings

      // this is necessary because the $scope.destinationSearchCode filter requires an empty string (null is not breaks the filtering)
      destinations = (destinations || []).map(destination => {
        if (destination.destinationCode === null) {
          destination.destinationCode = '';
        }
        return destination;
      });

      const sortedDestinations = (destinations || []).sort((a, b) => new Date(b.name) - new Date(a.name));

      dispatch(getDestinationsSuccess(sortedDestinations));
      return sortedDestinations;
    } catch (error) {
      console.log(`Error when retrieving Destinations list: ${JSON.stringify(error)}`);
      dispatch(getDestinationsFailure(errorMsg));
    }
  };
};

export const deleteDestinationRequest = () => ({ type: DESTINATION_DELETE_REQUEST });

export const deleteDestinationSuccess = (message, destinationId) => ({
  type: DESTINATION_DELETE_SUCCESS,
  message,
  destinationId
});

export const deleteDestinationFailure = errorMsg => ({ type: DESTINATION_DELETE_FAILURE, error: { message: errorMsg } });

export const deleteDestination = destinationId => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(deleteDestinationRequest());

    try {
      await agent.DestinationsService.deleteDestination(destinationId);
      const deletionSuccessMessage = i18next.t('DESTINATION_DELETION_SUCCESS');
      dispatch(deleteDestinationSuccess(deletionSuccessMessage, destinationId));
      dispatch(setToastSuccessMessage(deletionSuccessMessage, `Destination successfully deleted: ${destinationId}`));
    } catch (error) {
      const errorMsg = `${i18next.t('DESTINATION_DELETION_ERROR_PREFIX')} : ${error.message}`;
      dispatch(deleteDestinationFailure(errorMsg));
      dispatch(setToastErrorMessage(errorMsg, `Error when deleting a Destination: ${JSON.stringify(error)}`));
    }
  };
};

export const postDestinationRequest = () => ({ type: DESTINATION_POST_REQUEST });

export const postDestinationSuccess = (message, payload) => ({
  type: DESTINATION_POST_SUCCESS,
  message,
  payload
});

export const postDestinationFailure = errorMsg => ({ type: DESTINATION_POST_FAILURE, error: { message: errorMsg } });

export const createDestination = destination => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(postDestinationRequest());

    try {
      // result contains id and success message
      const result = await agent.DestinationsService.postDestination(destination);
      const newDestinaton = { ...destination, _id: result.id }
      // transform new entry for destinations list
      const newDestinationListItem = { ...newDestinaton, name: newDestinaton.name.en, description: newDestinaton.description.en, title: newDestinaton.title.en, country: newDestinaton.country.en }
      dispatch(postDestinationSuccess(i18next.t('DESTINATION_CREATION_SUCCESS'), newDestinationListItem));
      dispatch(setToastSuccessMessage(i18next.t('DESTINATION_CREATION_SUCCESS'), `'Destination successfully created: ${result.id}`));
      return newDestinaton;
    } catch (error) {
      const errorMsg = `${i18next.t('DESTINATION_CREATION_ERROR_PREFIX')} : ${error.message}`;
      dispatch(postDestinationFailure(errorMsg));
      dispatch(setToastErrorMessage(errorMsg, `Error when creating a Destination: ${JSON.stringify(error)}`));
      return null;
    }
  };
};

export const getDestinationRequest = () => ({ type: DESTINATION_GET_REQUEST });

export const getDestinationSuccess = payload => ({
  type: DESTINATION_GET_SUCCESS,
  payload
});
export const getDestinationFailure = errorMsg => ({ type: DESTINATION_GET_FAILURE, error: { message: errorMsg } });

export const getDestination = destinationId => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(getDestinationRequest());

    try {
      let destination = await agent.DestinationsService.getDestination(destinationId);
      destination = { ...getDefaultDestination(), ...destination }; // prevents null pointers
      dispatch(getDestinationSuccess(destination));
      return destination;
    } catch (error) {
      const errorMsg = `${i18next.t('DESTINATION_RETRIEVAL_ERROR')} : ${error.message}`;
      dispatch(getDestinationFailure(errorMsg));
      dispatch(setToastErrorMessage(errorMsg, `Error when retrieving a Destination: ${JSON.stringify(error)}`));
      return null;
    }
  };
};

export const putDestinationRequest = () => ({ type: DESTINATION_PUT_REQUEST });

export const putDestinationSuccess = (message, payload) => ({
  type: DESTINATION_PUT_SUCCESS,
  message,
  payload
});
export const putDestinationFailure = errorMsg => ({ type: DESTINATION_PUT_FAILURE, error: { message: errorMsg } });

export const saveDestination = (destinationId, destination) => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(putDestinationRequest());

    try {
      // result contains id and success message
      const result = await agent.DestinationsService.updateDestination(destinationId, destination);
      dispatch(putDestinationSuccess(i18next.t('DESTINATION_SAVE_SUCCESS'), destination));
      dispatch(setToastSuccessMessage(i18next.t('DESTINATION_SAVE_SUCCESS'), `Destination successfully saved: ${destinationId}`));
      return destination;
    } catch (error) {
      const errorMsg = `${i18next.t('DESTINATION_UPDATE_ERROR_PREFIX')} : ${error.message}`;
      dispatch(putDestinationFailure(errorMsg));
      dispatch(setToastErrorMessage(errorMsg, `Error when updating a Destination: ${JSON.stringify(error)}`));
      return null;
    }
  };
};
