import React from 'react';
import CONSTANTS from '../constants/constants';
import SelectCustomBox from './SelectCustomBox';

const SelectTransportationBox = ({ transportationType, hasDefault, onTransportationChange }) => {
  return (
    <SelectCustomBox value={transportationType} values={CONSTANTS.DIRECTIONS_TYPES} hasDefault={hasDefault} onValueChange={onTransportationChange} />
  );
};

export default SelectTransportationBox;
