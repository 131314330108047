import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const TooltipIcon = ({ iconClass, textKey, icon, tooltip }) => {
  const { t } = useTranslation();

  if (!icon) {
    return null;
  }
  return (
    <OverlayTrigger
      overlay={
        <Tooltip placement="top" delay={{ show: 150, hide: 200 }} show={tooltip} id="tooltip-disabled">
          {t(textKey)}
        </Tooltip>
      }
    >
      <i className={iconClass} title={t(textKey)} aria-hidden="true"></i>
    </OverlayTrigger>
  );
};

export default TooltipIcon;
