import Chart from 'chart.js';

const getArrayFromObjectArray = function (arr, propertyName) {
  return (arr || []).map(function (element) {
    return element[propertyName];
  });
};

export const eraseChart = function (pieChart) {
  if (!pieChart) {
    return;
  }
  pieChart.destroy();
};

/*
 * chartId : DOM id of the chart
 * data: array of objects containing label and value
 */
export const buildPieChart = function (chartId, data) {
  const canvas = document.getElementById(chartId);
  if (!canvas) {
    return;
  }
  const ctx = canvas.getContext('2d');

  const labels = getArrayFromObjectArray(data, 'label');
  let values = getArrayFromObjectArray(data, 'value');
  const colors = getArrayFromObjectArray(data, 'color');

  // convert values in cents to currency units
  values = values.map(function (value) {
    return value / 100;
  });

  // Global Options:
  Chart.defaults.global.defaultFontColor = 'black';
  Chart.defaults.global.defaultFontSize = 16;

  const piedata = {
    labels: labels,
    datasets: [
      {
        fill: true,
        backgroundColor: colors,
        data: values
      }
    ]
  };

  const options = {
    responsive: true,
    legend: {
      display: false
    }
  };

  // Chart declaration:
  const pieChart = new Chart(ctx, {
    type: 'pie',
    data: piedata,
    options
  });

  return pieChart;
};

export const getChartPiece = (label, value, color) => {
  return {
    label,
    value,
    color
  };
};
