export const getAllCurrencies = () => {
  return {
    EUR: {
      label: 'Euro',
      symbol: '€'
    },
    BRL: {
      label: 'Real',
      symbol: 'R$'
    },
    USD: {
      label: 'US Dollar',
      symbol: '$'
    }
  };
};

export const getCurrency = currencyCode => {
  const currencies = getAllCurrencies();
  const defaultCurrency = {
    label: '',
    symbol: ''
  };

  return currencies[currencyCode] || defaultCurrency;
};

/* Receives a price value in cents as input and returns the parsed string in monetary units*/
export const getMonetaryValueString = valueInCents => {
  let value, strValue;

  value = convertToMonetaryValue(valueInCents) || '';
  strValue = value.toString();

  if (valueInCents === 0) {
    strValue = '0.00';
  } else if (valueInCents % 100 === 0) {
    // for instance, case 1100
    strValue += '.00';
  } else if (valueInCents % 10 === 0) {
    // for instance, case 1170
    strValue += '0';
  }
  return strValue.replace('.', ',');
};

export const formatCurrency = (currencyCode, valueInCents) => {
  const currency = getCurrency(currencyCode).symbol || '';
  const monetaryValue = getMonetaryValueString(valueInCents) || '-';
  return `${currency} ${monetaryValue}`;
};

/* Receives a price value in cents as input and returns a value in monetary unit*/
export const convertToMonetaryValue = valueInCents => {
  let value;

  if (typeof valueInCents !== 'number') {
    return;
  }
  valueInCents = valueInCents / 100;
  value = +valueInCents.toFixed(2);
  return value;
};

/* Receives a price number as input and returns the corresponding value in cents*/
export const getMonetaryValueInCents = function (value) {
  if (typeof value !== 'number') {
    return;
  }
  return Math.floor(value.toFixed(2) * 100);
};

/* Checks validity of price */
export const isValidMonetaryValue = valueInCents => {
  if (typeof valueInCents !== 'number') {
    return false;
  }
  // checks whether price value is an integer value expressed in cents
  if (parseInt(valueInCents, 10) !== valueInCents) {
    return false;
  }
  return true;
};
