import { getDirectionEvent } from './tripUtils';
import CONSTANTS from '../constants/constants';
import { reorder, transferArrayElement } from './common';

/**
 * Return all trip plan events
 *
 * @param {Object} tripPlan
 */
export const retrieveAllTripPlanEvents = (tripPlan) => {
  const { days } = tripPlan;
  const events = days.map(day => day.events);
  return events.flat();
};

/**
 * Update Trip Plan days according to a certain action
 *
 * @param {Object} tripPlan
 * @param {Function} actionFn
 */
export const updateTripPlanDays = (tripPlan, actionFn) => {
  const { days } = tripPlan;
  const updatedDays = actionFn(days);
  const updatedTripPlan = { ...tripPlan, days: updatedDays };
  return updatedTripPlan;
};

/**
 * Move Event in Trip Plan Days array and return the two updated days in an object
 *
 * @param {String} tripDataModelId
 * @param {String} dayIdFrom
 * @param {Number} positionFrom
 * @param {String} dayIdTo
 * @param {Number} positionTo
 * @param {String} isTripTemplate
 */
export const moveEventFromOneDayToAnother = async (tripPlanDays, dayIdFrom, positionFrom, dayIdTo, positionTo) => {
  const daySourceIndex = tripPlanDays.findIndex(day => day._id === dayIdFrom);
  const dayTargetIndex = tripPlanDays.findIndex(day => day._id === dayIdTo);

  let tripPlanDayEvents = tripPlanDays[daySourceIndex].events;
  // case 1: events reordered in the same day
  if (daySourceIndex === dayTargetIndex) {
    tripPlanDays[daySourceIndex].events = reorder(tripPlanDayEvents, positionFrom, positionTo);
  } else {
    // case 2: events reordered in the different days
    const tripPlanDayEventsTarget = tripPlanDays[dayTargetIndex].events;
    transferArrayElement(tripPlanDayEvents, tripPlanDayEventsTarget, positionFrom, positionTo);
  }
};

const filterOutInvalidDirectionEvents = (dayEvents = []) => {
  const tripEventIdsToRemove = [];
  let updateDayEvents = [...dayEvents];

  // first Trip Event cannot be a Direction Event
  const firstEvent = updateDayEvents[0];
  if (firstEvent && firstEvent.kind === CONSTANTS.TRIP_EVENT_KINDS.DIRECTIONS) {
    tripEventIdsToRemove.push(firstEvent._id);
  }

  // last Trip Event cannot be a Direction Event
  const lastEvent = updateDayEvents[updateDayEvents.length - 1];
  if (lastEvent && lastEvent.kind === CONSTANTS.TRIP_EVENT_KINDS.DIRECTIONS) {
    tripEventIdsToRemove.push(lastEvent._id);
  }

  // between the second and the last element, filters out all of the events that are duplicate or invalid
  for (let index = 1; index < updateDayEvents.length - 1; index++) {
    const previousEvent = updateDayEvents[index - 1];
    const currentEvent = updateDayEvents[index];
    const nextEvent = updateDayEvents[index + 1];

    const previousEventCondition = previousEvent.kind === currentEvent.kind && currentEvent.kind === CONSTANTS.TRIP_EVENT_KINDS.DIRECTIONS;
    const nextEventCondition = nextEvent.kind === currentEvent.kind && currentEvent.kind === CONSTANTS.TRIP_EVENT_KINDS.DIRECTIONS;
    if (previousEventCondition || nextEventCondition) {
      tripEventIdsToRemove.push(currentEvent._id);
    }
  }

  updateDayEvents = updateDayEvents.filter(tripEvent => tripEventIdsToRemove.indexOf(tripEvent._id) === -1);
  return updateDayEvents;
};

// Adds a missing Direction Trip Event between Two Consecutive Non Direction Trip Events
const executeAddMissingDirectionEvent = async (dayEvents = []) => {
  for (let index = 1; index < dayEvents.length; index++) {
    const previousEvent = dayEvents[index - 1];
    const currentEvent = dayEvents[index];

    if (previousEvent.kind !== CONSTANTS.TRIP_EVENT_KINDS.DIRECTIONS && currentEvent.kind !== CONSTANTS.TRIP_EVENT_KINDS.DIRECTIONS) {
      let newDirectionEvent = await getDirectionEvent(previousEvent, currentEvent);
      dayEvents.splice(index, 0, newDirectionEvent);

      index++; // avoids unnecessary iteration since we will certainly have a TripPlanDirectionEvent in the next loop execution
    }
  }
};

const executeFixDirectionsEventsAddress = (dayEvents = []) => {
  for (let index = 1; index < dayEvents.length - 1; index++) {
    const previousEvent = dayEvents[index - 1];
    const currentEvent = dayEvents[index];
    const nextEvent = dayEvents[index + 1];

    if (
      currentEvent.kind === CONSTANTS.TRIP_EVENT_KINDS.DIRECTIONS &&
      currentEvent.origin &&
      currentEvent.destination &&
      (previousEvent.address !== currentEvent.origin.address || nextEvent.address !== currentEvent.destination.address)
    ) {
      currentEvent.origin = { ...previousEvent };
      currentEvent.destination = { ...nextEvent };
    }
  }
};

/**
 * Fix inconsistencies related to misplaced or invalid Directions Trip events: Direction Trip Events should be intertwined with other types
 *
 * @param {Array} dayEvents array of Trip events in a day
 * @returns {Array} array of Trip events in a day
 */
export const applyCorrectionToTripDayEventsLocal = async dayEvents => {
  //let tripEventIdsToRemove = [];
  let updateDayEvents = [...dayEvents];

  // rule 1 : directions Events cannot be the first --> ACTION: Remove invalid directions events
  updateDayEvents = filterOutInvalidDirectionEvents(dayEvents);

  // rule 2 : no consecutive non directions events --> ACTION: Add one direction event between them
  await executeAddMissingDirectionEvent(updateDayEvents);

  // rule 3 : in directions event, origin address must be equal to previous event address and destination address must be equal to next destination address --> ACTION: update directions event data
  executeFixDirectionsEventsAddress(updateDayEvents);

  return updateDayEvents;
};

/**
 * Fix inconsistencies related to misplaced or invalid Directions Trip events: Direction Trip Events should be intertwined with other types
 *
 * @param {Object} tripPlan
 * @param {String} dayId
 * @param {String} eventId
 * @returns {Object} updated day after deletion
 */
export const removeEventAndApplyCorrection = async (tripPlan, dayId, eventId) => {
  const updatedDays = [...tripPlan.days];
  const [day] = updatedDays.filter(day => day._id === dayId);

  if (!day || !day.events) {
    throw new Error(`Something went wrong when deleting a Trip Event. Day could not be found by its corresponding ${dayId}`);
  }
  const dayEventsFiltered = day.events.filter(event => event._id !== eventId);
  const updatedDayEvents = await applyCorrectionToTripDayEventsLocal(dayEventsFiltered);
  return { ...day, events: updatedDayEvents };
};
