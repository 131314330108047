import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SideNav from '../components/SideNav';
import MapCanvasWrapper from '../components/MapCanvasWrapper';
import { addEventToTripDayPlan, updateEventInTripDayPlan, updateTripPlanCurrentEventId } from '../actions/tripPlans';
import { addMapIndexToTripEvents, initializeMapPositions } from '../utils/tripUtils';
import TripEventEditionSideNav from './TripEventEditionSideNav';
import TripDayPlannerLeftMenu from './TripDayPlannerLeftMenu';
import TripDayPlannerDaySummarySideNav from './TripDayPlannerDaySummarySideNav';
import TripDayPlannerEventSideNav from './TripDayPlannerEventSideNav';
import { getTripPlanDayWithIndex, getTripPlanEvent } from '../selectors/tripPlans';
import TripDayPlannerRightMenu from './TripDayPlannerRightMenu';

const MapContainer = ({ currentEvent, dayEvents, edition, searchPlace, destinationSearchName, onMapsLoaded }) => {
  useEffect(() => {
    setSelectedMarkerId(currentEvent ? currentEvent._id : null);
  }, [currentEvent]);

  const [selectedMarkerId, setSelectedMarkerId] = useState(null);

  const [mapPositions, setMapPositions] = useState(null);
  useEffect(() => {
    if (!dayEvents) {
      return;
    }
    const mapPositions = initializeMapPositions(dayEvents);
    setMapPositions(mapPositions);
  }, [dayEvents]);

  return (
    <MapCanvasWrapper
      id="map_canvas"
      positions={mapPositions}
      edition={edition}
      searchPlace={searchPlace}
      destinationSearchName={destinationSearchName}
      onMapsLoaded={onMapsLoaded}
      selectedMarkerId={selectedMarkerId}
    ></MapCanvasWrapper>
  );
};

const TripDayPlanner = ({
  trip,
  day,
  currentEvent,
  dayId,
  isTripTemplate,
  currentUser,
  updateEventInTripDayPlan,
  addEventToTripDayPlan,
  updateTripPlanCurrentEventId
}) => {
  console.log('Component TripDayPlanner rendered');
  let { _id: tripId, tripRequest } = trip;
  const isExpertUser = currentUser ? currentUser.isExpert : null;

  const [destinationSearch, setDestinationSearch] = useState({});

  useEffect(() => {
    const destination = tripRequest && tripRequest.destinations ? tripRequest.destinations[0] : {};
    setDestinationSearch(destination);
  }, [tripRequest]);

  const [isOpenEvent, setIsOpenEvent] = useState(null); // Three possible values: null, true and false
  const [isOpenLocationsSideNav, setIsOpenLocationsSideNav] = useState(null); // Three possible values: null, true and false
  const [showTripEventEditionForm, setShowTripEventEditionForm] = useState(false);
  const [maps, setMaps] = useState(null);
  const [searchPlace, setSearchPlace] = useState(null);
  const [newEventFromLocation, setNewEventFromLocation] = useState(null);

  useEffect(() => {
    // picked location from other component
    if (newEventFromLocation !== null) {
      setIsOpenLocationsSideNav(true);
    }
  }, [newEventFromLocation]);

  const resetNewEventAndOpenLocationsSideNav = async ev => {
    onOpenLocationsSideNav(ev);
    setShowTripEventEditionForm(false);
    updateTripPlanCurrentEventId(''); // this should reset new event
  };

  const onCloseEventSideNav = ev => {
    ev.preventDefault();
    setIsOpenEvent(false);
  };

  const onOpenLocationsSideNav = ev => {
    ev.preventDefault();
    setIsOpenLocationsSideNav(true);
  };
  const onCloseLocationsSideNav = ev => {
    ev.preventDefault();
    setIsOpenLocationsSideNav(false);
  };

  const onMapsLoaded = maps => {
    setMaps(maps);
  };

  const pickDayOrEventAction = (dayIndex, tripEvent) => {
    setIsOpenEvent(true);
    setIsOpenLocationsSideNav(false);

    if (tripEvent) {
      updateTripPlanCurrentEventId(tripEvent._id);
      //NavigationService.goToTripDayView(tripId, day._id, tripEvent._id, isTripTemplate);
      return;
    }
    updateTripPlanCurrentEventId('');
    //NavigationService.goToTripDayView(tripId, day._id, null, isTripTemplate);
  };

  const editEventOnParent = event => {
    setIsOpenLocationsSideNav(true);
    setShowTripEventEditionForm(true);
    updateTripPlanCurrentEventId(event._id);
  };

  const callbackEventSave = () => {
    setIsOpenLocationsSideNav(false);
    setIsOpenEvent(true);
  };

  const callbackEventDeletion = async () => {
    setIsOpenEvent(false);
  };

  const onUpdateEvent = async eventPayload => {
    const result = await updateEventInTripDayPlan(day, tripId, dayId, eventPayload, isTripTemplate);
    return result;
  };

  const onAddEvent = async eventPayload => {
    const result = await addEventToTripDayPlan(day, tripId, dayId, eventPayload, isTripTemplate);
    return result;
  };

  if (day && day.events) {
    addMapIndexToTripEvents(day.events); // mutates the events by adding a mapIndex property
  }
  return (
    <div className="day-view">
      <div className="day-view-resume">
        <TripDayPlannerLeftMenu
          day={day}
          trip={trip}
          isTripTemplate={isTripTemplate}
          openEventAdditionSideNav={resetNewEventAndOpenLocationsSideNav}
          pickDayOrEventAction={pickDayOrEventAction}
        />
      </div>
      <SideNav id="left-event-sidenav" isOpen={isOpenEvent} left={true} triggerCloseEvent={onCloseEventSideNav}>
        <div className="content">
          <div className="col-12 trip-event-info">
            {currentEvent && currentEvent._id ? (
              <TripDayPlannerEventSideNav
                tripId={tripId}
                dayId={dayId}
                isTripTemplate={isTripTemplate}
                dayEvents={day.events}
                isExpertUser={isExpertUser}
                callbackEventSave={callbackEventSave}
                callbackEventDeletion={callbackEventDeletion}
                editEventOnParent={editEventOnParent}
              />
            ) : (
              <TripDayPlannerDaySummarySideNav tripId={tripId} isTripTemplate={isTripTemplate} tripDay={day} isExpertUser={isExpertUser} />
            )}
          </div>
        </div>
      </SideNav>

      <SideNav id="left-locations-sidenav" isOpen={isOpenLocationsSideNav} left={true} triggerCloseEvent={onCloseLocationsSideNav}>
        {isExpertUser && isOpenLocationsSideNav !== null ? (
          <TripEventEditionSideNav
            currentEvent={currentEvent}
            maps={maps}
            showTripEventEditionForm={showTripEventEditionForm}
            destinationSearch={destinationSearch}
            setSearchPlace={setSearchPlace}
            setDestinationSearch={setDestinationSearch}
            isTripTemplate={isTripTemplate}
            updateEvent={onUpdateEvent}
            addEvent={onAddEvent}
            newEventFromLocation={newEventFromLocation}
          />
        ) : null}
      </SideNav>

      <div className="day-view-content">
        <MapContainer
          dayEvents={day && day.events ? day.events : null}
          edition={isExpertUser}
          searchPlace={searchPlace}
          destinationSearchName={destinationSearch.name}
          onMapsLoaded={onMapsLoaded}
          currentEvent={currentEvent}
        ></MapContainer>
      </div>
      {isExpertUser ? <TripDayPlannerRightMenu setDestinationSearch={setDestinationSearch} setSearchPlace={setSearchPlace} setNewEventFromLocation={setNewEventFromLocation}> </TripDayPlannerRightMenu> : null}
    </div>
  );
};

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  day: getTripPlanDayWithIndex(state.tripPlans.currentTripPlan, state.tripPlans.currentTripPlanDayId),
  currentEvent: getTripPlanEvent(state.tripPlans.currentTripPlan, state.tripPlans.currentTripPlanDayId, state.tripPlans.currentTripPlanEventId)
});

const mapDispatchToProps = dispatch => ({
  updateEventInTripDayPlan: (day, tripId, dayId, queryEvent, isTemplate) =>
    dispatch(updateEventInTripDayPlan(day, tripId, dayId, queryEvent, isTemplate)),
  addEventToTripDayPlan: (day, tripId, dayId, queryEvent, isTemplate) => dispatch(addEventToTripDayPlan(day, tripId, dayId, queryEvent, isTemplate)),
  updateTripPlanCurrentEventId: eventId => dispatch(updateTripPlanCurrentEventId(eventId))
});

export default connect(mapStateToProps, mapDispatchToProps)(TripDayPlanner);
