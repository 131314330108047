import React, { useState } from 'react';
import { connect } from 'react-redux';
import { reviewExpertUser } from '../actions/trips';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { Button } from './Custom';

const ReviewExpertModal = ({ tripId, showExpertReviewModal, onCloseModal, reviewExpertUser }) => {
  const { t } = useTranslation();

  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [callbackMessage, setCallbackMessage] = useState('');

  const handleClose = () => {
    onCloseModal();
  };

  const submitReview = async () => {
    if (!tripId) {
      setCallbackMessage(t('MODAL_EXPERT_REVIEW_ERROR_MISSING_TRIP_ID'));
      return;
    }
    if (!comment || rating === 0) {
      setCallbackMessage(t('MODAL_EXPERT_REVIEW_ERROR_EVALUATION'));
      return;
    }

    const ratingModel = {
      comment,
      evaluation: rating
    };

    const error = await reviewExpertUser(tripId, ratingModel);

    if (!error) {
      // closes the modal on success only
      onCloseModal();
    }
    setCallbackMessage(error);
  };

  const setReviewComment = ev => {
    const message = ev.target.value;
    const comment = message;
    setComment(comment);
  };

  const setEvaluation = value => ev => {
    ev.preventDefault();
    const rating = value;
    setRating(rating);
  };

  const renderStar = (currentRating, value) => {
    return (
      <Button as="a" className="a-button" onClick={setEvaluation(value)}>
        <i className={`fa fa-3x star-color ${currentRating >= value ? 'fa-star' : 'fa-star-o'}`}></i>
      </Button>
    );
  };

  return (
    <div>
      <Modal id="changeRequestModal" size="lg" animation="true" show={showExpertReviewModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <h2>{t('MODAL_EXPERT_REVIEW_TITLE')}</h2>
            <Button as="a" href className="a-button float-right" onClick={handleClose}>
              <i className="material-icons">close</i>
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body" id="modal-body">
          <div className="width-100">
            <div className="container">
              <div className="row justify-content-center">
                <p className="col-12 text-center">{t('MODAL_EXPERT_REVIEW_EXPERIENCE_QUESTION')}</p>
                <div className="col-12 margin-10 text-center">
                  {renderStar(rating, 1)}
                  {renderStar(rating, 2)}
                  {renderStar(rating, 3)}
                  {renderStar(rating, 4)}
                  {renderStar(rating, 5)}
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="offset-xs-2 col-md-8">
                  <textarea
                    className="form-control"
                    rows="4"
                    placeholder={t('MODAL_EXPERT_REVIEW_COMMENT_PLACEHOLDER')}
                    value={comment}
                    onChange={setReviewComment}
                  ></textarea>
                </div>
              </div>
              <div className="row justify-content-center">
                <p className="callback-error-message">{callbackMessage}</p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="submit" className="btn btn-primary" onClick={submitReview}>
            {t('BUTTON_SUBMIT_REVIEW')}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  reviewExpertUser: (tripResumeId, rating) => dispatch(reviewExpertUser(tripResumeId, rating))
});

export default connect(null, mapDispatchToProps)(ReviewExpertModal);
