import {
  DESTINATION_RESET,
  UPDATE_CURRENT_DESTINATION_FIELD,
  DESTINATIONS_GET_REQUEST,
  DESTINATIONS_GET_SUCCESS,
  DESTINATIONS_GET_FAILURE,
  DESTINATION_POST_REQUEST,
  DESTINATION_POST_SUCCESS,
  DESTINATION_POST_FAILURE,
  DESTINATION_DELETE_REQUEST,
  DESTINATION_DELETE_SUCCESS,
  DESTINATION_DELETE_FAILURE,
  DESTINATION_GET_REQUEST,
  DESTINATION_GET_SUCCESS,
  DESTINATION_GET_FAILURE,
  DESTINATION_PUT_REQUEST,
  DESTINATION_PUT_SUCCESS,
  DESTINATION_PUT_FAILURE
} from '../constants/actionTypes';
import { getDefaultDestination } from '../utils/destinationUtils';

const defaultState = {
  destinations: [],
  message: '',
  currentDestination: getDefaultDestination(),
  inProgress: false,
  error: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case DESTINATION_RESET:
      return {
        ...state,
        currentDestination: defaultState.currentDestination
      };
    case UPDATE_CURRENT_DESTINATION_FIELD:
      let newValue;
      const language = action.language;

      // if language exists, we update the object with all translations
      if (language) {
        newValue = { ...state.currentDestination[action.key], [language]: action.value }
      } else {
        newValue = action.value;
      }
      const updatedCurrentDestination = { ...state.currentDestination, [action.key]: newValue };
      return {
        ...state,
        currentDestination: updatedCurrentDestination
      };

    case DESTINATIONS_GET_REQUEST:
    case DESTINATION_POST_REQUEST:
    case DESTINATION_DELETE_REQUEST:
    case DESTINATION_GET_REQUEST:
    case DESTINATION_PUT_REQUEST:
      return { ...state, inProgress: true };
    case DESTINATIONS_GET_SUCCESS:
      return {
        ...state,
        inProgress: false,
        error: null,
        currentDestination: defaultState.currentDestination,
        destinations: action.payload ? action.payload : null
      };
    case DESTINATION_GET_SUCCESS:
      return {
        ...state,
        inProgress: false,
        error: null,
        message: action.message ? action.message : null,
        currentDestination: action.payload ? action.payload : null
      };
    case DESTINATION_PUT_SUCCESS:
      const { payload } = action;
      const updatedDestinations = state.destinations.map(destination => {
        if (payload && payload._id === destination._id) {
          return payload;
        }
        return destination;
      });

      return {
        ...state,
        inProgress: false,
        error: null,
        message: action.message ? action.message : null,
        destinations: updatedDestinations,
        currentDestination: payload ? payload : null
      };
    case DESTINATION_POST_SUCCESS:
      const locs = [...state.destinations];
      if (action.payload) {
        locs.push(action.payload);
      }

      return {
        ...state,
        inProgress: false,
        error: null,
        message: action.message ? action.message : null,
        destinations: locs,
        currentDestination: defaultState.currentDestination
      };
    case DESTINATION_DELETE_SUCCESS:
      const filteredDestinations = state.destinations.filter(el => el._id !== action.destinationId);

      return {
        ...state,
        inProgress: false,
        error: null,
        message: action.message ? action.message : null,
        destinations: filteredDestinations,
        currentDestination: defaultState.currentDestination
      };

    case DESTINATIONS_GET_FAILURE:
    case DESTINATION_POST_FAILURE:
    case DESTINATION_DELETE_FAILURE:
    case DESTINATION_GET_FAILURE:
    case DESTINATION_PUT_FAILURE:
      return {
        ...state,
        inProgress: false,
        message: [],
        error: action.error ? action.error : null
      };
    default:
      return state;
  }
};
