import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CONSTANTS from '../constants/constants';
import { Button, CustomTooltip } from '../components/Custom';
import { getLocation, getLocations, createLocation, saveLocation, deleteLocation } from '../actions/locations';
import LocationsMenu from './LocationsMenu';
import TripEventForm from './TripEventForm';
import { checkEventPayload, resetNewEvent } from '../utils/tripUtils';
import FileUploader from '../components/FileUploader';
import SearchPlacesInput from '../components/SearchPlacesInput';
import { deepCopy } from '../utils/common';
import TripEventTitle from '../components/TripEventTitle';
import { convertLocationToEvent } from '../utils/locationUtils';

const TripEventEditionSideNav = ({
  currentEvent,
  maps,
  showTripEventEditionForm,
  destinationSearch,
  setSearchPlace,
  setDestinationSearch,
  updateEvent,
  addEvent,
  newEventFromLocation,
  locations,
  getLocation,
  getLocations,
  createLocation,
  saveLocation,
  deleteLocation
}) => {
  const { t } = useTranslation();

  const [editionEvent, setEditionEvent] = useState(null);
  const [drawerClassName, setDrawerClassName] = useState('drawer');
  const [formError, setFormError] = useState('');

  console.log('Started EventEditionSideNav');

  useEffect(() => {
    async function fetchLocations() {
      await getLocations();
      console.log('Loaded locations');
    }
    fetchLocations();

    console.log('****EventEditionSideNav useEffect');
  }, [getLocations]);

  useEffect(() => {
    setEditionEvent(currentEvent);
    console.log('**currentEvent useEffect');
  }, [currentEvent]);

  // for locations that were picked up outside this component
  useEffect(() => {
    setEditionEvent(newEventFromLocation);
    slideContentToLeft();
    console.log('**newEventFromLocation useEffect');
  }, [newEventFromLocation]);

  useEffect(() => {
    if (showTripEventEditionForm) {
      slideContentToLeft();
    }
  }, [showTripEventEditionForm]);

  const slideContentToLeft = () => {
    setDrawerClassName('drawer drawer-slide-left');
  };

  const slideContentToRight = () => {
    setDrawerClassName('drawer drawer-slide-right');
  };

  const clearPlacesField = () => {
    const input = document.getElementById(CONSTANTS.SEARCH_PLACES_BOX_ID);
    if (!input) {
      console.error(`Search Input field was not found: ${CONSTANTS.SEARCH_PLACES_BOX_ID}`);
      return;
    }
    input.value = '';
  };

  const updateSearchPlace = (id, latitude, longitude) => {
    const searchPlace = {
      id,
      latitude,
      longitude
    };
    setSearchPlace(searchPlace);
  };

  const onUpdateCurrentTripEvent = event => {
    setEditionEvent(event);
  };

  const updateNonDirectionEventInTripDay = async () => {
    // validates payload before saving
    const errorMessageKey = checkEventPayload(editionEvent);
    if (errorMessageKey) {
      setFormError(t(errorMessageKey));
      return;
    }

    const result = await updateEvent(editionEvent);
    if (result === true) {
      clearPlacesField(); // clear Search Input field
    }
  };

  const addEventToTripDay = async eventPayload => {
    // validates payload before saving
    const errorMessageKey = checkEventPayload(eventPayload);
    if (errorMessageKey) {
      setFormError(t(errorMessageKey));
      return;
    }

    const result = await addEvent(editionEvent);

    if (result === true) {
      clearPlacesField(); // clear Search Input field
    }
  };

  const onGetLocation = async locationId => {
    const location = await getLocation(locationId);
    const { _id: id, latitude, longitude } = location;
    updateSearchPlace(id, latitude, longitude);
    clearPlacesField(); // clear Search Input field
    slideContentToLeft();
    const newEvent = convertLocationToEvent(location);
    setEditionEvent(newEvent);
  };

  const onSaveLocation = async (newEvent, locationId) => {
    if (!newEvent.name) {
      setFormError(t('LOCATION_VALIDATION_FORM_NAME_MISSING'));
      return;
    }
    if (!newEvent.accommodationType && !newEvent.eventType && !newEvent.establishmentType) {
      setFormError(t('LOCATION_VALIDATION_FORM_TYPE_MISSING'));
      return;
    }

    let location = { ...newEvent };

    location.destinationCode = destinationSearch ? destinationSearch.code : null;
    location.locationType = newEvent.accommodationType || newEvent.eventType || newEvent.establishmentType;
    location._id = locationId;

    if (!locationId) {
      location = await createLocation(location);
    } else {
      location = await saveLocation(locationId, location);
    }
    setEditionEvent(newEvent);
  };

  const onDeleteLocation = async locationId => {
    await deleteLocation(locationId);
    slideContentToRight();
    const newEvent = resetNewEvent();
    setEditionEvent(newEvent);
  };

  const deleteImage = () => {
    setEditionEvent({ ...editionEvent, imageUrl: '' });
  };

  const onSaveEventImage = fileUrl => {
    setEditionEvent({ ...editionEvent, imageUrl: fileUrl });
  };

  const onPlaceCleared = () => {
    setSearchPlace(null);
  };

  const onResetTripEvent = () => {
    const resetTripEvent = resetNewEvent();
    setEditionEvent(resetTripEvent);
  };

  const onPlaceFound = place => {
    const { id, latitude, longitude, name, address, zipCode } = place;
    updateSearchPlace(id, latitude, longitude);

    let newEvent;
    if (!editionEvent || !editionEvent._id) {
      newEvent = resetNewEvent();
    } else {
      newEvent = deepCopy(editionEvent);
    }

    newEvent = { ...newEvent, latitude, longitude, name, address, zipCode };
    setEditionEvent(newEvent);
  };

  const onChangeDestinationSearchObject = destinationSearch => {
    setDestinationSearch(destinationSearch);
  };

  return (
    <div className={drawerClassName}>
      <div className="drawer-left">
        <div className="col-12 top-menu">
          <div>
            <Button as="a" className="a-button col-12" onClick={slideContentToLeft}>
              {t('TOOL_DAY_VIEW_SEARCH_PLACES')}
              <i className="fa fa-angle-right" aria-hidden="true"></i>
            </Button>
          </div>
        </div>
        <hr />
        <LocationsMenu locations={locations} onGetLocation={onGetLocation} onChangeDestinationSearchObject={onChangeDestinationSearchObject} />
      </div>
      <div className="drawer-right">
        <Button as="a" className="a-button col-12" onClick={slideContentToRight}>
          <i className="fa fa-angle-left" aria-hidden="true"></i>
          {t('TOOL_DAY_VIEW_MY_LOCATIONS')}
        </Button>
        <hr />
        <div id="search-location-input">
          {maps ? <SearchPlacesInput maps={maps} onPlaceFound={onPlaceFound} onPlaceCleared={onPlaceCleared}></SearchPlacesInput> : null}
        </div>
        {!editionEvent ? (
          <div className="col-12">
            <span>{t('TOOL_DAY_VIEW_SELECT_LOCATION')}</span>
          </div>
        ) : (
          <div className="col-12">
            <div className="row padding-10">
              <div className="col-8">
                <TripEventTitle event={editionEvent} />
              </div>
              <div className="col-4">
                <button className="btn btn-primary btn-sm spaced" onClick={onResetTripEvent}>
                  {t('BUTTON_RESET')}
                </button>
              </div>
            </div>
            <div id="day-view-image-container-wrapper" className="image-container-wrapper">
              <div className="image-container">
                <Button as="a" className="close-button a-button" onClick={deleteImage}>
                  <CustomTooltip text="Delete" isKey={false}>
                    <i className="fa fa-times fa-1halfx" aria-hidden="true"></i>
                  </CustomTooltip>
                </Button>
                <img className="event-img-sm" src={editionEvent.imageUrl || '/images/default-image-bk.png'} alt="Trip Event" />
              </div>
              <div className="file-uploader-container">
                <FileUploader initialFileUrl={editionEvent.imageUrl} folder="event" hideText={true} onFileURLUpdate={onSaveEventImage} />
              </div>
            </div>

            <TripEventForm event={editionEvent} setCurrentEvent={onUpdateCurrentTripEvent}>
              <div className="row padding-10">
                <p className="error no-margin">{formError}</p>
              </div>
              <div className="row padding-10">
                {editionEvent && editionEvent._id ? (
                  <button type="button" className="btn btn-primary btn-sm spaced" onClick={updateNonDirectionEventInTripDay}>
                    {t('BUTTON_UPDATE_EVENT')}
                  </button>
                ) : (
                  <button type="button" className="btn btn-primary btn-sm spaced" onClick={ev => addEventToTripDay(editionEvent)}>
                    {t('BUTTON_ADD_EVENT')}
                  </button>
                )}

                {editionEvent && editionEvent.locationId ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-primary btn-sm spaced"
                      onClick={ev => onSaveLocation(editionEvent, editionEvent.locationId)}
                    >
                      {t('BUTTON_UPDATE_MY_LOCATION')}
                    </button>
                    <button type="button" className="btn btn-primary btn-sm spaced" onClick={ev => onDeleteLocation(editionEvent.locationId)}>
                      {t('BUTTON_DELETE_MY_LOCATION')}
                    </button>
                  </>
                ) : (
                  <button type="button" className="btn btn-primary btn-sm spaced" onClick={ev => onSaveLocation(editionEvent)}>
                    {t('BUTTON_SAVE_MY_LOCATION')}
                  </button>
                )}
              </div>
            </TripEventForm>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  locations: state.locations.locations
});

const mapDispatchToProps = dispatch => ({
  getLocation: locationId => dispatch(getLocation(locationId)),
  getLocations: () => dispatch(getLocations()),
  createLocation: location => dispatch(createLocation(location)),
  saveLocation: (locationId, location) => dispatch(saveLocation(locationId, location)),
  deleteLocation: locationId => dispatch(deleteLocation(locationId))
});

export default connect(mapStateToProps, mapDispatchToProps)(TripEventEditionSideNav);
