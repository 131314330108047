import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../utils/currencyUtils';
import { buildPieChart, eraseChart, getChartPiece } from '../services/pieChartJSBuilder';
import CONSTANTS from '../constants/constants';
import { deepCopy } from '../utils/common';
import i18next from 'i18next';
import { getDayCurrencyCode } from '../utils/tripUtils';

const PIE_CHART_ID = 'chart-canvas';
const defaultTotals = {
  foodTotal: 0,
  attractionsTotal: 0,
  accommodationTotal: 0,
  transportTotal: 0,
  globalTotal: 0
};

const updateChartData = (accommodationTotal, attractionsTotal, transportTotal, foodTotal) => {
  const chartData = [];
  let chartPiece = getChartPiece(i18next.t('CHART_CANVAS_LEGEND_HOTEL'), accommodationTotal, CONSTANTS.CHART_COLOR_SET[0]);
  chartData.push(chartPiece);
  chartPiece = getChartPiece(i18next.t('CHART_CANVAS_LEGEND_ATTRACTIONS'), attractionsTotal, CONSTANTS.CHART_COLOR_SET[1]);
  chartData.push(chartPiece);
  chartPiece = getChartPiece(i18next.t('CHART_CANVAS_LEGEND_TRANSPORTATION'), transportTotal, CONSTANTS.CHART_COLOR_SET[2]);
  chartData.push(chartPiece);
  chartPiece = getChartPiece(i18next.t('CHART_CANVAS_LEGEND_FOOD'), foodTotal, CONSTANTS.CHART_COLOR_SET[3]);
  chartData.push(chartPiece);
  return chartData;
};

const PieChartCanvas = ({ totals, currencyCode, isTrip }) => {
  const { t } = useTranslation();

  const [pieChart, setPieChart] = useState(null);

  useEffect(() => {
    const { accommodationTotal, attractionsTotal, transportTotal, foodTotal } = totals;
    const chartData = updateChartData(accommodationTotal, attractionsTotal, transportTotal, foodTotal);
    eraseChart(pieChart);
    const newPieChart = buildPieChart(PIE_CHART_ID, chartData);
    setPieChart(newPieChart);
  }, [totals]);

  const renderLegendRow = (label, className, currencyCode, totalType) => {
    return (
      <div className="row">
        <div className="col-1">
          <div className={className}></div>
        </div>
        <div className="col-5">
          <span>{label}:</span>
        </div>
        <div className="col-5">
          <span>{formatCurrency(currencyCode, totalType)}</span>
        </div>
      </div>
    );
  };

  const { globalTotal, accommodationTotal, attractionsTotal, transportTotal, foodTotal } = totals;

  return (
    <div id="chart-canvas-wrapper" className="width-100">
      <div className="chart-top">
        {totals.globalTotal === 0 ? (
          <div className="col-12">
            <p>{isTrip ? t('CHART_CANVAS_NO_TRIP_EXPENSES') : t('CHART_CANVAS_NO_DAY_EXPENSES')}</p>
          </div>
        ) : (
          <div>
            <canvas id={PIE_CHART_ID}></canvas>
          </div>
        )}
      </div>
      <div id="chart-legend" className="col-12">
        <ul>
          <li>{renderLegendRow(t('CHART_CANVAS_LEGEND_HOTEL'), 'box accommodation', currencyCode, accommodationTotal)}</li>
          <li>{renderLegendRow(t('CHART_CANVAS_LEGEND_ATTRACTIONS'), 'box attractions', currencyCode, attractionsTotal)}</li>
          <li>{renderLegendRow(t('CHART_CANVAS_LEGEND_TRANSPORTATION'), 'box transport', currencyCode, transportTotal)}</li>
          <li>{renderLegendRow(t('CHART_CANVAS_LEGEND_FOOD'), 'box food', currencyCode, foodTotal)}</li>
          <li>{renderLegendRow(t('CHART_CANVAS_LEGEND_TOTAL'), 'total', currencyCode, globalTotal)}</li>
        </ul>
      </div>
    </div>
  );
};

const ChartCanvas = ({ tripEvents, isTrip }) => {
  const [totals, setTotals] = useState({ ...defaultTotals });
  const [currencyCode, setCurrencyCode] = useState('');

  useEffect(() => {
    const currencyCode = getDayCurrencyCode(tripEvents);
    setCurrencyCode(currencyCode);
    initializeChartData(tripEvents);
  }, [tripEvents]);

  const initializeChartData = dayEvents => {
    const totals = deepCopy(defaultTotals);

    (dayEvents || []).forEach(tripEvent => {
      const { totalPrice, kind: tripEventKind, eventType } = tripEvent;
      // price not defined for this event
      if (totalPrice === null || totalPrice === undefined) {
        return;
      }
      //invalid number not taken into account
      if (isNaN(totalPrice)) {
        console.warn(`${tripEventKind}  has a price that is not a number: ${totalPrice}`);
        return;
      }
      if (tripEventKind === CONSTANTS.TRIP_EVENT_KINDS.ACCOMMODATION) {
        totals.accommodationTotal += totalPrice;
      } else if (tripEventKind === CONSTANTS.TRIP_EVENT_KINDS.DIRECTIONS) {
        totals.transportTotal += totalPrice;
      } else if (eventType === CONSTANTS.ATTRACTION_TYPES_OBJECT.RESTAURANT) {
        totals.foodTotal += totalPrice;
      } else {
        totals.attractionsTotal += totalPrice;
      }
    });

    totals.globalTotal = totals.foodTotal + totals.attractionsTotal + totals.accommodationTotal + totals.transportTotal;
    setTotals({ ...totals });
  };

  return <PieChartCanvas totals={totals} currencyCode={currencyCode} isTrip={isTrip} />;
};

export default ChartCanvas;
