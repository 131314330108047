import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { saveDay } from '../actions/tripPlans';
import ChartCanvas from '../components/ChartCanvas';

const TripDayPlannerDaySummarySideNav = ({ tripId, tripDay, isTripTemplate, isExpertUser, saveDay }) => {
  const { t } = useTranslation();

  const [generalComment, setGeneralComment] = useState('');

  useEffect(() => {
    if (!tripDay) {
      return;
    }
    setGeneralComment(tripDay.generalComment);
  }, [tripDay]);

  const changeComment = generalComment => {
    const { _id: dayId } = tripDay;
    const updatedTripDay = { ...tripDay, generalComment };
    saveDay(tripId, dayId, updatedTripDay, isTripTemplate);
  };

  const onSave = ev => {
    changeComment(generalComment);
  };

  const onChangeComment = ev => {
    const { value } = ev.target;
    setGeneralComment(value);
  };

  return (
    <>
      <div>
        <h4>{t('TOOL_DAY_VIEW_EXPENSES_SUMMARY')}</h4>
      </div>
      <div>
        {tripDay ? <ChartCanvas tripEvents={tripDay.events}></ChartCanvas> : null}
        {isExpertUser ? (
          <div>
            <div className="row form">
              <label className="col-12">{t('TOOL_DAY_VIEW_SUMMARY_MESSAGE')} </label>
              <div className="col-12">
                <textarea
                  className="form-control"
                  rows="5"
                  placeholder="Add comments for this day"
                  value={generalComment}
                  onChange={onChangeComment}
                ></textarea>
              </div>
            </div>
            <div className="row  padding-10">
              <button type="button" className="btn btn-primary" onClick={onSave}>
                {t('BUTTON_SAVE')}
              </button>
            </div>
          </div>
        ) : (
          <div>
            <div className="row form">
              <label className="field-label col-md-4">{t('TOOL_DAY_VIEW_SUMMARY_MESSAGE')}</label> {generalComment}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  saveDay: (tripId, dayId, day, isTemplate) => dispatch(saveDay(tripId, dayId, day, isTemplate))
});

export default connect(mapStateToProps, mapDispatchToProps)(TripDayPlannerDaySummarySideNav);
