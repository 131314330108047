import React, { useState, useEffect } from 'react';
import ShowError from '../components/ShowError';
import InProgress from '../components/InProgress';
import { connect } from 'react-redux';
import { updateCurrentDestinationField, deleteDestination, createDestination, saveDestination, resetCurrentDestination } from '../actions/destinations';
import { useTranslation } from 'react-i18next';
import AutocompleteDestinationsInput from '../components/AutocompleteDestinationsInput';
import { Button } from '../components/Custom';
import { setToastErrorMessage } from '../actions/common';
import FileUploader from '../components/FileUploader';

const DestinationTranslateFields = ({ currentDestination, language, updateTranslateField }) => {
  const { t } = useTranslation();

  return (<>
    <fieldset className="form-group col-12">
      <div className="row form2">
        <label htmlFor="name" className="col-form-label col-sm-4">
          {t('DESTINATION_NAME')}*{' '}
        </label>
        <div className="col-sm-8">
          <input
            type="text"
            className="form-control textbox"
            placeholder={t('DESTINATION_NAME_PLACEHOLDER')}
            name="name"
            value={currentDestination.name[language]}
            onChange={updateTranslateField('name', language)}
            required
          />
        </div>
      </div>
      <div className="row form2">
        <label htmlFor="name" className="col-form-label col-sm-4">
          {t('DESTINATION_COUNTRY')}*{' '}
        </label>
        <div className="col-sm-8">
          <input
            type="text"
            className="form-control textbox"
            placeholder={t('DESTINATION_COUNTRY_PLACEHOLDER')}
            name="name"
            value={currentDestination.country[language]}
            onChange={updateTranslateField('country', language)}
            required
          />
        </div>
      </div>
      <div className="row form2">
        <label htmlFor="name" className="col-form-label col-sm-4">
          {t('DESTINATION_TITLE')}*{' '}
        </label>
        <div className="col-sm-8">
          <input
            type="text"
            className="form-control textbox"
            placeholder={t('DESTINATION_TITLE_PLACEHOLDER')}
            name="name"
            value={currentDestination.title[language]}
            onChange={updateTranslateField('title', language)}
            required
          />
        </div>
      </div>
      <div className="row form2">
        <label htmlFor="description" className="col-form-label  col-sm-4">
          {t('DESTINATION_DESCRIPTION')}*{' '}
        </label>
        <div className="col-sm-12">
          <textarea
            className="form-control"
            rows="5"
            placeholder={t('DESTINATION_DESCRIPTION_PLACEHOLDER')}
            value={currentDestination.description[language]}
            onChange={updateTranslateField('description', language)}
          ></textarea>
        </div>
      </div>
    </fieldset>
  </>)
}

const DestinationForm = ({
  currentDestination,
  error,
  inProgress,
  updateCurrentDestinationField,
  deleteDestination,
  createDestination,
  saveDestination,
  resetCurrentDestination,
  isDuplicateDestination,
  setToastErrorMessage
}) => {
  const { t } = useTranslation();
  const [destinationSearchResultList, setDestinationSearchResultList] = useState([]);
  const [countrySearchResultList, setCountrySearchResultList] = useState([]);

  useEffect(() => {
    const destinationSearchResultList = [];
    if (currentDestination.search_code && currentDestination.name.en) {
      const destinationSearch = {
        code: currentDestination.search_code,
        name: currentDestination.name.en
      };
      destinationSearchResultList.push(destinationSearch);
    }
    setDestinationSearchResultList(destinationSearchResultList);

    const countrySearchResultList = [];
    if (currentDestination.country_code && currentDestination.country.en) {
      const destinationSearch = {
        code: currentDestination.country_code,
        name: currentDestination.country.en
      };
      countrySearchResultList.push(destinationSearch);
    }
    setCountrySearchResultList(countrySearchResultList);
  }, [currentDestination]);

  const onChangeDestination = (destinationSearchResultList = []) => {
    const destinationSearch = destinationSearchResultList[0] || {};
    setDestinationSearchResultList(destinationSearchResultList);
    updateCurrentDestinationField('search_code', destinationSearch.code);
    updateCurrentDestinationField('destinationType', destinationSearch.destinationType);
    updateCurrentDestinationField('name', destinationSearch.name, 'en'); // fills name field automatically
    updateCurrentDestinationField('name', destinationSearch.name, 'pt'); // fills name field automatically
  };

  const onChangeDestinationCountry = (countrySearchResultList = []) => {
    const destinationSearch = countrySearchResultList[0] || {};
    setCountrySearchResultList(countrySearchResultList);
    updateCurrentDestinationField('country_code', destinationSearch.code);
    updateCurrentDestinationField('country', destinationSearch.name, 'en'); // fills name field automatically
    updateCurrentDestinationField('country', destinationSearch.name, 'pt'); // fills name field automatically
  };

  const onSaveDestination = (destinationId, destination) => ev => {
    ev.preventDefault();
    if (!validateDestinationPayload(destination)) {
      return;
    }
    saveDestination(destinationId, destination);
  };
  const onDeleteDestination = destinationId => ev => {
    ev.preventDefault();
    deleteDestination(destinationId);
  };
  const onCreateDestination = destination => ev => {
    ev.preventDefault();
    if (!validateDestinationPayload(destination)) {
      return;
    }
    createDestination(destination);
  };
  const onResetDestination = () => ev => {
    resetCurrentDestination();
  };

  const updateField = field => ev => {
    ev.preventDefault();
    const { value } = ev.target;
    updateCurrentDestinationField(field, value);
  };
  const updateTranslateField = (field, language) => ev => {
    ev.preventDefault();
    const { value } = ev.target;
    updateCurrentDestinationField(field, value, language);
  };

  const deleteImage = ev => {
    ev.preventDefault();
    updateCurrentDestinationField('imageUrl', '');
  };

  const onSaveDestinationImage = imageUrl => {
    updateCurrentDestinationField('imageUrl', imageUrl);
  };

  const validateDestinationPayload = destination => {
    if (!destination.search_code) {
      setToastErrorMessage(t('DESTINATION_FORM_SEARCH_CODE_MISSING'));
      return false;
    }

    if (!destination.name.en || !destination.name.pt) {
      setToastErrorMessage(t('DESTINATION_FORM_NAME_MISSING'));
      return false;
    }

    if (!destination.title.en || !destination.title.pt) {
      setToastErrorMessage(t('DESTINATION_FORM_TITLE_MISSING'));
      return false;
    }

    if (!destination.description.en || !destination.description.pt) {
      setToastErrorMessage(t('DESTINATION_FORM_DESCRIPTION_MISSING'));
      return false;
    }

    if (!destination.imageUrl) {
      setToastErrorMessage(t('DESTINATION_FORM_IMAGEURL_MISSING'));
      return false;
    }

    if (!destination.videoUrl) {
      setToastErrorMessage(t('DESTINATION_FORM_VIDEOURL_MISSING'));
      return false;
    }

    if (!destination.isDefault && isDuplicateDestination(destination)) {
      setToastErrorMessage(t('DESTINATION_FORM_DUPLICATE'));
      return false;
    }
    return true;
  };

  const destinationName =
    destinationSearchResultList && destinationSearchResultList[0] && destinationSearchResultList[0].code ? destinationSearchResultList[0].name : null;

  return (
    <>

      <div className="row form">

        <button type="button" className="btn btn-primary" onClick={onResetDestination()}>
          RESET
        </button>
      </div>

      <fieldset className="form-group col-12">
        <div className="row">
          <div className="col-12 form2">
            <label htmlFor="destinationSearch" className="col-form-label col-12">
              {t('MY_LOCATIONS_DESTNATION_2')} {currentDestination.destinationType ? currentDestination.destinationType : null}
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <div className="filter width-100 destination">
              <AutocompleteDestinationsInput
                maxItems={1}
                values={destinationSearchResultList}
                placeholder="Search Destination"
                onChangeValues={onChangeDestination}
                className="width-100"
              />
            </div>
          </div>
          <div className="col-4">
            <div className="filter width-100 destination">
              <AutocompleteDestinationsInput
                maxItems={1}
                values={countrySearchResultList}
                placeholder="Search Country"
                onChangeValues={onChangeDestinationCountry}
                className="width-100"
              />
            </div>
          </div>
        </div>
      </fieldset>

      <h2>ENGLISH</h2>

      <DestinationTranslateFields currentDestination={currentDestination} language={'en'} updateTranslateField={updateTranslateField} />

      <h2>PORTUGUÊS</h2>

      <DestinationTranslateFields currentDestination={currentDestination} language={'pt'} updateTranslateField={updateTranslateField} />

      <fieldset className="form-group col-12">
        <div className="row form">
          <label htmlFor="videoUrl" className="col-form-label col-sm-4">
            Video URL* :
          </label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control textbox"
              placeholder="Video URL"
              name="videoUrl"
              value={currentDestination.videoUrl}
              onChange={updateField('videoUrl')}
              required
            />
          </div>
        </div>
      </fieldset>

      <fieldset className="form-group col-12">
        <div className="row form">
          <div id="my-locations-image-container-wrapper" className="image-container-wrapper">
            <div className="image-container">
              <Button as="a" className="a-button close-button" onClick={deleteImage}>
                <i className="fa fa-times fa-1halfx" aria-hidden="true"></i>
              </Button>
              <img className="location-img" src={currentDestination.imageUrl || '/images/default-image-bk.png'} alt="Location" />
            </div>
            <div className="file-uploader-container">
              <FileUploader initialFileUrl={currentDestination.imageUrl} folder="destination" hideText={false} onFileURLUpdate={onSaveDestinationImage} />
            </div>
          </div>
        </div>
      </fieldset>

      <div className="row form">
        {currentDestination && currentDestination._id ? (
          <>
            <button type="button" className="btn btn-primary mr-2" onClick={onSaveDestination(currentDestination._id, currentDestination)}>
              {t('BUTTON_EDIT')}
            </button>
            <button type="button" className="btn btn-primary" onClick={onDeleteDestination(currentDestination._id)}>
              {t('BUTTON_DELETE')}
            </button>
          </>
        ) : (
          <button type="button" className="btn btn-primary" onClick={onCreateDestination(currentDestination)}>
            {t('BUTTON_SAVE')}
          </button>
        )}
      </div>

      <ShowError error={error} />
      <InProgress inProgress={inProgress} />
    </>
  );
};

const mapStateToProps = state => ({
  currentDestination: state.destinations.currentDestination,
  inProgress: state.destinations.inProgress,
  error: state.destinations.error
});

const mapDispatchToProps = dispatch => ({
  updateCurrentDestinationField: (name, value, language) => dispatch(updateCurrentDestinationField(name, value, language)),
  createDestination: destination => dispatch(createDestination(destination)),
  saveDestination: (destinationId, destination) => dispatch(saveDestination(destinationId, destination)),
  deleteDestination: destinationId => dispatch(deleteDestination(destinationId)),
  resetCurrentDestination: () => dispatch(resetCurrentDestination()),
  setToastErrorMessage: errorMsg => dispatch(setToastErrorMessage(errorMsg))
});

export default connect(mapStateToProps, mapDispatchToProps)(DestinationForm);
