import { TRIP_REQUEST_POST_REQUEST, TRIP_REQUEST_POST_SUCCESS, TRIP_REQUEST_POST_FAILURE, TRIPS_REQUESTS_GET_REQUEST, TRIPS_REQUESTS_GET_FAILURE, TRIPS_GET_SUCCESS, TRIPS_REQUESTS_GET_SUCCESS } from '../constants/actionTypes';

const defaultState = {
  message: '',
  inProgress: false,
  error: null,
  tripRequests: []
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case TRIP_REQUEST_POST_REQUEST:
    case TRIPS_REQUESTS_GET_REQUEST:
      return { ...state, inProgress: true };
    case TRIP_REQUEST_POST_SUCCESS:
      return {
        ...state,
        inProgress: false,
        error: null,
        message: action.payload ? action.payload : null
      };
    case TRIPS_REQUESTS_GET_SUCCESS:
      return {
        ...state,
        inProgress: false,
        error: null,
        tripRequests: action.payload ? action.payload : null
      };
    case TRIP_REQUEST_POST_FAILURE:
      return {
        ...state,
        inProgress: false,
        message: '',
        error: action.error ? action.error : null
      };
    case TRIPS_REQUESTS_GET_FAILURE:
      return {
        ...state,
        tripRequests: defaultState.tripRequests,
        inProgress: false,
        message: '',
        error: action.error ? action.error : null
      };
    default:
      return state;
  }
};
