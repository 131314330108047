import React from 'react';
import { useTranslation } from 'react-i18next';
import TooltipIcon from './TooltipIcon';

const IconAttractionItem = ({ iconKey, keyToMatch, iconClass, textKey, icon, tooltip, text, big }) => {
  const { t } = useTranslation();

  if (iconKey !== keyToMatch) {
    return null;
  }
  const bigClass = big ? 'fa-2x circle-icon' : '';

  return (
    <div className="expert-icon-container">
      <TooltipIcon iconClass={`${bigClass} ${iconClass}`} textKey={textKey} icon={icon} tooltip={tooltip} big={big} />
      {text ? t(textKey) : ''}
    </div>
  );
};

const IconAttractionSwitcher = ({ iconKey = 'OTHER', icon, tooltip, text, big }) => {
  return (
    <>
      <IconAttractionItem
        iconKey={iconKey}
        keyToMatch="MONUMENT"
        iconClass="fa fa-university"
        textKey="TRIP_EVENT__ATTRACTION_TYPE_MONUMENT"
        big={big}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />

      <IconAttractionItem
        iconKey={iconKey}
        keyToMatch="MUSEUM"
        iconClass="icon ive-museum"
        textKey="TRIP_EVENT__ATTRACTION_TYPE_MUSEUM"
        big={big}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />

      <IconAttractionItem
        iconKey={iconKey}
        keyToMatch="PARK"
        iconClass="fa fa-leaf"
        textKey="TRIP_EVENT__ATTRACTION_TYPE_PARK"
        big={big}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />

      <IconAttractionItem
        iconKey={iconKey}
        keyToMatch="RESTAURANT"
        iconClass="fa fa-cutlery"
        textKey="TRIP_EVENT__ATTRACTION_TYPE_RESTAURANT"
        big={big}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />

      <IconAttractionItem
        iconKey={iconKey}
        keyToMatch="NIGHT_CLUB"
        iconClass="fa fa-moon-o"
        textKey="TRIP_EVENT__ATTRACTION_TYPE_NIGHT_CLUB"
        big={big}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />

      <IconAttractionItem
        iconKey={iconKey}
        keyToMatch="BAR"
        iconClass="fa fa-beer"
        textKey="TRIP_EVENT__ATTRACTION_TYPE_BAR"
        big={big}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />

      <IconAttractionItem
        iconKey={iconKey}
        keyToMatch="OTHER"
        iconClass="fa fa-map-marker"
        textKey="TRIP_EVENT__ATTRACTION_TYPE_OTHER"
        big={big}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />
    </>
  );
};

export default IconAttractionSwitcher;
