import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import NavigationService from '../services/navigation';
import { getTripResume, putTripResumeSuccess } from '../actions/trips';
import { getTripTemplate, getTripTemplates, createTripTemplate, getTripTemplatesSuccess } from '../actions/tripTemplates';
import { addDay, updateTripPlanPayload, saveTripPlan } from '../actions/tripPlans';
import { convertTimestampToDate, formatDateCustom } from '../utils/dateUtils';
import { Button } from '../components/Custom';
import SideNav from '../components/SideNav';
import TripResumeContent from '../components/TripResumeContent';
import TripCanvasBoard from '../containers/TripCanvasBoard';
import TripDayListItem from '../components/TripDayListItem';
import TripTitleSubtitle from '../components/TripTitleSubtitle';
import TooltipIcon from '../components/TooltipIcon';

const TripTemplateListItem = ({ tripTemplate, onGetTripTemplate }) => {
  const { t } = useTranslation();

  const { days: tripPlanDays } = tripTemplate.tripPlan;
  return (
    <div className="col-12 item cursor-pointer" onClick={onGetTripTemplate(tripTemplate._id)}>
      <p>
        <span className="font-bold">{tripTemplate.name}</span>{' '}
        <span>
          ({tripPlanDays.length || '-'} {t('TRIP_PLAN_EDIT_DAYS') + (tripPlanDays.length > 1 ? 's' : '')})
        </span>
      </p>
      <span className="meta">
        {t('TRIP_PLAN_EDIT_CREATION_DATE')} {formatDateCustom(tripTemplate.created_at, 'MMMM DD, YYYY')}
      </span>
    </div>
  );
};

const TripResumeEditPage = ({
  currentUser,
  trip,
  inProgress,
  tripPlan,
  getTripResume,
  addDay,
  tripTemplates,
  getTripTemplate,
  getTripTemplates,
  saveTripPlan,
  createTripTemplate,
  getTripTemplatesSuccess
}) => {
  const { t } = useTranslation();
  let { tripId } = useParams();

  // Wrap with useCallback to avoid change on every render. It returns a memoized version of the callback that only changes if one of the provided dependencies change.
  const fetchTripResume = useCallback(async () => {
    await getTripResume(tripId);
  }, [tripId, getTripResume]);

  useEffect(() => {
    fetchTripResume();
  }, [fetchTripResume]);

  const [isOpenResumeContentSideNav, setIsOpenResumeContentSideNav] = useState(null); // Three possible values: null, true and false
  const [isOpenTemplatesSideNav, setIsOpenTemplatesSideNav] = useState(null); // Three possible values: null, true and false
  const isExpertUser = currentUser ? currentUser.isExpert : null;
  const [drawerClassName, setDrawerClassName] = useState('content');
  const [selectedTripTemplateName, setSelectedTripTemplateName] = useState('');
  const [selectedTripTemplateDays, setSelectedTripTemplateDays] = useState(null);

  const goToTrip = event => {
    event.stopPropagation();
    NavigationService.goToTripView(tripId);
  };

  const onOpenResumeContentSideNavEvent = ev => {
    ev.preventDefault();
    setIsOpenResumeContentSideNav(true);
  };
  const onCloseResumeContentSideNavEvent = ev => {
    ev.preventDefault();
    setIsOpenResumeContentSideNav(false);
  };

  const onOpenTemplatesSideNavEvent = async ev => {
    ev.preventDefault();
    setIsOpenTemplatesSideNav(true);
    await getTripTemplates();
  };
  const onCloseTemplatesSideNavEvent = ev => {
    ev.preventDefault();
    setIsOpenTemplatesSideNav(false);
  };

  const slideContentToLeft = () => {
    setDrawerClassName('content drawer-slide-left');
  };

  const slideContentToRight = () => {
    setDrawerClassName('content drawer-slide-right');
  };

  const saveAsTripTemplate = async ev => {
    const tripTemplate = {
      days: tripPlan.days,
      name: `${t('TRIP_TEMPLATE_TITLE_PREFIX')} ${trip.title}`
    };

    const newTripTemplate = await createTripTemplate(tripTemplate);
    const newTripTemplates = [...tripTemplates, newTripTemplate];
    getTripTemplatesSuccess(newTripTemplates);
  };

  const onAddDay = async () => {
    await addDay(tripId, false); // action should display error message if any
  };

  const renderTripPlan = (isExpertUser, trip) => {
    if (!trip) {
      return;
    }
    return <TripCanvasBoard trip={trip} view={false} isExpertUser={isExpertUser} isTripTemplate={false} />;
  };

  const onGetTripTemplate = tripTemplateId => async ev => {
    const tripTemplate = await getTripTemplate(tripTemplateId);
    slideContentToLeft();
    if (!tripTemplate) {
      return;
    }
    setSelectedTripTemplateName(tripTemplate.name);
    setSelectedTripTemplateDays(tripTemplate.tripPlan.days);
  };

  const appendToTripPlan = async () => {
    const tripTemplateDays = selectedTripTemplateDays.map(day => {
      const { _id, ...newDay } = day;
      const newEvents = day.events.map(event => {
        const { isTemplateEvent, _id, __v, ...eventCopy } = event;
        return eventCopy;
      });

      return { ...newDay, events: newEvents };
    });

    const updatedTripPlan = { ...tripPlan };
    const { days = [] } = updatedTripPlan;
    updatedTripPlan.days = [...days, ...tripTemplateDays];
    saveTripPlan(tripId, updatedTripPlan);
    setIsOpenTemplatesSideNav(false);
  };

  if (!trip) {
    return null;
  }

  const { title, startDate, endDate } = trip;
  let subtitle = '';
  if (startDate && endDate) {
    const startTimeLabel = convertTimestampToDate(startDate);
    const endTimeLabel = convertTimestampToDate(endDate);
    subtitle = `(${startTimeLabel} - ${endTimeLabel})`;
  }

  return (
    <div className="container special padding-10 trip-plan-edit-container">
      <div className="row padding-10">
        <div className="col-6">
          <TripTitleSubtitle title={title} subtitle={subtitle} isEdition={false}>
            <div className="display-inline-flex cursor-pointer" onClick={onOpenResumeContentSideNavEvent}>
              <TooltipIcon iconClass="fa fa-angle-double-right" textKey="BUTTON_MORE_INFO" icon={true} tooltip={true} />
            </div>
          </TripTitleSubtitle>
        </div>
        <div className="col-6 trip-canvas-top">
          <div className="btn-toolbar" role="toolbar" aria-label="Second group">
            <div className="btn-group service-icons" role="group" aria-label="...">
              <button type="button" className="btn btn-outline-primary full-opacity" onClick={goToTrip}>
                {t('BUTTON_VIEW_TRIP')}
              </button>
              <button type="button" className="btn btn-outline-primary full-opacity" onClick={saveAsTripTemplate}>
                {t('BUTTON_SAVE_TEMPLATE')}
              </button>
              <button type="button" className="btn btn-outline-primary full-opacity" onClick={onOpenTemplatesSideNavEvent}>
                {t('BUTTON_USE_TEMPLATE')}
              </button>
              <button type="button" className="btn btn-outline-primary full-opacity" onClick={onAddDay}>
                {t('BUTTON_NEW_DAY')}
              </button>
            </div>
          </div>
        </div>
      </div>

      <SideNav
        id="left-trip-resume-sidenav"
        isOpen={isOpenResumeContentSideNav}
        reverseColor={true}
        left={true}
        triggerCloseEvent={onCloseResumeContentSideNavEvent}
      >
        <TripResumeContent trip={trip} isExpertUser={isExpertUser}></TripResumeContent>
      </SideNav>
      <div className="trip-canvas-wrapper">
        {inProgress ? (
          <div className="load-overlay show">
            <div className="text">
              <i className="fa fa-spinner fa-spin spin-size"></i>
            </div>
          </div>
        ) : (
          <div className="trip-canvas width-100">{renderTripPlan(isExpertUser, trip)}</div>
        )}
      </div>

      <SideNav
        id="right-templates-sidenav"
        isOpen={isOpenTemplatesSideNav}
        reverseColor={false}
        left={false}
        triggerCloseEvent={onCloseTemplatesSideNavEvent}
      >
        <div className={drawerClassName}>
          <div className="content-left">
            <h3 className="text-center">{t('TRIP_PLAN_EDIT_MY_TEMPLATES')}</h3>
            {tripTemplates.length === 0 ? <div>{t('TRIP_PLAN_EDIT_NO_TEMPLATES')}</div> : null}
            {tripTemplates.map((tripTemplate, index) => {
              return <TripTemplateListItem key={index} tripTemplate={tripTemplate} onGetTripTemplate={onGetTripTemplate}></TripTemplateListItem>;
            })}
          </div>
          <div className="content-right">
            <Button as="a" className="a-button col-12" onClick={slideContentToRight}>
              <i className="fa fa-angle-left" aria-hidden="true"></i>
              {t('BUTTON_BACK')}
            </Button>
            <hr />
            <div className="top">
              <button type="button" className="btn btn-primary" onClick={appendToTripPlan}>
                {t('BUTTON_ADD_TO_PLAN')}
              </button>
              <h3>{selectedTripTemplateName}</h3>
            </div>
            {(selectedTripTemplateDays || []).map((day, index) => {
              return <TripDayListItem day={day} key={index} index={index}></TripDayListItem>;
            })}
          </div>
        </div>
      </SideNav>
    </div>
  );
};

const mapStateToProps = state => ({
  trip: state.trips.currentTripResume,
  inProgress: state.trips.inProgress,
  currentUser: state.common.currentUser,
  tripPlan: state.tripPlans.currentTripPlan,
  tripTemplates: state.tripTemplates.tripTemplates
});

const mapDispatchToProps = dispatch => ({
  getTripResume: tripResumeId => dispatch(getTripResume(tripResumeId)),
  putTripResumeSuccess: (message, updatedTripResume) => dispatch(putTripResumeSuccess(message, updatedTripResume)),
  addDay: (tripId, isTemplate) => dispatch(addDay(tripId, isTemplate)),
  updateTripPlanPayload: tripPlan => dispatch(updateTripPlanPayload(tripPlan)),
  getTripTemplate: tripTemplateId => dispatch(getTripTemplate(tripTemplateId)),
  getTripTemplates: () => dispatch(getTripTemplates()),
  saveTripPlan: (tripId, tripPlan) => dispatch(saveTripPlan(tripId, tripPlan)),
  createTripTemplate: tripTemplate => dispatch(createTripTemplate(tripTemplate)),
  getTripTemplatesSuccess: tripTemplates => dispatch(getTripTemplatesSuccess(tripTemplates))
});

export default connect(mapStateToProps, mapDispatchToProps)(TripResumeEditPage);
