import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import NavigationService from '../services/navigation';
import CONSTANTS from '../constants/constants';
import { Button } from '../components/Custom';
import { changeTripStatus } from '../actions/trips';

const TripStatusAction = ({ isExpert, tripId, tripStatus, changeTripStatus }) => {
  const { t } = useTranslation();

  // expert actions
  const acceptTripRequest = ev => {
    ev.preventDefault();
    ev.stopPropagation();
    changeTripStatus(tripId, CONSTANTS.TRIP_STATUS.WAITING_PAYMENT);
  };
  const rejectTripRequest = ev => {
    ev.preventDefault();
    ev.stopPropagation();
    changeTripStatus(tripId, CONSTANTS.TRIP_STATUS.TRIP_REQUEST_REJECTED);
  };
  const cancelTripRequest = ev => {
    ev.preventDefault();
    ev.stopPropagation();
    const confirmWindow = window.confirm(t('CONFIRM_CANCEL_TRIP_REQUEST'));
    if (confirmWindow === false) {
      return;
    }
    changeTripStatus(tripId, CONSTANTS.TRIP_STATUS.TRIP_REQUEST_CANCELLED);
  };
  const goToPaymentPage = ev => {
    ev.preventDefault();
    ev.stopPropagation();
    NavigationService.goToTripPayment(tripId);
  };
  const cancelPayment = ev => {
    ev.preventDefault();
    ev.stopPropagation();
    const confirmWindow = window.confirm(t('CONFIRM_CANCEL_PAYMENT'));
    if (confirmWindow === false) {
      return;
    }
    changeTripStatus(tripId, CONSTANTS.TRIP_STATUS.PAYMENT_CANCELLED);
  };
  const editTrip = ev => {
    ev.preventDefault();
    ev.stopPropagation();
    NavigationService.goToTripEdition(tripId);
  };

  const renderActionLink = (actionLabel, actionFn) => {
    return (
      <Button as="a" className="a-button" onClick={actionFn}>
        {t(actionLabel)}
      </Button>
    );
  };

  const conditionShowActionLinks =
    tripStatus === CONSTANTS.TRIP_STATUS.TRIP_REQUEST ||
    (tripStatus === CONSTANTS.TRIP_STATUS.WAITING_PAYMENT && !isExpert) ||
    (tripStatus === CONSTANTS.TRIP_STATUS.TRIP_PROCESSING && isExpert) ||
    (tripStatus === CONSTANTS.TRIP_STATUS.TRIP_REVIEW && !isExpert);

  if (!conditionShowActionLinks) {
    return null;
  }

  return (
    <span>
      {t('TRIP_RESUME_ACTION')}:{' '}
      <span className={tripStatus === CONSTANTS.TRIP_STATUS.TRIP_REQUEST && isExpert ? '' : 'd-none'}>
        {renderActionLink('BUTTON_ACCEPT_REQUEST', acceptTripRequest)}
        {' | '}
        {renderActionLink('BUTTON_REJECT_REQUEST', rejectTripRequest)}
      </span>
      <span className={tripStatus === CONSTANTS.TRIP_STATUS.TRIP_REQUEST && !isExpert ? '' : 'd-none'}>
        {renderActionLink('BUTTON_CANCEL', cancelTripRequest)}
      </span>
      <span className={tripStatus === CONSTANTS.TRIP_STATUS.WAITING_PAYMENT && !isExpert ? '' : 'd-none'}>
        {renderActionLink('BUTTON_CANCEL', cancelPayment)}
        {' | '}
        {renderActionLink('BUTTON_PAY', goToPaymentPage)}
      </span>
      <span className={tripStatus === CONSTANTS.TRIP_STATUS.TRIP_PROCESSING && isExpert ? '' : 'd-none'}>
        {renderActionLink('BUTTON_EDIT_TRIP', editTrip)}
      </span>
    </span>
  );
};

const mapDispatchToProps = dispatch => ({
  changeTripStatus: (tripResumeId, toStatus) => dispatch(changeTripStatus(tripResumeId, toStatus))
});

export default connect(null, mapDispatchToProps)(TripStatusAction);
