import React, { useState, useEffect, useRef } from 'react';
import CONSTANTS from '../constants/constants';
import GoogleMapReact from 'google-map-react';
import MarkerFactory from '../services/MarkerFactory';
import { GOOGLE_MAPS_API_KEY } from '../appConfiguration';
import {
  zoomOnDestinationSearchResult,
  zoomToIncludeMarkers,
  clearAllDirections,
  initializeWayPoints,
  updateDirectionsDisplay
} from '../utils/googleAPIUtils';
import { generateTimestampId } from '../utils/common';

const { DEFAULT_MAP_DESTINATION } = CONSTANTS;

const MapCanvas = ({ children, positions, searchPlace, selectedMarkerId, destinationSearchName = null, onMapsLoaded, props }) => {
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [markersArray, setMarkersArray] = useState([]);
  const directionsDisplay = useRef(null);
  const destinationSearchQuery = useRef(null); // this is a fallback destination when there are no markers on the map

  const printDirectionsLayer = (directionsDisplay, maps, positions = []) => {
    // we need at least two points to build a path
    const markersLength = positions.length;
    if (markersLength < 2) {
      clearAllDirections(directionsDisplay);
      return;
    }
    initializeWayPoints(maps, directionsDisplay, positions);
  };

  const addMarkerToMap = (maps, map, latitude, longitude, textLabel, markerId, markerType) => {
    const id = markerId ? markerId : generateTimestampId(); // if id does not exist, generates a random marker id based on a timestamp
    const marker = MarkerFactory.buildMarkerMap(maps, map, latitude, longitude, textLabel, id, markerType);
    return marker;
  };

  const addMarkersToMap = (maps, map, positions) => {
    const updatedMarkersArray = [...markersArray];
    MarkerFactory.clearMarkers(updatedMarkersArray); // clear map markers if they exist
    (positions || []).forEach(function (position, index) {
      const { latitude, longitude, textLabel, id } = position;
      const marker = addMarkerToMap(maps, map, latitude, longitude, textLabel || index + 1, id);
      updatedMarkersArray.push(marker);
    });
    setMarkersArray(updatedMarkersArray);
  };

  useEffect(() => {
    console.log('####**** MAP positions');
    if (!(maps && map) || !positions) {
      return;
    }
    addMarkersToMap(maps, map, positions);
    directionsDisplay.current = updateDirectionsDisplay(map, maps, directionsDisplay.current);
    printDirectionsLayer(directionsDisplay.current, maps, positions);
  }, [maps, map, positions]);

  useEffect(() => {
    if (!(maps && map && markersArray)) {
      return;
    }
    console.log('####**** MAP zoomToIncludeMarkers');
    zoomToIncludeMarkers(maps, map, markersArray);
  }, [maps, map, markersArray]);

  const selectedMarker = useRef(null);
  useEffect(() => {
    console.log('####****MAP selectedMarkerId');

    // marker is already selected
    if (selectedMarker.current === selectedMarkerId) {
      return;
    }
    console.log(`New selected id: ${selectedMarkerId}`);

    // unselects current selection
    let markerIndex = markersArray.findIndex(marker => marker.id === selectedMarker.current);
    if (markerIndex !== -1) {
      markersArray[markerIndex].setIcon(MarkerFactory.MARKER_TYPES.NORMAL);
    }

    // selects new marker
    markerIndex = markersArray.findIndex(marker => marker.id === selectedMarkerId);
    if (markerIndex !== -1) {
      markersArray[markerIndex].setIcon(MarkerFactory.MARKER_TYPES.SELECTED);
    }
    selectedMarker.current = selectedMarkerId;
  }, [selectedMarkerId, markersArray]);

  useEffect(() => {
    if (!(maps && map) || !positions) {
      return;
    }
    console.log('####**** MAP destinationSearchName');
    // center map on destination when no marker was added to the map
    if (positions.length === 0) {
      destinationSearchQuery.current = destinationSearchName;
      zoomOnDestinationSearchResult(maps, map, destinationSearchName);
    }
  }, [maps, map, positions, destinationSearchName]);

  const searchMarkerId = useRef(undefined);
  useEffect(() => {
    console.log('####****MAP searchPlace');

    const setSearchMarker = (markerId, latitude, longitude) => {
      const updatedMarkersArray = [...markersArray];
      // remove marker from previous search
      if (searchMarkerId.current) {
        MarkerFactory.removeMarker(updatedMarkersArray, searchMarkerId.current);
      }

      if (!(latitude && longitude)) {
        searchMarkerId.current = null;
        return;
      }
      const marker = addMarkerToMap(maps, map, latitude, longitude, updatedMarkersArray.length + 1, markerId, MarkerFactory.MARKER_TYPES.SEARCH);
      updatedMarkersArray.push(marker);

      searchMarkerId.current = markerId;
      setMarkersArray(updatedMarkersArray);
    };

    if (maps && map && searchPlace && searchMarkerId.current !== searchPlace.id) {
      const { id: searchId, latitude, longitude } = searchPlace;
      setSearchMarker(searchId, latitude, longitude);
    }
  }, [maps, map, searchPlace, markersArray]);

  const initializeMaps = (map, maps, callback) => {
    setMap(map);
    setMaps(maps);
    onMapsLoaded(maps); // initialize maps for reference outside this component

    // initialize custom Pins once google api is loaded
    MarkerFactory.init(maps);
  };

  const onMapsload = ({ map, maps }) => {
    // console.log(map, maps);
    initializeMaps(map, maps);
  };

  return (
    <>
      <GoogleMapReact
        id="map_canvas-container"
        bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY, libraries: 'places' }}
        defaultCenter={DEFAULT_MAP_DESTINATION.CENTER}
        defaultZoom={DEFAULT_MAP_DESTINATION.ZOOM}
        options={{ mapTypeControl: true }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={onMapsload}
        {...props}
      >
        {children}
      </GoogleMapReact>
    </>
  );
};

export default MapCanvas;
