import {
  TRIP_TEMPLATE_RESET,
  UPDATE_CURRENT_TRIP_TEMPLATE_FIELD,
  TRIP_TEMPLATES_GET_REQUEST,
  TRIP_TEMPLATES_GET_SUCCESS,
  TRIP_TEMPLATES_GET_FAILURE,
  TRIP_TEMPLATE_POST_REQUEST,
  TRIP_TEMPLATE_POST_SUCCESS,
  TRIP_TEMPLATE_POST_FAILURE,
  TRIP_TEMPLATE_DELETE_REQUEST,
  TRIP_TEMPLATE_DELETE_SUCCESS,
  TRIP_TEMPLATE_DELETE_FAILURE,
  TRIP_TEMPLATE_GET_REQUEST,
  TRIP_TEMPLATE_GET_SUCCESS,
  TRIP_TEMPLATE_GET_FAILURE,
  TRIP_TEMPLATE_PUT_REQUEST,
  TRIP_TEMPLATE_PUT_SUCCESS,
  TRIP_TEMPLATE_PUT_FAILURE
} from '../constants/actionTypes';

import agent from '../agent';
import i18next from 'i18next';
import { setToastSuccessMessage, setToastErrorMessage } from './common';
import { updateTripPlanPayload, resetTripPlanPayload } from './tripPlans';

export const resetCurrentTripTemplate = () => ({ type: TRIP_TEMPLATE_RESET });
export const updateCurrentTripTemplateField = (name, value) => ({ type: UPDATE_CURRENT_TRIP_TEMPLATE_FIELD, key: name, value });

export const getTripTemplatesRequest = () => ({ type: TRIP_TEMPLATES_GET_REQUEST });

export const getTripTemplatesSuccess = payload => ({
  type: TRIP_TEMPLATES_GET_SUCCESS,
  payload
});
export const getTripTemplatesFailure = errorMsg => ({ type: TRIP_TEMPLATES_GET_FAILURE, error: { message: errorMsg } });

export const getTripTemplates = () => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(getTripTemplatesRequest());

    const errorMsg = i18next.t('TRIP_TEMPLATE_LIST_RETRIVAL_ERROR');

    try {
      const paginatedResponse = await agent.TripTemplatesService.getTripTemplates();
      const tripTemplates = paginatedResponse.result.listings

      if (!tripTemplates || tripTemplates.length === 0) {
        dispatch(getTripTemplatesFailure(errorMsg)); // forces an error
        return;
      }
      const sortedTripTemplates = (tripTemplates || []).sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

      dispatch(getTripTemplatesSuccess(sortedTripTemplates));
    } catch (error) {
      console.log(`Error when retrieving trip templates list: ${JSON.stringify(error)}`);
      dispatch(getTripTemplatesFailure(errorMsg));
    }
  };
};

export const deleteTripTemplateRequest = () => ({ type: TRIP_TEMPLATE_DELETE_REQUEST });

export const deleteTripTemplateSuccess = (message, tripTemplateId) => ({
  type: TRIP_TEMPLATE_DELETE_SUCCESS,
  message,
  tripTemplateId
});

export const deleteTripTemplateFailure = errorMsg => ({ type: TRIP_TEMPLATE_DELETE_FAILURE, error: { message: errorMsg } });

export const deleteTripTemplate = tripTemplateId => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(deleteTripTemplateRequest());

    try {
      await agent.TripTemplatesService.deleteTripTemplate(tripTemplateId);
      const deletionSuccessMessage = i18next.t('TRIP_TEMPLATE_DELETION_SUCCESS');
      dispatch(deleteTripTemplateSuccess(deletionSuccessMessage, tripTemplateId));
      dispatch(setToastSuccessMessage(deletionSuccessMessage, `Trip Template successfully deleted: ${tripTemplateId}`));
    } catch (error) {
      const errorMsg = `${i18next.t('TRIP_TEMPLATE_DELETION_ERROR_PREFIX')} : ${error.message}`;
      dispatch(deleteTripTemplateFailure(errorMsg));
      dispatch(setToastErrorMessage(errorMsg, `Error when deleting a Trip Template: ${JSON.stringify(error)}`));
    }
  };
};

export const postTripTemplateRequest = () => ({ type: TRIP_TEMPLATE_POST_REQUEST });

export const postTripTemplateSuccess = (message, payload) => ({
  type: TRIP_TEMPLATE_POST_SUCCESS,
  message,
  payload
});

export const postTripTemplateFailure = errorMsg => ({ type: TRIP_TEMPLATE_POST_FAILURE, error: { message: errorMsg } });

export const createTripTemplate = tripTemplate => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(postTripTemplateRequest());

    try {
      const newTripTemplate = await agent.TripTemplatesService.createTripTemplate(tripTemplate);
      dispatch(postTripTemplateSuccess(i18next.t('TRIP_TEMPLATE_CREATION_SUCCESS'), newTripTemplate));
      dispatch(setToastSuccessMessage(i18next.t('TRIP_TEMPLATE_CREATION_SUCCESS'), `'Trip Template successfully created: ${newTripTemplate._id}`));
      return newTripTemplate;
    } catch (error) {
      const errorMsg = `${i18next.t('TRIP_TEMPLATE_CREATION_ERROR_PREFIX')} : ${error.message}`;
      dispatch(postTripTemplateFailure(errorMsg));
      dispatch(setToastErrorMessage(errorMsg, `Error when creating a trip template: ${JSON.stringify(error)}`));
      return null;
    }
  };
};

export const getTripTemplateRequest = () => ({ type: TRIP_TEMPLATE_GET_REQUEST });

export const getTripTemplateSuccess = payload => ({
  type: TRIP_TEMPLATE_GET_SUCCESS,
  payload
});
export const getTripTemplateFailure = errorMsg => ({ type: TRIP_TEMPLATE_GET_FAILURE, error: { message: errorMsg } });

export const getTripTemplate = (tripTemplateId, updateTripPlan) => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(getTripTemplateRequest());

    try {
      const tripTemplate = await agent.TripTemplatesService.getTripTemplate(tripTemplateId);
      const { tripPlan } = tripTemplate;
      dispatch(getTripTemplateSuccess(tripTemplate));
      if (updateTripPlan) {
        dispatch(updateTripPlanPayload(tripPlan));
      }
      return tripTemplate;
    } catch (error) {
      const errorMsg = `${i18next.t('TRIP_TEMPLATE_RETRIEVAL_ERROR_PREFIX')} : ${error.message}`;
      dispatch(getTripTemplateFailure(errorMsg));
      dispatch(resetTripPlanPayload());
      dispatch(setToastErrorMessage(errorMsg, `Error when retrieving a Trip Template: ${JSON.stringify(error)}`));
      return null;
    }
  };
};

export const putTripTemplateRequest = () => ({ type: TRIP_TEMPLATE_PUT_REQUEST });

export const putTripTemplateSuccess = (message, payload) => ({
  type: TRIP_TEMPLATE_PUT_SUCCESS,
  message,
  payload
});
export const putTripTemplateFailure = errorMsg => ({ type: TRIP_TEMPLATE_PUT_FAILURE, error: { message: errorMsg } });

export const saveTripTemplate = (tripTemplateId, tripTemplate) => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(putTripTemplateRequest());

    try {
      const updatedTripTemplate = await agent.TripTemplatesService.saveTripTemplate(tripTemplateId, tripTemplate);
      const { tripPlan } = updatedTripTemplate;
      dispatch(putTripTemplateSuccess(i18next.t('TRIP_TEMPLATE_UPDATE_SUCCESS'), updatedTripTemplate));
      // depending on query params, the trip plan is not updated    
      if (typeof tripPlan !== 'string') {
        dispatch(updateTripPlanPayload(tripPlan));
      }
      dispatch(setToastSuccessMessage(i18next.t('TRIP_TEMPLATE_UPDATE_SUCCESS'), `Trip Template successfully saved: ${tripTemplateId}`));
    } catch (error) {
      const errorMsg = `${i18next.t('TRIP_TEMPLATE_UPDATE_ERROR')} : ${error.message}`;
      dispatch(putTripTemplateFailure(errorMsg));
      dispatch(setToastErrorMessage(errorMsg, `Error when updating a Trip Template: ${JSON.stringify(error)}`));
    }
  };
};
