import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import agent from '../agent';
import stripe from '../services/stripe';
import Header from './Header';
import { appLoad, redirectAction, setEnvironment } from '../actions/common';
import { Route as ReactRouter, Switch, Redirect, useLocation } from 'react-router-dom';
import navigationConfig from '../configs/navigation';
import OuterLayout from './OuterLayout';
import 'font-awesome/css/font-awesome.min.css';
import ROUTES from '../constants/routes';
import { history } from '../store';
import { STRIPE_PUBLISHABLE_KEY } from '../appConfiguration'
import LocalStorage from '../LocalStorage';
import environments from '../configs/environments';
import useQuery from '../hooks/useQuery';
import { parseObject } from '../utils/common';

const mapStateToProps = state => ({
  appLoaded: state.common.appLoaded,
  appName: state.common.appName,
  currentUser: state.common.currentUser,
  redirectTo: state.common.redirectTo
});

const mapDispatchToProps = dispatch => ({
  onLoad: () => dispatch(appLoad()),
  onRedirect: () => dispatch(redirectAction()),
  setEnvironment: value => dispatch(setEnvironment(value))
});

const Route = ({ path, component, exact }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <ReactRouter exact={exact} path={path} component={component} />;
};

const App = ({ redirectTo, appLoaded, appName, currentUser, onLoad, onRedirect, setEnvironment }) => {
  let query = useQuery();

  const onLoadSetLocalStorage = (query) => {
    const token = query.get('token');
    const expirationTime = query.get('expirationTime');
    const user = parseObject(query.get('user'));
    if (token && expirationTime && user) {
      console.log('Setting received authentication local storage data via query string');
      LocalStorage.setAll(token, user, expirationTime);
    }
  }

  useEffect(() => {
    agent.initializeAgent();

    // incase page is reloaded, we get the info on the stored environment
    const environment = LocalStorage.getCurrentEnvironment() || environments[0]; // first environment is the default
    setEnvironment(environment);

    const onLoadStripe = async () => {
      await stripe.initialize(STRIPE_PUBLISHABLE_KEY);
    };

    onLoadStripe();

    onLoadSetLocalStorage(query);

    onLoad();
  }, [onLoad]);

  useEffect(() => {
    if (redirectTo) {
      history.push(redirectTo);
      onRedirect();
    }
  }, [redirectTo, onRedirect]);

  const navigationValues = Object.values(navigationConfig);
  if (appLoaded) {
    return (
      <OuterLayout appName={appName} currentUser={currentUser}>
        <Switch>
          {navigationValues.map((page, index) => (
            <Route key={index} exact path={page.route} component={page.component} />
          ))}
          <Route path="*" component={() => <Redirect to={ROUTES.NOT_FOUND} />} />
        </Switch>
      </OuterLayout>
    );
  }

  return (
    <OuterLayout>
      <Header appName={appName} currentUser={currentUser} />
    </OuterLayout>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
