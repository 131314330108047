import React from 'react';

const TermsOfUsePage = () => {
  return (
    <div className="container padding-10">
      <div className="row">
        <h1>Terms of Use</h1>
      </div>
    </div>
  );
};

export default TermsOfUsePage;
