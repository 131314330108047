import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getExperts, registerExpert, updateExpertUserList } from '../actions/experts';
import { deleteUser } from '../actions/users';
import InProgress from '../components/InProgress';

const RegisterExpertUserPage = ({ allExperts, inProgress, getExperts, updateExpertUserList, registerExpert, deleteUser, environmentUrl }) => {
  const { t } = useTranslation();

  //submission message
  const [message, setMessage] = useState('');
  const [newUser, setNewUser] = useState({ email: '', password: '', passwordConfirm: '' });

  // Wrap with useCallback to avoid change on every render. It returns a memoized version of the callback that only changes if one of the provided dependencies change.
  const fetchAllExperts = useCallback(async () => {
    await getExperts();
  }, [getExperts]);

  useEffect(() => {
    fetchAllExperts();
  }, [fetchAllExperts]);

  const inactivateExpertUser = userId => {
    alert('Not Yet Available');
  };

  const deleteExpertUser = async userId => {
    const confirmWindow = window.confirm('Are you sure?');
    if (confirmWindow === false) {
      return;
    }
    const isDeleted = await deleteUser(userId);
    if (isDeleted) {
      setMessage(`User succefully deleted: ${userId}`);
      const updatedExperts = allExperts.filter(expert => expert._id !== userId)
      updateExpertUserList(updatedExperts);
      return;
    }
    setMessage(`User could not be deleted: ${userId}`);

  };

  const onChangeEmail = ev => setNewUser({ ...newUser, email: ev.target.value });
  const onChangePassword = ev => setNewUser({ ...newUser, password: ev.target.value });
  const onChangeConfirmPassword = ev => setNewUser({ ...newUser, passwordConfirm: ev.target.value });

  const onRegisterExpert = async () => {
    const { email, password, passwordConfirm } = newUser

    if (passwordConfirm !== password) {
      setMessage('Passwords must be identical!');
      return;
    }

    const payload = await registerExpert({ email, password });
    setMessage(`Expert Registered: ${payload.email}`);
  };

  return (
    <div className="container padding-10">
      <div className="row">
        <form onSubmit={onRegisterExpert}>
          <h2>{t('DASHBOARD_REGISTER_EXPERT')}</h2>
          <div className="row">
            <div className="col-4">
              <input type="email" placeholder="E-mail" className="form-control" value={newUser.email} onChange={onChangeEmail} /><br />
            </div>
            <div className="col-3">
              <input type="text" placeholder="Password" className="form-control" value={newUser.password} onChange={onChangePassword} /><br />
            </div>
            <div className="col-3">
              <input type="text" placeholder="Confirm Password" className="form-control" value={newUser.passwordConfirm} onChange={onChangeConfirmPassword} /><br />
            </div>
            <div className="col-2">
              <input type="submit" value="Register" className="btn btn-primary" />
            </div>
          </div>
        </form>
      </div>
      <div className="row">
        <p className="text-warning">{message}</p>
      </div>
      <div className="row fadein-container">
        <div>
          <h2>Expert Users List</h2>
        </div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>{t('DASHBOARD_REGISTER_EXPERT_ID')}</th>
              <th>{t('DASHBOARD_REGISTER_EXPERT_EMAIL')}</th>
              <th>{t('DASHBOARD_REGISTER_EXPERT_NAME')}</th>
              <th>Status</th>
              <th>{t('DASHBOARD_REGISTER_EXPERT_ACTIONS')}</th>
            </tr>
          </thead>
          <tbody>
            {(allExperts || []).map((expertUser, index) => {
              const { _id, firstname, lastname, email, status } = expertUser;
              return (
                <tr key={index}>
                  <td>
                    <span>{_id} </span>
                    <span>
                      <a href={`${environmentUrl}/experts/${_id}`} target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-external-link"></i>
                      </a>
                    </span>
                  </td>
                  <td>{email}</td>
                  <td>{`${firstname} ${lastname}`}</td>
                  <td>{status}</td>
                  <td>
                    <input type="submit" value="Delete" className="btn btn-primary  mr-2" onClick={ev => deleteExpertUser(expertUser._id)} />
                    <input type="submit" value="Inactivate" className="btn btn-primary" onClick={ev => inactivateExpertUser(expertUser._id)} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <InProgress inProgress={inProgress} />
      {!inProgress && allExperts.length === 0 ? <div>{t('DASHBOARD_REGISTER_EXPERT_NO_USERS')}</div> : null}
    </div>
  );
};

const mapStateToProps = state => ({
  allExperts: state.experts.allExperts,
  inProgress: state.experts.inProgress,
  environmentUrl: state.common.environment ? state.common.environment.url : '',
});

const mapDispatchToProps = dispatch => ({
  getExperts: () => dispatch(getExperts()),
  updateExpertUserList: (experts) => dispatch(updateExpertUserList(experts)),
  registerExpert: (newExpertUser) => dispatch(registerExpert(newExpertUser)),
  deleteUser: (userId) => dispatch(deleteUser(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterExpertUserPage);
