import {
  APP_LOAD,
  REDIRECT,
  SET_ENVIRONMENT,
  UPDATE_TRIP_REQUEST_FIELD,
  RESET_TRIP_REQUEST,
  SUBMIT_FEEDBACK_REQUEST,
  SUBMIT_FEEDBACK_FAILURE,
  SUBMIT_FEEDBACK_SUCCESS,
  SHOW_TOAST,
  HIDE_TOAST,
  COMMON_USER_UPDATE
} from '../constants/actionTypes';
import LocalStorage from '../LocalStorage';
import agent from '../agent';
import i18next from 'i18next';

export const redirectAction = () => ({ type: REDIRECT });

export const setEnvironment = (value) => {
  const {serverUrl} = value;
  agent.setApiRoot(serverUrl);
  LocalStorage.setEnvironment(value);
  return { type: SET_ENVIRONMENT, value }
};

export const setToastSuccessMessage = (message, consoleMsg) => {
  if (consoleMsg) {
    console.log(consoleMsg);
  }
  return { type: SHOW_TOAST, message, mtype: 'success' };
};
export const setToastErrorMessage = (message, consoleMsg) => {
  if (consoleMsg) {
    console.log(consoleMsg);
  }
  return { type: SHOW_TOAST, message, mtype: 'error' };
};
export const setToastWarningMessage = (message, consoleMsg) => {
  if (consoleMsg) {
    console.log(consoleMsg);
  }
  return { type: SHOW_TOAST, message, mtype: 'warning' };
};
export const hideToastMessage = () => {
  return { type: HIDE_TOAST };
};

export const appLoad = token => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    const token = LocalStorage.getToken();
    const user = LocalStorage.getCurrentUser();
    const isSessionExpired = LocalStorage.isSessionExpired();

    if (!token || !user || isSessionExpired) {
      dispatch({ type: APP_LOAD, user: null, token: null, skipTracking: true });
      return;
    }

    dispatch({ type: APP_LOAD, user, token, skipTracking: true });
  };
};

export const resetTripRequest = (name, value) => ({ type: RESET_TRIP_REQUEST });
export const updateTripRequestField = (name, value) => ({ type: UPDATE_TRIP_REQUEST_FIELD, key: name, value });

export const postFeedback = () => ({ type: SUBMIT_FEEDBACK_REQUEST });

export const postFeedbackSuccess = payload => ({
  type: SUBMIT_FEEDBACK_SUCCESS,
  payload
});
export const postFeedbackFailure = error => ({ type: SUBMIT_FEEDBACK_FAILURE, error });

export const submitFeedback = feedback => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(postFeedback());

    try {
      if (!feedback.email || !feedback.text) {
        const errMessage = i18next.t('FOOTER_CONTACT_FORM_VALIDATION_ERROR');
        throw new Error(errMessage);
      }

      await agent.AppService.postFeedback(feedback);
      const message = i18next.t('FOOTER_CONTACT_SUCCESS');
      dispatch(postFeedbackSuccess(message));
    } catch (error) {
      dispatch(postFeedbackFailure(error));
    }
  };
};

export const updateCommonUser = payload => ({
  type: COMMON_USER_UPDATE,
  payload
});
