import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import NavigationService from '../services/navigation';
import { getTripStatusTranslationString } from '../utils/common';
import { formatCurrency } from '../utils/currencyUtils';
import { formatDate } from '../utils/dateUtils';
import { connect } from 'react-redux';
import { getTripResume } from '../actions/trips';
import CONSTANTS from '../constants/constants';
import { getUserFullName } from '../utils/userUtils';
import PaymentCheckout from '../containers/PaymentCheckout';

const TripPaymentPage = ({ currentTripResume, getTripResume }) => {
  const { t } = useTranslation();
  let { tripId } = useParams();

  // Wrap with useCallback to avoid change on every render. It returns a memoized version of the callback that only changes if one of the provided dependencies change.
  const fetchTripResume = useCallback(async () => {
    await getTripResume(tripId);
  }, [tripId, getTripResume]);

  useEffect(() => {
    fetchTripResume();
  }, [fetchTripResume]);

  const goBack = event => {
    event.stopPropagation();
    NavigationService.goToDashboard(CONSTANTS.DASHBOARD_TAB_NAMES.TRIP_PLANS);
  };

  if (!currentTripResume) {
    return null;
  }

  const { expertUser, title, startDate, tripRequest, status, tripProcessingStatusState, payment } = currentTripResume;
  const tripStatusStr = getTripStatusTranslationString(status);
  const expertName = getUserFullName(expertUser, '');

  return (
    <div className="container padding-10">
      <div className="row">
        <div className="col-12 col-md-6">
          <h4 className="title">
            {t('TRIP_REQUEST_TRIP_SUMMARY')} : {title || '-'}
          </h4>
          <div className="row">
            <div className="trip-edit-avatar payment float-left">
              <img src={expertUser.imageUrl || '/images/default-user-bk.png'} className="rounded-circle img-fluid" alt={expertName} />
            </div>
            <h3>{expertName}</h3>
          </div>
          <hr />
          <div className="row">
            <div className="col-5">{t('DASHBOARD_PAYMENTS_DATE')} :</div>
            <div className="col-7">
              {formatDate(startDate) || '-'} ({tripRequest ? tripRequest.numberOfDays : '-'} days)
            </div>
          </div>
          <div className="row">
            <div className="col-5">{t('TRIP_RESUME_TRIP_STATUS')} :</div>
            <div className="col-7">
              {t(tripStatusStr)}
              {status === CONSTANTS.TRIP_STATUS.TRIP_PROCESSING && tripProcessingStatusState ? <span> ({t(tripProcessingStatusState)}) </span> : null}
            </div>
            <hr />
          </div>
          <div className="row">
            <div className="col-5">{t('TRIP_INFO_SERVICES')} :</div>
            <div className="col-7">
              <ul>
                {Object.keys(CONSTANTS.TRIP_SERVICES_CONFIG).map((serviceConfigKey, index) => {
                  const serviceConfig = CONSTANTS.TRIP_SERVICES_CONFIG[serviceConfigKey];
                  const modelKey = serviceConfig.MODEL_KEY;
                  if (tripRequest && tripRequest.services && tripRequest.services[modelKey]) {
                    return <li>{t(serviceConfig.SERVICE_NAME_LABEL_KEY)}</li>;
                  }
                  return null;
                })}
              </ul>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-5">
              <b>{t('TRIP_RESUME_PAYMENT_VALUE')} :</b>
            </div>
            <div className="col-7">
              <b>{formatCurrency(payment.currencyCode, payment.value)}</b>
            </div>
          </div>
          <div className="row padding-10">
            <div className="col-12">
              <button className="btn btn-primary margin-10" onClick={goBack}>
                {t('BUTTON_BACK')}
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <h4 className="title">Checkout</h4>
          <div className="row padding-10">
            <PaymentCheckout tripId={tripId} status={status} payment={payment} />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  currentTripResume: state.trips.currentTripResume,
  error: state.trips.error
});

const mapDispatchToProps = dispatch => ({
  getTripResume: tripResumeId => dispatch(getTripResume(tripResumeId))
});

export default connect(mapStateToProps, mapDispatchToProps)(TripPaymentPage);
