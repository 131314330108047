import React from 'react';
import { formatCurrency } from '../utils/currencyUtils';
import { convertTimestampToHourOfTheDay, convertTimestampToHourOfTheDayVerbose } from '../utils/dateUtils';
import { useTranslation } from 'react-i18next';
import CONSTANTS from '../constants/constants';
import TripEventTitle from './TripEventTitle';

const TripEventInfo = ({ event }) => {
  const { t } = useTranslation();

  const renderEventDescription = event => {
    if (event.kind !== CONSTANTS.TRIP_EVENT_KINDS.ATTRACTION) {
      return null;
    }

    return (
      <div className="row form text-justify">
        <span>
          <strong>{t('TOOL_DAY_VIEW_DESCRIPTION')} </strong>
        </span>
        <span>{event.description || '-'} </span>
      </div>
    );
  };

  const renderEventAddress = event => {
    const { kind, imageUrl, address } = event;
    // Directions events are the only type without images
    if (kind === CONSTANTS.TRIP_EVENT_KINDS.DIRECTIONS) {
      return null;
    }

    return (
      <div className="row form">
        <img src={imageUrl || '/images/default-image-bk.png'} className="event-img-sm" alt="Trip Event" />
        <br />
        <div className="row form">
          <span>
            <strong>{t('TOOL_DAY_VIEW_ADDRESS')} </strong> {address || t('TRIP_EVENT_NOT_SPECIFIED')}
          </span>
        </div>
      </div>
    );
  };

  const renderEventDirections = event => {
    if (event.kind !== CONSTANTS.TRIP_EVENT_KINDS.DIRECTIONS) {
      return null;
    }

    return (
      <>
        <div className="row form">
          <div className="field-label">
            <label>{t('TOOL_DAY_VIEW_DISTANCE')}</label>
          </div>
          <div>{event.distance || '-'} km</div>
        </div>
        <div className="row form">
          <div className="field-label">
            <label>{t('TOOL_DAY_VIEW_FROM')} </label>
          </div>
          <div>{event && event.origin ? event.origin.address : '-'}</div>
        </div>
        <div className="row form">
          <div className="field-label">
            <label>{t('TOOL_DAY_VIEW_TO')} </label>
          </div>
          <div>{event && event.destination ? event.destination.address : '-'}</div>
        </div>
        <div className="row form">
          <div className="field-label">
            <label>{t('TOOL_DAY_VIEW_EVENT_HIDDEN')}</label>
          </div>
          <div>{event.hidden ? t('LABEL_YES') : t('LABEL_NO')}</div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="padding-10">
        <h3>
          <TripEventTitle event={event} />
        </h3>
      </div>
      {renderEventAddress(event)}
      {renderEventDescription(event)}
      {renderEventDirections(event)}
      <div className="row form">
        <div className="field-label">
          <label>{t('TOOL_DAY_VIEW_START_TIME')}</label>
        </div>
        <div>{convertTimestampToHourOfTheDay(event.timestampStart) || '-'}</div>
      </div>
      <div className="row form">
        <div className="field-label">
          <label>{t('TOOL_DAY_VIEW_DURATION')}</label>
        </div>
        <div>{convertTimestampToHourOfTheDayVerbose(event.duration) || '-'}</div>
      </div>
      <div className="row form">
        <div className="field-label">
          <label>{t('TOOL_DAY_VIEW_PRICE')} </label>
        </div>
        <div>{formatCurrency(event.totalPriceCurrencyCode, event.totalPrice)}</div>
      </div>
      <div className="row form text-justify">
        <span>
          <strong>{t('TOOL_DAY_VIEW_TIP')} </strong>
        </span>
        <span>{event.tips || '-'}</span>
      </div>
    </>
  );
};

export default TripEventInfo;
