import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateTripRequestField } from '../actions/common';
import { getExperts } from '../actions/experts';
import i18next from 'i18next';
import ExpertItem from '../components/ExpertItem';
import InProgress from '../components/InProgress';
import ServicesCheckboxes from '../components/ServicesCheckboxes';
import CONSTANTS from '../constants/constants';
import NavigationService from '../services/navigation';
import { calculateFullPriceInCents, isSomeServiceChosen } from '../utils/tripUtils';

const TripRequestExpertsPage = ({
  allExperts,
  tripRequest,
  onChangeTripRequestExpertField,
  onChangeTripRequestFullPriceField,
  onChangeTripRequestServicesField,
  getExperts,
  inProgress
}) => {
  const { t } = useTranslation();

  const tripRequestNumberOfDays = tripRequest.numberOfDays
    ? tripRequest.numberOfDays + i18next.t('TRIP_INFO_DAY') + (tripRequest.numberOfDays === 1 ? '' : 's')
    : ' - ';

  const goToConfirmationStep = expertUser => ev => {
    ev.stopPropagation();
    onChangeTripRequestExpertField(expertUser);

    // Updates trip request price before moving to nevt step
    const fullTripPrice = calculateFullPriceInCents(tripRequest.numberOfDays, tripRequest.expert, tripRequest.services, tripRequest.destinations);
    onChangeTripRequestFullPriceField(fullTripPrice);

    NavigationService.goToTripRequestConfirmation();
  };

  const onChangeServices = (tripRequestServices = {}) => {
    // Business rule: if no service is selected, selects basic itinerary service, because at least one service must be selected
    const noServiceEnabled = !isSomeServiceChosen(tripRequestServices);
    if (noServiceEnabled) {
      tripRequestServices[CONSTANTS.TRIP_SERVICES_CONFIG.ITINERARY.MODEL_KEY] = true;
    }

    onChangeTripRequestServicesField(tripRequestServices);
  };

  useEffect(() => {
    getExperts();
  }, [getExperts]);

  return (
    <div className="container padding-10">
      <div className="row trip-request-bg">
        {/*Filter Column*/}
        <div className="d-none d-md-block col-md-3 trip-request-left-menu">
          <div className="trip-request-filter-container">
            <legend>{t('TRIP_INFO_FILTER_TITLE')}</legend>
            <hr />
            <div className="row">
              <label className="col-form-label col-xs-6">{t('TRIP_INFO_TRIP_DAYS')}: </label>
              <label className="col-form-label col-xs-6">{tripRequestNumberOfDays}</label>
            </div>
            <div className="row">
              <label className="col-form-label col-xs-12">{t('TRIP_INFO_SERVICES')}: </label>
            </div>
            <div className="col-xs-12">
              <ServicesCheckboxes tripRequestServices={tripRequest.services} onChangeServices={onChangeServices} />
            </div>
            <div className="row">
              <label className="col-form-label col-xs-6">{t('TRIP_INFO_TRIP_TYPE')}: </label>
              {tripRequest.tripType === CONSTANTS.TRIP_TYPE.SOLO ? <label className="col-form-label col-xs-6">{t('TRIP_TYPE_SOLO')}</label> : null}
              {tripRequest.tripType === CONSTANTS.TRIP_TYPE.COUPLE ? (
                <label className="col-form-label col-xs-6">{t('TRIP_TYPE_COUPLE')}</label>
              ) : null}
              {tripRequest.tripType === CONSTANTS.TRIP_TYPE.FAMILY ? (
                <label className="col-form-label col-xs-6">{t('TRIP_TYPE_FAMILY')}</label>
              ) : null}
              {tripRequest.tripType === CONSTANTS.TRIP_TYPE.FRIENDS ? (
                <label className="col-form-label col-xs-6">{t('TRIP_TYPE_FRIENDS')}</label>
              ) : null}
              {!tripRequest.tripType ? <label className="col-form-label col-xs-6"> - </label> : null}
            </div>
            <div className="row">
              <label className="col-form-label col-xs-6">{t('TRIP_INFO_BUDGET')}:</label>
              {tripRequest.tripBudget === CONSTANTS.TRIP_BUDGET.LOW ? (
                <label className="col-form-label col-xs-6">{t('TRIP_BUDGET_LOW_COST')}</label>
              ) : null}
              {tripRequest.tripBudget === CONSTANTS.TRIP_BUDGET.MEDIUM ? (
                <label className="col-form-label col-xs-6">{t('TRIP_BUDGET_BALANCED')}</label>
              ) : null}
              {tripRequest.tripBudget === CONSTANTS.TRIP_BUDGET.HIGH ? (
                <label className="col-form-label col-xs-6">{t('TRIP_BUDGET_LUXURY')}</label>
              ) : null}
              {!tripRequest.tripBudget ? <label className="col-form-label col-xs-6"> - </label> : null}
            </div>
            <div className="row">
              <label className="col-form-label col-xs-12">{t('TRIP_INFO_INTERESTS')}: </label>
              <ul className="trip-request-filter-list col-xs-offset-2">
                {tripRequest.preferences && tripRequest.preferences.adventure ? <li>{t('TRIP_INTERESTS_ADVENTURE')}</li> : null}
                {tripRequest.preferences && tripRequest.preferences.culture ? <li>{t('TRIP_INTERESTS_CULTURE')}</li> : null}
                {tripRequest.preferences && tripRequest.preferences.backpacker ? <li>{t('TRIP_INTERESTS_BACKPACK')}</li> : null}
                {tripRequest.preferences && tripRequest.preferences.nightlife ? <li>{t('TRIP_INTERESTS_NIGHT_LIFE')}</li> : null}
                {tripRequest.preferences && tripRequest.preferences.gastronomy ? <li>{t('TRIP_INTERESTS_GASTRONOMY')}</li> : null}
                {tripRequest.preferences && tripRequest.preferences.museum ? <li>{t('TRIP_INTERESTS_MUSEUMS')}</li> : null}
                {tripRequest.preferences && tripRequest.preferences.shopping ? <li>{t('TRIP_INTERESTS_SHOPPING')}</li> : null}
                {tripRequest.preferences && tripRequest.preferences.photography ? <li>{t('TRIP_INTERESTS_PHOTOGRAPHY')}</li> : null}
                {tripRequest.preferences && tripRequest.preferences.guided_tour ? <li>{t('TRIP_INTERESTS_TOURS')}</li> : null}
                {tripRequest.preferences && tripRequest.preferences.pet ? <li>{t('TRIP_INTERESTS_PETS')}</li> : null}
                {tripRequest.preferences && tripRequest.preferences.bike_tour ? <li>{t('TRIP_INTERESTS_BIKE')}</li> : null}
                {tripRequest.preferences && tripRequest.preferences.sport ? <li>{t('TRIP_INTERESTS_SPORTS')}</li> : null}
              </ul>
            </div>
          </div>
          <div className="trip-request-filter-container d-none">
            <legend>Filtrar</legend>
            <label className="col-form-label col-sm-12">Duração da Viagem:</label>
            <div className="checkbox">
              <label>
                <input type="checkbox" /> 1-5 dias
              </label>
            </div>
            <div className="checkbox">
              <label>
                <input type="checkbox" /> 5-10 dias
              </label>
            </div>
            <div className="checkbox">
              <label>
                <input type="checkbox" /> +10 Dias
              </label>
            </div>
            <br />
            <label className="col-form-label col-sm-12">Serviços:</label>
            <div className="checkbox">
              <label>
                <input type="checkbox" />
                Roteiro detalhado das atrações turísticas
              </label>
            </div>
            <div className="checkbox">
              <label>
                <input type="checkbox" /> Transporte até o(s) destino(s)
              </label>
            </div>
            <div className="checkbox">
              <label>
                <input type="checkbox" />
                Acomodações
              </label>
            </div>
            <div className="checkbox">
              <label>
                <input type="checkbox" />
                Restaurantes
              </label>
            </div>
            <br />
            <label className="col-form-label col-sm-12">Tipo da Viagem:</label>
            <div className="checkbox">
              <label>
                <input type="checkbox" />
                <i className="fa fa-cutlery" aria-hidden="true"></i> Solo
              </label>
            </div>
            <div className="checkbox">
              <label>
                <input type="checkbox" />
                <i className="fa fa-cutlery" aria-hidden="true"></i> Casal
              </label>
            </div>
            <div className="checkbox">
              <label>
                <input type="checkbox" />
                <i className="fa fa-cutlery" aria-hidden="true"></i> Familia
              </label>
            </div>
            <div className="checkbox">
              <label>
                <input type="checkbox" />
                <i className="fa fa-cutlery" aria-hidden="true"></i> Amigos
              </label>
            </div>
            <br />
            <label className="col-form-label col-sm-12">Orçamento:</label>
            <div className="checkbox">
              <label>
                <input type="checkbox" />
                <i className="fa fa-cutlery" aria-hidden="true"></i> Baixo Custo
              </label>
            </div>
            <div className="checkbox">
              <label>
                <input type="checkbox" />
                <i className="fa fa-cutlery" aria-hidden="true"></i> Medio Custo
              </label>
            </div>
            <div className="checkbox">
              <label>
                <input type="checkbox" />
                <i className="fa fa-cutlery" aria-hidden="true"></i> Alto Custo
              </label>
            </div>
            <br />
            <label className="col-form-label col-sm-12">Atividades:</label>
            <div className="checkbox">
              <label>
                <input type="checkbox" />
                <i className="fa fa-cutlery" aria-hidden="true"></i> Aventura
              </label>
            </div>
            <div className="checkbox">
              <label>
                <input type="checkbox" />
                <i className="fa fa-cutlery" aria-hidden="true"></i> Historico-Cultural
              </label>
            </div>
            <div className="checkbox">
              <label>
                <input type="checkbox" />
                <i className="fa fa-cutlery" aria-hidden="true"></i> Mochilao
              </label>
            </div>
            <div className="checkbox">
              <label>
                <input type="checkbox" />
                <i className="fa fa-cutlery" aria-hidden="true"></i> Vida Noturna
              </label>
            </div>
            <div className="checkbox">
              <label>
                <input type="checkbox" />
                <i className="fa fa-cutlery" aria-hidden="true"></i> Gastronomica
              </label>
            </div>
          </div>
        </div>
        {/*Expert List Column*/}
        <div className="col-md-9">
          <InProgress inProgress={inProgress} />
          {(allExperts && allExperts.length > 0) ? allExperts.map((expertUser, index) => {
            return (
              <div key={index} className="col-12">
                <ExpertItem expertUser={expertUser} tripRequest={tripRequest} goToConfirmationStep={goToConfirmationStep} />
              </div>
            );
          }) : t('EXPERT_USER_LIST_NOT_FOUND')}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  allExperts: state.experts.allExperts,
  tripRequest: state.common.tripRequest
});

const mapDispatchToProps = dispatch => ({
  onChangeTripRequestExpertField: value => dispatch(updateTripRequestField('expert', value)),
  onChangeTripRequestServicesField: value => dispatch(updateTripRequestField('services', value)),
  onChangeTripRequestFullPriceField: value => dispatch(updateTripRequestField('fullPrice', value)),
  getExperts: () => dispatch(getExperts())
});

export default connect(mapStateToProps, mapDispatchToProps)(TripRequestExpertsPage);
