import React, { useState } from 'react';
import ShowError from '../components/ShowError';
import InProgress from '../components/InProgress';
import { connect } from 'react-redux';
import { updateAuthField, loginRequest } from '../actions/auth';
import { setEnvironment } from '../actions/common';
import { useTranslation } from 'react-i18next';
import environments from '../configs/environments'

const LoginForm = ({ auth, onChangeEmail, onChangePassword, onChangeEnvironment, onSubmit, isDialogModal, environment }) => {
  const { email, password, error, inProgress } = auth;
  const { t } = useTranslation();
  const [currentEnv, setCurrentEnv] = useState(environment);

  const changeEmail = ev => onChangeEmail(ev.target.value);
  const changePassword = ev => onChangePassword(ev.target.value);
  const changeEnvironment = ev => {
    console.log(`Env: ${ev.target.value}`)
    const selectedEnvironment = environments.find(env => env.name === ev.target.value)
    onChangeEnvironment(selectedEnvironment)
    setCurrentEnv(selectedEnvironment);
  };
  const submitForm = (email, password) => ev => {
    ev.preventDefault();
    onSubmit(email, password, isDialogModal);
  };

  return (
    <>
      <ShowError error={error} />
      <form onSubmit={submitForm(email, password)} className="form-auth">
        <fieldset>
          <fieldset className="form-group">
            <select id="envs" className="form-control form-control-lg" name="Environments" value={currentEnv ? currentEnv.name : ''} onChange={changeEnvironment}>
              {environments.map((env, index) => <option key={index} value={env.name}>{env.name}</option>)}
            </select>
          </fieldset>

          <fieldset className="form-group">
            <input className="form-control form-control-lg" type="email" placeholder="Email" value={email} onChange={changeEmail} />
          </fieldset>

          <fieldset className="form-group">
            <input
              className="form-control form-control-lg"
              type="password"
              placeholder={t('LOGIN_PASSWORD')}
              value={password}
              onChange={changePassword}
            />
          </fieldset>

          <button className="btn btn-lg btn-primary pull-xs-right" type="submit" disabled={inProgress}>
            {t('LOGIN_LOGIN')}
          </button>
          <InProgress inProgress={inProgress} />
        </fieldset>
      </form>
    </>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  environment: state.common.environment,
});

const mapDispatchToProps = dispatch => ({
  onChangeEmail: value => dispatch(updateAuthField('email', value)),
  onChangePassword: value => dispatch(updateAuthField('password', value)),
  onChangeEnvironment: value => dispatch(setEnvironment(value)),
  onSubmit: (email, password, isDialogModal) => {
    dispatch(loginRequest(email, password, isDialogModal));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
