import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getLocations, getLocation, getLocationSuccess } from '../actions/locations';
import LocationsMenu from '../containers/LocationsMenu';
import LocationForm from '../containers/LocationForm';
import MapCanvasWrapper from '../components/MapCanvasWrapper';
import SearchPlacesInput from '../components/SearchPlacesInput';
import CONSTANTS from '../constants/constants';
import { getDefaultLocation } from '../utils/locationUtils';

const ManageLocationsPage = ({ currentUser, locations, getLocation, getLocations, getLocationSuccess }) => {
  const { t } = useTranslation();

  const isExpertUser = currentUser ? currentUser.isExpert : null;

  const fetchLocations = useCallback(async () => {
    await getLocations();
  }, [getLocations]);

  useEffect(() => {
    fetchLocations();
  }, [fetchLocations]);

  const [destinationName, setDestinationName] = useState('');
  const [searchPlace, setSearchPlace] = useState(null);
  const [maps, setMaps] = useState(null);

  const onMapsLoaded = maps => {
    setMaps(maps);
  };

  const onChangeDestinationSearchObject = destinationSearch => {
    const destinationSearchName = destinationSearch.code ? destinationSearch.name : null;
    setDestinationName(destinationSearchName);
  };

  const isDuplicateLocation = location => {
    const [foundLocation] = locations.filter(
      loc => loc._id !== location._id && loc.name === location.name && loc.latitude === location.latitude && loc.longitude === location.longitude
    );
    const elementFound = !!foundLocation;

    return elementFound;
  };

  // clear existing value on search field
  const clearPlacesField = () => {
    const input = document.getElementById(CONSTANTS.SEARCH_PLACES_BOX_ID);
    input.value = '';
  };

  const onGetLocation = async locationId => {
    const location = await getLocation(locationId);
    const { _id: id, latitude, longitude } = location;
    const searchPlace = {
      id,
      latitude,
      longitude
    };
    setSearchPlace(searchPlace);
    clearPlacesField();
  };

  const resetLocation = () => {
    const location = getDefaultLocation();
    return location;
  };

  const onPlaceFound = place => {
    const { id, latitude, longitude, name, address, zipCode } = place;
    const searchPlace = {
      id,
      latitude,
      longitude
    };
    setSearchPlace(searchPlace);

    const newLocation = resetLocation();
    const location = { ...newLocation, latitude, longitude, name, address, zipCode };
    getLocationSuccess(location);
  };

  return (
    <div className="container my-locations">
      <div className="row">
        <div className="col-4 location-list">
          <LocationsMenu locations={locations} onGetLocation={onGetLocation} onChangeDestinationSearchObject={onChangeDestinationSearchObject} />
        </div>
        <div className="col-8 location-form">
          <h3>
            {t('MY_LOCATIONS_LOCATION')} <span className="event-destination-text">{destinationName ? `(${destinationName})` : null}</span> :
          </h3>
          <div className="row">
            <div className="col-12">
              <div id="search-location-input">{maps ? <SearchPlacesInput maps={maps} onPlaceFound={onPlaceFound}></SearchPlacesInput> : null}</div>
              <div className="location-map">
                <MapCanvasWrapper id="map_canvas" edition={isExpertUser} searchPlace={searchPlace} onMapsLoaded={onMapsLoaded}></MapCanvasWrapper>
              </div>
            </div>
          </div>
          <LocationForm isDuplicateLocation={isDuplicateLocation} searchPlace={searchPlace} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  locations: state.locations.locations,
  currentLocation: state.locations.currentLocation,
  currentUser: state.common.currentUser
});

const mapDispatchToProps = dispatch => ({
  getLocations: () => dispatch(getLocations()),
  getLocation: locationId => dispatch(getLocation(locationId)),
  getLocationSuccess: location => dispatch(getLocationSuccess(location))
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageLocationsPage);
