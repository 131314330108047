import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { CustomTooltip } from '../components/Custom';
import { getConversations, getConversationsSuccess, getConversation } from '../actions/messages';

const ConversationItem = ({ conversation, isSelected, selectConversationFn }) => {
  const { regularUser, expertUser, hasNewMessages, title } = conversation;
  return (
    <div className={`chat-item-container cursor-pointer ${isSelected ? 'selected' : ''}`} onClick={ev => selectConversationFn(conversation)}>
      <div className="container">
        <div className="row subtitle">{title}</div>
        <div className="row">
          <div className="col-6">
            <div className="chat-person cursor-pointer">
              <img src={regularUser.imageUrl || '/images/default-user-bk.png'} alt={regularUser.name} className="chat-img" />
              <div className="info">
                <div className="title">
                  <CustomTooltip text={regularUser.email} isKey={false}>
                    <div>
                      {regularUser.name}
                      <label className={hasNewMessages ? 'unread-resource-alert' : ''}></label>
                    </div>
                  </CustomTooltip>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="chat-person cursor-pointer">
              <img src={expertUser.imageUrl || '/images/default-user-bk.png'} alt={expertUser.name} className="chat-img" />
              <div className="info">
                <div className="title">
                  <CustomTooltip text={expertUser.email} isKey={false}>
                    <div>
                      {expertUser.name}
                      <label className={hasNewMessages ? 'unread-resource-alert' : ''}></label>
                    </div>
                  </CustomTooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/* Responsible for business logic on the conversation list */
const ConversationItemList = ({ conversations, currentConversationId, selectConversation }) => {
  useEffect(() => {
    const onLoad = async () => {
      if (!conversations || currentConversationId) {
        return;
      }
      // default behaviour is to always select first conversation on load
      const firstConversation = conversations[0];
      if (firstConversation) {
        selectConversation(firstConversation);
      }
    };

    onLoad();
  }, [conversations, currentConversationId, selectConversation]);

  return (
    <>
      {conversations.map((conversation, index) => {
        return (
          <ConversationItem key={index} conversation={conversation} isSelected={currentConversationId === conversation._id} selectConversationFn={selectConversation} />
        );
      })}
    </>
  );
};

/* Responsible for triggering the retrieval of the conversation list */
const ConversationsList = ({ conversations, isExpertUser, currentConversation, getConversation, getConversations, getConversationsSuccess }) => {
  // Wrap with useCallback to avoid change on every render. It returns a memoized version of the callback that only changes if one of the provided dependencies change.
  const fetchConversations = useCallback(async () => {
    const conversations = await getConversations(isExpertUser);
    return conversations;
  }, [isExpertUser, getConversations]);

  // Wrap with useCallback to avoid change on every render. It returns a memoized version of the callback that only changes if one of the provided dependencies change.
  const fetchConversation = useCallback(
    async conversationId => {
      const conversation = await getConversation(conversationId);
      return conversation;
    },
    [getConversation]
  );

  useEffect(() => {
    const onLoad = async () => {
      await fetchConversations();
    };

    onLoad();
  }, [fetchConversations]);

  const selectConversation = async conversation => {
    if (currentConversation._id === conversation._id) {
      return;
    }
    const conv = await fetchConversation(conversation._id);
    if (!conv) {
      console.error(`Could not fetch conversation ${conversation._id}`); // error is handled in actions
      return;
    }
    updateConversationStatus(conv);
  };

  const updateConversationStatus = conversation => {
    const updatedConversations = [...conversations];
    const conversationIndex = conversations.findIndex(conv => conv._id === conversation._id);
    if (conversationIndex !== -1) {
      updatedConversations[conversationIndex].hasNewMessages = false;
      getConversationsSuccess(updatedConversations);
    }
  };

  return (conversations.length === 0 ? 'No conversations.' :
    <ConversationItemList conversations={conversations} currentConversationId={currentConversation._id} selectConversation={selectConversation} />
  );
};

const mapStateToProps = state => ({
  conversations: state.messages.conversations,
  currentConversation: state.messages.currentConversation
});

const mapDispatchToProps = dispatch => ({
  getConversations: isExpertUser => dispatch(getConversations(isExpertUser)),
  getConversationsSuccess: conversations => dispatch(getConversationsSuccess(conversations)),
  getConversation: conversationId => dispatch(getConversation(conversationId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ConversationsList);
