import React from 'react';

const AboutUsPage = () => {
  return (
    <div className="container padding-10">
      <div className="row">
        <p>
          Voyageasy is the new way to plan your trip. By contacting Trip Experts, you can get an amazing trip plan and get the best of your trip. No
          matter your trip style, you can have a great time when travelling.
        </p>
      </div>
    </div>
  );
};

export default AboutUsPage;
