import React from 'react';
import Header from './Header';
import Footer from '../containers/Footer';
import ErrorBoundary from '../components/ErrorBoundary';
import { useLocation } from 'react-router-dom';
import LoginDialogModal from '../containers/LoginDialogModal';
import CustomToast from '../containers/CustomToast';
import NavigationService from '../services/navigation';
import AlertEnvironment from '../containers/AlertEnvironment';

const OuterLayout = ({ children, currentUser }) => {

  const location = useLocation();
  const currentPath = location.pathname;
  const hideFooter = NavigationService.hideFooter(currentPath);

  const containerClassname = `main-container padding-top-navbar`;

  return (
    <ErrorBoundary>
      <div id="main" className="app-container">
        <CustomToast />
        <div>
          <Header currentUser={currentUser} />
        </div>
        <div className={containerClassname} autoscroll="true">
          <AlertEnvironment />
          {children}
        </div>
        <div>{hideFooter ? null : <Footer currentUser={currentUser} isHideFooter={hideFooter} />}</div>
        <LoginDialogModal />
      </div>
    </ErrorBoundary>
  );
};

export default OuterLayout;
