import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import agent from '../agent';
import _stripe from '../services/stripe';
import { ROUTES_PLACEHOLDERS } from '../services/navigation';
import ROUTES from '../constants/routes';

const StripeCheckout = ({ tripResume, payment }) => {
  const { t } = useTranslation();

  const [stripe, setStripe] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    const stripe = _stripe.getStripe()
    setStripe(stripe);
  }, []);

  const initializeSession = async tripResume => {
    const { _id: tripId, regularUser, title } = tripResume;
    const tripPaymentDoneUrlPath = ROUTES.TRIP_PAYMENT.replace(ROUTES_PLACEHOLDERS.TRIP_ID, tripId);
    const successPaymentUrl = window.location.origin + tripPaymentDoneUrlPath;

    const session = await agent.PaymentsService.initializePaymentSession({
      customerEmail: regularUser.email,
      clientReferenceId: tripId,
      tripId,
      lineItem: {
        name: `${t('TRIP_RESUME_PAYMENT_TITLE_PREFIX')} ${title}`,
        description: `${t('TRIP_RESUME_PAYMENT_TITLE_PREFIX')} ${title} (${regularUser.email})`,
        amount: payment.value,
        currency: payment.currencyCode,
        quantity: 1
      },
      successUrl: successPaymentUrl,
      cancelUrl: window.location.href // returns to the same page on cancel
    });
    return session;
  };

  const handleSubmit = async event => {
    event.preventDefault();

    try {
      const session = await initializeSession(tripResume);
      const result = await stripe.redirectToCheckout({
        sessionId: session.id
      });
      console.log(result.error.message);
    } catch (error) {
      setErrorMessage(true);
    }
  };

  if (!stripe) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit}>
      <button type="submit" className="btn btn-primary margin-10 mr-2">
        {t('TRIP_RESUME_PAYMENT_BUTTON_LABEL')} <i className="fa fa-arrow-right"></i>
      </button>
      <p className="error">{errorMessage ? t('TRIP_RESUME_PAYMENT_CHECKOUT_ERROR') : null}</p>
    </form>
  );
};

export default StripeCheckout;
