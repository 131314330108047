import React from 'react';

const TripStatusInstruction = ({ message }) => {
  if (!message) {
    return null;
  }

  return (
    <div className="row trip-status-container">
      <div className="col-12">
        <div className="row trip-status-card">{message}</div>
      </div>
    </div>
  );
};

export default TripStatusInstruction;
