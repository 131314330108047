import superagentPromise from 'superagent-promise';
import superagentIntercept from 'superagent-intercept';
import _superagent from 'superagent';
import CONSTANTS from './constants/constants';
import { showLoginDialogAction } from './actions/modals';
import LocalStorage from './LocalStorage';
import environments from './configs/environments';

const superagent = superagentPromise(_superagent, global.Promise);

const apiBasePath = CONSTANTS.API_BASE,
  authenticationApiUrl = '/auth',
  appConfigUrl = '/app-config',
  logErrorUrl = '/log-client-message',
  mapsApiUrl = apiBasePath + '/maps',
  expertsApiUrl = apiBasePath + '/experts',
  usersApiUrl = apiBasePath + '/users',
  destinationsApiUrl = apiBasePath + '/destinations',
  tripsApiUrl = apiBasePath + '/trips',
  tripTemplatesApiUrl = apiBasePath + '/trip-templates',
  tripRequestsApiUrl = apiBasePath + '/trip-requests',
  tripRequestsNoAuthApiUrl = apiBasePath + '/trip-request-no-authentication',
  conversationsApiUrl = apiBasePath + '/conversations',
  locationsApiUrl = apiBasePath + '/locations',
  notificationsApiUrl = apiBasePath + '/notifications',
  feedbacksApiUrl = apiBasePath + '/feedbacks',
  adminApiUrl = apiBasePath + '/admin',
  paymentsApiUrl = apiBasePath + '/payment';

const responseBody = res => res.body;

let token = null;
let apiRoot = null;
let language = CONSTANTS.DEFAULT_LANGUAGE;
let currentUserId = null;
let currentSessionTimeout;

const tokenPlugin = req => {
  if (token) {
    req.set('authorization', `${token}`);
  }
};

const languagePlugin = req => {
  const currentLanguage = language || CONSTANTS.DEFAULT_LANGUAGE;
  req.set('Accept-Language', `${currentLanguage}`);
};

let AuthIntercept = superagentIntercept((err, res) => {
  if (err) {
    console.log('An Error has occured: ' + JSON.stringify(err.message));
  }

  if (res && res.status === 401) {
    console.log('You are not authorized to access his resource: ' + JSON.stringify(res));
    showLoginDialogAction(); // route to login modal
  }
});

const requests = {
  del: url => superagent.del(`${apiRoot}${url}`).use(tokenPlugin).use(AuthIntercept).then(responseBody),
  get: url => superagent.get(`${apiRoot}${url}`).use(tokenPlugin).use(languagePlugin).use(AuthIntercept).then(responseBody),
  put: (url, body) => superagent.put(`${apiRoot}${url}`, body).use(tokenPlugin).use(AuthIntercept).then(responseBody),
  post: (url, body) => superagent.post(`${apiRoot}${url}`, body).use(tokenPlugin).use(languagePlugin).use(AuthIntercept).then(responseBody)
};

const MapsService = {
  getDirections: (originQuery, destinationQuery) => requests.get(`${mapsApiUrl}/directions?origin=${originQuery}&destination=${destinationQuery}`)
};

const AuthService = {
  /* Returns payload with:
      - expirationTime: expiration timestamp
      - token: JWT token
      - state: success|failure
      - user: payload with user info
  */
  login: (email, password) => requests.post(`${authenticationApiUrl}/login`, { email, password }),
  register: (email, password) => requests.post(`${authenticationApiUrl}/signup`, { email, password }),
  registerExpert: (email, password) => requests.post(`${authenticationApiUrl}/signupexpert`, { email, password }),
  signout: () => requests.get(`${authenticationApiUrl}/signout`),
  forgotPassword: email => requests.post(`${authenticationApiUrl}/forgot-password`, { email }),
  resetPassword: (token, password) => requests.post(`${authenticationApiUrl}/reset-password/${token}`, { password })
};

const UserService = {
  current: () => requests.get(`${usersApiUrl}/${currentUserId}`),
  saveUser: user => requests.put(`${usersApiUrl}/${user._id}`, user),
  saveUserProfileImage: (userId, imageUrl) => requests.put(`${usersApiUrl}/${userId}/image`, { imageUrl }),
  getUser: userId => requests.get(`${usersApiUrl}/${userId}`),
  deleteUser: userId => requests.del(`${usersApiUrl}/${userId}`),
  changeUserPassword: (userId, currentPassword, newPassword) =>
    requests.put(`${usersApiUrl}/${userId}/change-password`, { currentPassword, newPassword }),
  getUserPaymentHistory: userId => requests.get(`${usersApiUrl}/${userId}/payment-history`),
  getAllUsers: () => requests.get(`${usersApiUrl}`), // admin
};

const ExpertService = {
  getExperts: quantity => {
    let params = '?';

    params += quantity ? 'quantity=' + quantity : '';
    return requests.get(`${expertsApiUrl}${params}`);
  },
  getExpertProfile: expertId => requests.get(`${expertsApiUrl}/${expertId}`),
  getExpertRatings: userId => requests.get(`${expertsApiUrl}/${userId}/ratings`)
};

const AppService = {
  getAppConfig: () => requests.get(`${appConfigUrl}`),
  postFeedback: feedback => requests.post(`${feedbacksApiUrl}`, feedback),
  logClientError: message => requests.post(`${logErrorUrl}`, message)
};

const DestinationsService = {
  getDestinations: destinationCodes => {
    let params = '?';

    params += destinationCodes ? 'codes=' + destinationCodes : '';
    return requests.get(`${destinationsApiUrl}${params}`);
  },
  getDestination: destinationId => requests.get(`${destinationsApiUrl}/${destinationId}`),
  searchDestinations: query => {
    let params = '?';

    params += query ? 'q=' + query : '';
    return requests.get(`${destinationsApiUrl}/search${params}`);
  },
  postDestination: destination => requests.post(`${destinationsApiUrl}`, destination),
  updateDestination: (destinationId, destination) => requests.put(`${destinationsApiUrl}/${destinationId}`, destination),
  deleteDestination: destinationId => requests.del(`${destinationsApiUrl}/${destinationId}`)

};

const LocationsService = {
  getLocations: () => requests.get(locationsApiUrl),
  getLocation: locationId => requests.get(`${locationsApiUrl}/${locationId}`),
  postLocation: location => requests.post(`${locationsApiUrl}`, location),
  updateLocation: (locationId, location) => requests.put(`${locationsApiUrl}/${locationId}`, location),
  deleteLocation: locationId => requests.del(`${locationsApiUrl}/${locationId}`)
};

const NotificationsService = {
  getNotifications: () => requests.get(notificationsApiUrl)
};

const MessagesService = {
  getConversation: conversationId => requests.get(`${conversationsApiUrl}/${conversationId}`),
  postMessage: (conversationId, messagePayload, isChangeRequestMessage) => {
    let params = '?';

    params += isChangeRequestMessage ? 'isChangeRequestMessage=' + isChangeRequestMessage : '';
    return requests.put(`${conversationsApiUrl}/${conversationId}${params}`, messagePayload);
  }
};

const TripRequestsService = {
  getTripRequests: (userId, isExpert) => {
    return requests.get(tripRequestsApiUrl);
  },
  postTripRequest: tripRequest => {
    return requests.post(tripRequestsApiUrl, tripRequest);
  },

  postTripRequestNoAuth: tripRequest => {
    const res = requests.post(tripRequestsNoAuthApiUrl, tripRequest);
    return res;
  }
};

const TripsService = {
  getTripResumes: (populateTripRequest, populateConversation, status) => {
    let params = [];

    if (populateTripRequest) {
      params.push('populateTripRequest=true');
    }
    if (populateConversation) {
      params.push('populateConversation=true');
    }
    if (status) {
      params.push(`status=${status}`);
    }

    const allParams = params.join('&');
    return requests.get(`${tripsApiUrl}?${allParams}`);
  },
  getTripResume: tripId => requests.get(`${tripsApiUrl}/${tripId}`),
  saveTripPlan: (tripId, tripPlan) => requests.put(`${tripsApiUrl}/${tripId}`, tripPlan),
  deleteTripResume: tripId => requests.del(`${tripsApiUrl}/${tripId}`),
  changeTripStatus: (tripId, toStatus) => {
    const url = `${tripsApiUrl}/${tripId}/change-status/${toStatus}`;
    return requests.post(url);
  },
  changeTripStatusState: (tripId, status, toState) => {
    const url = `${tripsApiUrl}/${tripId}/status/${status}/change-to-state/${toState}`;
    return requests.post(url);
  },
  makeTripPayment: (tripId, paymentData) => {
    const url = `${tripsApiUrl}/${tripId}/payment`;
    return requests.post(url, paymentData);
  },
  changeTravelerReadPermission: (tripId, toggleValue) => {
    const regularUserRead = 'regularUserRead=' + (toggleValue === true ? 'true' : 'false');
    const url = `${tripsApiUrl}/${tripId}/change-permissions?${regularUserRead}`;
    return requests.put(url, {});
  },
  reviewExpertUser: (tripId, rating) => {
    const url = `${tripsApiUrl}/${tripId}/ratings`;
    return requests.post(url, rating);
  },
  //admin route
  getAllTripResumes: (populateTripRequest, populateConversation) => {
    let params = [];

    if (populateTripRequest) {
      params.push('populateTripRequest=true');
    }
    if (populateConversation) {
      params.push('populateConversation=true');
    }
    const allParams = params.join('&');
    return requests.get(`${adminApiUrl}/trips?${allParams}`)
  },
  //admin route
  getAllTripRequestsNoAuth: () => {
    return requests.get(`${adminApiUrl}/trip-requests?noauth=true`)
  },
};

const TripTemplatesService = {
  getTripTemplates: () => requests.get(tripTemplatesApiUrl),
  createTripTemplate: tripTemplate => requests.post(tripTemplatesApiUrl, tripTemplate),
  getTripTemplate: tripTemplateId => requests.get(`${tripTemplatesApiUrl}/${tripTemplateId}`),
  saveTripTemplate: (tripTemplateId, tripTemplate) => requests.put(`${tripTemplatesApiUrl}/${tripTemplateId}`, tripTemplate),
  deleteTripTemplate: tripTemplateId => requests.del(`${tripTemplatesApiUrl}/${tripTemplateId}`)
};

const TripPlansService = {
  addTripPlanDayEvent: (tripId, dayId, eventPayload, isTemplate) => {
    const apiUrl = isTemplate ? tripTemplatesApiUrl : tripsApiUrl;
    const url = `${apiUrl}/${tripId}/days/${dayId}/events/`;
    return requests.post(url, eventPayload);
  },
  updateTripPlanDayEvent: (tripId, dayId, eventPayload, isTemplate) => {
    const apiUrl = isTemplate ? tripTemplatesApiUrl : tripsApiUrl;
    const url = `${apiUrl}/${tripId}/days/${dayId}/events/${eventPayload._id}`;
    return requests.put(url, eventPayload);
  },
  // replaced by batch-event-update
  moveTripPlanDayEvent: (tripId, dayIdFrom, positionFrom, dayIdTo, positionTo, isTemplate) => {
    const apiUrl = isTemplate ? tripTemplatesApiUrl : tripsApiUrl;
    let url = `${apiUrl}/${tripId}/move/from-day/${dayIdFrom}/position/${positionFrom}/to-day/${dayIdTo}/position/${positionTo}`;

    return requests.put(url);
  },
  moveTripPlanDay: (tripId, dayId, positionFrom, positionTo, isTemplate) => {
    const apiUrl = isTemplate ? tripTemplatesApiUrl : tripsApiUrl;
    let url = `${apiUrl}/${tripId}/move-day/${dayId}/from/${positionFrom}/to/${positionTo}`;
    return requests.put(url);
  },

  deleteTripPlanDayEvent: (tripId, dayId, eventId, isTemplate) => {
    const apiUrl = isTemplate ? tripTemplatesApiUrl : tripsApiUrl;
    let url = `${apiUrl}/${tripId}/days/${dayId}/events/${eventId}`;
    return requests.del(url);
  },

  getTripPlanDay: (tripId, dayId, isTemplate) => {
    const apiUrl = isTemplate ? tripTemplatesApiUrl : tripsApiUrl;
    let url = `${apiUrl}/${tripId}/days/${dayId}`;
    return requests.get(url);
  },

  addTripPlanDay: (tripId, newDay, isTemplate) => {
    const apiUrl = isTemplate ? tripTemplatesApiUrl : tripsApiUrl;
    let url = `${apiUrl}/${tripId}/days/`;
    return requests.post(url, newDay);
  },

  updateTripPlanDay: (tripId, dayId, tripDay, isTemplate) => {
    const apiUrl = isTemplate ? tripTemplatesApiUrl : tripsApiUrl;
    let url = `${apiUrl}/${tripId}/days/${dayId}`;
    return requests.put(url, tripDay);
  },

  deleteTripPlanDay: (tripId, dayId, isTemplate) => {
    const apiUrl = isTemplate ? tripTemplatesApiUrl : tripsApiUrl;
    let url = `${apiUrl}/${tripId}/days/${dayId}`;
    return requests.del(url);
  },

  batchEventUpdate: (tripId, dayId, isTemplate, queryEvents) => {
    const apiUrl = isTemplate ? tripTemplatesApiUrl : tripsApiUrl;
    const url = `${apiUrl}/${tripId}/days/${dayId}/batch-event-update`;
    return requests.put(url, queryEvents);
  },

  appendEvents: (tripId, dayId, isTemplate, queryEvents) => {
    const apiUrl = isTemplate ? tripTemplatesApiUrl : tripsApiUrl;
    const url = `${apiUrl}/${tripId}/days/${dayId}/append-events`;
    return requests.put(url, queryEvents);
  },

  applyCorrectionToTripDayEvents: (tripId, dayId, isTemplate) => {
    const apiUrl = isTemplate ? tripTemplatesApiUrl : tripsApiUrl;
    let url = `${apiUrl}/${tripId}/days/${dayId}/apply-correction-to-events`;
    return requests.put(url, {});
  }
};

const PaymentsService = {
  initializePaymentSession: sessionData => {
    const url = `${paymentsApiUrl}/session-initiate`;
    return requests.post(url, sessionData);
  }
};

const set = (_token, _currentUserId) => {
  token = _token;
  currentUserId = _currentUserId;
};

const setApiRoot = _apiRoot => {
  apiRoot = _apiRoot;
}

const initializeAgent = () => {
  const token = LocalStorage.getToken();
  const user = LocalStorage.getCurrentUser();


  if (token && user) {
    set(token, user._id);
  }

  const environment = LocalStorage.getCurrentEnvironment();
  if (!environment) {
    environment = environments[0]  // first environment is the default
    LocalStorage.setEnvironment(environment)
  }
  setApiRoot(environment.serverUrl);

};

export default {
  AppService,
  AuthService,
  ExpertService,
  TripsService,
  TripPlansService,
  TripTemplatesService,
  UserService,
  TripRequestsService,
  DestinationsService,
  NotificationsService,
  LocationsService,
  MessagesService,
  PaymentsService,
  MapsService,
  initializeAgent,
  set,
  getApiRoot: () => apiRoot,
  setApiRoot,
  setToken: _token => {
    token = _token;
  },
  getLanguage: () => language,
  setLanguage: _language => {
    language = _language;
  },
  setCurrentUserId: _currentUserId => {
    currentUserId = _currentUserId;
  },
  getCurrentSessionTimeout: () => currentSessionTimeout,
  setCurrentSessionTimeout: _currentSessionTimeout => {
    currentSessionTimeout = _currentSessionTimeout;
  }
};
