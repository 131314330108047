import React from 'react';
import { useTranslation } from 'react-i18next';
import RatingStars from '../components/RatingStars';
import IconLine from '../components/IconLine';
import { formatCurrency } from '../utils/currencyUtils';
import CONSTANTS from '../constants/constants';

const ExpertCardServicePrice = ({ currencyCode, mappedService }) => {
  const { t } = useTranslation();

  if (!mappedService) {
    return t('EXPERT_CARD_SERVICE_NOT_OFFERED');
  }

  const servicePrice = `${formatCurrency(currencyCode, mappedService.value)}`;

  return (
    <span className={`${mappedService.isServiceOffered ? '' : 'line-through'}`}>
      {servicePrice} /{mappedService.perDay ? t('TRIP_INFO_PRICE_DAY') : t('TRIP_INFO_PRICE_DESTINATION')}
    </span>
  );
};

const ExpertCard = ({ expert, chosenServices, children }) => {
  const { t } = useTranslation();

  if (!expert) {
    return null;
  }

  const {
    firstname,
    lastname,
    overallRating,
    numberOfRatings,
    blog,
    imageUrl,
    specialities,
    services: expertServices,
    servicesCurrencyCode
  } = expert;
  const displayName = firstname && lastname ? `${firstname} ${lastname}` : '';

  // default behaviour is to never use line through in services
  const defaultChosenServiceValue = true;
  const mappedServices = {};
  (expertServices || []).forEach(function (expertService) {
    // determines whether service will be shown as strikethrough text or not
    expertService.isServiceOffered =
      chosenServices && chosenServices[expertService.stype] ? chosenServices[expertService.stype] : defaultChosenServiceValue;
    mappedServices[expertService.stype] = expertService;
  });

  const renderPriceRow = (index, currencyCode, mappedService, serviceKey) => {
    return (
      <div key={index} className="row">
        <p className="col-7 pl-3">{t(serviceKey)}</p>
        <p className="col-5">
          <ExpertCardServicePrice currencyCode={currencyCode} mappedService={mappedService} />
        </p>
      </div>
    );
  };

  return (
    <div className="expert-card text-center">
      <img
        src={imageUrl || '/images/default-user-bk.png'}
        className="rounded-circle center-block"
        alt="Expert Profile"
        width="157px"
        height="157px"
        padding-top="25px"
      />
      <div className="card-block">
        <div className="expert-flag">
          <img src="/images/flags/brazil.png" className="img-rounded" alt="Country" width="25px" height="25px" padding-top="25px" /> {displayName}
        </div>
        <RatingStars rating={overallRating || 0} />
        <span className="review">
          ({numberOfRatings === 0 ? t('EXPERT_USER_NO_RATINGS') : `${numberOfRatings} rating${numberOfRatings > 1 ? 's' : ''}`})
        </span>
        <div className="card-text">{blog ? blog.name : '-'}</div>
        <br />
        <div className="theme-color">
          <IconLine keys={specialities} />
        </div>
      </div>
      <div className="container card-footer text-left">
        {Object.keys(CONSTANTS.TRIP_SERVICES_CONFIG).map((serviceConfigKey, index) => {
          const serviceConfig = CONSTANTS.TRIP_SERVICES_CONFIG[serviceConfigKey];
          return renderPriceRow(index, servicesCurrencyCode, mappedServices[serviceConfig.MODEL_KEY], serviceConfig.SERVICE_NAME_LABEL_KEY);
        })}
        {/** Transclude and render all children */}
        {children}
      </div>
    </div>
  );
};

export default ExpertCard;
