import {
  TRIPS_GET_REQUEST,
  TRIPS_GET_SUCCESS,
  TRIPS_GET_FAILURE,
  TRIP_RESUME_RESET,
  UPDATE_CURRENT_TRIP_RESUME_FIELD,
  TRIP_RESUME_DELETE_REQUEST,
  TRIP_RESUME_DELETE_SUCCESS,
  TRIP_RESUME_DELETE_FAILURE,
  TRIP_RESUME_GET_REQUEST,
  TRIP_RESUME_GET_SUCCESS,
  TRIP_RESUME_GET_FAILURE,
  TRIP_RESUME_PUT_REQUEST,
  TRIP_RESUME_PUT_SUCCESS,
  TRIP_RESUME_PUT_FAILURE
} from '../constants/actionTypes';

const defaultState = {
  tripResumes: [],
  currentTripResume: {
    regularUser: {},
    expertUser: {},
    payment: {},
    cities: [],
    tripRequest: {},
    conversation: {},
    tripPlan: {}
  },
  inProgress: false,
  message: '',
  error: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case TRIP_RESUME_RESET:
      return {
        ...state,
        currentTripResume: defaultState.currentTripResume
      };
    case UPDATE_CURRENT_TRIP_RESUME_FIELD:
      const updatedCurrentTripResume = { ...state.currentTripResume, [action.key]: action.value };
      return {
        ...state,
        currentTripResume: updatedCurrentTripResume
      };

    case TRIPS_GET_REQUEST:
    case TRIP_RESUME_DELETE_REQUEST:
    case TRIP_RESUME_GET_REQUEST:
    case TRIP_RESUME_PUT_REQUEST:
      return { ...state, inProgress: true };
    case TRIPS_GET_SUCCESS:
      return {
        ...state,
        inProgress: false,
        error: null,
        tripResumes: action.payload ? action.payload : null
      };

    case TRIP_RESUME_GET_SUCCESS:
    case TRIP_RESUME_PUT_SUCCESS:
      return {
        ...state,
        inProgress: false,
        error: null,
        message: action.message ? action.message : null,
        currentTripResume: action.payload ? action.payload : null
      };

    case TRIP_RESUME_DELETE_SUCCESS:
      const filteredTripResumes = state.tripResumes.filter(el => el._id !== action.tripResumeId);

      return {
        ...state,
        inProgress: false,
        error: null,
        message: action.message ? action.message : null,
        tripResumes: filteredTripResumes
      };

    case TRIPS_GET_FAILURE:
      return {
        ...state,
        tripResumes: defaultState.tripResumes,
        inProgress: false,
        message: '',
        error: action.error ? action.error : null
      };
    case TRIP_RESUME_DELETE_FAILURE:
    case TRIP_RESUME_GET_FAILURE:
    case TRIP_RESUME_PUT_FAILURE:
      return {
        ...state,
        inProgress: false,
        message: '',
        error: action.error ? action.error : null
      };
    default:
      return state;
  }
};
