import React from 'react';
import i18next from 'i18next';
import agent from '../agent';
import ReactTags from 'react-tag-autocomplete';
import './AutocompleteDestinationsInput.css';
import InProgress from '../components/InProgress';

const loadTag = {
  id: '------',
  name: 'Loading...'
};

const MAX_NUMBER_DESTINATIONS = 10;
const MIN_QUERY_LENGTH = 2;

class AutocompleteDestinationsInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: this.props.values || [],
      suggestions: [],
      isBusy: false,
      placeholder: this.props.placeholder || i18next.t('SEARCH_PLACES_INPUT_PLACEHOLDER'),
      maxItems: this.props.maxItems || MAX_NUMBER_DESTINATIONS,
      isMaxItemsExceeded: false,
      noSuggestionsText: i18next.t('SEARCH_PLACES_NO_SUGGESTION_TEXT')
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.values !== this.props.values) {
      console.log('Autocomplete Destinations props has changed.');
      this.setState({ tags: this.props.values || [] });
    }
  }

  changeValues(ev) {
    this.props.onChangeValues();
  }

  async loadSearchDestinationsSuggestions(q) {
    const searchDestinations = await agent.DestinationsService.searchDestinations(q);

    const suggestionSearchDestinations = searchDestinations.map(searchDestination => {
      return { ...searchDestination, id: searchDestination.code };
    });

    return suggestionSearchDestinations;
  }

  async handleInputChange(query) {
    if (!this.state.isBusy && query.length >= MIN_QUERY_LENGTH) {
      this.setState({ isBusy: true });
      this.setState({ suggestions: [loadTag] });
      const searchDestinations = await this.loadSearchDestinationsSuggestions(query);
      this.setState({ isBusy: false });
      this.setState({ suggestions: searchDestinations });
    }
  }

  handleDelete(i) {
    this.setState({ isMaxItemsExceeded: false });

    const tags = this.state.tags.slice(0);
    tags.splice(i, 1);
    this.setState({ tags });
    this.props.onChangeValues(tags);
  }

  filterDuplicates(tags) {
    const tagCodes = [];

    const filteredTags = tags.filter(tag => {
      const includeTag = tagCodes.indexOf(tag.code) === -1;
      tagCodes.push(tag.code);
      return includeTag;
    });

    return filteredTags;
  }

  handleAddition(tag) {
    this.setState({ isMaxItemsExceeded: false });

    let tags = [].concat(this.state.tags, tag);
    tags = this.filterDuplicates(tags);

    if (tags.length > this.state.maxItems) {
      tags.splice(-1, 1);
      this.setState({ isMaxItemsExceeded: true });
    }

    this.setState({ tags });
    this.props.onChangeValues(tags);
  }

  render() {
    return (
      <>
        <ReactTags
          tags={this.state.tags}
          suggestions={this.state.suggestions}
          onInput={this.handleInputChange.bind(this)}
          onDelete={this.handleDelete.bind(this)}
          onAddition={this.handleAddition.bind(this)}
          placeholderText={this.state.placeholder}
          noSuggestionsText={this.state.noSuggestionsText}
        />
        <InProgress inProgress={this.state.isBusy} />
        {this.state.isMaxItemsExceeded ? (
          <div className="error small">
            <p>{i18next.t('SEARCH_PLACES_MAX_ITEMS_EXCEEDED')} </p>
          </div>
        ) : null}
      </>
    );
  }
}

export default AutocompleteDestinationsInput;
