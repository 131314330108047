import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AutocompleteDestinationsInput from '../components/AutocompleteDestinationsInput';
import SelectLocationBox from '../components/SelectLocationBox';

const LocationsMenuFilter = ({ onChangeFilter, onChangeDestinationSearchObject }) => {
  const { t } = useTranslation();

  const defaultFilter = { name: '', destinationCode: '', locationType: '' };

  const [filter, setFilter] = useState(defaultFilter);

  useEffect(() => {
    onChangeFilter(filter);
  }, [filter, onChangeFilter]);

  /*
  This list is supposed to contain an object like this:
  destinationSearchObject = [{
    code: 'FR:PARIS',
    name: 'Paris'
  }];
   */
  const [destinationSearchObject, setDestinationSearchObject] = useState([]);

  const onChangeDestination = (destinationSearchObject = []) => {
    const destinationSearch = destinationSearchObject[0] || {};
    setDestinationSearchObject(destinationSearchObject);
    const updatedFilter = { ...filter, destinationCode: destinationSearch.code };
    setFilter(updatedFilter);
    if (onChangeDestinationSearchObject) {
      onChangeDestinationSearchObject(destinationSearch);
    }
  };

  const onLocationTypeChange = locType => {
    const updatedFilter = { ...filter, locationType: locType };
    setFilter(updatedFilter);
  };

  const onSearchLocationNameChange = ev => {
    const updatedFilter = { ...filter, name: ev.target.value };
    setFilter(updatedFilter);
  };

  return (
    <>
      <div className="filter width-100 destination">
        <AutocompleteDestinationsInput
          maxItems={1}
          values={destinationSearchObject}
          placeholder={t('SEARCH_DESTINATION_PLACEHOLDER')}
          onChangeValues={onChangeDestination}
          className="width-100"
        />
      </div>
      <div className="row filter">
        <div className="col-6">
          <label className="width-100">{t('MY_LOCATIONS_LOCATION')}: </label>
          <div className="width-100">
            <input
              type="text"
              className="form-control"
              placeholder={t('MY_LOCATIONS_NAME_FILTER_PLACEHOLDER')}
              value={filter.name}
              onChange={onSearchLocationNameChange}
            />
          </div>
        </div>
        <div className="col-6">
          <label className="width-100">{t('MY_LOCATIONS_TYPE')} </label>
          <SelectLocationBox
            className="width-100"
            locationType={filter.locationType}
            hasDefault={true}
            onLocationChange={onLocationTypeChange}
            required
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LocationsMenuFilter);
