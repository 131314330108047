import {
  USER_RESET,
  UPDATE_CURRENT_USER_FIELD,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAILURE,
  USER_GET_REQUEST,
  USER_GET_SUCCESS,
  USER_GET_FAILURE,
  USER_PUT_CHANGE_PASSWORD_REQUEST,
  USER_PUT_CHANGE_PASSWORD_SUCCESS,
  USER_PUT_CHANGE_PASSWORD_FAILURE,
  USER_PUT_PROFILE_IMAGE_REQUEST,
  USER_PUT_PROFILE_IMAGE_SUCCESS,
  USER_PUT_PROFILE_IMAGE_FAILURE,
  USER_PUT_REQUEST,
  USER_PUT_SUCCESS,
  USER_PUT_FAILURE,
  USER_PAYMENTS_GET_REQUEST,
  USER_PAYMENTS_GET_SUCCESS,
  USER_PAYMENTS_GET_FAILURE
} from '../constants/actionTypes';

import agent from '../agent';
import i18next from 'i18next';
import { setToastSuccessMessage, setToastErrorMessage, updateCommonUser } from './common';
import { getUserFullName } from '../utils/userUtils';

export const resetCurrentUser = () => ({ type: USER_RESET });
export const updateCurrentUserField = (name, value) => ({ type: UPDATE_CURRENT_USER_FIELD, key: name, value });

export const deleteUserRequest = () => ({ type: USER_DELETE_REQUEST });

export const deleteUserSuccess = (message, userId) => ({
  type: USER_DELETE_SUCCESS,
  message,
  userId
});

export const deleteUserFailure = errorMsg => ({ type: USER_DELETE_FAILURE, error: { message: errorMsg } });

export const deleteUser = userId => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(deleteUserRequest());

    try {
      await agent.UserService.deleteUser(userId);
      const deletionSuccessMessage = i18next.t('USER_DELETION_SUCCESS');
      dispatch(deleteUserSuccess(deletionSuccessMessage, userId));
      dispatch(setToastSuccessMessage(deletionSuccessMessage, `User successfully deleted: ${userId}`));
      return true;
    } catch (error) {
      const errorMsg = `${i18next.t('USER_DELETION_ERROR_PREFIX')} : ${error.message}`;
      dispatch(deleteUserFailure(errorMsg));
      dispatch(setToastErrorMessage(errorMsg, `Error when deleting a User: ${JSON.stringify(error)}`));
      return false;
    }
  };
};

export const getUserRequest = () => ({ type: USER_GET_REQUEST });

export const getUserSuccess = payload => ({
  type: USER_GET_SUCCESS,
  payload
});
export const getUserFailure = errorMsg => ({ type: USER_GET_FAILURE, error: { message: errorMsg } });

export const getUser = userId => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(getUserRequest());

    try {
      const user = await agent.UserService.getUser(userId);
      user.birthDate = new Date(user.birthDate); // birth date comes as a string
      dispatch(getUserSuccess(user));
      return user;
    } catch (error) {
      const errorMsg = `${i18next.t('USER_RETRIEVAL_ERROR_PREFIX')} : ${error.message}`;
      dispatch(getUserFailure(errorMsg));
      dispatch(setToastErrorMessage(errorMsg, `Error when retrieving a User: ${JSON.stringify(error)}`));
    }
  };
};

export const putUserRequest = () => ({ type: USER_PUT_REQUEST });

export const putUserSuccess = (message, payload) => ({
  type: USER_PUT_SUCCESS,
  message,
  payload
});
export const putUserFailure = errorMsg => ({ type: USER_PUT_FAILURE, error: { message: errorMsg } });

export const saveUser = user => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(putUserRequest());

    try {
      const updatedUser = await agent.UserService.saveUser(user);
      updatedUser.birthDate = new Date(updatedUser.birthDate); // birth date comes as a string
      dispatch(putUserSuccess(i18next.t('USER_UPDATE_SUCCESS'), updatedUser));
      dispatch(updateCommonUser(updatedUser));
      dispatch(setToastSuccessMessage(i18next.t('USER_UPDATE_SUCCESS'), `User successfully saved: ${user._id}`));
      return updatedUser;
    } catch (error) {
      const errorMsg = `${i18next.t('USER_UPDATE_ERROR')} : ${error.message}`;
      dispatch(putUserFailure(errorMsg));
      dispatch(setToastErrorMessage(errorMsg, `Error when updating a User: ${JSON.stringify(error)}`));
    }
  };
};

export const putUserChangePasswordRequest = () => ({ type: USER_PUT_CHANGE_PASSWORD_REQUEST });

export const putUserChangePasswordSuccess = (message, payload) => ({
  type: USER_PUT_CHANGE_PASSWORD_SUCCESS,
  message,
  payload
});
export const putUserChangePasswordFailure = errorMsg => ({ type: USER_PUT_CHANGE_PASSWORD_FAILURE, error: { message: errorMsg } });

export const changeUserPassword = (userId, currentPassword, newPassword) => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(putUserChangePasswordRequest());

    try {
      await agent.UserService.changeUserPassword(userId, currentPassword, newPassword);
      dispatch(setToastSuccessMessage(i18next.t('USER_UPDATE_CREDENTIALS_SUCCESS'), `User password successfully saved: ${userId}`));
      dispatch(putUserChangePasswordSuccess(i18next.t('USER_UPDATE_CREDENTIALS_SUCCESS')));
      return null;
    } catch (error) {
      const errorMsg = `${i18next.t('USER_UPDATE_CREDENTIALS_ERROR_PREFIX')} : ${error.message}`;
      console.warn(`Error when updating User Credentials: ${JSON.stringify(error)}`);
      dispatch(putUserChangePasswordFailure(errorMsg));
      return errorMsg;
    }
  };
};

export const putUserProfileImageRequest = () => ({ type: USER_PUT_PROFILE_IMAGE_REQUEST });

export const putUserProfileImageSuccess = (message, payload) => ({
  type: USER_PUT_PROFILE_IMAGE_SUCCESS,
  message,
  payload
});
export const putUserProfileImageFailure = errorMsg => ({ type: USER_PUT_PROFILE_IMAGE_FAILURE, error: { message: errorMsg } });

export const saveUserProfileImage = (userId, imageUrl) => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(putUserProfileImageRequest());

    try {
      const updatedUser = await agent.UserService.saveUserProfileImage(userId, imageUrl);
      dispatch(putUserProfileImageSuccess(i18next.t('USER_PROFILE_IMAGE_UPDATE_SUCCESS'), updatedUser));
      dispatch(setToastSuccessMessage(i18next.t('USER_PROFILE_IMAGE_UPDATE_SUCCESS'), `User successfully saved: ${userId}`));
    } catch (error) {
      const errorMsg = `${i18next.t('USER_PROFILE_IMAGE_UPDATE_ERROR')} : ${error.message}`;
      dispatch(putUserProfileImageFailure(errorMsg));
      dispatch(setToastErrorMessage(errorMsg, `Error when updating a User Profile Image: ${JSON.stringify(error)}`));
    }
  };
};

export const getUserPaymentsRequest = () => ({ type: USER_PAYMENTS_GET_REQUEST });

export const getUserPaymentsSuccess = payload => ({
  type: USER_PAYMENTS_GET_SUCCESS,
  payload
});
export const getUserPaymentsFailure = errorMsg => ({ type: USER_PAYMENTS_GET_FAILURE, error: { message: errorMsg } });

export const getUserPayments = (userId, isExpertUser) => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(getUserPaymentsRequest());

    try {
      const fetchedUserPayments = await agent.UserService.getUserPaymentHistory(userId);

      (fetchedUserPayments || []).forEach(payment => {
        payment.date = new Date(payment.created_at);
        const user = isExpertUser ? payment.regularUser : payment.expertUser;
        payment.username = getUserFullName(user, user.email);
      });
      dispatch(getUserPaymentsSuccess(fetchedUserPayments));
      return fetchedUserPayments;
    } catch (error) {
      const errorMsg = i18next.t('USER_PAYMENT_HISTORY_RETRIEVAL_ERROR');
      dispatch(getUserPaymentsFailure(errorMsg));
      dispatch(setToastErrorMessage(errorMsg, `Error when retrieving expert payment history: ${JSON.stringify(error)}`));
      return null;
    }
  };
};
