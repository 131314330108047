import React from 'react';
import CONSTANTS from '../constants/constants';
import SelectCustomBox from './SelectCustomBox';

const SelectAccommodationBox = ({ accommodationType, hasDefault, onLocationChange }) => {
  return (
    <SelectCustomBox value={accommodationType} values={CONSTANTS.ACCOMMODATION_TYPES} hasDefault={hasDefault} onValueChange={onLocationChange} />
  );
};

export default SelectAccommodationBox;
