import React from 'react';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const AlertMessage = ({ message, messageType }) => {
  const { t } = useTranslation();

  if (!message) {
    return null;
  }
  return (
    <div className="row">
      <Alert variant={messageType} className="col-12">
        {t(message)}
      </Alert>
    </div>
  );
};

export default AlertMessage;
