import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TripEventListItem from './TripEventListItem';
import { Collapse } from 'react-bootstrap';

const TripDayListItem = ({ index, day }) => {
  const { t } = useTranslation();

  const [isCollapsed, setIsCollapsed] = useState(true);

  if (!day) {
    return null;
  }
  return (
    <div className="row day-item">
      <div className="trip-day-toolbar" onClick={() => setIsCollapsed(!isCollapsed)}>
        <h3>
          {t('TRIP_DAY_CANVAS_DAY')} {index + 1} <i className={`fa fa-angle-up rotate-icon ${isCollapsed ? '' : 'state-active'}`}></i>
        </h3>
      </div>
      <Collapse in={isCollapsed}>
        <div>
          {day.events.map((evnt, index) => (
            <TripEventListItem key={index} event={evnt}></TripEventListItem>
          ))}
          {day.events.length === 0 ? <div className="col-12">{t('TRIP_DAY_NO_EVENTS')}</div> : null}
        </div>
      </Collapse>
    </div>
  );
};

export default TripDayListItem;
