import {
  TRIPS_GET_REQUEST,
  TRIPS_GET_SUCCESS,
  TRIPS_GET_FAILURE,
  TRIP_RESUME_RESET,
  UPDATE_CURRENT_TRIP_RESUME_FIELD,
  TRIP_RESUME_DELETE_REQUEST,
  TRIP_RESUME_DELETE_SUCCESS,
  TRIP_RESUME_DELETE_FAILURE,
  TRIP_RESUME_GET_REQUEST,
  TRIP_RESUME_GET_SUCCESS,
  TRIP_RESUME_GET_FAILURE,
  TRIP_RESUME_PUT_REQUEST,
  TRIP_RESUME_PUT_SUCCESS,
  TRIP_RESUME_PUT_FAILURE
} from '../constants/actionTypes';

import agent from '../agent';
import i18next from 'i18next';
import NavigationService from '../services/navigation';
import { setToastSuccessMessage, setToastErrorMessage } from './common';
import { updateTripPlanPayload, resetTripPlanPayload } from './tripPlans';
import CONSTANTS from '../constants/constants';
import { getUserFullName } from '../utils/userUtils';

export const resetCurrentTripResume = () => ({ type: TRIP_RESUME_RESET });
export const updateCurrentTripResumeField = (name, value) => ({ type: UPDATE_CURRENT_TRIP_RESUME_FIELD, key: name, value });

export const getTripsRequest = () => ({ type: TRIPS_GET_REQUEST });

export const getTripsSuccess = payload => ({
  type: TRIPS_GET_SUCCESS,
  payload
});
export const getTripsFailure = error => ({ type: TRIPS_GET_FAILURE, error });

export const getTrips = (status) => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(getTripsRequest());

    try {
      const paginatedResponse = await agent.TripsService.getAllTripResumes(status);
      const trips = paginatedResponse.result.listings

      if (!trips) {
        throw new Error(i18next.t('TRIP_RESUME_LIST_RETRIVAL_ERROR'));
      }

      (trips || []).forEach(function (tripResume) {
        const { expertUser, regularUser } = tripResume;
        expertUser.name = getUserFullName(expertUser, expertUser.email);
        regularUser.name = getUserFullName(regularUser, regularUser.email);
      });

      dispatch(getTripsSuccess(trips));
    } catch (error) {
      console.log('Error when retrieving trips list: ' + JSON.stringify(error));
      dispatch(getTripsFailure(error));
    }
  };
};

export const deleteTripResumeRequest = () => ({ type: TRIP_RESUME_DELETE_REQUEST });

export const deleteTripResumeSuccess = (message, tripResumeId) => ({
  type: TRIP_RESUME_DELETE_SUCCESS,
  message,
  tripResumeId
});

export const deleteTripResumeFailure = errorMsg => ({ type: TRIP_RESUME_DELETE_FAILURE, error: { message: errorMsg } });

// admin action
export const deleteTripResume = tripResumeId => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(deleteTripResumeRequest());

    try {
      await agent.TripsService.deleteTripResume(tripResumeId);
      const deletionSuccessMessage = 'Trip Resume Deleted';
      dispatch(deleteTripResumeSuccess(deletionSuccessMessage, tripResumeId));
      dispatch(setToastSuccessMessage(deletionSuccessMessage, `Trip Resume successfully deleted: ${tripResumeId}`));
    } catch (error) {
      const errorMsg = `Error when deleting Trip Resume : ${error.message}`;
      dispatch(deleteTripResumeFailure(errorMsg));
      dispatch(setToastErrorMessage(errorMsg, `Error when deleting a Trip Resume: ${JSON.stringify(error)}`));
    }
  };
};

export const getTripResumeRequest = () => ({ type: TRIP_RESUME_GET_REQUEST });

export const getTripResumeSuccess = payload => ({
  type: TRIP_RESUME_GET_SUCCESS,
  payload
});
export const getTripResumeFailure = errorMsg => ({ type: TRIP_RESUME_GET_FAILURE, error: { message: errorMsg } });

export const getTripResume = tripResumeId => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(getTripResumeRequest());

    try {
      const tripResume = await agent.TripsService.getTripResume(tripResumeId);
      const { tripPlan } = tripResume;
      dispatch(getTripResumeSuccess(tripResume));
      dispatch(updateTripPlanPayload(tripPlan));
      return tripResume;
    } catch (error) {
      const errorMsg = `${i18next.t('TRIP_RESUME_RETRIEVAL_ERROR_PREFIX')} : ${error.message}`;
      dispatch(getTripResumeFailure(errorMsg));
      dispatch(resetTripPlanPayload());
      dispatch(setToastErrorMessage(errorMsg, `Error when retrieving a Trip Resume: ${JSON.stringify(error)}`));
      return null;
    }
  };
};

export const putTripResumeRequest = () => ({ type: TRIP_RESUME_PUT_REQUEST });

export const putTripResumeSuccess = (message, payload) => ({
  type: TRIP_RESUME_PUT_SUCCESS,
  message,
  payload
});
export const putTripResumeFailure = errorMsg => ({ type: TRIP_RESUME_PUT_FAILURE, error: { message: errorMsg } });

export const makeTripPayment = (tripResumeId, value, currencyCode) => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(putTripResumeRequest());

    try {
      const paymentData = {
        value,
        currencyCode
      };
      await agent.TripsService.makeTripPayment(tripResumeId, paymentData);
      const tripResume = getState().trips.currentTripResume;
      const updatedTripResume = { ...tripResume };
      updatedTripResume.status = CONSTANTS.TRIP_STATUS.TRIP_PROCESSING;
      dispatch(putTripResumeSuccess(i18next.t('TRIP_RESUME_PAYMENT_SUCCESS'), updatedTripResume));
      dispatch(setToastSuccessMessage(i18next.t('TRIP_RESUME_PAYMENT_SUCCESS'), `Successfully paid for trip: ${tripResumeId}`));
      NavigationService.goToTripList();
    } catch (error) {
      const errorMsg = i18next.t('TRIP_RESUME_PAYMENT_ERROR');
      dispatch(putTripResumeFailure(errorMsg));
      dispatch(setToastErrorMessage(errorMsg, `Error when changing trip status: ${JSON.stringify(error)}`));
    }
  };
};

export const changeTripStatus = (tripResumeId, toStatus) => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(putTripResumeRequest());

    try {
      const result = await agent.TripsService.changeTripStatus(tripResumeId, toStatus);
      const { message, tripStatus } = result;
      const tripResume = getState().trips.currentTripResume;
      const updatedTripResume = { ...tripResume };
      updatedTripResume.status = tripStatus;
      dispatch(putTripResumeSuccess(i18next.t('TRIP_RESUME_UPDATE_SUCCESS'), updatedTripResume));

      const tripResumes = getState().trips.tripResumes;
      const index = tripResumes.findIndex(tripResume => tripResume._id === tripResumeId);
      if (index !== -1) {
        const newTripResumes = [...tripResumes];
        newTripResumes[index].status = tripStatus;
        dispatch(getTripsSuccess(newTripResumes));
      }

      dispatch(setToastSuccessMessage(i18next.t('TRIP_RESUME_UPDATE_SUCCESS'), message));
    } catch (error) {
      const errorMsg = `${i18next.t('TRIP_RESUME_UPDATE_ERROR_PREFIX')} : ${error.message}`;
      dispatch(putTripResumeFailure(errorMsg));
      dispatch(setToastErrorMessage(errorMsg, 'Error when changing trip status: ' + JSON.stringify(error)));
    }
  };
};

export const changeTripStatusState = (tripResumeId, status, toStatusState) => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(putTripResumeRequest());

    try {
      const result = await agent.TripsService.changeTripStatusState(tripResumeId, status, toStatusState);
      const { message, tripProcessingStatusState, regularUserRoles } = result;
      console.log(message);

      const tripResume = getState().trips.currentTripResume;
      const updatedTripResume = { ...tripResume };
      updatedTripResume.tripProcessingStatusState = tripProcessingStatusState;
      updatedTripResume.regularUserRoles = regularUserRoles;
      dispatch(putTripResumeSuccess(i18next.t('TRIP_RESUME_UPDATE_SUCCESS'), updatedTripResume));

      const tripResumes = getState().trips.tripResumes;
      const index = tripResumes.findIndex(tripResume => tripResume._id === tripResumeId);
      if (index !== -1) {
        const newTripResumes = [...tripResumes];
        newTripResumes[index].tripProcessingStatusState = tripProcessingStatusState;
        newTripResumes[index].regularUserRoles = regularUserRoles;
        dispatch(getTripsSuccess(newTripResumes));
      }

      return true;
    } catch (error) {
      const errorMsg = 'Could not change trip status state. Contact administrator';
      dispatch(putTripResumeFailure(errorMsg));
      dispatch(setToastErrorMessage(errorMsg, 'Error when changing trip status state: ' + JSON.stringify(error)));
      return false;
    }
  };
};

export const changeTravelerReadPermission = (tripResumeId, shareTripPlanToggle) => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(putTripResumeRequest());

    try {
      const serverUpdatedTripResume = await agent.TripsService.changeTravelerReadPermission(tripResumeId, shareTripPlanToggle);

      const tripResume = getState().trips.currentTripResume;
      const updatedTripResume = { ...tripResume };
      updatedTripResume.regularUserRoles = serverUpdatedTripResume.regularUserRoles;
      dispatch(putTripResumeSuccess(i18next.t('TRIP_RESUME_UPDATE_SUCCESS'), updatedTripResume));

      const tripResumes = getState().trips.tripResumes;
      const index = tripResumes.findIndex(tripResume => tripResume._id === tripResumeId);
      if (index !== -1) {
        const newTripResumes = [...tripResumes];
        newTripResumes[index].regularUserRoles = updatedTripResume.regularUserRoles;
        dispatch(getTripsSuccess(newTripResumes));
      }

      return true; // on change success
    } catch (error) {
      const errorMsg = i18next.t(`TRIP_PLAN_SHARING_ERROR`);
      dispatch(putTripResumeFailure(errorMsg));
      dispatch(setToastErrorMessage(errorMsg, 'Error when changing traveler read permission: ' + JSON.stringify(error)));
      return false; // on change failure
    }
  };
};

export const reviewExpertUser = (tripResumeId, rating) => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    try {
      await agent.TripsService.reviewExpertUser(tripResumeId, rating);
      dispatch(setToastSuccessMessage(i18next.t('MODAL_EXPERT_REVIEW_SUCCESS')));
      return null; // on change success
    } catch (error) {
      const errorMsg = i18next.t('MODAL_EXPERT_REVIEW_ERROR_PREFIX');
      console.log('Error when reviewing expert user: ' + JSON.stringify(error));
      return errorMsg; // on change failure
    }
  };
};
