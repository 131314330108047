import React from 'react';
import { useTranslation } from 'react-i18next';
import TooltipIcon from './TooltipIcon';

const IconTripTypeItem = ({ iconKey, keyToMatch, iconClass, textKey, icon, tooltip, text }) => {
  const { t } = useTranslation();

  if (iconKey !== keyToMatch) {
    return null;
  }

  return (
    <div className="expert-icon-container">
      <TooltipIcon iconClass={`${iconClass} expert-specialist-icon`} textKey={textKey} icon={icon} tooltip={tooltip} />
      {text ? t(textKey) : ''}
    </div>
  );
};

const IconTripTypeSwitcher = ({ iconKey, icon, tooltip, text }) => {
  return (
    <>
      <IconTripTypeItem
        iconKey={iconKey}
        keyToMatch="SOLO"
        iconClass="icon ive-solo"
        textKey="TRIP_TYPE_SOLO"
        icon={icon}
        tooltip={tooltip}
        text={text}
      />

      <IconTripTypeItem
        iconKey={iconKey}
        keyToMatch="FAMILY"
        iconClass="icon ive-family"
        textKey="TRIP_TYPE_FAMILY"
        icon={icon}
        tooltip={tooltip}
        text={text}
      />

      <IconTripTypeItem
        iconKey={iconKey}
        keyToMatch="COUPLE"
        iconClass="icon ive-couple"
        textKey="TRIP_TYPE_COUPLE"
        icon={icon}
        tooltip={tooltip}
        text={text}
      />

      <IconTripTypeItem
        iconKey={iconKey}
        keyToMatch="FRIENDS"
        iconClass="icon ive-friends"
        textKey="TRIP_TYPE_FRIENDS"
        icon={icon}
        tooltip={tooltip}
        text={text}
      />

      <IconTripTypeItem
        iconKey={iconKey}
        keyToMatch="BUSINNESS"
        iconClass="fa fa-suitcase"
        textKey="TRIP_TYPE_BUSINESS"
        icon={icon}
        tooltip={tooltip}
        text={text}
      />
    </>
  );
};

export default IconTripTypeSwitcher;
