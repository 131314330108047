import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TripStatusInstruction from '../components/TripStatusInstruction';
import TripCanvasBoard from './TripCanvasBoard';
import CONSTANTS from '../constants/constants';

const TripResumeViewItinerary = ({ currentUser, trip, inProgress }) => {
  const { t } = useTranslation();
  const isExpertUser = currentUser ? currentUser.isExpert : null;

  useEffect(() => {
  }, []);

  const renderTripPlan = (isExpertUser, trip) => {
    if (!trip) {
      return;
    }

    return <TripCanvasBoard trip={trip} view={true} isExpertUser={isExpertUser} isTripTemplate={false} />;
  };

  const getTripPlanAccessMessage = (isExpertUser, tripStatus, tripProcessingStatusState) => {
    let message = '';

    if (isExpertUser && tripStatus === CONSTANTS.TRIP_STATUS.TRIP_REQUEST) {
      message = t('TRIP_PLAN_VIEW_MESSAGE_REQUEST_EXPERT');
    } else if (!isExpertUser && tripStatus === CONSTANTS.TRIP_STATUS.TRIP_REQUEST) {
      message = t('TRIP_PLAN_VIEW_MESSAGE_REQUEST_TRAVELER');
    } else if (isExpertUser && tripStatus === CONSTANTS.TRIP_STATUS.WAITING_PAYMENT) {
      message = t('TRIP_PLAN_VIEW_MESSAGE_PAYMENT_EXPERT');
    } else if (!isExpertUser && tripStatus === CONSTANTS.TRIP_STATUS.WAITING_PAYMENT) {
      message = t('TRIP_PLAN_VIEW_MESSAGE_PAYMENT_TRAVELER');
    } else if (
      !isExpertUser &&
      tripStatus === CONSTANTS.TRIP_STATUS.TRIP_PROCESSING &&
      tripProcessingStatusState === CONSTANTS.TRIP_STATUS_STATES.TRIP_PROCESSING_WORK_IN_PROGRESS
    ) {
      message = t('TRIP_PLAN_VIEW_MESSAGE_WIP');
    } else if (
      tripStatus === CONSTANTS.TRIP_STATUS.TRIP_REQUEST_CANCELLED ||
      tripStatus === CONSTANTS.TRIP_STATUS.TRIP_REQUEST_REJECTED ||
      tripStatus === CONSTANTS.TRIP_STATUS.PAYMENT_CANCELLED
    ) {
      message = t('TRIP_PLAN_VIEW_MESSAGE_ACTION');
    }
    return message;
  };

  const renderTripCanvasContent = (isExpertUser, trip) => {
    const { status: tripStatus, tripProcessingStatusState } = trip;

    const accessMessage = getTripPlanAccessMessage(isExpertUser, tripStatus, tripProcessingStatusState);

    if (!accessMessage) {
      return renderTripPlan(isExpertUser, trip);
    }
    return <TripStatusInstruction message={accessMessage} />;
  };

  if (!trip) {
    return null;
  }

  return (
    <div className="container padding-10 trip-plan-view-container">
      <div className="board-wrapper">
        <div className="board-main-content">
          <div className="trip-canvas-wrapper">
            {inProgress ? (
              <div className="load-overlay show">
                <div className="text">
                  <i className="fa fa-spinner fa-spin spin-size"></i>
                </div>
              </div>
            ) : (
              <div className="trip-canvas view width-100">{renderTripCanvasContent(isExpertUser, trip)}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  trip: state.trips.currentTripResume,
  inProgress: state.trips.inProgress,
  currentUser: state.common.currentUser
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(TripResumeViewItinerary);
