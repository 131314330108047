export const APP_LOAD = 'APP_LOAD';
export const REDIRECT = 'REDIRECT';
export const SET_ENVIRONMENT = 'SET_ENVIRONMENT';
export const HOME_PAGE_LOADED = 'HOME_PAGE_LOADED';
export const HOME_PAGE_UNLOADED = 'HOME_PAGE_UNLOADED';
export const SET_PAGE = 'SET_PAGE';
export const HIDE_TOAST = 'HIDE_TOAST';
export const SHOW_TOAST = 'SHOW_TOAST';
export const COMMON_USER_UPDATE = 'COMMON_USER_UPDATE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const REGISTER_EXPERT_REQUEST = 'REGISTER_EXPERT_REQUEST';
export const REGISTER_EXPERT_FAILURE = 'REGISTER_EXPERT_FAILURE';
export const LOGIN_PAGE_LOADED = 'LOGIN_PAGE_LOADED';
export const LOGIN_PAGE_UNLOADED = 'LOGIN_PAGE_UNLOADED';
export const REGISTER_PAGE_UNLOADED = 'REGISTER_PAGE_UNLOADED';
export const UPDATE_FIELD_AUTH = 'UPDATE_FIELD_AUTH';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const RESET_PASSWORD_PAGE_UNLOADED = 'RESET_PASSWORD_PAGE_UNLOADED';
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
export const FORGOT_PASSWORD_PAGE_UNLOADED = 'FORGOT_PASSWORD_PAGE_UNLOADED';
export const SHOW_LOGIN_DIALOG = 'SHOW_LOGIN_DIALOG';
export const HIDE_LOGIN_DIALOG = 'HIDE_LOGIN_DIALOG';

export const SUBMIT_FEEDBACK_REQUEST = 'SUBMIT_FEEDBACK_REQUEST';
export const SUBMIT_FEEDBACK_FAILURE = 'SUBMIT_FEEDBACK_FAILURE';
export const SUBMIT_FEEDBACK_SUCCESS = 'SUBMIT_FEEDBACK_SUCCESS';
export const EXPERTS_GET_REQUEST = 'EXPERTS_GET_REQUEST';
export const EXPERTS_GET_SUCCESS = 'EXPERTS_GET_SUCCESS';
export const EXPERTS_GET_FAILURE = 'EXPERTS_GET_FAILURE';
export const EXPERT_PROFILE_GET_REQUEST = 'EXPERT_PROFILE_GET_REQUEST';
export const EXPERT_PROFILE_GET_SUCCESS = 'EXPERT_PROFILE_GET_SUCCESS';
export const EXPERT_PROFILE_GET_FAILURE = 'EXPERT_PROFILE_GET_FAILURE';
export const EXPERTS_ADD_USER = 'EXPERTS_ADD_USER';
export const EXPERTS_UPDATE_LIST = 'EXPERTS_UPDATE_LIST';

export const UPDATE_TRIP_REQUEST_FIELD = 'UPDATE_TRIP_REQUEST_FIELD';
export const RESET_TRIP_REQUEST = 'RESET_TRIP_REQUEST';
export const TRIP_REQUEST_POST_REQUEST = 'TRIP_REQUEST_POST_REQUEST';
export const TRIP_REQUEST_POST_SUCCESS = 'TRIP_REQUEST_POST_SUCCESS';
export const TRIP_REQUEST_POST_FAILURE = 'TRIP_REQUEST_POST_FAILURE';
export const TRIPS_REQUESTS_GET_REQUEST = 'TRIPS_REQUESTS_GET_REQUEST';
export const TRIPS_REQUESTS_GET_SUCCESS = 'TRIPS_REQUESTS_GET_SUCCESS';
export const TRIPS_REQUESTS_GET_FAILURE = 'TRIPS_REQUESTS_GET_FAILURE';

export const TRIP_RESUME_RESET = 'TRIP_RESUME_RESET';
export const UPDATE_CURRENT_TRIP_RESUME_FIELD = 'UPDATE_CURRENT_TRIP_RESUME_FIELD';
export const TRIPS_GET_REQUEST = 'TRIPS_GET_REQUEST';
export const TRIPS_GET_SUCCESS = 'TRIPS_GET_SUCCESS';
export const TRIPS_GET_FAILURE = 'TRIPS_GET_FAILURE';
export const TRIP_RESUME_DELETE_REQUEST = 'TRIP_RESUME_DELETE_REQUEST';
export const TRIP_RESUME_DELETE_SUCCESS = 'TRIP_RESUME_DELETE_SUCCESS';
export const TRIP_RESUME_DELETE_FAILURE = 'TRIP_RESUME_DELETE_FAILURE';
export const TRIP_RESUME_GET_REQUEST = 'TRIP_RESUME_GET_REQUEST';
export const TRIP_RESUME_GET_SUCCESS = 'TRIP_RESUME_GET_SUCCESS';
export const TRIP_RESUME_GET_FAILURE = 'TRIP_RESUME_GET_FAILURE';
export const TRIP_RESUME_PUT_REQUEST = 'TRIP_RESUME_PUT_REQUEST';
export const TRIP_RESUME_PUT_SUCCESS = 'TRIP_RESUME_PUT_SUCCESS';
export const TRIP_RESUME_PUT_FAILURE = 'TRIP_RESUME_PUT_FAILURE';

export const TRIP_TEMPLATE_RESET = 'TRIP_TEMPLATE_RESET';
export const UPDATE_CURRENT_TRIP_TEMPLATE_FIELD = 'UPDATE_CURRENT_TRIP_TEMPLATE_FIELD';
export const TRIP_TEMPLATES_GET_REQUEST = 'TRIP_TEMPLATES_GET_REQUEST';
export const TRIP_TEMPLATES_GET_SUCCESS = 'TRIP_TEMPLATES_GET_SUCCESS';
export const TRIP_TEMPLATES_GET_FAILURE = 'TRIP_TEMPLATES_GET_FAILURE';
export const TRIP_TEMPLATE_POST_REQUEST = 'TRIP_TEMPLATE_POST_REQUEST';
export const TRIP_TEMPLATE_POST_SUCCESS = 'TRIP_TEMPLATE_POST_SUCCESS';
export const TRIP_TEMPLATE_POST_FAILURE = 'TRIP_TEMPLATE_POST_FAILURE';
export const TRIP_TEMPLATE_DELETE_REQUEST = 'TRIP_TEMPLATE_DELETE_REQUEST';
export const TRIP_TEMPLATE_DELETE_SUCCESS = 'TRIP_TEMPLATE_DELETE_SUCCESS';
export const TRIP_TEMPLATE_DELETE_FAILURE = 'TRIP_TEMPLATE_DELETE_FAILURE';
export const TRIP_TEMPLATE_GET_REQUEST = 'TRIP_TEMPLATE_GET_REQUEST';
export const TRIP_TEMPLATE_GET_SUCCESS = 'TRIP_TEMPLATE_GET_SUCCESS';
export const TRIP_TEMPLATE_GET_FAILURE = 'TRIP_TEMPLATE_GET_FAILURE';
export const TRIP_TEMPLATE_PUT_REQUEST = 'TRIP_TEMPLATE_PUT_REQUEST';
export const TRIP_TEMPLATE_PUT_SUCCESS = 'TRIP_TEMPLATE_PUT_SUCCESS';
export const TRIP_TEMPLATE_PUT_FAILURE = 'TRIP_TEMPLATE_PUT_FAILURE';

export const USER_RESET = 'USER_RESET';
export const UPDATE_CURRENT_USER_FIELD = 'UPDATE_CURRENT_USER_FIELD';
export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAILURE = 'USER_DELETE_FAILURE';
export const USER_GET_REQUEST = 'USER_GET_REQUEST';
export const USER_GET_SUCCESS = 'USER_GET_SUCCESS';
export const USER_GET_FAILURE = 'USER_GET_FAILURE';
export const USER_PAYMENTS_GET_REQUEST = 'USER_PAYMENTS_GET_REQUEST';
export const USER_PAYMENTS_GET_SUCCESS = 'USER_PAYMENTS_GET_SUCCESS';
export const USER_PAYMENTS_GET_FAILURE = 'USER_PAYMENTS_GET_FAILURE';
export const USER_PUT_REQUEST = 'USER_PUT_REQUEST';
export const USER_PUT_SUCCESS = 'USER_PUT_SUCCESS';
export const USER_PUT_FAILURE = 'USER_PUT_FAILURE';
export const USER_PUT_CHANGE_PASSWORD_REQUEST = 'USER_PUT_CHANGE_PASSWORD_REQUEST';
export const USER_PUT_CHANGE_PASSWORD_SUCCESS = 'USER_PUT_CHANGE_PASSWORD_SUCCESS';
export const USER_PUT_CHANGE_PASSWORD_FAILURE = 'USER_PUT_CHANGE_PASSWORD_FAILURE';
export const USER_PUT_PROFILE_IMAGE_REQUEST = 'USER_PUT_PROFILE_IMAGE_REQUEST';
export const USER_PUT_PROFILE_IMAGE_SUCCESS = 'USER_PUT_PROFILE_IMAGE_SUCCESS';
export const USER_PUT_PROFILE_IMAGE_FAILURE = 'USER_PUT_PROFILE_IMAGE_FAILURE';

export const GET_CONVERSATIONS_REQUEST = 'GET_CONVERSATIONS_REQUEST';
export const GET_CONVERSATIONS_FAILURE = 'GET_CONVERSATIONS_FAILURE';
export const GET_CONVERSATIONS_SUCCESS = 'GET_CONVERSATIONS_SUCCESS';
export const GET_CONVERSATION_REQUEST = 'GET_CONVERSATION_REQUEST';
export const GET_CONVERSATION_FAILURE = 'GET_CONVERSATION_FAILURE';
export const GET_CONVERSATION_SUCCESS = 'GET_CONVERSATION_SUCCESS';
export const POST_MESSAGE_REQUEST = 'POST_MESSAGE_REQUEST';
export const POST_MESSAGE_FAILURE = 'POST_MESSAGE_FAILURE';
export const POST_MESSAGE_SUCCESS = 'POST_MESSAGE_SUCCESS';

export const LOCATION_RESET = 'LOCATION_RESET';
export const UPDATE_CURRENT_LOCATION_FIELD = 'UPDATE_CURRENT_LOCATION_FIELD';
export const LOCATIONS_GET_REQUEST = 'LOCATIONS_GET_REQUEST';
export const LOCATIONS_GET_SUCCESS = 'LOCATIONS_GET_SUCCESS';
export const LOCATIONS_GET_FAILURE = 'LOCATIONS_GET_FAILURE';
export const LOCATION_POST_REQUEST = 'LOCATION_POST_REQUEST';
export const LOCATION_POST_SUCCESS = 'LOCATION_POST_SUCCESS';
export const LOCATION_POST_FAILURE = 'LOCATION_POST_FAILURE';
export const LOCATION_DELETE_REQUEST = 'LOCATION_DELETE_REQUEST';
export const LOCATION_DELETE_SUCCESS = 'LOCATION_DELETE_SUCCESS';
export const LOCATION_DELETE_FAILURE = 'LOCATION_DELETE_FAILURE';
export const LOCATION_GET_REQUEST = 'LOCATION_GET_REQUEST';
export const LOCATION_GET_SUCCESS = 'LOCATION_GET_SUCCESS';
export const LOCATION_GET_FAILURE = 'LOCATION_GET_FAILURE';
export const LOCATION_PUT_REQUEST = 'LOCATION_PUT_REQUEST';
export const LOCATION_PUT_SUCCESS = 'LOCATION_PUT_SUCCESS';
export const LOCATION_PUT_FAILURE = 'LOCATION_PUT_FAILURE';

export const DESTINATION_RESET = 'DESTINATION_RESET';
export const UPDATE_CURRENT_DESTINATION_FIELD = 'UPDATE_CURRENT_DESTINATION_FIELD';
export const DESTINATIONS_GET_REQUEST = 'DESTINATIONS_GET_REQUEST';
export const DESTINATIONS_GET_SUCCESS = 'DESTINATIONS_GET_SUCCESS';
export const DESTINATIONS_GET_FAILURE = 'DESTINATIONS_GET_FAILURE';
export const DESTINATION_POST_REQUEST = 'DESTINATION_POST_REQUEST';
export const DESTINATION_POST_SUCCESS = 'DESTINATION_POST_SUCCESS';
export const DESTINATION_POST_FAILURE = 'DESTINATION_POST_FAILURE';
export const DESTINATION_DELETE_REQUEST = 'DESTINATION_DELETE_REQUEST';
export const DESTINATION_DELETE_SUCCESS = 'DESTINATION_DELETE_SUCCESS';
export const DESTINATION_DELETE_FAILURE = 'DESTINATION_DELETE_FAILURE';
export const DESTINATION_GET_REQUEST = 'DESTINATION_GET_REQUEST';
export const DESTINATION_GET_SUCCESS = 'DESTINATION_GET_SUCCESS';
export const DESTINATION_GET_FAILURE = 'DESTINATION_GET_FAILURE';
export const DESTINATION_PUT_REQUEST = 'DESTINATION_PUT_REQUEST';
export const DESTINATION_PUT_SUCCESS = 'DESTINATION_PUT_SUCCESS';
export const DESTINATION_PUT_FAILURE = 'DESTINATION_PUT_FAILURE';

export const TRIP_PLAN_RESET = 'TRIP_PLAN_RESET';
export const UPDATE_TRIP_PLAN_REQUEST = 'UPDATE_TRIP_PLAN_REQUEST';
export const UPDATE_TRIP_PLAN_SUCCESS = 'UPDATE_TRIP_PLAN_SUCCESS';
export const UPDATE_TRIP_PLAN_DAY_SUCCESS = 'UPDATE_TRIP_PLAN_DAY_SUCCESS';
export const UPDATE_TRIP_PLAN_FAILURE = 'UPDATE_TRIP_PLAN_FAILURE';
export const UPDATE_TRIP_PLAN_CURRENT_DAY = 'UPDATE_TRIP_PLAN_CURRENT_DAY';
export const UPDATE_TRIP_PLAN_CURRENT_EVENT = 'UPDATE_TRIP_PLAN_CURRENT_EVENT';
