import React from 'react';

const ExpertsPage = () => {
  return (
    <div className="container padding-10">
      <h1>Experts</h1>
    </div>
  );
};

export default ExpertsPage;
