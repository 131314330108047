import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CONSTANTS from '../constants/constants';
import { deleteEvent, updateSingleEventInTripDayPlan } from '../actions/tripPlans';
import { setToastWarningMessage, setToastErrorMessage } from '../actions/common';
import TripEventInfo from '../components/TripEventInfo';
import TripEventEditionModal from '../components/TripEventEditionModal';
import { checkEventPayload } from '../utils/tripUtils';
import { deepCopy } from '../utils/common';
import { getTripPlanDayWithIndex, getTripPlanEvent } from '../selectors/tripPlans';

const TripDayPlannerEventSideNav = ({
  currentEvent,
  tripId,
  dayId,
  dayEvents,
  isExpertUser,
  isTripTemplate,
  callbackEventSave,
  callbackEventDeletion,
  editEventOnParent,
  deleteEvent,
  updateSingleEventInTripDayPlan,
  setToastWarningMessage,
  setToastErrorMessage
}) => {
  const { t } = useTranslation();

  const [showTripEventModal, setShowTripEventModal] = useState(false);
  const [modalEditionEvent, setModalEditionEvent] = useState(currentEvent);

  const onCloseTripEventModal = ev => {
    setShowTripEventModal(false);
  };

  const onSetModalEditionEvent = event => {
    setModalEditionEvent(event);
  };

  const onUpdateEventInTripDay = ev => {
    if (currentEvent.kind === CONSTANTS.TRIP_EVENT_KINDS.DIRECTIONS) {
      if (dayId === undefined || tripId === undefined) {
        setToastWarningMessage(t('TRIP_PLAN_DAY_ADD_NEW_EVENT_MISSING_TRIPID_DAY_NUMBER_ERROR'));
      }
      setModalEditionEvent(currentEvent);
      setShowTripEventModal(true);
      return;
    }
    editEventOnParent(currentEvent);
  };

  const updateEventInTripDay = event => {
    const updatedEvent = deepCopy(event); // deep copy of current event
    updateSingleEventInTripDayPlan(tripId, dayId, updatedEvent, isTripTemplate);
    callbackEventSave();
  };

  const onSaveEventFromModal = () => {
    // validates payload before saving
    const errorMessageKey = checkEventPayload(modalEditionEvent);
    if (errorMessageKey) {
      setToastErrorMessage(t(errorMessageKey));
      return;
    }
    setShowTripEventModal(false);
    updateEventInTripDay(modalEditionEvent);
  };

  const deleteTripDayPlanEvent = async event => {
    const { _id: eventId } = event;
    const eventIndex = dayEvents.findIndex(evento => evento._id === eventId);

    if (event.kind === CONSTANTS.TRIP_EVENT_KINDS.DIRECTIONS && eventIndex !== 0 && eventIndex !== dayEvents.length - 1) {
      const previousEvent = dayEvents[eventIndex - 1];
      const nextEvent = dayEvents[eventIndex + 1];

      // if previous and next Directions events already satisfy the condition, no need to delete it
      if (
        previousEvent.latitude === event.origin.latitude &&
        previousEvent.longitude === event.origin.longitude &&
        nextEvent.longitude === event.destination.longitude &&
        nextEvent.longitude === event.destination.longitude
      ) {
        setToastWarningMessage(t('TRIP_EVENT_CANNOT_BE_DELETED_WARNING'));
        return;
      }
    }

    const isEventDeleted = await deleteEvent(tripId, dayId, eventId, isTripTemplate);
    if (isEventDeleted) {
      callbackEventDeletion();
    }
  };

  return (
    <>
      {currentEvent ? <TripEventInfo event={currentEvent} /> : null}
      {isExpertUser ? (
        <div className="row padding-10">
          <button type="button" className="btn btn-primary mr-2" onClick={onUpdateEventInTripDay}>
            {t('BUTTON_EDIT')}
          </button>
          <button type="button" className="btn btn-primary" onClick={ev => deleteTripDayPlanEvent(currentEvent)}>
            {t('BUTTON_DELETE')}
          </button>
          <TripEventEditionModal
            event={modalEditionEvent}
            showModal={showTripEventModal}
            onCloseModal={onCloseTripEventModal}
            setEditionEvent={onSetModalEditionEvent}
            onSaveEvent={onSaveEventFromModal}
          />
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  tripPlan: state.tripPlans.currentTripPlan,
  day: getTripPlanDayWithIndex(state.tripPlans.currentTripPlan, state.tripPlans.currentTripPlanDayId),
  currentEvent: getTripPlanEvent(state.tripPlans.currentTripPlan, state.tripPlans.currentTripPlanDayId, state.tripPlans.currentTripPlanEventId)
});

const mapDispatchToProps = dispatch => ({
  deleteEvent: (tripId, dayId, eventId, isTemplate) => dispatch(deleteEvent(tripId, dayId, eventId, isTemplate)),
  updateSingleEventInTripDayPlan: (tripId, dayId, queryEvent, isTemplate) =>
    dispatch(updateSingleEventInTripDayPlan(tripId, dayId, queryEvent, isTemplate)),
  setToastWarningMessage: message => dispatch(setToastWarningMessage(message)),
  setToastErrorMessage: errorMsg => dispatch(setToastErrorMessage(errorMsg))
});

export default connect(mapStateToProps, mapDispatchToProps)(TripDayPlannerEventSideNav);
