import React from 'react';
import { CustomTooltip } from './Custom';

const ToggleButton = ({ sharedTripPlanToggle, labelActive, labelHidden, tooltipText, onToggleChange }) => {
  return (
    <div className="toggle-switch">
      <label>
        {sharedTripPlanToggle ? labelActive : labelHidden}
        <CustomTooltip text={tooltipText}>
          <i className="fa fa-question-circle"></i>
        </CustomTooltip>
      </label>
      <label className="switch">
        <input type="checkbox" checked={sharedTripPlanToggle} onChange={onToggleChange} />
        <span className="slider round"></span>
      </label>
    </div>
  );
};

export default ToggleButton;
