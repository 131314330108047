import {
  LOGIN_REQUEST,
  REGISTER_REQUEST,
  RESET_PASSWORD_REQUEST,
  FORGOT_PASSWORD_REQUEST,
  LOGIN_PAGE_UNLOADED,
  REGISTER_PAGE_UNLOADED,
  RESET_PASSWORD_PAGE_UNLOADED,
  FORGOT_PASSWORD_PAGE_UNLOADED,
  UPDATE_FIELD_AUTH,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE
} from '../constants/actionTypes';

const defaultState = {
  email: '',
  password: '',
  confirmPassword: '',
  inProgress: false,
  message: '',
  error: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
    case REGISTER_REQUEST:
    case RESET_PASSWORD_REQUEST:
    case FORGOT_PASSWORD_REQUEST:
      return { ...state, inProgress: true };
    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        inProgress: false,
        password: '', // removes password for security reasons
        confirmPassword: '', // removes password for security reasons
        message: action.payload ? action.payload.message : null,
        error: action.error ? action.error : null
      };
    case LOGIN_FAILURE:
    case REGISTER_FAILURE:
    case RESET_PASSWORD_FAILURE:
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        inProgress: false,
        error: action.error ? action.error : null
      };
    case LOGIN_PAGE_UNLOADED:
    case REGISTER_PAGE_UNLOADED:
    case RESET_PASSWORD_PAGE_UNLOADED:
    case FORGOT_PASSWORD_PAGE_UNLOADED:
      return { ...defaultState };
    case UPDATE_FIELD_AUTH:
      return { ...state, [action.key]: action.value };
    default:
      return state;
  }
};
