import React from 'react';
import HeaderAvatar from '../components/HeaderAvatar';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutRequest } from '../actions/auth';
import { useTranslation } from 'react-i18next';
import agent from '../agent';
import CONSTANTS from '../constants/constants';

const mapStateToProps = state => ({
  currentUser: state.common.currentUser
});

const mapDispatchToProps = dispatch => ({
  onClickLogout: () => dispatch(logoutRequest())
});

const MenuLinksView = ({ currentUser, onClickLogout }) => {
  const { i18n } = useTranslation();

  const changeLanguage = langKey => {
    if (typeof langKey === 'string') {
      langKey = langKey.toLowerCase();
    } else {
      console.log('Language code was not defined. Translation will not be executed.');
      return;
    }

    i18n.changeLanguage(langKey);
    agent.setLanguage(langKey);
  };

  return (
    <ul>

      <LoggedInOrNotView currentUser={currentUser} onClickLogout={onClickLogout} />

      <li className="nav-item">
        <div className="header-links-spacing login-link">
          <a href="#pt" title="Português-BR" onClick={() => changeLanguage(CONSTANTS.PORTUGUESE_LANGUAGE)} className="language-link">
            <strong>PT</strong>
          </a>
          <span> | </span>
          <a href="#en" title="English" onClick={() => changeLanguage(CONSTANTS.ENGLISH_LANGUAGE)} className="language-link">
            <strong>EN</strong>
          </a>
        </div>
      </li>
    </ul>
  );
};



const LoggedInOrNotView = ({ currentUser, onClickLogout }) => {
  const { t } = useTranslation();

  if (currentUser) {
    return (
      <li className="nav-item">
        <HeaderAvatar currentUser={currentUser} onClickLogout={onClickLogout} />
      </li>
    );
  }

  return (
    <li className="nav-item">
      <div className="header-links-spacing login-link">
        <Link to="/login">{t('HEADER_MENU_LOGIN')}</Link>
      </div>
    </li>
  );
};

const HeaderMenu = ({ currentUser, onClickLogout }) => {
  return <MenuLinksView currentUser={currentUser} onClickLogout={onClickLogout} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMenu);
