import React from 'react';
import { useTranslation } from 'react-i18next';
import CONSTANTS from '../constants/constants';

const ServicesCheckboxes = ({ tripRequestServices = {}, onChangeServices }) => {
  const { t } = useTranslation();

  const onChange = stype => ev => {
    const checked = ev.target.checked;

    const newTripRequestServices = { ...tripRequestServices, [stype]: checked };
    onChangeServices(newTripRequestServices);
  };

  return (
    <>
      {Object.keys(CONSTANTS.TRIP_SERVICES_CONFIG).map((serviceConfigKey, index) => {
        const serviceConfig = CONSTANTS.TRIP_SERVICES_CONFIG[serviceConfigKey];
        return (
          <div key={index} className="checkbox">
            <label>
              <input type="checkbox" checked={tripRequestServices[serviceConfig.MODEL_KEY]} onChange={onChange(serviceConfig.MODEL_KEY)} />{' '}
              <i className={serviceConfig.ICON_NAME} aria-hidden="true"></i> {t(serviceConfig.SERVICE_DESCRIPTION_LABEL_KEY)}
            </label>
          </div>
        );
      })}
    </>
  );
};

export default ServicesCheckboxes;
