import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import agent from '../agent';
import { connect } from 'react-redux';
import { updateTripRequestField } from '../actions/common';
import RatingStars from '../components/RatingStars';
import ExpertCard from '../components/ExpertCard';
import NavigationService from '../services/navigation';
import { Helmet } from 'react-helmet';

const RenderSocialLink = ({ socialArr, socialType, classSocialName }) => {
  if (!socialArr || !socialArr[socialType]) {
    return null;
  }

  const socialLink = socialArr[socialType];
  return (
    <li>
      <a href={socialLink} target="_blank" rel="noopener noreferrer">
        <i className={classSocialName}></i>
      </a>
    </li>
  );
};

const ReviewsSection = ({ expertFirstname, expertRatings }) => {
  const { t } = useTranslation();

  if (!expertRatings || expertRatings.length === 0) {
    return (
      <div>
        <p>
          {expertFirstname || '-'} {t('EXPERT_PROFILE_NO_REVIEWS')}{' '}
        </p>
      </div>
    );
  }

  return (
    <>
      {(expertRatings || []).map((rating, index) => {
        return (
          <div key={index} className="review-container">
            <div className="reviewer-photo">
              <img
                src={rating.created_by.imageUrl || '/images/default-user-bk.png'}
                className="rounded-circle center-block"
                alt=""
                width="50px"
                height="50px"
              />
            </div>
            <div className="review-text-box">
              <div className="reviewer-name"> {rating.created_by.firstname}</div>
              <div className="review-text">
                <RatingStars rating={rating.evaluation || 0} />
                <p>{rating.comment}</p>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

const ExpertProfilePage = ({ onChangeTripRequestExpertField }) => {
  const { t } = useTranslation();
  let { expertId } = useParams();
  const appDestinations = []; // Hidden section for now

  const [expert, setExpert] = useState({});
  const [expertRatings, setExpertRatings] = useState([]);
  const [expertRatingsRetrievalError, setExpertRatingsRetrievalError] = useState(false);

  useEffect(() => {
    const fetchExpertProfile = async expertId => {
      try {
        const fetchedExpert = await agent.ExpertService.getExpertProfile(expertId);
        setExpert(fetchedExpert);
      } catch (error) {
        console.log(`Error when retrieving expert profile: ${error.messsage}`);
      }
    };

    const loadExpertRatings = async expertId => {
      try {
        const expertRatings = await agent.ExpertService.getExpertRatings(expertId);
        setExpertRatings(expertRatings);
      } catch (error) {
        console.log(`Error when retrieving expert ratings: ${error.messsage}`);
        setExpertRatingsRetrievalError(true);
      }
    };

    const onLoad = async () => {
      await fetchExpertProfile(expertId);
      await loadExpertRatings(expertId);
    };
    onLoad();
  }, [expertId]);

  const goToPickExpertStep = () => {
    NavigationService.goToTripRequestPickExpert();
  };

  const goToTripRequestConfirmation = expertUser => ev => {
    onChangeTripRequestExpertField(expertUser);
    NavigationService.goToTripRequestConfirmation();
  };

  const renderUpperSection = (expert = {}) => {
    const { pitchDescription, summary, blog, social } = expert;
    return (
      <div className="row">
        <div className="col-12 col-lg-4">
          <ExpertCard expert={expert} />
        </div>
        <div className="profile-about col-12 col-lg-8">
          <div className="profile-info">
            <h1>{pitchDescription || '-'}</h1>
            <p>{summary}</p>
            <ul className="row list-inline social-buttons justify-content-center">
              <RenderSocialLink socialArr={blog} socialType="url" classSocialName="fa fa-rss" />
              <RenderSocialLink socialArr={social} socialType="facebook" classSocialName="fa fa-facebook" />
              <RenderSocialLink socialArr={social} socialType="twitter" classSocialName="fa fa-twitter" />
              <RenderSocialLink socialArr={social} socialType="instagram" classSocialName="fa fa-instagram" />
              <RenderSocialLink socialArr={social} socialType="youtube" classSocialName="fa fa-youtube" />
            </ul>
          </div>
          <div>
            <button type="button" className="btn btn-primary btn-profile-info" onClick={goToTripRequestConfirmation(expert)}>
              {' '}
              {t('BUTTON_SELECT_PLANNER')}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderExpertDestinationsSection = expert => {
    const { firstname, destinations } = expert;
    return (
      <div className="row profile-section">
        <h2 className="col-12">
          {t('EXPERT_PROFILE_DESTINATIONS')} {firstname || '-'}
        </h2>
        <div className="container">
          {(destinations || []).map((destination, index) => {
            return (
              <div key={index} className="chip">
                {destination.name}{' '}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderFeaturedDestinationsSection = (expert, appDestinations) => {
    const { firstname } = expert;

    return (
      <div className="row profile-section d-none">
        <h2 className="col-12">{firstname || '-'} Featured Destinations</h2>
        <div className="container">
          {(appDestinations || []).map((appDestination, index) => {
            return <destination key={index} info={appDestination} expert="expert" />;
          })}
        </div>
      </div>
    );
  };

  const renderReviewsSection = (expert, expertRatings) => {
    const { firstname } = expert;

    return (
      <div className="row profile-section">
        <h2 className="col-12">
          {t('EXPERT_PROFILE_REVIEWS')} {firstname || '-'}
        </h2>
        <div className="reviews-section container">
          {expertRatingsRetrievalError ? (
            t('EXPERT_USER_RATINGS_RETRIEVAL_ERROR')
          ) : (
            <ReviewsSection expertFirstname={firstname} expertRatings={expertRatings} />
          )}
        </div>
      </div>
    );
  };

  // render empty container in case expert was ot retrieved
  if (!expert) {
    return <div className="container"></div>;
  }

  const { pitchDescription, summary, firstname, lastname, blog } = expert;
  const pageTitle = `${firstname} ${lastname} - ${blog ? blog.name : ''}`;
  const pageDescription = `${pitchDescription} - ${summary}`;
  return (
    <div className="container">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>
      {/*<!-- Expert main Section --> */}
      {renderUpperSection(expert)}
      {/*<!-- Expert Destinations Section --> */}
      {renderExpertDestinationsSection(expert)}
      {/*<!-- Destinations Section --> */}
      {renderFeaturedDestinationsSection(expert, appDestinations)}
      {/* <!-- Review Section --> */}
      {renderReviewsSection(expert, expertRatings)}

      <div className="row padding-10">
        <button type="button" className="btn btn-primary" onClick={goToPickExpertStep}>
          {t('BUTTON_BACK')}
        </button>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  onChangeTripRequestExpertField: value => dispatch(updateTripRequestField('expert', value))
});

export default connect(null, mapDispatchToProps)(ExpertProfilePage);
