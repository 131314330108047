const ROUTES = {
  ROOT: '/',
  DASHBOARD: '/dashboard',
  FAQ: '/faq',
  ABOUT_US: '/about-us',
  TERMS_OF_USE: '/terms-of-use',
  PRIVACY_POLICY: '/privacy-policy',
  LOGIN: '/login',
  REGISTER: '/register',
  RESET_PASSWORD: '/reset-password/:token',
  DESTINATIONS: '/destinations',
  EXPERTS: '/experts',
  EXPERT_PROFILE: '/experts/:expertId',
  MANAGE_DESTINATIONS: '/manage-destinations',
  MANAGE_LOCATIONS: '/manage-locations',
  TRIP_LIST: '/trip-list',
  TRIPS: '/trips',
  TRIP_TEMPLATES: '/trip-templates',
  TRIP_TEMPLATE_VIEW: '/trip-templates/:tripTemplateId',
  TRIP_TEMPLATE_EDIT: '/trip-templates/:tripTemplateId/edit',
  TRIP_RESUME_VIEW: '/trips/:tripId',
  TRIP_RESUME_EDIT: '/trips/:tripId/edit',
  TRIP_PAYMENT: '/trips/:tripId/payment',
  TRIP_RESUME_DAY_VIEW: '/trips/:tripId/days/:dayId',
  TRIP_RESUME_DAY_EVENT_VIEW: '/trips/:tripId/days/:dayId/events/:eventId',
  TRIP_TEMPLATE_DAY_VIEW: '/trip-templates/:tripTemplateId/days/:dayId',
  TRIP_TEMPLATE_DAY_EVENT_VIEW: '/trip-templates/:tripTemplateId/days/:dayId/events/:eventId',
  TRIP_REQUEST_INFO: '/trip-request/1-info',
  TRIP_REQUEST_EXPERT: '/trip-request/2-pick-expert',
  TRIP_REQUEST_CONFIRMATION: '/trip-request/3-confirmation',
  NOT_AUTHORIZED: '/not-authorized',
  NOT_FOUND: '/not-found'
};

export default ROUTES;
