import {
  LOCATION_RESET,
  UPDATE_CURRENT_LOCATION_FIELD,
  LOCATIONS_GET_REQUEST,
  LOCATIONS_GET_SUCCESS,
  LOCATIONS_GET_FAILURE,
  LOCATION_POST_REQUEST,
  LOCATION_POST_SUCCESS,
  LOCATION_POST_FAILURE,
  LOCATION_DELETE_REQUEST,
  LOCATION_DELETE_SUCCESS,
  LOCATION_DELETE_FAILURE,
  LOCATION_GET_REQUEST,
  LOCATION_GET_SUCCESS,
  LOCATION_GET_FAILURE,
  LOCATION_PUT_REQUEST,
  LOCATION_PUT_SUCCESS,
  LOCATION_PUT_FAILURE
} from '../constants/actionTypes';
import { getDefaultLocation } from '../utils/locationUtils';

const defaultState = {
  locations: [],
  message: '',
  currentLocation: getDefaultLocation(),
  inProgress: false,
  error: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOCATION_RESET:
      return {
        ...state,
        currentLocation: state.currentLocation
      };
    case UPDATE_CURRENT_LOCATION_FIELD:
      const updatedCurrentLocation = { ...state.currentLocation, [action.key]: action.value };
      return {
        ...state,
        currentLocation: updatedCurrentLocation
      };

    case LOCATIONS_GET_REQUEST:
    case LOCATION_POST_REQUEST:
    case LOCATION_DELETE_REQUEST:
    case LOCATION_GET_REQUEST:
    case LOCATION_PUT_REQUEST:
      return { ...state, inProgress: true };
    case LOCATIONS_GET_SUCCESS:
      return {
        ...state,
        inProgress: false,
        error: null,
        currentLocation: defaultState.currentLocation,
        locations: action.payload ? action.payload : null
      };
    case LOCATION_GET_SUCCESS:
      return {
        ...state,
        inProgress: false,
        error: null,
        message: action.message ? action.message : null,
        currentLocation: action.payload ? action.payload : null
      };
    case LOCATION_PUT_SUCCESS:
      const { payload } = action;
      const updatedLocations = state.locations.map(location => {
        if (payload && payload._id === location._id) {
          return payload;
        }
        return location;
      });

      return {
        ...state,
        inProgress: false,
        error: null,
        message: action.message ? action.message : null,
        locations: updatedLocations,
        currentLocation: payload ? payload : null
      };
    case LOCATION_POST_SUCCESS:
      const locs = [...state.locations];
      if (action.payload) {
        locs.push(action.payload);
      }

      return {
        ...state,
        inProgress: false,
        error: null,
        message: action.message ? action.message : null,
        locations: locs,
        currentLocation: defaultState.currentLocation
      };
    case LOCATION_DELETE_SUCCESS:
      const filteredLocations = state.locations.filter(el => el._id !== action.locationId);

      return {
        ...state,
        inProgress: false,
        error: null,
        message: action.message ? action.message : null,
        locations: filteredLocations,
        currentLocation: defaultState.currentLocation
      };

    case LOCATIONS_GET_FAILURE:
    case LOCATION_POST_FAILURE:
    case LOCATION_DELETE_FAILURE:
    case LOCATION_GET_FAILURE:
    case LOCATION_PUT_FAILURE:
      return {
        ...state,
        inProgress: false,
        message: [],
        error: action.error ? action.error : null
      };
    default:
      return state;
  }
};
