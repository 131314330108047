import React from 'react';
import { useTranslation } from 'react-i18next';

const SelectCustomBox = ({ value, values = [], hasDefault, onValueChange }) => {
  const { t } = useTranslation();

  const changeValue = ev => {
    onValueChange(ev.target.value);
  };

  return (
    <select className="form-control" value={value} onChange={changeValue}>
      {hasDefault ? <option value="">{t('SELECT_BOX_DEFAULT_VALUE_LABEL')} </option> : null}
      {values.map((currentValue, index) => (
        <option key={index} value={currentValue}>
          {t(currentValue)}
        </option>
      ))}
    </select>
  );
};

export default SelectCustomBox;
