import React from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrency } from '../utils/currencyUtils';
import NavigationService from '../services/navigation';
import IconLine from '../components/IconLine';
import RatingStars from '../components/RatingStars';
import { calculateFullPriceInCents } from '../utils/tripUtils';
import { getUserFullName } from '../utils/userUtils';

const ExpertItem = ({ expertUser, tripRequest, goToConfirmationStep }) => {
  const { t } = useTranslation();
  const DEFAULT_NUMBER_OF_DAYS = 10;

  const expertUserNumberOfRatings =
    expertUser.numberOfRatings === 0
      ? t('EXPERT_USER_NO_RATINGS')
      : expertUser.numberOfRatings + ' rating' + (expertUser.numberOfRatings > 1 ? 's' : '');

  const goToProfile = expertUserId => ev => {
    ev.stopPropagation();
    NavigationService.goToExpertProfile(expertUserId);
  };

  const getTotalPricePerDay = (expert, tripRequest = {}) => {
    const days = tripRequest.numberOfDays;
    const numberOfDays = days ? days : DEFAULT_NUMBER_OF_DAYS;

    const totalPriceInCents = calculateFullPriceInCents(numberOfDays, expert, tripRequest.services, tripRequest.destinations);
    const totalPrice = (totalPriceInCents / 100).toFixed(2);
    const totalPricePerDay = Math.ceil(totalPrice / numberOfDays);
    return totalPricePerDay;
  };

  const expertFullName = getUserFullName(expertUser, '');

  return (
    <div className="row trip-request-experts" onClick={goToProfile(expertUser.id)}>
      <div className="col-xs-5 col-lg-3 ">
        <div className="row">
          <img
            src={expertUser.imageUrl || '/images/default-user-bk.png'}
            className="rounded-circle"
            alt=""
            width="157px"
            height="157px"
            padding-top="25px"
          />
        </div>
      </div>
      <div className="col-xs-7 col-lg-6">
        <div className="row">
          <div className="expert-flag col-12 p-0 m-0">
            <img src="/images/flags/brazil.png" className="img-circle" alt="Country" width="25px" height="25px" padding-top="25px" />
            {expertFullName}
          </div>
          <div className="expert-blog-name col-12">{expertUser?.blog?.name}</div>
          <div className="col-12 p-0 m-0">
            <RatingStars rating={expertUser.overallRating || 0} />
            <span className="review"> {expertUserNumberOfRatings}</span>
          </div>
          <div>{expertUser.pitch}</div>
          <br />
          <div className="col-12 p-0 m-0 theme-color">
            <IconLine keys={expertUser.specialities} />
          </div>
          <div className="center-block col-12 p-0 m-0">
            {(expertUser.destinations || []).map((destination, index) => {
              return (
                <span key={index} className="badge badge-primary label-expert-destination">
                  {destination.name}
                </span>
              );
            })}
          </div>
        </div>
      </div>
      <div className="col-xs-4 col-lg-3 text-center">
        <div className="row align-middle">
          <h2 className="col-12" style={{ marginTop: '20px' }}>
            {getCurrency(expertUser.servicesCurrencyCode).symbol} {getTotalPricePerDay(expertUser, tripRequest) || '-'}
          </h2>
          <br />
          <p className="col-12">{t('TRIP_REQUEST_AVERAGE_PRICE')}</p>
          <div className="col-12">
            <button type="button" className="btn btn-primary" onClick={goToConfirmationStep(expertUser)} style={{ marginTop: '15px' }}>
              {t('BUTTON_SELECT_PLANNER')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpertItem;
