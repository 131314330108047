import React from 'react';
import { useTranslation } from 'react-i18next';
import CONSTANTS from '../constants/constants';
import { CustomTooltip } from './Custom';
import TripEventKindIconSwitcher from './TripEventKindIconSwitcher';
import { getTripEventDateLabel } from '../utils/tripUtils';
import { Button } from '../components/Custom';
import { convertTimestampToHourOfTheDayVerbose } from '../utils/dateUtils';

const TripEventSkeleton = ({ children, tripEvent, leftSideContent, titleContent, actionFn }) => {
  const tripEventDateLabel = getTripEventDateLabel(tripEvent, true);
  const duration = tripEvent.duration ? ` ${convertTimestampToHourOfTheDayVerbose(tripEvent.duration)} ` : '';

  return (
    <>
      <div className="hour-column">
        <div className="trip-canvas-cell-title">
          <span className="badge badge-light">{tripEventDateLabel}</span>
        </div>
        <div className="trip-canvas-cell-actions"></div>
      </div>

      <div className="icon-column">
        {/*<div className="timeline2"></div>*/}
        <div className="trip-canvas-cell-title">
          <div className="trip-event-icon">{leftSideContent}</div>
        </div>
        <div className="trip-canvas-cell-actions"></div>
      </div>

      <div className="inner-event">
        <div className="trip-canvas-cell-title">
          <span className="list-card-title">{titleContent}</span>
          <br />
          <span className="xs-text">{tripEvent.distance ? `${tripEvent.distance} km` : ''}</span>
          <span className="trip-canvas-cell-icons">
            <Button as="a" className="a-button" onClick={actionFn}>
              <i className="fa fa-2x fa-angle-right" aria-hidden="true"></i>
            </Button>
          </span>
        </div>
        <div className="trip-canvas-cell-actions">
          {duration ? (
            <span className="xs-text">
              <i className="fa fa-clock-o" aria-hidden="true"></i>
              {duration}
            </span>
          ) : null}
        </div>
        {children}
      </div>
    </>
  );
};

const TripEventDirection = ({ children, tripEvent, actionFn }) => {
  const { t } = useTranslation();

  const renderLabel = tripEvent => {
    return (
      <>
        <TripEventKindIconSwitcher tripEvent={tripEvent} icon={false} tooltip={false} text={true} />{' '}
        {tripEvent && tripEvent.hidden ? (
          <div className="expert-icon-container">
            <CustomTooltip text={t('TRIP_EVENT_FIELD_HIDDEN')} isKey={true}>
              <i className="fa fa-eye-slash" title={t('TRIP_EVENT_FIELD_HIDDEN')} aria-hidden="true"></i>
            </CustomTooltip>
          </div>
        ) : null}
      </>
    );
  };

  const renderTimeline = tripEvent => {
    return (
      <>
        <TripEventKindIconSwitcher tripEvent={tripEvent} icon={true} tooltip={false} text={false} />
      </>
    );
  };

  const leftSideContent = renderTimeline(tripEvent);
  const titleContent = renderLabel(tripEvent);
  return (
    <TripEventSkeleton tripEvent={tripEvent} leftSideContent={leftSideContent} titleContent={titleContent} actionFn={actionFn}>
      {children}
    </TripEventSkeleton>
  );
};

const TripEventNonDirection = ({ children, tripEvent, mapIndex, isSelected, actionFn }) => {
  const renderTitle = tripEvent => {
    const tripEventTitle =
      tripEvent.kind === CONSTANTS.TRIP_EVENT_KINDS.ESTABLISHMENT ? `${tripEvent.establishmentType} - ${tripEvent.name}` : tripEvent.name;

    return (
      <>
        <span className="title">{tripEventTitle}</span>
      </>
    );
  };

  const renderIcon = tripEvent => {
    return (
      <>
        <TripEventKindIconSwitcher tripEvent={tripEvent} icon={true} tooltip={false} text={false} />
      </>
    );
  };

  const leftSideContent = renderIcon(tripEvent);
  const titleContent = renderTitle(tripEvent);
  return (
    <TripEventSkeleton tripEvent={tripEvent} leftSideContent={leftSideContent} titleContent={titleContent} actionFn={actionFn}>
      {children}
    </TripEventSkeleton>
  );
};

const TripEvent = ({ tripEvent, isEdition, isSelected, mapIndex, onSelectEvent, actionFn }) => {
  const getEventClassName = (tripEventKind, isSelected, isEdition, eventType) => {
    let className = 'trip-canvas-cell trip-canvas-drag-box ';

    className += isSelected ? ' selected ' : '';
    className += isEdition ? ' cursor-grab ' : '';
    className += eventType === CONSTANTS.ATTRACTION_TYPES_OBJECT.RESTAURANT ? ' restaurant ' : '';

    switch (tripEventKind) {
      case CONSTANTS.TRIP_EVENT_KINDS.DIRECTIONS:
        className += 'directions';
        break;
      case CONSTANTS.TRIP_EVENT_KINDS.ESTABLISHMENT:
        className += 'establishment';
        break;
      case CONSTANTS.TRIP_EVENT_KINDS.ACCOMMODATION:
        className += 'accommodation';
        break;
      case CONSTANTS.TRIP_EVENT_KINDS.ATTRACTION:
      default:
        className += '';
        break;
    }

    return className;
  };

  const eventClassName = getEventClassName(tripEvent.kind, isSelected, isEdition, tripEvent.eventType);

  return (
    <div className={eventClassName} onClick={onSelectEvent(tripEvent)}>
      {tripEvent.kind === CONSTANTS.TRIP_EVENT_KINDS.DIRECTIONS ? (
        <TripEventDirection tripEvent={tripEvent} mapIndex={mapIndex} isSelected={isSelected} actionFn={actionFn}></TripEventDirection>
      ) : (
        <TripEventNonDirection tripEvent={tripEvent} mapIndex={mapIndex} isSelected={isSelected} actionFn={actionFn}>
          <div className="distance-sub-header">
            <div className="map-index-container">
              {mapIndex ? <label className={`map-index ${isSelected ? 'selected' : ''}`}>{mapIndex}</label> : null}
            </div>
          </div>
        </TripEventNonDirection>
      )}
    </div>
  );
};

export default TripEvent;
