import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NavigationService from '../services/navigation';
import TripListItem from '../components/TripListItem';
import { connect } from 'react-redux';
import ShowError from '../components/ShowError';
import { getTrips } from '../actions/trips';
import TripListFilter from '../components/TripListFilter';
import { Pagination } from 'react-bootstrap';

const TripListPage = ({ currentUser, tripResumes, error, inProgress, getTrips, environmentUrl }) => {
  const { t } = useTranslation();

  const isExpertUser = currentUser ? currentUser.isExpert : null;

  const [filter, setFilter] = useState(undefined);
  useEffect(() => {
    const applyTripListFilter = async (filter = {}) => {
      const { status } = filter;
      await getTrips(status);
    };

    applyTripListFilter(filter); // apply filter if there is any active filter
  }, [filter, getTrips]);

  const renderTripListTop = isExpertUser => {
    return (
      <div className="width-100 padding-10">
        {!isExpertUser ? (
          <button type="button" className="btn btn-primary" onClick={goToTripRequest}>
            {t('LANDING_PAGE_TITLE_BUTTON')}
          </button>
        ) : null}
        <TripListFilter onChangeFilter={setFilter} />
      </div>
    );
  };

  const goToTripRequest = event => {
    event.stopPropagation();
    NavigationService.goToTripRequest();
  };

  return (
    <div className="container padding-10">
      {renderTripListTop(isExpertUser)}
      <div className="row">
        <ShowError error={error} />
        {!inProgress && tripResumes.length === 0 ? <p className="col-12">{t('TRIP_RESUME_LIST_RETRIVAL_ERROR')}</p> : ''}
        <div className="col-12">
          <ul className="list-unstyled">
            {inProgress ? (
              <>
                <li>
                  <TripListItem isExpert={isExpertUser} trip={{}}></TripListItem>
                </li>
                <li>
                  <TripListItem isExpert={isExpertUser} trip={{}}></TripListItem>
                </li>
              </>
            ) : (
              tripResumes.map((trip, index) => {
                return (
                  <li key={index}>
                    <TripListItem isExpert={isExpertUser} trip={trip} environmentUrl={environmentUrl}></TripListItem>
                  </li>
                );
              })
            )}
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Pagination className="justify-content-center">
            <Pagination.Prev />
            <Pagination.Item active>{1}</Pagination.Item>
            <Pagination.Next />
          </Pagination>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  tripResumes: state.trips.tripResumes,
  inProgress: state.trips.inProgress,
  error: state.trips.error,
  environmentUrl: state.common.environment ? state.common.environment.url : '',
});

const mapDispatchToProps = dispatch => ({
  getTrips: (status) => dispatch(getTrips(status))
});

export default connect(mapStateToProps, mapDispatchToProps)(TripListPage);
