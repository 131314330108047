import i18next from 'i18next';
import ROUTES from '../constants/routes';

import LoginPage from '../pages/LoginPage';
import RegisterPage from '../pages/RegisterPage';
import ResetPasswordPage from '../pages/ResetPasswordPage';
import NotFound from '../pages/NotFound';
import TripListPage from '../pages/TripListPage';
import TripResumeViewPage from '../pages/TripResumeViewPage';
import TripTemplateListPage from '../pages/TripTemplateListPage';
import TripTemplateViewPage from '../pages/TripTemplateViewPage';
import TripTemplateEditPage from '../pages/TripTemplateEditPage';
import DestinationsPage from '../pages/DestinationsPage';
import ExpertsPage from '../pages/ExpertsPage';
import ExpertProfilePage from '../pages/ExpertProfilePage';
import TripRequestInfoPage from '../pages/TripRequestInfoPage';
import TripRequestExpertsPage from '../pages/TripRequestExpertsPage';
import TripRequestConfirmationPage from '../pages/TripRequestConfirmationPage';
import FaqPage from '../pages/FaqPage';
import AboutUsPage from '../pages/AboutUsPage';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';
import TermsOfUsePage from '../pages/TermsOfUsePage';
import NotAuthorized from '../pages/NotAuthorized';
import DashboardPage from '../pages/DashboardPage';
import TripDayViewPage from '../pages/TripDayViewPage';
import TripResumeEditPage from '../pages/TripResumeEditPage';
import TripPaymentPage from '../pages/TripPaymentPage';
import ManageLocationsPage from '../pages/ManageLocationsPage';
import ManageDestinationsPage from '../pages/ManageDestinationsPage';

// The order of declaration of these rtoutes must be handled carefully, since they matter. For instance, Trip Resume Edit and View.
const navigation = {
  ROOT: {
    route: ROUTES.ROOT,
    component: LoginPage,
    title: i18next.t('LOGIN_PAGE_TITLE'),
  },
  DASHBOARD: { route: ROUTES.DASHBOARD, component: DashboardPage },
  LOGIN: {
    route: ROUTES.LOGIN,
    component: LoginPage,
    title: i18next.t('LOGIN_PAGE_TITLE'),
    metaDescription: i18next.t('LOGIN_PAGE_META_DESCRIPTION')
  },
  REGISTER: {
    route: ROUTES.REGISTER,
    component: RegisterPage,
    title: i18next.t('REGISTER_PAGE_TITLE'),
    metaDescription: i18next.t('REGISTER_PAGE_META_DESCRIPTION')
  },
  RESET_PASSWORD: { route: ROUTES.RESET_PASSWORD, component: ResetPasswordPage, title: i18next.t('RESET_PASSWORD_PAGE_TITLE') },
  TRIP_LIST: { route: ROUTES.TRIP_LIST, component: TripListPage },
  TRIP_RESUME_DAY_EVENT_VIEW: { route: ROUTES.TRIP_RESUME_DAY_EVENT_VIEW, component: TripDayViewPage },
  TRIP_RESUME_EDIT: { route: ROUTES.TRIP_RESUME_EDIT, component: TripResumeEditPage },
  TRIP_PAYMENT: { route: ROUTES.TRIP_PAYMENT, component: TripPaymentPage },
  TRIP_RESUME_DAY_VIEW: { route: ROUTES.TRIP_RESUME_DAY_VIEW, component: TripDayViewPage },
  TRIP_RESUME_VIEW: { route: ROUTES.TRIP_RESUME_VIEW, component: TripResumeViewPage },
  TRIP_TEMPLATE_DAY_EVENT_VIEW: { route: ROUTES.TRIP_TEMPLATE_DAY_EVENT_VIEW, component: TripDayViewPage },
  TRIP_TEMPLATE_EDIT: { route: ROUTES.TRIP_TEMPLATE_EDIT, component: TripTemplateEditPage },
  TRIP_TEMPLATE_DAY_VIEW: { route: ROUTES.TRIP_TEMPLATE_DAY_VIEW, component: TripDayViewPage },
  TRIP_TEMPLATES_VIEW: { route: ROUTES.TRIP_TEMPLATE_VIEW, component: TripTemplateViewPage },
  TRIP_TEMPLATES: { route: ROUTES.TRIP_TEMPLATES, component: TripTemplateListPage },
  DESTINATIONS: {
    route: ROUTES.DESTINATIONS,
    component: DestinationsPage,
    title: i18next.t('DESTINATIONS_PAGE_TITLE'),
    metaDescription: i18next.t('DESTINATIONS_PAGE_META_DESCRIPTION')
  },
  EXPERT_PROFILE: {
    route: ROUTES.EXPERT_PROFILE,
    component: ExpertProfilePage,
    title: i18next.t('EXPERTS_PAGE_TITLE'),
    metaDescription: i18next.t('EXPERTS_PAGE_META_DESCRIPTION')
  },
  EXPERTS: {
    route: ROUTES.EXPERTS,
    component: ExpertsPage,
    title: i18next.t('EXPERTS_PAGE_TITLE'),
    metaDescription: i18next.t('EXPERTS_PAGE_META_DESCRIPTION')
  },
  MANAGE_LOCATIONS: { route: ROUTES.MANAGE_LOCATIONS, component: ManageLocationsPage },
  MANAGE_DESTINATIONS: {
    route: ROUTES.MANAGE_DESTINATIONS,
    component: ManageDestinationsPage,
    title: i18next.t('DESTINATIONS_PAGE_TITLE'),
  },
  TRIP_REQUEST_INFO: { route: ROUTES.TRIP_REQUEST_INFO, component: TripRequestInfoPage },
  TRIP_REQUEST_EXPERT: { route: ROUTES.TRIP_REQUEST_EXPERT, component: TripRequestExpertsPage },
  TRIP_REQUEST_CONFIRMATION: { route: ROUTES.TRIP_REQUEST_CONFIRMATION, component: TripRequestConfirmationPage },
  FAQ: { route: ROUTES.FAQ, component: FaqPage },
  ABOUT_US: { route: ROUTES.ABOUT_US, component: AboutUsPage },
  TERMS_OF_USE: { route: ROUTES.TERMS_OF_USE, component: TermsOfUsePage },
  PRIVACY_POLICY: { route: ROUTES.PRIVACY_POLICY, component: PrivacyPolicyPage },
  NOT_AUTHORIZED: { route: ROUTES.NOT_AUTHORIZED, component: NotAuthorized },
  NOT_FOUND: { route: ROUTES.NOT_FOUND, component: NotFound },
};

export default navigation;
