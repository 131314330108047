import React, { useState, useEffect } from 'react';
import { Button } from './Custom';
const SIDENAV_BASE_CLASS = 'sidenav';

const SideNav = ({ children, id, isOpen, triggerCloseEvent, reverseColor, left, padding }) => {
  const [sidenavClass, setSidenavClass] = useState(SIDENAV_BASE_CLASS);

  const closeHandler = ev => {
    ev.preventDefault();
    triggerCloseEvent(ev);
  };

  useEffect(() => {
    const buildAndSetSidenavClassname = (isOpen, reverseColor, left) => {
      const reverseColorClassName = reverseColor ? 'reversed-color' : '';
      const paddingTopClassName = padding ? 'padding-top' : '';
      const stateClassName = typeof isOpen === 'boolean' ? (isOpen ? 'open' : 'close') : '';
      const positionClassName = typeof left === 'boolean' ? (left ? 'left' : 'right') : '';

      const sidenavClassName = `${SIDENAV_BASE_CLASS} ${positionClassName} ${reverseColorClassName} ${stateClassName} ${paddingTopClassName}`;
      setSidenavClass(sidenavClassName);
    };

    buildAndSetSidenavClassname(isOpen, reverseColor, left);
  }, [isOpen, reverseColor, left]);

  return (
    <div id={id} className={sidenavClass}>
      <div className="top">
        <Button as="a" className={`float-${left ? 'right' : 'left'} a-button`} onClick={closeHandler}>
          <i className="material-icons">close</i>
        </Button>
      </div>
      <div className="display-block">{children}</div>
    </div>
  );
};

export default SideNav;
