import React from 'react';
import { getTripStatusTranslationString } from '../utils/common';
import { formatDate } from '../utils/dateUtils';
import { useTranslation } from 'react-i18next';
import TripActionsButtons from '../containers/TripActionsButtons';
import IconTripTypeSwitcher from './IconTripTypeSwitcher';
import IconBudgetSwitcher from './IconBudgetSwitcher';
import IconLine from './IconLine';
import { CustomTooltip } from './Custom';
import CONSTANTS from '../constants/constants';
import { getUserFullName } from '../utils/userUtils';

const TripResumeContent = ({ trip, isExpertUser }) => {
  const { t } = useTranslation();

  const { tripRequest } = trip;

  const tripRequestUser = isExpertUser ? tripRequest.regularUser : tripRequest.expertUser;

  const renderTripContentTop = (tripRequestUser, tripTitle) => {
    if (!tripRequestUser) {
      return null;
    }

    const tripRequestUserName = getUserFullName(tripRequestUser, tripRequestUser.email);
    return (
      <div className="row width-100">
        <div className="col-4">
          <div className="trip-edit-avatar">
            <img src={tripRequestUser.imageUrl || '/images/default-user-bk.png'} className="rounded-circle img-fluid" alt={tripRequestUserName} />
          </div>
        </div>
        <div className="col-8">
          <CustomTooltip text={tripRequestUser.email || ''}>
            <p style={{ wordWrap: 'break-word' }}>{tripRequestUserName}</p>
          </CustomTooltip>
          <p>{tripTitle || '-'}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="trip-resume-content">
      {!tripRequestUser ? null : renderTripContentTop(tripRequestUser, trip.title)}
      <div className="container">
        <div className="row">
          <span className="col-4">{t('TRIP_RESUME_PERIOD')}: </span>
          <span className="col-8">
            {formatDate(trip.startDate) || '-'} - {formatDate(trip.endDate) || '-'} ({tripRequest.numberOfDays || '-'} days)
          </span>
        </div>
        <div className="row">
          <span className="col-4">{t('TRIP_RESUME_TRIP_STATUS')}: </span>
          <span className="col-8">
            {trip.status ? t(getTripStatusTranslationString(trip.status)) : ''}
            <span>
              ({trip.status === CONSTANTS.TRIP_STATUS.TRIP_PROCESSING && trip.tripProcessingStatusState ? t(trip.tripProcessingStatusState) : null})
            </span>
          </span>
        </div>
        <div className="row d-none">
          <span className="col-4">Participants : </span>
          <span className="col-8">{tripRequest.numberOfParticipants || '-'}</span>
        </div>
        <div className="row">
          <span className="col-4">{t('TRIP_RESUME_COST_STYLE')}: </span>
          <div className="col-8">
            <IconBudgetSwitcher iconKey={tripRequest.tripBudget} icon={true} tooltip={true} text={true}></IconBudgetSwitcher>
          </div>
        </div>
        <div className="row">
          <span className="col-4">{t('TRIP_RESUME_TRIP_STYLE')}: </span>
          <div className="col-8">
            <IconTripTypeSwitcher iconKey={tripRequest.tripType} icon={true} tooltip={true} text={true}></IconTripTypeSwitcher>
          </div>
        </div>
        <div className="row">
          <span className="col-4">{t('TRIP_RESUME_PREFERENCES')}: </span>
          <div className="col-8">
            <IconLine keys={tripRequest.preferences} />
          </div>
        </div>
        <div className="row">
          <span className="col-12">{t('TRIP_RESUME_ACTIONS')}: </span>
          <div className="col-12 trip-actions-buttons-container">
            <TripActionsButtons trip={trip} isExpertUser={isExpertUser} iconVersion={true} />
          </div>
        </div>
        <div className="row">
          <span className="col-12">{t('TRIP_RESUME_MESSAGE')}: </span>
          <div className="col-12">
            <div className="trip-resume-request-message">
              <p>{tripRequest.message} </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripResumeContent;
