import React, { useState } from 'react';
import { connect } from 'react-redux';
import { postMessage } from '../actions/messages';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { Button } from '../components/Custom';

const ChangeRequestModal = ({ isExpertUser, conversationId, showChangeRequestModal, onCloseModal, postMessage }) => {
  const { t } = useTranslation();

  const defaultMessage = t('MODAL_CHANGE_REQUEST_DEFAULT_MESSAGE');
  const [message, setMessage] = useState(defaultMessage);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleClose = () => {
    onCloseModal();
  };

  const changeRequest = async () => {
    setIsFormSubmitted(true);
    if (!message) {
      return;
    }

    const queryMessage = {
      personKind: isExpertUser ? 'ExpertUser' : 'RegularUser',
      text: message
    };

    await postMessage(conversationId, queryMessage);
    // regardless of the result, closes the modal
    onCloseModal();
  };

  const changeMessage = ev => {
    const message = ev.target.value;
    setMessage(message);
  };

  return (
    <div>
      <Modal id="changeRequestModal" size="lg" animation="true" show={showChangeRequestModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <h2>{t('MODAL_CHANGE_REQUEST_TITLE')}</h2>
            <Button as="a" className="a-button float-right" onClick={handleClose}>
              <i className="material-icons">close</i>
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body" id="modal-body">
          <div className="width-100">
            <div className="row">
              <div className="col-12">
                <form className="form-horizontal">
                  <div className="form-group">
                    <div className="row">
                      <label for="uname" className="col-form-label col-md-4">
                        {t('MODAL_CHANGE_REQUEST_MESSAGE')}
                      </label>
                      <div className="col-md-6">
                        <textarea
                          className={`form-control ${isFormSubmitted && !message ? 'is-invalid' : ''}`}
                          rows="5"
                          placeholder={t('MODAL_CHANGE_REQUEST_MESSAGE_PLACEHOLDER')}
                          value={message}
                          onChange={changeMessage}
                          required
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="submit" className="btn btn-primary" onClick={changeRequest}>
            {t('BUTTON_SEND_CHANGE_REQUEST')}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  postMessage: (conversationId, messagePayload) => dispatch(postMessage(conversationId, messagePayload))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeRequestModal);
