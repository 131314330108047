import React from 'react';
import { useTranslation } from 'react-i18next';
import NavigationService from '../services/navigation';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';

const TripTemplateListItem = ({ tripTemplate, deleteTripTemplate }) => {
  const { t } = useTranslation();

  if (!tripTemplate) {
    return (
      <div className="row collection-item cursor-pointer">
        <div className="col-12 col-md-4">
          <Skeleton />
          <Skeleton width={50} />
          <Skeleton />
        </div>
        <div className="col-12 col-md-4">{/*<!-- Empty space here--> */}</div>
        <div className="col-12 col-md-4 text-center">
          <Skeleton height={100} />
        </div>
      </div>
    );
  }

  const goToTripTemplate = tripTemplateId => ev => {
    NavigationService.goToTripTemplateView(tripTemplateId);
  };

  const deleteTemplate = tripTemplate => event => {
    event.stopPropagation();
    const confirmWindow = window.confirm(t('TRIP_TEMPLATE_CONFIRM_DELETION'));
    if (confirmWindow === false) {
      return;
    }
    deleteTripTemplate(tripTemplate._id);
  };

  const editTemplate = tripTemplate => event => {
    event.stopPropagation();
    NavigationService.goToTripTemplateEdition(tripTemplate._id);
  };

  const longDate = moment(tripTemplate.created_at).format('MMMM DD, YYYY');
  const tripTemplateDaysLength = tripTemplate.tripPlan && tripTemplate.tripPlan.days ? tripTemplate.tripPlan.days.length : null;
  return (
    <div className="row collection-item cursor-pointer" onClick={goToTripTemplate(tripTemplate._id)}>
      <div className="col-xs-12 col-md-4">
        <p>{tripTemplate.name}</p>
        <p>
          {tripTemplateDaysLength || '-'} {t('DASHBOARD_TEMPLATE_DAYS')}
        </p>
        <span className="meta">
          {t('DASHBOARD_TEMPLATE_CREATION_DATE')} {longDate}
        </span>
      </div>
      <div className="col-xs-12 col-md-4">{/*<!-- Empty space here--> */}</div>
      <div className="col-xs-12 col-md-4 text-center">
        <div className="row collection-item-body">
          <button type="button" className="btn btn-primary  mr-2" onClick={editTemplate(tripTemplate)}>
            {t('BUTTON_EDIT')}
          </button>
          <button type="button" className="btn btn-primary" onClick={deleteTemplate(tripTemplate)}>
            {t('BUTTON_DELETE')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TripTemplateListItem;
