import auth from './reducers/auth';
import { combineReducers } from 'redux';
import common from './reducers/common';
import home from './reducers/home';
import footer from './reducers/footer';
import experts from './reducers/experts';
import trips from './reducers/trips';
import tripTemplates from './reducers/tripTemplates';
import tripRequest from './reducers/tripRequest';
import users from './reducers/users';
import locations from './reducers/locations';
import messages from './reducers/messages';
import tripPlans from './reducers/tripPlans';
import destinations from './reducers/destinations';
import { connectRouter } from 'connected-react-router';

export default history =>
  combineReducers({
    auth,
    common,
    home,
    footer,
    experts,
    tripRequest,
    trips,
    tripTemplates,
    locations,
    users,
    messages,
    tripPlans,
    destinations,
    router: connectRouter(history)
  });
