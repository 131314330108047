import CONSTANTS from './constants/constants';

export const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY;

export const STRIPE_PUBLISHABLE_KEY = process.env.STRIPE_PUBLISHABLE_KEY;

export const SESSION_CONFIG = {
  COOKIE_NAME: 'VOYAGEZY_SESSION',
  TIMEOUT: CONSTANTS.TIME.HOUR // 1 hour session
};
