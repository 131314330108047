import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import packageJson from '../../package.json';
const { version } = packageJson;

const LoggedInView = ({ currentUser }) => {
  const { t } = useTranslation();

  if (currentUser && !currentUser.isExpert) {
    return (
      <>
        <li>
          <a href="/trip-list">{t('FOOTER_TRIP_PLANS')}</a>
        </li>
      </>
    );
  } else {
    return (
      <>
        <li>
          <a href="/trip-list">{t('FOOTER_TRIP_TEMPLATES')}</a>
        </li>
        <li>
          <a href="/trip-list">{t('FOOTER_MY_TRIP_PLANS')}</a>
        </li>
      </>
    );
  }
};

const LoggedOutView = ({ currentUser }) => {
  const { t } = useTranslation();

  if (!currentUser) {
    return (
      <>
        <li>
          <a href="/login">{t('HEADER_MENU_LOGIN')}</a>
        </li>
        <li>
          <a href="/register">{t('FOOTER_SIGN_UP')}</a>
        </li>
      </>
    );
  }
  return null;
};

const Footer = ({ currentUser, footer }) => {
  const { message, error, inProgress } = footer;
  const { t } = useTranslation();



  return (
    <div>
      <footer id="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 footerleft">
              <div className="logofooter">
                <img alt="Voyagezy Logo" src="/images/logo_white.png" />
              </div>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <a href="https://www.facebook.com/voyagezy">
                    <i className="fa fa-facebook-official fa-2x"></i>&nbsp;
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.instagram.com/voyagezy">
                    <i className="fa fa-instagram fa-2x" aria-hidden="true"></i>&nbsp;
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://twitter.com/voyagezy">
                    <i className="fa fa-twitter fa-2x" aria-hidden="true"></i>&nbsp;
                  </a>
                </li>
              </ul>
              <p>
                <i className="fa fa-envelope"></i> E-mail : info@voyagezy.com
              </p>
            </div>
            <div className="col-lg-4 col-md-6 ">
              <h6 className="heading-footer">VOYAGEZY</h6>
              <div className="row">
                <div className="col-sm-6">
                  <ul className="footer-ul">
                    <li>
                      <a href="/destinations">{t('FOOTER_DESTINATIONS')} </a>
                    </li>

                    <LoggedOutView currentUser={currentUser} />
                    <LoggedInView currentUser={currentUser} />
                  </ul>
                </div>
                <div className="col-sm-6">
                  <ul className="footer-ul col-sm-12 col-lg-6">
                    <li>
                      <a href="/faq"> {t('FOOTER_FAQ')}</a>
                    </li>
                    <li>
                      <a href="/privacy-policy"> {t('FOOTER_PRIVACY_POLICY')}</a>
                    </li>
                    <li>
                      <a href="/about-us" className="d-none">
                        {t('FOOTER_ABOUT_US')}
                      </a>
                    </li>
                    <li>
                      <a href="/#" className="d-none">
                        {t('FOOTER_TERMS_OF_USE')}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 contact-box">

            </div>
          </div>
        </div>
      </footer>

      <div className="copyright">
        <div className="container">
          <div className="col-sm-12">
            <p>© 2022 - Voyagezy Admin (v{version}) - {t('FOOTER_ALL_RIGHTS')} </p>
          </div>
          <div className="d-none">
            <ul className="bottom_ul">
              <li>
                <a href="/#">Sobre nós</a>
              </li>
              <li>
                <a href="/#">Conceito</a>
              </li>
              <li>
                <a href="/#">{t('FOOTER_FAQ')}</a>
              </li>
              <li>
                <a href="/#">Contato</a>
              </li>
              <li>
                <a href="/#">Site Map</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  footer: state.footer
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
