import React from 'react';
import CONSTANTS from '../constants/constants';
import { getTripStatusTranslationString } from '../utils/common';
import { useTranslation } from 'react-i18next';

const SelectTripStatusBox = ({ tripStatus, onStatusChange }) => {
  const { t } = useTranslation();

  const changeValue = ev => {
    onStatusChange(ev.target.value);
  };
  const values = Object.keys(CONSTANTS.TRIP_STATUS);
  const hasDefault = true;
  return (
    <select className="form-control" value={tripStatus} onChange={changeValue}>
      {hasDefault ? <option value="">{t('SELECT_BOX_DEFAULT_VALUE_LABEL')} </option> : null}
      {values.map((currentValue, index) => (
        <option key={index} value={currentValue}>
          {t(getTripStatusTranslationString(currentValue))}
        </option>
      ))}
    </select>
  );
};

export default SelectTripStatusBox;
