import {
  APP_LOAD,
  HOME_PAGE_UNLOADED,
  LOGIN_PAGE_UNLOADED,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  REDIRECT,
  REGISTER_PAGE_UNLOADED,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  SET_ENVIRONMENT,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  UPDATE_TRIP_REQUEST_FIELD,
  RESET_TRIP_REQUEST,
  SHOW_LOGIN_DIALOG,
  HIDE_LOGIN_DIALOG,
  SHOW_TOAST,
  HIDE_TOAST,
  COMMON_USER_UPDATE
} from '../constants/actionTypes';

import ROUTES from '../constants/routes';

const defaultState = {
  appLoaded: false,
  appName: 'Voyagezy',
  currentUser: null,
  redirectTo: null,
  token: null,
  showLoginDialog: false,
  tripRequest: {
    destinations: [],
    startDate: new Date(),
    services: {
      itinerary: true
    },
    preferences: {},
    notAuthenticatedUser: {
      name: '',
      email: ''
    }
  },
  callbackMessage: {
    message: '',
    mtype: 'info',
    serverMessage: ''
  }
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case APP_LOAD:
      return {
        ...state,
        token: action.token || null,
        appLoaded: true,
        currentUser: action.user ? action.user : null,
        isExpertUser: action.user ? action.user.isExpert === true : null
      };
    case REDIRECT:
      return { ...state, redirectTo: null }; // cleans redirectTo after redirection
    case SET_ENVIRONMENT:
      return { ...state, environment: action.value };
    case LOGOUT_SUCCESS:
      return { ...state, redirectTo: ROUTES.LOGIN, token: null, currentUser: null };
    case LOGOUT_FAILURE:
      return { ...state };
    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS:
    case LOGIN_FAILURE:
    case REGISTER_FAILURE:
      return {
        ...state,
        redirectTo: action.error ? null : ROUTES.DASHBOARD,
        token: action.error ? null : action.payload.token,
        currentUser: action.error ? null : action.payload.user,
        isExpertUser: action.error ? null : action.payload.user.isExpert === true
      };
    case LOGOUT_REQUEST:
    case HOME_PAGE_UNLOADED:
    case LOGIN_PAGE_UNLOADED:
    case REGISTER_PAGE_UNLOADED:
      return { ...state };
    case UPDATE_TRIP_REQUEST_FIELD:
      const newTripRequest = { ...state.tripRequest, [action.key]: action.value };
      return { ...state, tripRequest: newTripRequest };
    case RESET_TRIP_REQUEST:
      return { ...state, tripRequest: state.tripRequest };
    case SHOW_LOGIN_DIALOG:
      return { ...state, showLoginDialog: true };
    case HIDE_LOGIN_DIALOG:
      return { ...state, showLoginDialog: false };
    case SHOW_TOAST:
      const callbackMessage = {
        message: action.message,
        mtype: action.mtype
      };
      return { ...state, callbackMessage };
    case HIDE_TOAST:
      return { ...state, callbackMessage: defaultState.callbackMessage };
    case COMMON_USER_UPDATE:
      const updatedCurrentUser = action.payload ? action.payload : null;
      const currentUser = { ...state.currentUser, ...updatedCurrentUser };
      return { ...state, currentUser };
    default:
      return state;
  }
};
