import React from 'react';
import { connect } from 'react-redux';
import TripDayCanvas from './TripDayCanvas';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { moveDay, moveEvent } from '../actions/tripPlans';
import CONSTANTS from '../constants/constants';

const TripCanvasBoard = ({ view, trip, tripPlan, isExpertUser, isTripTemplate, moveDay, moveEvent }) => {
  if (!tripPlan) {
    return null;
  }

  const { startDate, _id: tripId } = trip;
  const { days: tripDays } = tripPlan;

  const onDragEndTripEvent = (eventId, source, destination) => {
    const { droppableId: dayIdFrom, index: positionFrom } = source;
    const { droppableId: dayIdTo, index: positionTo } = destination;
    moveEvent(tripId, dayIdFrom, positionFrom, dayIdTo, positionTo, isTripTemplate);
  };

  const onDragEndTripDay = (dayId, source, destination) => {
    const { index: positionFrom } = source;
    const { index: positionTo } = destination;
    moveDay(tripId, dayId, positionFrom, positionTo, isTripTemplate);
  };

  async function onDragEnd(result) {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    console.log(`Trip Board Canvas has an event ${JSON.stringify(result)}`);

    switch (result.type) {
      case CONSTANTS.DRAGGABLE_TYPES.TRIP_EVENT:
        onDragEndTripEvent(draggableId, source, destination);
        break;

      case CONSTANTS.DRAGGABLE_TYPES.TRIP_DAY:
        onDragEndTripDay(draggableId, source, destination);
        break;

      default:
        console.log(`Unhandled Drag Type found on Drag End ${result.type}`);
        break;
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="trip-canvas-board" isDropDisabled={view} type={CONSTANTS.DRAGGABLE_TYPES.TRIP_DAY} direction={'horizontal'}>
        {provided => (
          <div className="trip-canvas-board" ref={provided.innerRef} {...provided.droppableProps}>
            {(tripDays || []).map((tripDay, index) => {
              return (
                <Draggable
                  key={tripDay._id}
                  draggableId={`${tripDay._id}`}
                  index={index}
                  isDragDisabled={view}
                  type={CONSTANTS.DRAGGABLE_TYPES.TRIP_DAY}
                >
                  {provided => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      <TripDayCanvas
                        view={view}
                        key={index}
                        tripId={tripId}
                        dayIndex={index}
                        day={tripDay}
                        startDate={startDate}
                        isTripTemplate={isTripTemplate}
                        isDragAndDropDisabled={view || !isExpertUser}
                      />
                      {provided.placeholder}
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const mapStateToProps = state => ({
  tripPlan: state.tripPlans.currentTripPlan
});

const mapDispatchToProps = dispatch => ({
  moveDay: (tripId, dayId, positionFrom, positionTo, isTemplate) => dispatch(moveDay(tripId, dayId, positionFrom, positionTo, isTemplate)),
  moveEvent: (tripId, dayIdFrom, positionFrom, dayIdTo, positionTo, isTemplate) =>
    dispatch(moveEvent(tripId, dayIdFrom, positionFrom, dayIdTo, positionTo, isTemplate))
});

export default connect(mapStateToProps, mapDispatchToProps)(TripCanvasBoard);
