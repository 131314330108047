import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import LoginForm from '../containers/LoginForm';
import { connect } from 'react-redux';
import { loginPageUnload, loginPageLoad } from '../actions/auth';
import { useTranslation } from 'react-i18next';

const mapDispatchToProps = dispatch => ({
  onLoad: () => dispatch(loginPageLoad()),
  onUnload: () => dispatch(loginPageUnload())
});

const LoginPage = ({ onLoad, onUnload }) => {
  const { t } = useTranslation();

  useEffect(() => {
    onLoad();

    return () => onUnload();
  }, [onLoad, onUnload]);

  return (
    <div className="container padding-10">
      <div className="row">
        <div className="col-md-6 offset-md-3 col-xs-12">
          <h1 className="text-xs-center">Portal do Admin</h1>
          <LoginForm />
        </div>
      </div>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(LoginPage);
