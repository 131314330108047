import React from 'react';
import { useTranslation } from 'react-i18next';
import { getTripStatusTranslationString } from '../utils/common';
import CONSTANTS from '../constants/constants';
import agent from '../agent';
import moment from 'moment';

const TripStatusTimeline = ({ tripStatus, trip }) => {
  const { t } = useTranslation();
  const language = agent.getLanguage();
  const LOCALE_CONFIGS = CONSTANTS.LOCALE_CONFIGS; // date format according to language chosen
  const currentMomentFormat = LOCALE_CONFIGS[language].momentFormat;

  const tripRequestSubmissionDate = trip.created_at ? new Date(trip.created_at) : undefined;
  const paymentDate = trip && trip.payment ? new Date(trip.payment.date) : undefined;
  const tripValidationDate = trip.validated ? new Date(trip.validated) : undefined;
  const startDate = new Date(trip.startDate);
  const endDate = new Date(trip.endDate);

  const articles = [
    {
      status: CONSTANTS.TRIP_STATUS.TRIP_REQUEST,
      name: getTripStatusTranslationString(CONSTANTS.TRIP_STATUS.TRIP_REQUEST),
      dateFrom: moment(tripRequestSubmissionDate).format(currentMomentFormat)
    },
    {
      status: CONSTANTS.TRIP_STATUS.WAITING_PAYMENT,
      name: getTripStatusTranslationString(CONSTANTS.TRIP_STATUS.WAITING_PAYMENT),
      dateFrom: moment(paymentDate).format(currentMomentFormat)
    },
    {
      status: CONSTANTS.TRIP_STATUS.TRIP_PROCESSING,
      name: getTripStatusTranslationString(CONSTANTS.TRIP_STATUS.TRIP_PROCESSING),
      dateFrom: moment(tripValidationDate).format(currentMomentFormat)
    },
    {
      status: CONSTANTS.TRIP_STATUS.TRIP_VALIDATED,
      name: getTripStatusTranslationString(CONSTANTS.TRIP_STATUS.TRIP_VALIDATED),
      dateFrom: moment(startDate).format(currentMomentFormat),
      dateTo: moment(endDate).format(currentMomentFormat)
    }
  ];

  let currentIndex = 0;
  switch (tripStatus) {
    case CONSTANTS.TRIP_STATUS.TRIP_REQUEST:
    case CONSTANTS.TRIP_STATUS.TRIP_REQUEST_CANCELLED:
    case CONSTANTS.TRIP_STATUS.TRIP_REQUEST_REJECTED:
      currentIndex = 0;
      break;
    case CONSTANTS.TRIP_STATUS.WAITING_PAYMENT:
    case CONSTANTS.TRIP_STATUS.PAYMENT_CANCELLED:
      currentIndex = 1;
      break;
    case CONSTANTS.TRIP_STATUS.TRIP_PROCESSING:
      currentIndex = 2;
      break;
    case CONSTANTS.TRIP_STATUS.TRIP_VALIDATED:
      currentIndex = 3;
      break;
    case CONSTANTS.TRIP_STATUS.TRIP_REVIEW:
      currentIndex = 4;
      break;
    default:
      currentIndex = 0;
  }

  articles.forEach((article, index) => {
    if (index < currentIndex) {
      article.checked = true;
    }
  });

  const articlesLength = articles.length;
  const articleWidth = 100 / (articlesLength - 1) + '%';

  const getTimelineClassName = index => `timeline-article ${index === articlesLength - 1 ? 'last-stage' : ''}`;
  const getStageClassName = (article, index) => {
    const classNames = [];

    classNames.push('stage-unchecked');

    if (article.checked) {
      classNames.push('checked');
    }
    if (index === currentIndex) {
      classNames.push('current');
    }
    if (
      tripStatus === CONSTANTS.TRIP_STATUS.TRIP_REQUEST_CANCELLED ||
      tripStatus === CONSTANTS.TRIP_STATUS.TRIP_REQUEST_REJECTED ||
      tripStatus === CONSTANTS.TRIP_STATUS.PAYMENT_CANCELLED
    ) {
      classNames.push('failed');
    }

    return classNames.join(' ');
  };

  return (
    <div className="status-timeline">
      <div className="center-line"></div>
      {articles.map((article, index) => {
        return (
          <div key={index} className={getTimelineClassName(index)} style={{ width: articleWidth }}>
            <div className="text-content content-top">{t(article.name)}</div>
            <div className="text-content content-bottom">{article.dateFrom}</div>
            <div className="text-content content-bottom2">{article.dateTo}</div>
            <div className={getStageClassName(article, index)}></div>
          </div>
        );
      })}
    </div>
  );
};

export default TripStatusTimeline;
