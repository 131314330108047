import { history } from '../store';
import ROUTES from '../constants/routes';

export const ROUTES_PLACEHOLDERS = {
  DAY_ID: ':dayId',
  EVENT_ID: ':eventId',
  EXPERT_ID: ':expertId',
  PASSWORD_TOKEN: ':token',
  TRIP_ID: ':tripId',
  TRIP_TEMPLATE_ID: ':tripTemplateId'
};

const NavigationService = {
  goToLogin: () => {
    history.push(ROUTES.LOGIN);
  },
  goToTripRequest: () => {
    history.push(ROUTES.TRIP_REQUEST_INFO);
  },
  goToTripRequestConfirmation: () => {
    history.push(ROUTES.TRIP_REQUEST_CONFIRMATION);
  },
  goToTripRequestPickExpert: () => {
    history.push(ROUTES.TRIP_REQUEST_EXPERT);
  },
  goToTripList: () => {
    history.push(ROUTES.TRIP_LIST);
  },
  goToTripTemplateList: () => {
    history.push(ROUTES.TRIP_TEMPLATES);
  },
  goToTripTemplateEdition: tripTemplateId => {
    const tripTemplateEditionUrl = ROUTES.TRIP_TEMPLATE_EDIT.replace(ROUTES_PLACEHOLDERS.TRIP_TEMPLATE_ID, tripTemplateId);

    history.push(tripTemplateEditionUrl);
  },
  goToTripTemplateView: tripTemplateId => {
    const tripTemplateUrl = ROUTES.TRIP_TEMPLATE_VIEW.replace(ROUTES_PLACEHOLDERS.TRIP_TEMPLATE_ID, tripTemplateId);

    history.push(tripTemplateUrl);
  },
  goToTripDayView: (tripId, dayId, eventId, isTripTemplate) => {
    let tripDayViewUrl, tripDayViewUrlPlaceholder;

    if (isTripTemplate) {
      tripDayViewUrlPlaceholder = eventId ? ROUTES.TRIP_TEMPLATE_DAY_EVENT_VIEW : ROUTES.TRIP_TEMPLATE_DAY_VIEW;
      tripDayViewUrl = tripDayViewUrlPlaceholder.replace(ROUTES_PLACEHOLDERS.TRIP_TEMPLATE_ID, tripId);
    } else {
      tripDayViewUrlPlaceholder = eventId ? ROUTES.TRIP_RESUME_DAY_EVENT_VIEW : ROUTES.TRIP_RESUME_DAY_VIEW;
      tripDayViewUrl = tripDayViewUrlPlaceholder.replace(ROUTES_PLACEHOLDERS.TRIP_ID, tripId);
    }

    tripDayViewUrl = tripDayViewUrl.replace(ROUTES_PLACEHOLDERS.DAY_ID, dayId);
    tripDayViewUrl = eventId ? tripDayViewUrl.replace(ROUTES_PLACEHOLDERS.EVENT_ID, eventId) : tripDayViewUrl;
    history.push(tripDayViewUrl);
  },
  goToExpertProfile: expertUserId => {
    const expertProfileUrl = ROUTES.EXPERT_PROFILE.replace(ROUTES_PLACEHOLDERS.EXPERT_ID, expertUserId);

    history.push(expertProfileUrl);
  },
  goToDashboard: tabName => {
    const dashboardRoute = tabName ? `${ROUTES.DASHBOARD}#${tabName}` : `${ROUTES.DASHBOARD}`;
    history.push(dashboardRoute);
  },
  goToTripView: tripId => {
    const tripUrl = ROUTES.TRIP_RESUME_VIEW.replace(ROUTES_PLACEHOLDERS.TRIP_ID, tripId);

    history.push(tripUrl);
  },
  goToTripEdition: tripId => {
    const tripEditionUrl = ROUTES.TRIP_RESUME_EDIT.replace(ROUTES_PLACEHOLDERS.TRIP_ID, tripId);

    history.push(tripEditionUrl);
  },
  goToTripPayment: tripId => {
    const tripPaymentUrl = ROUTES.TRIP_PAYMENT.replace(ROUTES_PLACEHOLDERS.TRIP_ID, tripId);

    history.push(tripPaymentUrl);
  },
  goToManageLocations: () => {
    history.push(`${ROUTES.MANAGE_LOCATIONS}`);
  },
  goToManageDestinations: () => {
    history.push(`${ROUTES.MANAGE_DESTINATIONS}`);
  },
  hideFooter: (currentLocation = '') => {
    return (
      currentLocation.startsWith(`${ROUTES.TRIPS}/`) ||
      currentLocation.startsWith(`${ROUTES.TRIP_TEMPLATES}/`) ||
      currentLocation.startsWith(`${ROUTES.MANAGE_LOCATIONS}`) ||
      currentLocation.startsWith(`${ROUTES.MANAGE_DESTINATIONS}`)
    );
  }
};

export default NavigationService;
