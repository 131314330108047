import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getLocation, getLocationSuccess } from '../actions/locations';
import IconAttractionSwitcher from '../components/IconAttractionSwitcher';
import LocationsMenuFilter from './LocationsMenuFilter';
import Skeleton from 'react-loading-skeleton';

const LocationListItem = ({ item, loadLocation }) => {
  if (!item) {
    return (
      <li>
        <Skeleton height={50} />
        <Skeleton count={2} />
      </li>
    );
  }

  return (
    <li onClick={() => loadLocation(item._id)}>
      {item.isDefault ? <img src="/images/logo_voyagezy_mini.png" alt="Voyagezy Location" className="voyagezy-img" /> : null}
      <img src={item.imageUrl || '/images/default-image-bk.png'} alt={item.name} />
      <div style={{ display: 'table' }}>
        <h3 className="no-margin no-padding">{item.name}</h3>
        <h4>{item.destinationName}</h4>
        <span>
          <IconAttractionSwitcher iconKey={item.locationType} icon={true} tooltip={false} text={true}></IconAttractionSwitcher>
        </span>
      </div>
    </li>
  );
};

const LocationsMenu = ({ locations, inProgress, onGetLocation, onChangeDestinationSearchObject }) => {
  const { t } = useTranslation();

  const [filter, setFilter] = useState(undefined);
  const [filteredLocations, setFilteredLocations] = useState(locations);

  useEffect(() => {
    applyLocationsFilter(filter, locations); // apply filter if there is any active filter
  }, [locations, filter]);

  const applyLocationsFilter = (filter = {}, locations) => {
    const filteredLocations = locations.filter(location => {
      let conditionLocationType = true;
      let conditionDestinationCode = true;
      let conditionName = true;

      if (filter.locationType) {
        conditionLocationType = location.locationType === filter.locationType;
      }
      if (filter.destinationCode) {
        conditionDestinationCode = location.destinationCode === filter.destinationCode;
      }
      if (filter.name) {
        const filterName = filter.name.toLowerCase();
        const locationName = location.name.toLowerCase();
        conditionName = locationName.indexOf(filterName) !== -1;
      }

      return conditionLocationType && conditionDestinationCode && conditionName;
    });

    setFilteredLocations(filteredLocations);
  };

  return (
    <>
      <div className="width-100 top-menu">
        <LocationsMenuFilter onChangeFilter={setFilter} onChangeDestinationSearchObject={onChangeDestinationSearchObject} />
        <hr />
      </div>
      <div className="row main-menu">
        {!inProgress && (!filteredLocations || filteredLocations.length === 0) ? (
          <div className="col-12">{t('MY_LOCATIONS_NO_LOCATIONS')}</div>
        ) : null}
        <div className="col-12 no-margin no-padding thumbnail-list">
          <ul>
            {inProgress ? (
              <>
                <LocationListItem />
                <LocationListItem />
              </>
            ) : (
              (filteredLocations || []).map((location, index) => {
                return <LocationListItem key={index} item={location} loadLocation={onGetLocation} />;
              })
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  inProgress: state.locations.inProgress
});

const mapDispatchToProps = dispatch => ({
  getLocation: locationId => dispatch(getLocation(locationId)),
  getLocationSuccess: location => dispatch(getLocationSuccess(location))
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationsMenu);
