import React from 'react';
import { useTranslation } from 'react-i18next';
import TooltipIcon from './TooltipIcon';

const IconPreferencesItem = ({ iconKey, keyToMatch, active, iconClass, textKey, icon, tooltip, text }) => {
  const { t } = useTranslation();

  if (iconKey !== keyToMatch || !active) {
    return null;
  }

  return (
    <div className="expert-icon-container">
      <TooltipIcon iconClass={`${iconClass} expert-specialist-icon`} textKey={textKey} icon={icon} tooltip={tooltip} />
      {text ? t(textKey) : ''}
    </div>
  );
};

const IconPreferencesSwitcher = ({ iconKey, active, icon, tooltip, text }) => {
  return (
    <>
      <IconPreferencesItem
        iconKey={iconKey}
        keyToMatch="CULTURE"
        iconClass="icon ive-culture"
        textKey="TRIP_INTERESTS_CULTURE"
        active={active}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />

      <IconPreferencesItem
        iconKey={iconKey}
        keyToMatch="NIGHTLIFE"
        iconClass="icon ive-nightlife"
        textKey="TRIP_INTERESTS_NIGHT_LIFE"
        active={active}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />

      <IconPreferencesItem
        iconKey={iconKey}
        keyToMatch="GASTRONOMY"
        iconClass="fa fa-cutlery"
        textKey="TRIP_INTERESTS_GASTRONOMY"
        active={active}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />

      <IconPreferencesItem
        iconKey={iconKey}
        keyToMatch="ADVENTURE"
        iconClass="icon ive-adventure"
        textKey="TRIP_INTERESTS_ADVENTURE"
        active={active}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />

      <IconPreferencesItem
        iconKey={iconKey}
        keyToMatch="BACKPACKER"
        iconClass="icon ive-backpack"
        textKey="TRIP_INTERESTS_BACKPACK"
        active={active}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />

      <IconPreferencesItem
        iconKey={iconKey}
        keyToMatch="MUSEUM"
        iconClass="icon ive-museum"
        textKey="TRIP_INTERESTS_MUSEUMS"
        active={active}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />

      <IconPreferencesItem
        iconKey={iconKey}
        keyToMatch="SHOPPING"
        iconClass="icon ive-shopping"
        textKey="TRIP_INTERESTS_SHOPPING"
        active={active}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />

      <IconPreferencesItem
        iconKey={iconKey}
        keyToMatch="PHOTOGRAPHY"
        iconClass="icon ive-photographer"
        textKey="TRIP_INTERESTS_PHOTOGRAPHY"
        active={active}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />

      <IconPreferencesItem
        iconKey={iconKey}
        keyToMatch="GUIDED_TOUR"
        iconClass="icon ive-guide"
        textKey="TRIP_INTERESTS_TOURS"
        active={active}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />

      <IconPreferencesItem
        iconKey={iconKey}
        keyToMatch="PET"
        iconClass="icon ive-animal"
        textKey="TRIP_INTERESTS_PETS"
        active={active}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />

      <IconPreferencesItem
        iconKey={iconKey}
        keyToMatch="BIKE_TOUR"
        iconClass="icon ive-bike"
        textKey="TRIP_INTERESTS_BIKE"
        active={active}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />

      <IconPreferencesItem
        iconKey={iconKey}
        keyToMatch="SPORT"
        iconClass="icon ive-sport"
        textKey="TRIP_INTERESTS_SPORTS"
        active={active}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />
    </>
  );
};

export default IconPreferencesSwitcher;
