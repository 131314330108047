import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TripTemplateListItem from '../components/TripTemplateListItem';
import { connect } from 'react-redux';
import ShowError from '../components/ShowError';
import { getTripTemplates, deleteTripTemplate, createTripTemplate } from '../actions/tripTemplates';
import NavigationService from '../services/navigation';

const TripTemplateListPage = ({
  tripTemplates,
  error,
  inProgress,
  getTripTemplates,
  deleteTripTemplate,
  createTripTemplate,
  currentTripTemplate
}) => {
  const { t } = useTranslation();

  const createTemplate = async event => {
    event.stopPropagation();
    currentTripTemplate.name = !currentTripTemplate.name ? t('TRIP_TEMPLATE_DEFAULT_NAME') : !currentTripTemplate.name;
    const newTripTemplate = await createTripTemplate(currentTripTemplate);
    NavigationService.goToTripTemplateEdition(newTripTemplate._id);
  };

  // Passing an empty array as the second argument to useEffect makes it only run on mount and unmount, thus stopping any infinite loops.
  useEffect(() => {
    const onLoad = async () => {
      await getTripTemplates();
    };

    onLoad();
  }, [getTripTemplates]);

  const renderTripTemplateListButtons = () => {
    return (
      <div className="padding-10">
        <button type="button" className="btn btn-primary" onClick={createTemplate}>
          {t('BUTTON_CREATE_TEMPLATE')}
        </button>
      </div>
    );
  };

  return (
    <div className="container padding-10">
      {renderTripTemplateListButtons()}
      <div className="width-100">
        <ShowError error={error} />
        <div>
          <ul className="list-unstyled">
            {inProgress ? (
              <>
                <li>
                  <TripTemplateListItem />
                </li>
                <li>
                  <TripTemplateListItem />
                </li>
              </>
            ) : (
              tripTemplates.map((tripTemplate, index) => {
                return (
                  <li key={index}>
                    <TripTemplateListItem tripTemplate={tripTemplate} deleteTripTemplate={deleteTripTemplate}></TripTemplateListItem>
                  </li>
                );
              })
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  tripTemplates: state.tripTemplates.tripTemplates,
  currentTripTemplate: state.tripTemplates.currentTripTemplate,
  inProgress: state.tripTemplates.inProgress,
  error: state.tripTemplates.error
});

const mapDispatchToProps = dispatch => ({
  getTripTemplates: () => dispatch(getTripTemplates()),
  deleteTripTemplate: tripTemplateId => dispatch(deleteTripTemplate(tripTemplateId)),
  createTripTemplate: currentTripTemplate => dispatch(createTripTemplate(currentTripTemplate))
});

export default connect(mapStateToProps, mapDispatchToProps)(TripTemplateListPage);
