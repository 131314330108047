import React from 'react';
import { connect } from 'react-redux';
import { hideLoginDialogAction } from '../actions/modals';
import { useTranslation } from 'react-i18next';
import LoginForm from './LoginForm';
import { Modal } from 'react-bootstrap';
import { Button } from '../components/Custom';

const LoginDialogModal = ({ showLoginDialog, onCloseModal }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    onCloseModal();
  };

  return (
    <div>
      <Modal
        id="loginDialogModal"
        size="lg"
        animation="true"
        aria-labelledby="example-modal-sizes-title-lg"
        aria-describedby="example-modal-sizes-title-lg"
        show={showLoginDialog}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title>
            <h2>{t('LOGIN_LOGIN')}</h2>
            <Button as="a" className="a-button float-right" onClick={handleClose}>
              <i className="material-icons">close</i>
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body" id="modal-body">
          <div className="width-100">
            <div className="row">
              <div className="col-12">
                {' '}
                <LoginForm isDialogModal={true} />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  showLoginDialog: state.common.showLoginDialog
});

const mapDispatchToProps = dispatch => ({
  onCloseModal: () => hideLoginDialogAction()
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginDialogModal);
