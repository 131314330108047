import React from 'react';
import SelectCurrencyBox from './SelectCurrencyBox';
import CONSTANTS from '../constants/constants';

const PriceFields = ({ price, currencyCode, disable, disableCurrency, setPrice, setCurrency }) => {
  if (!currencyCode && setCurrency) {
    setCurrency(CONSTANTS.DEFAULT_CURRENCY_CODE);
  }

  const onCurrencyChange = selectedCurrency => {
    setCurrency(selectedCurrency);
  };

  const checkAmount = event => {
    const newPrice = event.target.value;
    const newPriceNumber = +newPrice;
    // user can be still typing number: '17.' or '17,'
    if (typeof newPriceNumber !== 'number') {
      return;
    }
    setPrice(newPriceNumber);
  };

  return (
    <div className="row">
      <div className="col-6 price-value">
        <input
          type="number"
          className="form-control textbox"
          placeholder="0,00"
          value={price || 0}
          min="0"
          step="0.01"
          disabled={disable}
          onChange={checkAmount}
        />
      </div>
      <div className="col-6 price-currency">
        <SelectCurrencyBox currency={currencyCode} disable={disable || disableCurrency} onCurrencyChange={onCurrencyChange} />
      </div>
    </div>
  );
};

export default PriceFields;
