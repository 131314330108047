import {
  USER_RESET,
  UPDATE_CURRENT_USER_FIELD,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAILURE,
  USER_GET_REQUEST,
  USER_GET_SUCCESS,
  USER_GET_FAILURE,
  USER_PUT_CHANGE_PASSWORD_REQUEST,
  USER_PUT_CHANGE_PASSWORD_SUCCESS,
  USER_PUT_CHANGE_PASSWORD_FAILURE,
  USER_PUT_PROFILE_IMAGE_REQUEST,
  USER_PUT_PROFILE_IMAGE_SUCCESS,
  USER_PUT_PROFILE_IMAGE_FAILURE,
  USER_PUT_REQUEST,
  USER_PUT_SUCCESS,
  USER_PUT_FAILURE,
  USER_PAYMENTS_GET_REQUEST,
  USER_PAYMENTS_GET_SUCCESS,
  USER_PAYMENTS_GET_FAILURE
} from '../constants/actionTypes';

const defaultState = {
  message: '',
  currentUser: {
    firstname: '', // many of these are here to avoid uncontrolled input warning
    lastname: '',
    nationality: '',
    birthDate: '',
    telephone: '',
    address: {
      street: '',
      zipCode: '',
      city: ''
    },
    paymentData: {},
    blog: {
      name: '',
      url: ''
    },
    social: {
      facebook: '',
      twitter: '',
      instagram: '',
      youtube: '',
      snapchat: ''
    },
    specialities: {},
    destinations: [],
    account: {},
    pitchDescription: '',
    summary: '',
    servicesCurrencyCode: ''
  },
  userPayments: [],
  inProgress: false,
  error: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case USER_RESET:
      return {
        ...state,
        currentUser: defaultState.currentUser
      };
    case UPDATE_CURRENT_USER_FIELD:
      const updatedcurrentUser = { ...state.currentUser, [action.key]: action.value };
      return {
        ...state,
        currentUser: updatedcurrentUser
      };

    case USER_PUT_CHANGE_PASSWORD_REQUEST:
    case USER_DELETE_REQUEST:
    case USER_GET_REQUEST:
    case USER_PAYMENTS_GET_REQUEST:
    case USER_PUT_REQUEST:
    case USER_PUT_PROFILE_IMAGE_REQUEST:
      return { ...state, inProgress: true };
    case USER_GET_SUCCESS:
    case USER_PUT_PROFILE_IMAGE_SUCCESS:
    case USER_PUT_SUCCESS:
      let currentUser = action.payload ? action.payload : null;
      currentUser = { ...defaultState.currentUser, ...currentUser }; /// some fields that are 'object' types might be missing and this avoids undefined errors

      return {
        ...state,
        inProgress: false,
        error: null,
        message: action.message ? action.message : null,
        currentUser
      };
    case USER_PAYMENTS_GET_SUCCESS:
      return {
        ...state,
        inProgress: false,
        error: null,
        message: action.message ? action.message : null,
        userPayments: action.payload ? action.payload : defaultState.userPayments
      };

    case USER_PUT_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        inProgress: false,
        error: null,
        message: action.message ? action.message : null
      };
    case USER_DELETE_SUCCESS:
      return {
        ...state,
        inProgress: false,
        error: null,
        message: action.message ? action.message : null,
        user: defaultState.currentUser
      };

    case USER_PUT_CHANGE_PASSWORD_FAILURE:
    case USER_PUT_PROFILE_IMAGE_FAILURE:
    case USER_DELETE_FAILURE:
    case USER_GET_FAILURE:
    case USER_PAYMENTS_GET_FAILURE:
    case USER_PUT_FAILURE:
      return {
        ...state,
        inProgress: false,
        message: [],
        error: action.error ? action.error : null
      };
    default:
      return state;
  }
};
