import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getDestination, getDestinationSuccess } from '../actions/destinations';
import DestinationsMenuFilter from './DestinationsMenuFilter';
import Skeleton from 'react-loading-skeleton';

const DestinationListItem = ({ item, loadDestination }) => {
  if (!item) {
    return (
      <li>
        <Skeleton height={50} />
        <Skeleton count={2} />
      </li>
    );
  }

  return (
    <li onClick={() => loadDestination(item._id)}>
      {item.isDefault ? <img src="/images/logo_voyagezy_mini.png" alt="Voyagezy Destination" className="voyagezy-img" /> : null}
      <img src={item.imageUrl || '/images/default-image-bk.png'} alt={item.name} />
      <div style={{ display: 'table' }}>
        <h3 className="no-margin no-padding">{item.name}</h3>
        <h4>{item.destinationName}</h4>
        <span className="badge badge-info">{item.destinationType} </span>
        <span> {item.search_code}</span>
      </div>
    </li>
  );
};

const DestinationsMenu = ({ destinations, inProgress, onGetDestination }) => {
  const [filter, setFilter] = useState(undefined);
  const [filteredDestinations, setFilteredDestinations] = useState(destinations);

  useEffect(() => {
    applyDestinationsFilter(filter, destinations); // apply filter if there is any active filter
  }, [destinations, filter]);

  const applyDestinationsFilter = (filter = {}, destinations) => {
    const filteredDestinations = destinations.filter(destination => {
      let conditionDestinationType = true;
      let conditionNameOrCode = true;

      if (filter.destinationType) {
        conditionDestinationType = destination.destinationType === filter.destinationType;
      }
      if (filter.name) {
        const filterName = filter.name.toLowerCase();
        const destinationName = destination.name.toLowerCase();
        const destinationCode = destination.search_code.toLowerCase();
        conditionNameOrCode = destinationName.indexOf(filterName) !== -1 || destinationCode.indexOf(filterName) !== -1;
      }

      return conditionDestinationType && conditionNameOrCode;
    });

    setFilteredDestinations(filteredDestinations);
  };

  return (
    <>
      <div className="width-100 top-menu">
        <DestinationsMenuFilter onChangeFilter={setFilter} />
        <hr />
      </div>
      <div className="row main-menu">
        {!inProgress && (!filteredDestinations || filteredDestinations.length === 0) ? (
          <div className="col-12">No destinations added.</div>
        ) : null}
        <div className="col-12 no-margin no-padding thumbnail-list">
          <ul>
            {inProgress ? (
              <>
                <DestinationListItem />
                <DestinationListItem />
              </>
            ) : (
              (filteredDestinations || []).map((destination, index) => {
                return <DestinationListItem key={index} item={destination} loadDestination={onGetDestination} />;
              })
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  inProgress: state.destinations.inProgress
});

const mapDispatchToProps = dispatch => ({
  getDestination: destinationId => dispatch(getDestination(destinationId)),
  getDestinationSuccess: destination => dispatch(getDestinationSuccess(destination))
});

export default connect(mapStateToProps, mapDispatchToProps)(DestinationsMenu);
