import React from 'react';

const NotFound = () => {
  return (
    <div className="container padding-10">
      <div className="row">
        <p>Not Found</p>
      </div>
    </div>
  );
};

export default NotFound;
