import React from 'react';
import IconPreferencesSwitcher from './IconPreferencesSwitcher';

const IconLine = ({ keys = {} }) => {
  return (
    <>
      <IconPreferencesSwitcher iconKey={'CULTURE'} active={keys.culture} icon={true} tooltip={true} text={false}></IconPreferencesSwitcher>
      <IconPreferencesSwitcher iconKey={'NIGHTLIFE'} active={keys.nightlife} icon={true} tooltip={true} text={false}></IconPreferencesSwitcher>
      <IconPreferencesSwitcher iconKey={'GASTRONOMY'} active={keys.gastronomy} icon={true} tooltip={true} text={false}></IconPreferencesSwitcher>
      <IconPreferencesSwitcher iconKey={'ADVENTURE'} active={keys.adventure} icon={true} tooltip={true} text={false}></IconPreferencesSwitcher>
      <IconPreferencesSwitcher iconKey={'BACKPACKER'} active={keys.backpacker} icon={true} tooltip={true} text={false}></IconPreferencesSwitcher>
      <IconPreferencesSwitcher iconKey={'MUSEUM'} active={keys.museum} icon={true} tooltip={true} text={false}></IconPreferencesSwitcher>
      <IconPreferencesSwitcher iconKey={'SHOPPING'} active={keys.shopping} icon={true} tooltip={true} text={false}></IconPreferencesSwitcher>
      <IconPreferencesSwitcher iconKey={'PHOTOGRAPHY'} active={keys.photography} icon={true} tooltip={true} text={false}></IconPreferencesSwitcher>
      <IconPreferencesSwitcher iconKey={'GUIDED_TOUR'} active={keys.guided_tour} icon={true} tooltip={true} text={false}></IconPreferencesSwitcher>
      <IconPreferencesSwitcher iconKey={'PET'} active={keys.pet} icon={true} tooltip={true} text={false}></IconPreferencesSwitcher>
      <IconPreferencesSwitcher iconKey={'BIKE_TOUR'} active={keys.bike_tour} icon={true} tooltip={true} text={false}></IconPreferencesSwitcher>
      <IconPreferencesSwitcher iconKey={'SPORT'} active={keys.sport} icon={true} tooltip={true} text={false}></IconPreferencesSwitcher>
    </>
  );
};

export default IconLine;
