import socketIOClient from 'socket.io-client';
import agent from '../agent'

const ENDPOINT = agent.getApiRoot();

// TODO insecure way: token should be passed here
const socket = socketIOClient(ENDPOINT);

const socketClient = {
  on: function (eventName, callback) {
    socket.on(eventName, data => {
      if (typeof callback === 'function') {
        callback(data);
      }
    });
  },
  emit: function (eventName, data) {
    socket.emit(eventName, data);
  }
};

export default socketClient;
