import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NavigationService from '../services/navigation';
import ChangeRequestModal from '../containers/ChangeRequestModal';
import ReviewExpertModal from '../components/ReviewExpertModal';
import CONSTANTS from '../constants/constants';
import { changeTravelerReadPermission, changeTripStatus, changeTripStatusState } from '../actions/trips';
import ToggleButton from '../components/ToggleButton';
import { CustomTooltip } from '../components/Custom';
import { Button } from '../components/Custom';

const TripActionsButtons = ({ trip, isExpertUser, iconVersion, changeTripStatus, changeTripStatusState }) => {
  const { t } = useTranslation();

  const { _id: tripId, status: tripStatus, tripProcessingStatusState } = trip;

  const [showChangeRequestModal, setShowChangeRequestModal] = useState(false);
  const [showExpertReviewModal, setShowExpertReviewModal] = useState(false);

  const changeTripProcessingStatusState = (tripId, toStatusState) => async ev => {
    await changeTripStatusState(tripId, CONSTANTS.TRIP_STATUS.TRIP_PROCESSING, toStatusState);
  };

  /* Toggles read Trip Plan Permission for Regular User. When forceChange value is not specified, toggles the current value*/
  const onToggleChange = async ev => {
    const toStatusState =
      tripProcessingStatusState === CONSTANTS.TRIP_STATUS_STATES.TRIP_PROCESSING_WORK_DONE
        ? CONSTANTS.TRIP_STATUS_STATES.TRIP_PROCESSING_WORK_IN_PROGRESS
        : CONSTANTS.TRIP_STATUS_STATES.TRIP_PROCESSING_WORK_DONE;
    await changeTripStatusState(tripId, CONSTANTS.TRIP_STATUS.TRIP_PROCESSING, toStatusState);
  };

  const renderButton = (condition, execFn, labelKey, iconClass, hide) => {
    if (!condition || hide) {
      return;
    }

    if (iconVersion) {
      return (
        <div className="button-as-icon">
          <Button as="a" className="a-button" onClick={execFn}>
            <CustomTooltip text={t(labelKey)} isKey={true}>
              <i className={`fa fa-2x ${iconClass}`} title={t(labelKey)} aria-hidden="true"></i>
            </CustomTooltip>
          </Button>
        </div>
      );
    }

    return (
      <button type="button" className="btn btn-primary" onClick={execFn}>
        {t(labelKey)}
      </button>
    );
  };

  const renderToggleButton = condition => {
    if (!condition) {
      return;
    }

    return (
      <ToggleButton
        sharedTripPlanToggle={tripProcessingStatusState === CONSTANTS.TRIP_STATUS_STATES.TRIP_PROCESSING_WORK_DONE}
        labelActive={t('TOGGLE_BUTTON_SHARE')}
        labelHidden={t('TOGGLE_BUTTON_SHARE')}
        tooltipText={t('TOGGLE_BUTTON_SHARE_TRIP_PLAN_TOOLTIP')}
        onToggleChange={onToggleChange}
      />
    );
  };

  // expert actions
  const acceptTripRequest = tripId => ev => {
    ev.stopPropagation();
    changeTripStatus(tripId, CONSTANTS.TRIP_STATUS.WAITING_PAYMENT);
  };

  const rejectTripRequest = tripId => ev => {
    ev.stopPropagation();
    changeTripStatus(tripId, CONSTANTS.TRIP_STATUS.TRIP_REQUEST_REJECTED);
  };
  const editTrip = tripId => ev => {
    ev.stopPropagation();
    NavigationService.goToTripEdition(tripId);
  };

  // traveler actions
  const editTripRequest = tripId => ev => {
    alert('Trip Request Edition Not Available Yet.');
  };

  const cancelTripRequest = tripId => ev => {
    const confirmWindow = window.confirm(t('CONFIRM_CANCEL_TRIP_REQUEST'));
    if (confirmWindow === false) {
      return;
    }
    changeTripStatus(tripId, CONSTANTS.TRIP_STATUS.TRIP_REQUEST_CANCELLED);
  };

  const goToPaymentPage = tripId => ev => {
    NavigationService.goToTripPayment(tripId);
  };
  const cancelPayment = tripId => ev => {
    const confirmWindow = window.confirm(t('CONFIRM_CANCEL_PAYMENT'));
    if (confirmWindow === false) {
      return;
    }
    changeTripStatus(tripId, CONSTANTS.TRIP_STATUS.PAYMENT_CANCELLED);
  };
  const validateTrip = tripId => ev => {
    changeTripStatus(tripId, CONSTANTS.TRIP_STATUS.TRIP_VALIDATED);
  };
  const reviewExpert = tripId => ev => {
    openReviewModal(tripId);
  };

  const goToMessages = event => {
    event.stopPropagation();
    NavigationService.goToMessages();
  };

  const openChangeRequestModal = ev => {
    setShowChangeRequestModal(true);
  };

  const onCloseChangeRequestModal = () => {
    setShowChangeRequestModal(false);
  };

  const openReviewModal = () => {
    setShowExpertReviewModal(true);
  };

  const onCloseReviewModal = () => {
    setShowExpertReviewModal(false);
  };

  return (
    <>
      {/*<!-- Status TRIP_REQUEST-->*/}
      {renderButton(
        tripStatus === CONSTANTS.TRIP_STATUS.TRIP_REQUEST && isExpertUser,
        acceptTripRequest(tripId),
        'BUTTON_ACCEPT_REQUEST',
        'fa-check-circle-o'
      )}
      {renderButton(
        tripStatus === CONSTANTS.TRIP_STATUS.TRIP_REQUEST && isExpertUser,
        rejectTripRequest(tripId),
        'BUTTON_REJECT_REQUEST',
        'fa-times-circle-o'
      )}
      {renderButton(
        tripStatus === CONSTANTS.TRIP_STATUS.TRIP_REQUEST && !isExpertUser,
        editTripRequest(tripId),
        'BUTTON_EDIT_REQUEST',
        'fa-pencil-square-o'
      )}
      {renderButton(
        tripStatus === CONSTANTS.TRIP_STATUS.TRIP_REQUEST && !isExpertUser,
        cancelTripRequest(tripId),
        'BUTTON_CANCEL',
        'fa-times-circle-o'
      )}
      {/*<!-- Status WAITING_PAYMENT-->*/}
      {renderButton(
        tripStatus === CONSTANTS.TRIP_STATUS.WAITING_PAYMENT && !isExpertUser,
        editTripRequest(tripId),
        'BUTTON_EDIT_REQUEST',
        'fa-pencil-square-o'
      )}
      {renderButton(tripStatus === CONSTANTS.TRIP_STATUS.WAITING_PAYMENT && !isExpertUser, goToPaymentPage(tripId), 'BUTTON_PAY', 'fa-credit-card')}
      {renderButton(
        tripStatus === CONSTANTS.TRIP_STATUS.WAITING_PAYMENT && !isExpertUser,
        cancelPayment(tripId),
        'BUTTON_CANCEL',
        'fa-times-circle-o'
      )}
      {/*<!-- Status TRIP_PROCESSING-->*/}
      {tripStatus === CONSTANTS.TRIP_STATUS.TRIP_PROCESSING ? (
        <>
          {renderButton(isExpertUser, editTrip(tripId), 'BUTTON_EDIT_TRIP', 'fa-pencil-square-o')}
          {renderButton(
            tripProcessingStatusState === CONSTANTS.TRIP_STATUS_STATES.TRIP_PROCESSING_WORK_IN_PROGRESS && isExpertUser,
            changeTripProcessingStatusState(tripId, CONSTANTS.TRIP_STATUS_STATES.TRIP_PROCESSING_WORK_DONE),
            'BUTTON_SEND_FOR_APPROVAL',
            'fa-check',
            true
          )}
          {renderButton(
            tripProcessingStatusState === CONSTANTS.TRIP_STATUS_STATES.TRIP_PROCESSING_WORK_DONE && isExpertUser,
            changeTripProcessingStatusState(tripId, CONSTANTS.TRIP_STATUS_STATES.TRIP_PROCESSING_WORK_IN_PROGRESS),
            'BUTTON_REMOVE_APPROVAL_PERMISSION',
            'fa-thumbs-o-up',
            true
          )}
          {renderButton(
            tripProcessingStatusState === CONSTANTS.TRIP_STATUS_STATES.TRIP_PROCESSING_WORK_DONE && !isExpertUser,
            validateTrip(tripId),
            'BUTTON_VALIDATE_TRIP',
            'fa-check-square-o',
            true
          )}
          {renderButton(!isExpertUser, openChangeRequestModal, 'BUTTON_CHANGE_REQUEST', 'fa-share-square-o')}
          {renderToggleButton(isExpertUser)}
        </>
      ) : null}
      {/*<!-- Status TRIP_VALIDATED-->*/}
      {renderButton(tripStatus === CONSTANTS.TRIP_STATUS.TRIP_VALIDATED && isExpertUser, editTrip(tripId), 'BUTTON_EDIT_TRIP', 'fa-pencil-square-o')}
      {/*<!-- Status TRIP_REVIEW or TRIP_VALIDATED-->*/}
      {renderButton(
        (tripStatus === CONSTANTS.TRIP_STATUS.TRIP_PROCESSING || tripStatus === CONSTANTS.TRIP_STATUS.TRIP_VALIDATED) && !isExpertUser,
        reviewExpert(tripId),
        'BUTTON_SUBMIT_REVIEW',
        'fa-star-o'
      )}

      <button type="button" className="btn btn-primary d-none" onClick={goToMessages}>
        {t('BUTTON_MESSAGES')}
      </button>
      <ChangeRequestModal
        isExpertUser={isExpertUser}
        conversationId={trip.conversation}
        showChangeRequestModal={showChangeRequestModal}
        onCloseModal={onCloseChangeRequestModal}
      />
      <ReviewExpertModal tripId={tripId} showExpertReviewModal={showExpertReviewModal} onCloseModal={onCloseReviewModal} />
    </>
  );
};

const mapStateToProps = state => ({
  //  trip: state.trips.currentTripResume,
});

const mapDispatchToProps = dispatch => ({
  changeTripStatus: (tripResumeId, toStatus) => dispatch(changeTripStatus(tripResumeId, toStatus)),
  changeTripStatusState: (tripId, status, toStatusState) => dispatch(changeTripStatusState(tripId, status, toStatusState)),
  changeTravelerReadPermission: (tripId, shareTripPlanToggle) => dispatch(changeTravelerReadPermission(tripId, shareTripPlanToggle))
});

export default connect(mapStateToProps, mapDispatchToProps)(TripActionsButtons);
