import React from 'react';
import { useTranslation } from 'react-i18next';
import CONSTANTS from '../constants/constants';

const SelectTripEventKindBox = ({ currentEventKind, disabled, hasDirectionsOption, onValueChange }) => {
  const { t } = useTranslation();

  const changeValue = ev => {
    onValueChange(ev.target.value);
  };

  const eventTypes = [
    {
      value: CONSTANTS.TRIP_EVENT_KINDS.ATTRACTION,
      label: t('EVENT_KIND_ATTRACTION')
    },
    {
      value: CONSTANTS.TRIP_EVENT_KINDS.ESTABLISHMENT,
      label: t('EVENT_KIND_ESTABLISHMENT')
    },
    {
      value: CONSTANTS.TRIP_EVENT_KINDS.ACCOMMODATION,
      label: t('EVENT_KIND_ACCOMMODATION')
    }
  ];

  if (hasDirectionsOption) {
    eventTypes.push({
      value: CONSTANTS.TRIP_EVENT_KINDS.DIRECTIONS,
      label: t('EVENT_KIND_DIRECTIONS')
    });
  }

  return (
    <select className="form-control" value={currentEventKind} onChange={changeValue} disabled={disabled} required>
      {eventTypes.map((eventType, index) => (
        <option key={index} value={eventType.value}>
          {' '}
          {eventType.label}
        </option>
      ))}
    </select>
  );
};

export default SelectTripEventKindBox;
