import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getDestination, getDestinations, getDestinationSuccess } from '../actions/destinations';
import DestinationsMenu from '../containers/DestinationsMenu';
import DestinationForm from '../containers/DestinationForm';
import CONSTANTS from '../constants/constants';

const ManageDestinationsPage = ({ destinations, getDestination, getDestinations }) => {
  const { t } = useTranslation();

  const fetchDestinations = useCallback(async () => {
    await getDestinations();
  }, [getDestinations]);

  useEffect(() => {
    fetchDestinations();
  }, [fetchDestinations]);

  const [destinationName, setDestinationName] = useState('');
  const [searchPlace, setSearchPlace] = useState(null);

  const onChangeDestinationSearchObject = destinationSearch => {
    const destinationSearchName = destinationSearch.code ? destinationSearch.name : null;
    setDestinationName(destinationSearchName);
  };

  const isDuplicateDestination = destination => {
    const [foundDestination] = destinations.filter(
      dest => dest._id !== destination._id && dest.search_code === destination.search_code
    );
    const elementFound = !!foundDestination;

    return elementFound;
  };

  // clear existing value on search field
  const clearPlacesField = () => {
    const input = document.getElementById(CONSTANTS.SEARCH_PLACES_BOX_ID);
    input.value = '';
  };

  const onGetDestination = async destinationId => {
    const destination = await getDestination(destinationId);
    const { _id: id, latitude, longitude } = destination;
    const searchPlace = {
      id,
      latitude,
      longitude
    };
    setSearchPlace(searchPlace);
    clearPlacesField();
  };

  return (
    <div className="container my-locations">
      <div className="row">
        <div className="col-4 location-list">
          <DestinationsMenu destinations={destinations} onGetDestination={onGetDestination} onChangeDestinationSearchObject={onChangeDestinationSearchObject} />
        </div>
        <div className="col-8 location-form">
          <h3>
            {t('DESTINATION_MAIN_TITLE')} <span className="event-destination-text">{destinationName ? `(${destinationName})` : null}</span> :
          </h3>
          <DestinationForm isDuplicateDestination={isDuplicateDestination} searchPlace={searchPlace} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  destinations: state.destinations.destinations,
  currentDestination: state.destinations.currentDestination,
});

const mapDispatchToProps = dispatch => ({
  getDestinations: () => dispatch(getDestinations()),
  getDestination: destinationId => dispatch(getDestination(destinationId)),
  getDestinationSuccess: destination => dispatch(getDestinationSuccess(destination))
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageDestinationsPage);
