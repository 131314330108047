import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getTripRequests } from '../actions/tripRequest';
import InProgress from '../components/InProgress';
import { getTripStatusTranslationString } from '../utils/common';

const ManageTripsRequestsPage = ({ allTrips, inProgress, getTripRequests, environmentUrl }) => {
  const { t } = useTranslation();

  // Wrap with useCallback to avoid change on every render. It returns a memoized version of the callback that only changes if one of the provided dependencies change.
  const fetchAllTripRequests = useCallback(async () => {
    await getTripRequests();
  }, [getTripRequests]);

  useEffect(() => {
    fetchAllTripRequests();
  }, [fetchAllTripRequests]);

  return (
    <div className="container padding-10">
      <div className="row fadein-container">
        <div>
          <h2>Manage Trip Requests</h2>
        </div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>{t('DASHBOARD_REGISTER_EXPERT_ID')}</th>
              <th>Expert User</th>
              <th>Regular User</th>
              <th>Status</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>
            {(allTrips || []).map((tripRequest, index) => {
              const { _id, message, status, notAuthenticatedUser } = tripRequest;
              return (
                <tr key={index}>
                  <td>
                    <span>{_id} </span>
                    <span>
                      <a href={`${environmentUrl}/trips/${_id}`} target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-external-link"></i>
                      </a>
                    </span>
                  </td>
                  <td>{notAuthenticatedUser.expertUsername}</td>
                  <td>{notAuthenticatedUser.email} - {notAuthenticatedUser.name}</td>
                  <td>{t(getTripStatusTranslationString(status))}</td>
                  <td>{message}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <InProgress inProgress={inProgress} />
      {!inProgress && allTrips.length === 0 ? <div>{t('TRIP_RESUME_LIST_RETRIVAL_ERROR')}</div> : null}
    </div>
  );
};

const mapStateToProps = state => ({
  allTrips: state.tripRequest.tripRequests,
  inProgress: state.tripRequest.inProgress,
  environmentUrl: state.common.environment ? state.common.environment.url : '',
});

const mapDispatchToProps = dispatch => ({
  getTripRequests: () => dispatch(getTripRequests()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageTripsRequestsPage);
