import React from 'react';
import { CustomTooltip } from './Custom';

const IconGroupButton = ({ index, isActive, label, iconClass }) => {
  const iconType = `icon ${iconClass} fa-lg `;
  const isActiveClassName = `${isActive ? 'default-theme-text-color' : 'text-light-2 fade-opacity'} `;
  const className = `${iconType} ${isActiveClassName}`;

  return (
    <button type="button" className="btn btn-outline-primary full-opacity" disabled>
      <CustomTooltip text={label} isKey={true}>
        <i className={className} aria-hidden="true"></i>
      </CustomTooltip>
    </button>
  );
};

export default IconGroupButton;
