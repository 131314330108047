import React from 'react';
import CONSTANTS from '../constants/constants';
import { convertTimestampToHourOfTheDayVerbose, convertTimestampToHourOfTheDay } from '../utils/dateUtils';
import { useTranslation } from 'react-i18next';

const SelectTimeBox = ({ selectedTime, isDuration, hasDefault, onTimeChange }) => {
  const { t } = useTranslation();

  const updateTime = ev => {
    const timeValue = parseInt(ev.target.value);
    onTimeChange(timeValue);
  };

  const getDayTimes = () => {
    const dayTimes = [];

    let time = 0;
    while (time < CONSTANTS.TIME.DAY) {
      const dayTime = {
        value: time,
        label: isDuration ? convertTimestampToHourOfTheDayVerbose(time) : convertTimestampToHourOfTheDay(time)
      };
      dayTimes.push(dayTime);
      time += CONSTANTS.TIME.TIME_STEP;
    }
    return dayTimes;
  };

  const dayTimes = getDayTimes();

  return (
    <select className="form-control" value={selectedTime} onChange={updateTime}>
      {hasDefault ? <option value="">{t('SELECT_BOX_DEFAULT_VALUE_LABEL')} </option> : null}
      {dayTimes.map((dayTime, index) => (
        <option key={index} value={dayTime.value}>
          {dayTime.label}
        </option>
      ))}
    </select>
  );
};

export default SelectTimeBox;
