import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formatDateCustom } from '../utils/dateUtils';
import InProgress from '../components/InProgress';
import { postMessage } from '../actions/messages';
import CONSTANTS from '../constants/constants';
import sockets from '../services/socketFactory';

const WEB_SOCKETS = {
  SEND_MESSAGE: 'send:message',
  ACKNOWLEDGE_READ_MESSAGE: 'acknowledge:read-message',
  PRIVATE_SOCKET_NAME_PREFIX: 'chat-',
  PRIVATE_SOCKET_ERROR_SUFFIX: ':error'
};

const MessageItem = ({ message, isExpertUser, currentConversation }) => {
  const { t } = useTranslation();

  const personKind = isExpertUser ? CONSTANTS.USER_MODEL_KIND.EXPERT : CONSTANTS.USER_MODEL_KIND.REGULAR;
  const personModelKey = isExpertUser ? 'regularUser' : 'expertUser';
  const chatPerson = currentConversation && currentConversation[personModelKey] ? currentConversation[personModelKey] : null;
  const chatPersonImage = chatPerson ? chatPerson.imageUrl : null;

  const { timestamp, isChangeRequestMessage, text } = message;

  return (
    <div className={personKind === message.personKind ? 'message toright' : 'message'}>
      {personKind !== message.personKind ? (
        <img src={chatPersonImage || '/images/default-user-bk.png'} className="chat-img float-left" alt="User" />
      ) : null}
      <div className="message-content">
        {isChangeRequestMessage === true ? <span className="badge badge-danger">{t('DASHBOARD_MESSAGES_CHANGE_REQUEST')}</span> : null}
        {text}
      </div>
      <div className="date">{formatDateCustom(timestamp, 'DD/MM/YYYY HH:mm')}</div>
    </div>
  );
};

const MessageBox = ({ sendMessage, isDisabled }) => {
  const { t } = useTranslation();

  const [message, setMessage] = useState('');

  const onMessageChange = ev => {
    const { value } = ev.target;
    setMessage(value);
  };

  const onSend = () => {
    sendMessage(message);
    // clear message box
    setMessage('');
  };

  return (
    <>
      <div className="col-9">
        <input
          type="text"
          placeholder={t('DASHBOARD_MESSAGES_PLACEHOLDER_FIELD')}
          className="form-control"
          value={message}
          onChange={onMessageChange}
          disabled={isDisabled}
        />
      </div>
      <div className="col-3">
        <button className="btn btn-primary" onClick={onSend} disabled={isDisabled}>
          {t('BUTTON_SEND')}
        </button>
      </div>
    </>
  );
};

const ConversationMessagesContainer = ({ isExpertUser, currentConversation, inProgress }) => {
  const { t } = useTranslation();

  const selectedConversation = useRef(null);
  const [messages, setMessages] = useState(null);
  const currentPersonKind = isExpertUser ? CONSTANTS.USER_MODEL_KIND.EXPERT : CONSTANTS.USER_MODEL_KIND.REGULAR;

  const initConversation = async conv => {
    const { messages } = conv;
    selectedConversation.current = conv;
    setMessages(messages);
    initConversationSockets(conv);
    emitAcknowledgeReadMessage(conv);
  };

  const onLoad = async () => {
    if (!currentConversation || !currentConversation._id) {
      return;
    }
    initConversation(currentConversation);
  };

  useEffect(() => {
    onLoad();
  }, [currentConversation]);

  const updateMessages = messageObj => {
    const messages = selectedConversation.current.messages;
    const updatedMessages = [...messages, messageObj];
    setMessages(updatedMessages);
    selectedConversation.current.messages = updatedMessages;
  };

  const socketSuccessFn = function (messageObj) {
    console.log(`Message Received : ${JSON.stringify(messageObj)}`);
    const { messages: currentMessages, _id: currentId } = selectedConversation.current;
    if (messageObj.conversationId !== currentId) {
      return;
    }
    const [duplicateMessage] = (currentMessages || []).filter(message => message._id === messageObj._id);
    if (duplicateMessage) {
      console.warn(`Message id  ${duplicateMessage._id} is already in the conversation body: ${duplicateMessage.text}`);
      return;
    }
    updateMessages(messageObj);
    emitAcknowledgeReadMessage(currentConversation);
  };

  const socketErrorFn = function (err) {
    console.log(`Error when receiving message : ${JSON.stringify(err)}`);
  };

  const initConversationSockets = conversation => {
    conversation.privateSocketName = `${WEB_SOCKETS.PRIVATE_SOCKET_NAME_PREFIX}${conversation._id}`;
    conversation.privateSocketError = `${conversation.privateSocketName}${WEB_SOCKETS.PRIVATE_SOCKET_ERROR_SUFFIX}`;
    sockets.on(conversation.privateSocketName, socketSuccessFn);
    sockets.on(conversation.privateSocketError, socketErrorFn);
  };

  const emitMessage = (conversation, messageObj) => {
    const { privateSocketName, privateSocketError, _id: conversationId } = conversation;
    sockets.emit(WEB_SOCKETS.SEND_MESSAGE, {
      privateSocketName,
      privateSocketError,
      conversationId,
      message: messageObj
    });
  };

  const emitAcknowledgeReadMessage = conversation => {
    const { privateSocketName, privateSocketError, _id: conversationId } = conversation;
    sockets.emit(WEB_SOCKETS.ACKNOWLEDGE_READ_MESSAGE, {
      privateSocketName,
      privateSocketError,
      conversationId,
      personKind: currentPersonKind
    });
  };

  const createMessage = (personKind, text, timestamp) => {
    return {
      personKind,
      text,
      timestamp: new Date().getTime()
    };
  };

  const sendMessage = message => {
    const messageObj = createMessage(currentPersonKind, message);
    emitMessage(currentConversation, messageObj);
    updateMessages(messageObj);
  };

  return (
    <>
      <div className="text-center">
        <h4>{t('DASHBOARD_MESSAGES')}</h4>
      </div>
      <InProgress inProgress={inProgress} />
      <div className="chat-body">
        {(messages || []).map((message, index) => {
          return <MessageItem key={index} message={message} currentConversation={currentConversation} isExpertUser={isExpertUser} />;
        })}
      </div>
      <div className="row">
        <MessageBox sendMessage={sendMessage} isDisabled={!(currentConversation._id)}> </MessageBox>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  currentConversation: state.messages.currentConversation,
  inProgress: state.messages.inProgress
});

const mapDispatchToProps = dispatch => ({
  postMessage: (conversationId, message) => dispatch(postMessage(conversationId, message))
});

export default connect(mapStateToProps, mapDispatchToProps)(ConversationMessagesContainer);
