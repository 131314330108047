import CONSTANTS from '../constants/constants';

export const getUserFullName = (obj = {}, fallbackValue) => {
  if (typeof obj !== 'object' || !(obj.firstname && obj.lastname)) {
    return fallbackValue;
  }

  const { firstname, lastname } = obj;
  const fullName = `${firstname} ${lastname}`;
  return fullName;
};

export const isExpert = currentUser => {
  if (!currentUser) {
    return null;
  }
  if (currentUser.isExpert) {
    return true;
  }
  return false;
};

export const checkPasswordValidity = pwdValue => {
  // tests validity of the password
  const passwordRegex = RegExp(CONSTANTS.PASSWORD_REGEX);
  if (passwordRegex.test(pwdValue) === false) {
    const msgKey = 'PASSWORD_ACCEPTANCE_CRITERIA';
    return msgKey;
  }
  return null;
};
