import React from 'react';
import { useTranslation } from 'react-i18next';
import TooltipIcon from './TooltipIcon';

const IconTransportItem = ({ iconKey, keyToMatch, iconClass, textKey, icon, tooltip, text, big }) => {
  const { t } = useTranslation();

  if (iconKey !== keyToMatch) {
    return null;
  }
  const bigClass = big ? 'fa-2x circle-icon' : '';

  return (
    <div className="expert-icon-container">
      <TooltipIcon iconClass={`${bigClass} ${iconClass}`} textKey={textKey} icon={icon} tooltip={tooltip} big={big} />
      {text ? t(textKey) : ''}
    </div>
  );
};

const IconTransportSwitcher = ({ iconKey = 'OTHER_TRANSPORTATION', icon, tooltip, text, big }) => {
  return (
    <>
      <IconTransportItem
        iconKey={iconKey}
        keyToMatch="AIRPLANE"
        iconClass="fa fa-plane"
        textKey="TRIP_EVENT_TRANSPORTATION_TYPE_AIRPLANE"
        big={big}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />

      <IconTransportItem
        iconKey={iconKey}
        keyToMatch="BICYCLE"
        iconClass="fa fa-bicycle"
        textKey="TRIP_EVENT_TRANSPORTATION_TYPE_BICYCLE"
        big={big}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />
      <IconTransportItem
        iconKey={iconKey}
        keyToMatch="WALKING"
        iconClass="icon ive-solo"
        textKey="TRIP_EVENT_TRANSPORTATION_TYPE_WALKING"
        big={big}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />
      <IconTransportItem
        iconKey={iconKey}
        keyToMatch="CAR"
        iconClass="fa fa-car"
        textKey="TRIP_EVENT_TRANSPORTATION_TYPE_CAR"
        big={big}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />
      <IconTransportItem
        iconKey={iconKey}
        keyToMatch="TAXI"
        iconClass="fa fa-taxi"
        textKey="TRIP_EVENT_TRANSPORTATION_TYPE_TAXI"
        big={big}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />
      <IconTransportItem
        iconKey={iconKey}
        keyToMatch="UBER"
        iconClass="fa fa-taxi"
        textKey="TRIP_EVENT_TRANSPORTATION_TYPE_UBER"
        big={big}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />
      <IconTransportItem
        iconKey={iconKey}
        keyToMatch="FERRY"
        iconClass="fa fa-ship"
        textKey="TRIP_EVENT_TRANSPORTATION_TYPE_FERRY"
        big={big}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />
      <IconTransportItem
        iconKey={iconKey}
        keyToMatch="SUBWAY"
        iconClass="fa fa-subway"
        textKey="TRIP_EVENT_TRANSPORTATION_TYPE_SUBWAY"
        big={big}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />
      <IconTransportItem
        iconKey={iconKey}
        keyToMatch="TRAIN"
        iconClass="fa fa-train"
        textKey="TRIP_EVENT_TRANSPORTATION_TYPE_TRAIN"
        big={big}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />
      <IconTransportItem
        iconKey={iconKey}
        keyToMatch="BUS"
        iconClass="fa fa-bus"
        textKey="TRIP_EVENT_TRANSPORTATION_TYPE_BUS"
        big={big}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />
      <IconTransportItem
        iconKey={iconKey}
        keyToMatch="SHUTTLE"
        iconClass="fa fa-bus"
        textKey="TRIP_EVENT_TRANSPORTATION_TYPE_SHUTTLE"
        big={big}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />      
      <IconTransportItem
        iconKey={iconKey}
        keyToMatch="OTHER_TRANSPORTATION"
        iconClass="fa fa-map-marker"
        textKey="TRIP_EVENT_TRANSPORTATION_TYPE_OTHER_TRANSPORTATION"
        big={big}
        icon={icon}
        tooltip={tooltip}
        text={text}
      />
    </>
  );
};

export default IconTransportSwitcher;
