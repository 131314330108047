import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { updateTripRequestField } from '../actions/common';
import { useTranslation } from 'react-i18next';
import NavigationService from '../services/navigation';
import agent from '../agent';
import Destination from '../components/Destination';
import { trackEventClick } from '../utils/gtagUtils';

const DestinationsPage = ({ onChangeTripRequestDestinationField }) => {
  const { t } = useTranslation();
  const [destinations, setDestinations] = useState([]);

  const fetchDestinations = async () => {
    try {
      const paginatedResult = await agent.DestinationsService.getDestinations(); //paginated result
      const fetchedDestinations = paginatedResult.result.listings      
      setDestinations(fetchedDestinations);
    } catch (error) {
      console.log(`Error when retrieving destinations: ${error.messsage}`);
    }
  };

  // Passing an empty array as the second argument to useEffect makes it only run on mount and unmount, thus stopping any infinite loops.
  useEffect(() => {
    fetchDestinations();
  }, []);

  const goToTripRequest = tripDestination => ev => {
    ev.preventDefault();

    const tripRequestDestinations = [];

    if (tripDestination) {
      tripRequestDestinations.push({
        code: tripDestination.search_code,
        name: tripDestination.name
      });
    }
    onChangeTripRequestDestinationField(tripRequestDestinations);
    trackEventClick('trip_request', 'info-step', tripDestination ? tripDestination.name : '');
    NavigationService.goToTripRequest();
  };

  return (
    <div className="container padding-10">
      <h1>{t('DESTINATIONS_TITLE')}</h1>
      <hr />
      {(destinations || []).map((destination, index) => {
        return <Destination key={index} destination={destination} goToTripRequest={goToTripRequest} />;
      })}
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  onChangeTripRequestDestinationField: value => dispatch(updateTripRequestField('destinations', value))
});

export default connect(null, mapDispatchToProps)(DestinationsPage);
