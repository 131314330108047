import React from 'react';
import CONSTANTS from '../constants/constants';
import SelectCustomBox from './SelectCustomBox';

const SelectAccommodationActionBox = ({ actionType, hasDefault, onActionTypeChange }) => {
  return (
    <SelectCustomBox value={actionType} values={CONSTANTS.ACCOMMODATION_ACTION_TYPES} hasDefault={hasDefault} onValueChange={onActionTypeChange} />
  );
};

export default SelectAccommodationActionBox;
