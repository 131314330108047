import React from 'react';
import { useTranslation } from 'react-i18next';
import CONSTANTS from '../constants/constants';

const TripInfoButtons = ({ objInfo, isConfirmation, onChangeTripInfoField, preferencesKey }) => {
  const { t } = useTranslation();

  const switchTripRequestType = tripType => ev => {
    // trip type deselected
    if (objInfo.tripType === tripType) {
      onChangeTripInfoField('tripType', '');
      return;
    }

    onChangeTripInfoField('tripType', tripType);
  };

  const switchTripRequestBudget = budget => ev => {
    // trip budget deselected
    if (objInfo.tripBudget === budget) {
      onChangeTripInfoField('tripBudget', '');
      return;
    }

    onChangeTripInfoField('tripBudget', budget);
  };

  const togglePreference = preferenceType => ev => {
    const newPreferences = { ...objInfo[preferencesKey] };
    newPreferences[preferenceType] = !newPreferences[preferenceType];
    onChangeTripInfoField(preferencesKey, newPreferences);
  };

  const renderTripTypeButton = (tripRequest, tripType, tripTypeLabel, tripTypeClass) => {
    return (
      <div className="col-xs-12 col-md-6 col-lg-3">
        <button
          type="button"
          className={`btn btn-toggle btn-${tripRequest.tripType === tripType ? 'dark-blue' : 'light'}`}
          onClick={switchTripRequestType(tripType)}
        >
          <i className={tripTypeClass} aria-hidden="true"></i>
          <br />
          {t(tripTypeLabel)}
        </button>
      </div>
    );
  };

  const renderTripBudgetButton = (tripRequest, tripBudget, tripBudgetLabel, tripBudgetClass) => {
    return (
      <div className="col-xs-12 col-md-6 col-lg-3">
        <button
          type="button"
          className={`btn btn-toggle btn-${tripRequest.tripBudget === tripBudget ? 'dark-blue active' : 'light'}`}
          onClick={switchTripRequestBudget(tripBudget)}
        >
          <i className={tripBudgetClass} aria-hidden="true"></i>
          <br />
          {t(tripBudgetLabel)}
        </button>
      </div>
    );
  };

  const renderTripPreferenceButton = (tripRequest, tripPreferenceType, tripPreferenceLabel, tripPreferenceClass) => {
    const tripPreferences = tripRequest[preferencesKey];
    if (!tripPreferences) {
      return null;
    }

    return (
      <div className="col-6 col-md-4 col-lg-3">
        <button
          type="button"
          className={`btn btn-toggle btn-${tripPreferences[tripPreferenceType] ? 'dark-blue active' : 'light'}`}
          onClick={togglePreference(tripPreferenceType)}
        >
          <i className={tripPreferenceClass} aria-hidden="true"></i>
          <br />
          {t(tripPreferenceLabel)}
        </button>
      </div>
    );
  };

  return (
    <>
      <div className="row form-group trip-info-buttons">
        <div className="col-12">
          {isConfirmation ? <i className="confirm-color fa fa-check" aria-hidden="true"></i> : null} {t('TRIP_INFO_TRIP_TYPE')}
        </div>
        {renderTripTypeButton(objInfo, CONSTANTS.TRIP_TYPE.SOLO, 'TRIP_TYPE_SOLO', 'icon ive-solo')}
        {renderTripTypeButton(objInfo, CONSTANTS.TRIP_TYPE.COUPLE, 'TRIP_TYPE_COUPLE', 'icon ive-couple')}
        {renderTripTypeButton(objInfo, CONSTANTS.TRIP_TYPE.FAMILY, 'TRIP_TYPE_FAMILY', 'icon ive-family')}
        {renderTripTypeButton(objInfo, CONSTANTS.TRIP_TYPE.FRIENDS, 'TRIP_TYPE_FRIENDS', 'icon ive-friends')}
      </div>
      <div className="row form-group trip-info-buttons">
        <div className="col-12">
          {isConfirmation ? <i className="confirm-color fa fa-check" aria-hidden="true"></i> : null} {t('TRIP_INFO_BUDGET')}
        </div>
        {renderTripBudgetButton(objInfo, CONSTANTS.TRIP_BUDGET.LOW, 'TRIP_BUDGET_LOW_COST', 'icon ive-economic')}
        {renderTripBudgetButton(objInfo, CONSTANTS.TRIP_BUDGET.MEDIUM, 'TRIP_BUDGET_BALANCED', 'icon ive-regular')}
        {renderTripBudgetButton(objInfo, CONSTANTS.TRIP_BUDGET.HIGH, 'TRIP_BUDGET_LUXURY', 'icon ive-lux')}
      </div>
      <div className="row form-group trip-info-buttons">
        <div className="col-12">
          {isConfirmation ? <i className="confirm-color fa fa-check" aria-hidden="true"></i> : null} {t('TRIP_INFO_INTERESTS')}
        </div>
        {renderTripPreferenceButton(objInfo, 'adventure', 'TRIP_INTERESTS_ADVENTURE', 'icon ive-adventure')}
        {renderTripPreferenceButton(objInfo, 'culture', 'TRIP_INTERESTS_CULTURE', 'icon ive-culture')}
        {renderTripPreferenceButton(objInfo, 'backpacker', 'TRIP_INTERESTS_BACKPACK', 'icon ive-backpack')}
        {renderTripPreferenceButton(objInfo, 'nightlife', 'TRIP_INTERESTS_NIGHT_LIFE', 'icon ive-nightlife')}
        {renderTripPreferenceButton(objInfo, 'gastronomy', 'TRIP_INTERESTS_GASTRONOMY', 'fa fa-cutlery')}
        {renderTripPreferenceButton(objInfo, 'museum', 'TRIP_INTERESTS_MUSEUMS', 'icon ive-museum')}
        {renderTripPreferenceButton(objInfo, 'shopping', 'TRIP_INTERESTS_SHOPPING', 'icon ive-shopping')}
        {renderTripPreferenceButton(objInfo, 'photography', 'TRIP_INTERESTS_PHOTOGRAPHY', 'icon ive-photographer')}
        {renderTripPreferenceButton(objInfo, 'guided_tour', 'TRIP_INTERESTS_TOURS', 'icon ive-guide')}
        {renderTripPreferenceButton(objInfo, 'pet', 'TRIP_INTERESTS_PETS', 'icon ive-animal')}
        {renderTripPreferenceButton(objInfo, 'bike_tour', 'TRIP_INTERESTS_BIKE', 'icon ive-bike')}
        {renderTripPreferenceButton(objInfo, 'sport', 'TRIP_INTERESTS_SPORTS', 'icon ive-sport')}
      </div>
    </>
  );
};

export default TripInfoButtons;
