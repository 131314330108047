import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NavigationService from '../services/navigation';
import AlertMessage from '../components/AlertMessage';
import TripInfo from '../containers/TripInfo';
import { validateTripRequestFormParams } from '../utils/tripUtils';
import { connect } from 'react-redux';

const TripRequestInfoPage = ({ tripRequest }) => {
  const { t } = useTranslation();

  const [alertInfoMessage, setAlertInfoMessage] = useState('');
  const [alertInfoMessageType, setAlertInfoMessageType] = useState('info');

  const submitAndGoToPickExpertStep = () => {
    const errorMessage = validateTripRequestFormParams(tripRequest);

    if (errorMessage) {
      setAlertInfoMessage(errorMessage);
      setAlertInfoMessageType('danger');
      return;
    }
    NavigationService.goToTripRequestPickExpert();
  };

  return (
    <div className="container">
      <div className="row trip-request-wrapper">
        <div className="col-5 trip-request-form-image no-padding">
          <div id="side-image-trip-request" style={{ backgroundImage: "url(/images/trip-request-paris.jpg)" }}></div>
        </div>
        <div className="col-7 trip-request-form-container">
          <TripInfo isConfirmation={false}></TripInfo>
          <div className="row trip-request-bottom">
            <div className="offset-9 col-3">
              <button type="button" className="btn btn-primary float-right" onClick={submitAndGoToPickExpertStep}>
                {t('BUTTON_NEXT')}
              </button>
            </div>
          </div>
          <AlertMessage messageType={alertInfoMessageType} message={alertInfoMessage} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  tripRequest: state.common.tripRequest
});

export default connect(mapStateToProps)(TripRequestInfoPage);
