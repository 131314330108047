import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { Button } from './Custom';
import TripEventForm from '../containers/TripEventForm';

const TripEventEditionModal = ({ event, showModal, onCloseModal, setEditionEvent, onSaveEvent }) => {
  const { t } = useTranslation();

  const handleClose = ev => {
    if (ev) {
      ev.preventDefault();
    }
    onCloseModal();
  };

  return (
    <div>
      <Modal id="tripEventEditionModal" size="lg" animation="true" show={showModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <h2>{t('TEMPLATE_MODAL_EDITION')}</h2>
            <Button as="a" className="a-button float-right" onClick={handleClose}>
              <i className="material-icons">close</i>
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body" id="modal-body">
          <div className="width-100">
            <div className="row">
              <div className="col-12">
                <TripEventForm event={event} setCurrentEvent={setEditionEvent}>
                  <button type="button" className="btn btn-primary" onClick={ev => onSaveEvent()}>
                    {t('BUTTON_UPDATE_EVENT')}
                  </button>
                </TripEventForm>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TripEventEditionModal;
