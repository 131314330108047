import { TRIP_REQUEST_POST_REQUEST, TRIP_REQUEST_POST_SUCCESS, TRIP_REQUEST_POST_FAILURE, TRIPS_REQUESTS_GET_FAILURE, TRIPS_REQUESTS_GET_SUCCESS, TRIPS_REQUESTS_GET_REQUEST } from '../constants/actionTypes';

import agent from '../agent';

export const postTripRequestRequest = () => ({ type: TRIP_REQUEST_POST_REQUEST });

export const postTripRequestSuccess = payload => ({
  type: TRIP_REQUEST_POST_SUCCESS,
  payload
});
export const postTripRequestFailure = error => ({ type: TRIP_REQUEST_POST_FAILURE, error });

export const postTripRequest = (isUserAuthenticated, tripRequest) => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(postTripRequestRequest());

    try {
      let response;
      if (isUserAuthenticated) {
        response = await agent.TripRequestsService.postTripRequest(tripRequest);
      } else {
        response = await agent.TripRequestsService.postTripRequestNoAuth(tripRequest);
      }
      console.log(`Post Trip Request success: ${response}`);

      const successMessage = 'TRIP_REQUEST_FORM_SUBMISSION_SUCCESS';
      dispatch(postTripRequestSuccess(successMessage));
    } catch (error) {
      if (error.response && error.response.text) {
        error.message = error.response.text;
      }

      dispatch(postTripRequestFailure(error));
    }
  };
};

export const getTripRequestsRequest = () => ({ type: TRIPS_REQUESTS_GET_REQUEST });

export const getTripRequestsSuccess = payload => ({
  type: TRIPS_REQUESTS_GET_SUCCESS,
  payload
});
export const getTripRequestsFailure = error => ({ type: TRIPS_REQUESTS_GET_FAILURE, error });

export const getTripRequests = () => {
  // as defined in redux thunk
  return async (dispatch, getState) => {
    dispatch(getTripRequestsRequest());

    try {
      const tripRequests = await agent.TripsService.getAllTripRequestsNoAuth();

      if (!tripRequests) {
        throw new Error(i18next.t('TRIP_RESUME_LIST_RETRIVAL_ERROR'));
      }

      dispatch(getTripRequestsSuccess(tripRequests));
    } catch (error) {
      console.log('Error when retrieving trip requests list: ' + JSON.stringify(error));
      dispatch(getTripRequestsFailure(error));
    }
  };
};
