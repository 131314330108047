import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getLocation, getLocations } from '../actions/locations';
import LocationsMenu from './LocationsMenu';
import CONSTANTS from '../constants/constants';
import { convertLocationToEvent } from '../utils/locationUtils';

const TripDayPlannerRightMenu = ({
  locations,
  getLocations,
  getLocation,
  currentUser,
  setDestinationSearch,
  setSearchPlace,
  setNewEventFromLocation
}) => {
  console.log('Component TripDayPlanner LEFT MENU rendered');
  const { t } = useTranslation();
  const isExpertUser = currentUser ? currentUser.isExpert : null;

  // Wrap with useCallback to avoid change on every render. It returns a memoized version of the callback that only changes if one of the provided dependencies change.
  const fetchLocations = useCallback(async () => {
    const locations = await getLocations();
    return locations;
  }, [getLocations]);


  useEffect(() => {
    const onLoad = async () => {
      await fetchLocations();
    };

    onLoad();
  }, [fetchLocations]);


  const onChangeDestinationSearchObject = destinationSearch => {
    setDestinationSearch(destinationSearch);
  };

  const onGetLocation = async locationId => {
    const location = await getLocation(locationId);
    const { _id: id, latitude, longitude } = location;
    updateSearchPlace(id, latitude, longitude);
    clearPlacesField(); // clear Search Input field
    const newEvent = convertLocationToEvent(location);
    setNewEventFromLocation(newEvent);
  };

  const clearPlacesField = () => {
    const input = document.getElementById(CONSTANTS.SEARCH_PLACES_BOX_ID);
    if (!input) {
      console.error(`Search Input field was not found: ${CONSTANTS.SEARCH_PLACES_BOX_ID}`);
      return;
    }
    input.value = '';
  };

  const updateSearchPlace = (id, latitude, longitude) => {
    const searchPlace = {
      id,
      latitude,
      longitude
    };
    setSearchPlace(searchPlace);
  };

  return <div className="day-view-locations">
    <h2>{t('MY_LOCATIONS_LIBRARY')}</h2>
    <LocationsMenu locations={locations} onGetLocation={onGetLocation} onChangeDestinationSearchObject={onChangeDestinationSearchObject} setNewEventFromLocation={setNewEventFromLocation} />
  </div>;
};

const mapStateToProps = state => ({
  locations: state.locations.locations
});

const mapDispatchToProps = dispatch => ({
  getLocation: locationId => dispatch(getLocation(locationId)),
  getLocations: () => dispatch(getLocations()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TripDayPlannerRightMenu);
