const environments = [
    { name: 'PRODUCTION', node_env: 'production', url: 'https://www.voyagezy.com', serverUrl: 'https://server.voyagezy.com' },
    { name: 'STAGING', node_env: 'staging', url: 'https://staging.voyagezy.com', serverUrl: 'https://staging.voyagezy.com' },
    { name: 'DEMO', node_env: 'demo', url: 'https://demo.voyagezy.com', serverUrl: 'https://demo.voyagezy.com' },
    //    { name: 'DEVELOPMENT', node_env: 'development', url: 'http://localhost:8080', serverUrl: 'http://localhost:8080' },
    //    { name: 'DEVELOPMENT_CLIENT', node_env: 'development', url: 'http://localhost:3000', serverUrl: 'http://localhost:8080' },
]

export default environments;

