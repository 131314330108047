import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CONSTANTS from '../constants/constants';
import ChartCanvas from '../components/ChartCanvas';
import { retrieveAllTripPlanEvents } from '../utils/tripPlanUtils';
import { convertTimestampToDate } from '../utils/dateUtils';

const TripResumeViewOverview = ({ trip, inProgress }) => {
  const { t } = useTranslation();
  const [allTripEvents, setAllTripEvents] = useState([]);

  useEffect(() => {
    if (trip.tripPlan.days) {
      const allEvents = retrieveAllTripPlanEvents(trip.tripPlan);
      setAllTripEvents(allEvents)
    }
  }, [trip]);

  if (!trip) {
    return null;
  }

  const getHighlightForDay = (tripDay) => {
    if (tripDay.generalComment) {
      return tripDay.generalComment
    }
    const tripEventTitles = tripDay.events.filter(tripEvent => tripEvent.kind !== CONSTANTS.TRIP_EVENT_KINDS.DIRECTIONS).map(tripEvent => tripEvent.name).join(', ');

    return tripEventTitles || t('TRIP_PLAN_DAY_FREE_DAY')
  }

  const { title, startDate, endDate } = trip;
  let subtitle = '';
  if (startDate && endDate) {
    const startTimeLabel = convertTimestampToDate(startDate);
    const endTimeLabel = convertTimestampToDate(endDate);
    subtitle = `${startTimeLabel} - ${endTimeLabel}`;
  }

  return (
    <div className="container h-100 padding-10 trip-plan-view-container">
      {inProgress ? (
        <div className="text">
          <i className="fa fa-spinner fa-spin spin-size"></i>
        </div>
      ) : (
        <div className="container width-100">
          <div className="row padding-10 title-subtitle">
            <div className="col-12 text-center">
              <h1 className="">
                {title}
              </h1>
              <h3 className="subtitle">{subtitle}</h3>
              <hr />
            </div>
            <div className="col-12 col-md-3"></div>
          </div>
          <div className="row padding-10 trip-plan-overview-row">
            <div className="col-6">
              <h2 className="">{t('TRIP_PLAN_VIEW_OVERVIEW_HIGHLIGHTS')}</h2>
              {!trip.tripPlan ? ('This trip does not contain any events') : (
                trip.tripPlan.days.map((day, index) => <p key={index}>{t('TRIP_DAY_CANVAS_DAY')} {index + 1}: {getHighlightForDay(day)}</p>))
              }
            </div>
            <div className="col-6">
              <h2 className="">{t('TRIP_PLAN_VIEW_OVERVIEW_COST_BREAKDOWN')}</h2>
              <ChartCanvas tripEvents={allTripEvents} isTrip={true}></ChartCanvas>
            </div>
          </div>
          <div className="col-12">
            <hr />
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  trip: state.trips.currentTripResume,
  inProgress: state.trips.inProgress,
  currentUser: state.common.currentUser
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(TripResumeViewOverview);
