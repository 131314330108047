import { loadStripe } from '@stripe/stripe-js';

let stripe = null;

const set = (_stripe) => {
  stripe = _stripe;
};

const initialize = async (publicKey) => {
  const stripeScript = await loadStripe(publicKey, { apiVersion: '2020-08-27' });
  set(stripeScript);
};

export default {
  initialize,
  getStripe: () => {
    return stripe;
  }
};
