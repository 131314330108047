// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const trackEvent = (action, category, label, value) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value
  });
};

export const trackEventClick = (category, label, value) => trackEvent('click', category, label, value);
