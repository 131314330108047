import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NavigationService from '../services/navigation';
import { Button, CustomTooltip } from '../components/Custom';
import TripDayCanvas from './TripDayCanvas';
import { updateTripPlanDaySuccess, moveEvent } from '../actions/tripPlans';
import { reorder } from '../utils/common';
import { formatDate } from '../utils/dateUtils';
import { DragDropContext } from 'react-beautiful-dnd';

const TripDayPlannerLeftMenu = ({
  day,
  trip,
  isTripTemplate,
  currentUser,
  inProgress,
  tripPlanDays,
  openEventAdditionSideNav,
  pickDayOrEventAction,
  moveEvent,
  updateTripPlanDaySuccess
}) => {
  console.log('Component TripDayPlanner LEFT MENU rendered');
  const { t } = useTranslation();
  let { _id: tripId, startDate, endDate, title, name } = trip;
  let tooltipText = isTripTemplate ? name : `${title} (${formatDate(startDate)} - ${formatDate(endDate)}) `;
  let mainTitle = isTripTemplate ? name : title;
  const isExpertUser = currentUser ? currentUser.isExpert : null;
  const view = !isExpertUser;

  const goBackToTripView = ev => {
    ev.stopPropagation();
    if (isTripTemplate) {
      NavigationService.goToTripTemplateView(tripId);
      return;
    }
    NavigationService.goToTripView(tripId);
  };

  const goBackToTripEdition = ev => {
    ev.stopPropagation();
    if (isTripTemplate) {
      NavigationService.goToTripTemplateEdition(tripId);
      return;
    }
    NavigationService.goToTripEdition(tripId);
  };

  const goToDay = destDayIndex => {
    const day = tripPlanDays[destDayIndex];
    NavigationService.goToTripDayView(tripId, day._id, null, isTripTemplate);
  };

  async function onDragEnd(result) {
    const { destination, source } = result;
    if (!destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }

    const reorderedDayEvents = reorder(day.events, source.index, destination.index);
    updateTripPlanDaySuccess(day._id, { ...day, events: reorderedDayEvents });

    await moveEvent(tripId, day._id, source.index, destination.index, isTripTemplate);
  }

  const dayIndex = day ? day.index : 0;
  return (
    <>
      <div className="row day-view-title">
        <div className="row width-100 links small">
          <Button as="a" className="col-4 a-button" onClick={goBackToTripView}>
            {t('BUTTON_VIEW_TRIP')}
          </Button>
          {isExpertUser ? (
            <>
              <Button as="a" className="col-4 a-button" onClick={goBackToTripEdition}>
                {t('BUTTON_EDIT_TRIP')}
              </Button>
              <Button as="a" className="col-4 a-button" onClick={openEventAdditionSideNav}>
                {t('TOOL_DAY_VIEW_ADD_EVENT')}
                <i className="fa fa-angle-right" aria-hidden="true"></i>{' '}
              </Button>
            </>
          ) : null}
        </div>
        <div className="width-100 text-center">
          <CustomTooltip text={tooltipText} isKey={false}>
            <p className="ellipsis-text">{mainTitle}</p>
          </CustomTooltip>
        </div>
        <div className="row width-100 days-links small text-center">
          <div className="col-4">
            {dayIndex === 0 ? null : (
              <Button as="a" className="a-button" onClick={ev => goToDay(dayIndex - 1)}>
                <i className="fa fa-angle-left" aria-hidden="true"></i> {t('TRIP_DAY_CANVAS_DAY')} {dayIndex}
              </Button>
            )}
          </div>
          <div className="col-4">
            <span>
              {t('TRIP_DAY_CANVAS_DAY')} {dayIndex + 1}
            </span>
          </div>
          <div className="col-4">
            {tripPlanDays && tripPlanDays.length === dayIndex + 1 ? null : (
              <Button as="a" className="a-button" onClick={ev => goToDay(dayIndex + 1)}>
                {t('TRIP_DAY_CANVAS_DAY')} {dayIndex + 2} <i className="fa fa-angle-right" aria-hidden="true"></i>
              </Button>
            )}
          </div>
        </div>
      </div>

      {day ? (
        <div className="row">
          {inProgress ? (
            <div className="load-overlay show">
              <div className="text">
                <i className="fa fa-spinner fa-spin spin-size"></i>
              </div>
            </div>
          ) : null}
          <div className="day-canvas">
            <DragDropContext onDragEnd={onDragEnd}>
              <TripDayCanvas
                view={view}
                tripId={tripId}
                dayIndex={dayIndex}
                day={day}
                startDate={startDate}
                isTripTemplate={isTripTemplate}
                dayViewActionFn={pickDayOrEventAction}
                className="trip-canvas-column-wrapper fadein-container"
                isDragAndDropDisabled={view}
              />
            </DragDropContext>
          </div>
        </div>
      ) : null}
    </>
  );
};

// Selector
function getTripPlanDays(tripPlan) {
  if (!tripPlan || !tripPlan.days) {
    return [];
  }

  const { days } = tripPlan;
  const daysWithIndex = days.map((day, index) => ({ ...day, index }));
  return daysWithIndex;
}

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  inProgress: state.tripPlans.inProgress,
  tripPlanDays: getTripPlanDays(state.tripPlans.currentTripPlan)
});

const mapDispatchToProps = dispatch => ({
  updateTripPlanDaySuccess: (dayId, payload) => dispatch(updateTripPlanDaySuccess(null, dayId, payload)),
  moveEvent: (tripId, dayId, positionFrom, positionTo, isTemplate) => dispatch(moveEvent(tripId, dayId, positionFrom, dayId, positionTo, isTemplate))
});

export default connect(mapStateToProps, mapDispatchToProps)(TripDayPlannerLeftMenu);
