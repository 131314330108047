import { SHOW_LOGIN_DIALOG, HIDE_LOGIN_DIALOG } from '../constants/actionTypes';
import { store } from '../store';

export const showLoginDialogAction = () => {
  store.dispatch({ type: SHOW_LOGIN_DIALOG });
};

export const hideLoginDialogAction = () => {
  store.dispatch({ type: HIDE_LOGIN_DIALOG });
};
