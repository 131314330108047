import LocalStorage from '../LocalStorage';
import agent from '../agent'

const getOnreadystatechange = (xmlHttpRequest, successHandler, errorHandler, setLoading) => {
  return function () {
    // In local files, status is 0 upon success in Mozilla Firefox
    if (xmlHttpRequest.readyState === XMLHttpRequest.DONE) {
      setLoading(false);
      const status = xmlHttpRequest.status;
      if (status === 0 || (status >= 200 && status < 400)) {
        // The request has been completed successfully
        console.log(xmlHttpRequest.responseText);
        successHandler(xmlHttpRequest);
      } else {
        // There has been an error with the request!
        errorHandler(xmlHttpRequest);
      }
    }
  };
};

/*
    Function to get the temporary signed request from the app.
    If request successful, continue to upload the file using this signed
    request.
*/
const uploadFile = (file, signedRequest, url, setLoading, setSuccess, setMessage, setFileUrl) => {
  setLoading(true);

  const successHandler = xhr => {
    setFileUrl(url);
    console.log(`New upload: ${url}`);
    setSuccess(true);
    setMessage('Your file has been succesfully uploaded. Please click on Save button.');
  };

  const errorHandler = xhr => {
    setSuccess(false);
    setMessage('Could not upload file.');
    console.log('Could not get signed URL. Response Status: ' + xhr.status + ' Response: ' + xhr.response);
  };

  const xhr = new XMLHttpRequest();
  xhr.open('PUT', signedRequest);
  xhr.onreadystatechange = getOnreadystatechange(xhr, successHandler, errorHandler, setLoading);
  xhr.send(file);
};

/*
    Function to get the temporary signed request from the app.
    If request successful, continue to upload the file using this signed
    request.
*/
const authenticateAndUploadFile = (file, folder, setLoading, setSuccess, setMessage, setFileUrl) => {
  const token = LocalStorage.getToken();
  const urlPath = `${agent.getApiRoot()}/files/sign-s3`; // url to get signed request
  const params = [];
  params.push('file-type=' + file.type);
  params.push('file-size=' + file.size);
  params.push('folder-type=' + folder);
  //params.push('file-name=' + file.name);
  const url = `${urlPath}?${params.join('&')}`;

  setLoading(true);

  const successHandler = xhr => {
    try {
      const response = JSON.parse(xhr.responseText);
      const { signedRequest, url } = response;
      uploadFile(file, signedRequest, url, setLoading, setSuccess, setMessage, setFileUrl);
    } catch (e) {
      console.log('Issue during response parsing: ' + e);
      return;
    }
  };

  const errorHandler = xhr => {
    console.log(`Could not get signed URL. Response Status: ${xhr.status}.Response:  ${xhr.response} `);
    setSuccess(false);
    setMessage('Could not upload file.');
  };

  const xhr = new XMLHttpRequest();
  xhr.open('GET', url);
  xhr.setRequestHeader('Authorization', token);

  xhr.onreadystatechange = getOnreadystatechange(xhr, successHandler, errorHandler, setLoading);
  xhr.send();
};

/*
    Deprecated
    Function to carry out the actual DELETE request to S3 using the signed request from the app.
*/
const deleteFile = (filePathUrl, setLoading, setFileUrl) => {
  const urlPath = `${agent.getApiRoot()}/files/delete-s3`;
  const params = [];
  params.push('file-path=' + filePathUrl);
  const url = `${urlPath}?${params.join('&')}`;

  const successHandler = () => {
    setFileUrl('');
    console.log(`Element Deleted: ${url}`);
  };

  const errorHandler = () => {
    alert('Could not delete file.');
  };

  const xhr = new XMLHttpRequest();
  xhr.open('DELETE', url);
  xhr.onreadystatechange = getOnreadystatechange(xhr, successHandler, errorHandler, setLoading);
  xhr.send();
};

const FileUploadService = {
  uploadFile,
  authenticateAndUploadFile,
  deleteFile
};

export default FileUploadService;
