import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../utils/currencyUtils';
import { convertTimestampToHourOfTheDay, convertTimestampToHourOfTheDayVerbose } from '../utils/dateUtils';
import IconTransportSwitcher from './IconTransportSwitcher';
import CONSTANTS from '../constants/constants';
import { Collapse } from 'react-bootstrap';
import { Button } from './Custom';
import TripEventKindIconSwitcher from './TripEventKindIconSwitcher';

const TripEventListItem = ({ event }) => {
  const { t } = useTranslation();

  const [isTipsCollapsed, setIsTipsCollapsed] = useState(true);

  if (!event) {
    return null;
  }
  return (
    <div className="event-item well well-lg">
      <div className="event-item-prefix col-2">
        <div className="container-icon">
          <TripEventKindIconSwitcher tripEvent={event} icon={true} tooltip={true} text={false} />
        </div>
        <p className="hour">{convertTimestampToHourOfTheDay(event.timestampStart)}</p>
      </div>
      <div className="event-item-body col-10">
        <div className="col-12">
          <h4 className="title">
            {event.kind !== CONSTANTS.TRIP_EVENT_KINDS.DIRECTIONS ? (
              event.name
            ) : (
              <IconTransportSwitcher iconKey={event.meansOfTransportation} icon={false} tooltip={false} text={true}></IconTransportSwitcher>
            )}
          </h4>
        </div>
        <div className="col-12">
          <span style={{ paddingRight: '15px' }}>
            {t('TRIP_EVENT_FIELD_DURATION')} : <strong>{convertTimestampToHourOfTheDayVerbose(event.duration)}</strong>
          </span>
          <span>
            {t('TRIP_EVENT_FIELD_PRICE')} : <strong>{formatCurrency(event.totalPriceCurrencyCode, event.totalPrice)}</strong>
          </span>
        </div>
        <div className="col-12">
          <p>
            <Button as="a" className="a-button" onClick={() => setIsTipsCollapsed(!isTipsCollapsed)}>
              {t('TRIP_EVENT_FIELD_TIPS')} <i className={`fa fa-angle-down rotate-icon ${event.isTipsCollapsed ? '' : 'state-active'}`}></i>
            </Button>
          </p>
          <Collapse in={event.isTipsCollapsed}>
            <div className="tips-panel">{event.tips}</div>
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export default TripEventListItem;
