import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SelectDestinationTypeBox from '../components/SelectDestinationTypeBox';

const DestinationsMenuFilter = ({ onChangeFilter }) => {
  const { t } = useTranslation();

  const defaultFilter = { name: '', destinationType: '' };

  const [filter, setFilter] = useState(defaultFilter);

  useEffect(() => {
    onChangeFilter(filter);
  }, [filter, onChangeFilter]);

  const onDestinationTypeChange = destType => {
    const updatedFilter = { ...filter, destinationType: destType };
    setFilter(updatedFilter);
  };

  const onSearchDestinationNameChange = ev => {
    const updatedFilter = { ...filter, name: ev.target.value };
    setFilter(updatedFilter);
  };

  return (
    <>
      <div className="row filter">
        <div className="col-6">
          <label className="width-100">Destination: </label>
          <div className="width-100">
            <input
              type="text"
              className="form-control"
              placeholder="Filter Code or Name"
              value={filter.name}
              onChange={onSearchDestinationNameChange}
            />
          </div>
        </div>
        <div className="col-6">
          <label className="width-100">{t('MY_LOCATIONS_TYPE')} </label>
          <SelectDestinationTypeBox
            className="width-100"
            destinationType={filter.destinationType}
            hasDefault={true}
            onDestinationChange={onDestinationTypeChange}
            required
          />
        </div>
      </div>
    </>
  );
};


export default DestinationsMenuFilter;
