import CONSTANTS from '../constants/constants';

export const convertLocationToEvent = location => {
  const { name, description, imageUrl, latitude, longitude, address, zipCode, telephone, website, tips } = location;
  const newEvent = {
    name,
    description,
    imageUrl,
    latitude,
    longitude,
    address,
    zipCode,
    telephone,
    website,
    tips,
    validated: false
  };

  if (CONSTANTS.ACCOMMODATION_TYPES.indexOf(location.locationType) !== -1) {
    newEvent.kind = CONSTANTS.TRIP_EVENT_KINDS.ACCOMMODATION;
    newEvent.accommodationType = location.locationType;
  } else if (CONSTANTS.ATTRACTION_TYPES.indexOf(location.locationType) !== -1) {
    newEvent.kind = CONSTANTS.TRIP_EVENT_KINDS.ATTRACTION;
    newEvent.eventType = location.locationType;
  } else {
    // establishment event type can be any string
    newEvent.kind = CONSTANTS.TRIP_EVENT_KINDS.ESTABLISHMENT;
    newEvent.establishmentType = location.locationType;
  }

  // The next assignment is to check whether it is associated with an existing location or if it is new
  // They won't be persisted in the database because it does not belong to trip event model
  newEvent.locationId = location._id;
  //newEvent.created_by = location.created_by;

  return newEvent;
};

export const getDefaultLocation = () => {
  return {
    name: '',
    kind: CONSTANTS.TRIP_EVENT_KINDS.ATTRACTION,
    source: '',
    isDefault: false,
    locationType: '',
    tags: [],
    imageUrl: '',
    imageUrls: [],
    latitude: null,
    longitude: null,
    destinationName: '',
    destinationCode: '',
    address: '',
    zipCode: '',
    telephone: '',
    website: '',
    description: '',
    tips: ''
  };
}
