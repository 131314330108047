import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../utils/currencyUtils';
import { formatDate } from '../utils/dateUtils';
import { connect } from 'react-redux';
import { getTrips } from '../actions/trips';
import InProgress from '../components/InProgress';

const PaymentsListPage = ({ currentUser, allTrips, inProgress, getTrips, environmentUrl }) => {
  const { t } = useTranslation();
  const isExpertUser = currentUser ? currentUser.isExpert : null;

  // Wrap with useCallback to avoid change on every render. It returns a memoized version of the callback that only changes if one of the provided dependencies change.
  const fetchAllTrips = useCallback(async () => {
    await getTrips();
  }, [currentUser, isExpertUser, getTrips]);

  useEffect(() => {
    fetchAllTrips();
  }, [fetchAllTrips]);

  return (
    <>
      <div>
        <h2>{t('DASHBOARD_PAYMENTS_HISTORY')}</h2>
      </div>
      <div className="fadein-container">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>{t('DASHBOARD_PAYMENTS_DATE')}</th>
              <th>{t('DASHBOARD_PAYMENTS_EXPERT')}</th>
              <th>{t('DASHBOARD_PAYMENTS_CLIENT')}</th>
              <th>{t('DASHBOARD_PAYMENTS_TRIP_ID')}</th>
              <th>{t('DASHBOARD_PAYMENTS_TRIP_TITLE')}</th>
              <th>{t('DASHBOARD_PAYMENTS_VALUE')}</th>
            </tr>
          </thead>
          <tbody>
            {(allTrips || []).map((trip, index) => {
              const { _id, title, payment, expertUser, regularUser } = trip;
              const { currencyCode, value, date } = payment;
              return (
                <tr key={index}>
                  <td>{formatDate(date)}</td>
                  <td>{expertUser.email}</td>
                  <td>{regularUser.email}</td>
                  <td>
                    <span>{_id} </span>
                    <span>
                      <a href={`${environmentUrl}/trips/${_id}/payment`} target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-external-link"></i>
                      </a>
                    </span>
                  </td>
                  <td>{title}</td>
                  <td>{formatCurrency(currencyCode, value)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <InProgress inProgress={inProgress} />
      {!inProgress && allTrips.length === 0 ? <div>{t('DASHBOARD_PAYMENTS_NO_PAYMENTS')}</div> : null}
    </>
  );
};

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  inProgress: state.users.inProgress,
  allTrips: state.trips.tripResumes,
  inProgress: state.trips.inProgress,
  environmentUrl: state.common.environment ? state.common.environment.url : '',
});

const mapDispatchToProps = dispatch => ({
  getTrips: () => dispatch(getTrips()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsListPage);
