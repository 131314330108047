import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CONSTANTS from '../constants/constants';
import SelectLocationBox from '../components/SelectLocationBox';
import SelectTransportationBox from '../components/SelectTransportationBox';
import SelectAccommodationBox from '../components/SelectAccommodationBox';
import SelectTimeBox from '../components/SelectTimeBox';
import PriceFields from '../components/PriceFields';
import SelectTripEventKindBox from '../components/SelectTripEventKindBox';
import { convertToMonetaryValue, getMonetaryValueInCents } from '../utils/currencyUtils';
import SelectAccommodationActionBox from '../components/SelectAccommodationActionBox';

const TripEventForm = ({ children, event, setCurrentEvent }) => {
  const { t } = useTranslation();

  let totalPriceValue = convertToMonetaryValue(event.totalPrice || 0);

  const updateCurrentEventField = (field, value) => {
    const updatedEvent = { ...event, [field]: value };
    setCurrentEvent(updatedEvent);
  };

  const onSelectValidateAndUpdate = field => value => {
    if (value !== undefined && (isNaN(value) || value < 0)) {
      value = null;
    }
    updateCurrentEventField(field, value);
  };

  const onSelectUpdate = field => value => {
    updateCurrentEventField(field, value);
  };

  const setPrice = field => value => {
    updateCurrentEventField(field, value);
  };

  const setPriceValue = value => {
    const totalPrice = getMonetaryValueInCents(value);
    updateCurrentEventField('totalPrice', totalPrice);
  };

  if (!event) {
    return null;
  }

  return (
    <>
      <div className="form-group">
        <div className="row form2">
          <div className="col-6">
            <label htmlFor="etype" className="col-form-label">
              {`${t('TRIP_EVENT_FIELD_CATEGORY')}* : `}
            </label>
            <SelectTripEventKindBox
              currentEventKind={event.kind}
              onValueChange={onSelectUpdate('kind')}
              disabled={event._id ? true : false}
              hasDirectionsOption={event._id ? true : false}
            ></SelectTripEventKindBox>
          </div>
          <div className="col-6">
            <label htmlFor="eventType" className="col-form-label">
              {`${t('TRIP_EVENT_FIELD_TYPE')}* : `}
            </label>
            <div>
              {event.kind === CONSTANTS.TRIP_EVENT_KINDS.ATTRACTION ? (
                <SelectLocationBox locationType={event.eventType} hasDefault={true} onLocationChange={onSelectUpdate('eventType')} required />
              ) : null}
              {event.kind === CONSTANTS.TRIP_EVENT_KINDS.DIRECTIONS ? (
                <SelectTransportationBox
                  transportationType={event.meansOfTransportation}
                  hasDefault={true}
                  onTransportationChange={onSelectUpdate('meansOfTransportation')}
                  required
                />
              ) : null}
              {event.kind === CONSTANTS.TRIP_EVENT_KINDS.ACCOMMODATION ? (
                <SelectAccommodationBox
                  accommodationType={event.accommodationType}
                  hasDefault={true}
                  onLocationChange={onSelectUpdate('accommodationType')}
                  required
                />
              ) : null}
              {event.kind === CONSTANTS.TRIP_EVENT_KINDS.ESTABLISHMENT ? (
                <input
                  type="text"
                  className="form-control textbox"
                  placeholder="Enter Type"
                  name="eventType"
                  value={event.establishmentType}
                  onChange={ev => updateCurrentEventField('establishmentType', ev.target.value)}
                  required
                />
              ) : null}
            </div>
          </div>
        </div>
        {event.kind === CONSTANTS.TRIP_EVENT_KINDS.ATTRACTION ? (
          <>
            <div className="row form2">
              <label htmlFor="title" className="col-form-label col-md-4">
                {`${t('TRIP_EVENT_FIELD_NAME')}* : `}
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control textbox"
                  placeholder={t('TRIP_EVENT_FIELD_NAME_PLACEHOLDER')}
                  name="title"
                  value={event.name}
                  onChange={ev => updateCurrentEventField('name', ev.target.value)}
                  required
                />
              </div>
            </div>
            <div className="row form2">
              <label htmlFor="title" className="col-form-label col-12">
                {t('TRIP_EVENT_FIELD_ADDRESS')} *: {event.address || t('TRIP_EVENT_FIELD_ADDRESS_MISSING')}
              </label>
            </div>
            <div className="row form2">
              <label htmlFor="description" className="col-form-label  col-md-4">
                {`${t('TRIP_EVENT_FIELD_DESCRIPTION')}: `}
              </label>
              <div className="col-md-12">
                <textarea
                  className="form-control"
                  rows="5"
                  placeholder={t('TRIP_EVENT_FIELD_DESCRIPTION_PLACEHOLDER')}
                  value={event.description}
                  onChange={ev => updateCurrentEventField('description', ev.target.value)}
                ></textarea>
              </div>
            </div>
          </>
        ) : null}

        {event.kind === CONSTANTS.TRIP_EVENT_KINDS.DIRECTIONS ? (
          <>
            <div className="row form2">
              <label htmlFor="origin" className="col-form-label col-md-4">
                {`${t('TRIP_EVENT_FIELD_ORIGIN')}: `}
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control textbox"
                  placeholder={t('TRIP_EVENT_FIELD_ORIGIN_PLACEHOLDER')}
                  name="origin"
                  value={event.origin.address}
                  disabled={true}
                  required
                />
              </div>
            </div>
            <div className="row form2">
              <label htmlFor="destination" className="col-form-label col-md-4">
                {`${t('TRIP_EVENT_FIELD_DESTINATION')}: `}
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control textbox"
                  placeholder={t('TRIP_EVENT_FIELD_DESTINATION_PLACEHOLDER')}
                  name="destination"
                  value={event.destination.address}
                  disabled={true}
                  required
                />
              </div>
            </div>
            <div className="row form2">
              <label htmlFor="distance" className="col-form-label col-md-4">
                {`${t('TRIP_EVENT_FIELD_DISTANCE')}: `}
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control textbox"
                  placeholder={t('TRIP_EVENT_FIELD_DISTANCE_PLACEHOLDER')}
                  name="distance"
                  value={event.distance}
                  onChange={ev => updateCurrentEventField('distance', ev.target.value)}
                  required
                />
              </div>
            </div>
            <div className="row form2">
              <label htmlFor="hidden" className="col-form-label col-md-4">
                {`${t('TRIP_EVENT_FIELD_HIDDEN')}: `}
              </label>
              <div className="col-md-8">
                <input
                  type="checkbox"
                  className="checkbox"
                  name="hidden"
                  value={event.hidden}
                  onChange={ev => updateCurrentEventField('hidden', ev.target.checked)}
                />
              </div>
            </div>
          </>
        ) : null}

        {event.kind === CONSTANTS.TRIP_EVENT_KINDS.ACCOMMODATION || event.kind === CONSTANTS.TRIP_EVENT_KINDS.ESTABLISHMENT ? (
          <>
            <div className="row form2">
              <label htmlFor="name" className="col-form-label col-md-4">
                {`${t('TRIP_EVENT_FIELD_NAME')}* : `}
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control textbox"
                  placeholder={t('TRIP_EVENT_FIELD_NAME_PLACEHOLDER')}
                  name="name"
                  value={event.name}
                  onChange={ev => updateCurrentEventField('name', ev.target.value)}
                  required
                />
              </div>
            </div>
            <div className="row form2">
              <label htmlFor="address" className="col-form-label col-md-4">
                {`${t('TRIP_EVENT_FIELD_ADDRESS')}* : `}
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control textbox"
                  placeholder={t('TRIP_EVENT_FIELD_ADDRESS_PLACEHOLDER')}
                  name="address"
                  value={event.address}
                  onChange={ev => updateCurrentEventField('address', ev.target.value)}
                  required
                />
              </div>
            </div>
            <div className="row form2">
              <label htmlFor="actionType" className="col-form-label col-md-4">
                {`${t('TRIP_EVENT_FIELD_ACCOMMODATION_ACTION_TYPE')}* : `}
              </label>
              <div className="col-md-8">
                <SelectAccommodationActionBox
                  actionType={event.actionType}
                  hasDefault={true}
                  onActionTypeChange={onSelectUpdate('actionType')}
                  required
                />
              </div>
            </div>
          </>
        ) : null}

        <div className="row form2">
          <label htmlFor="timestampStart" className="col-form-label col-md-4">
            {`${t('TRIP_EVENT_FIELD_START_TIME')}: `}
          </label>
          <div className="col-md-8">
            <SelectTimeBox
              selectedTime={event.timestampStart}
              isDuration={false}
              hasDefault={true}
              onTimeChange={onSelectValidateAndUpdate('timestampStart')}
            ></SelectTimeBox>
          </div>
        </div>
        <div className="row form2">
          <label htmlFor="duration" className="col-form-label col-md-4">
            {`${t('TRIP_EVENT_FIELD_DURATION')}: `}
          </label>
          <div className="col-md-8">
            <SelectTimeBox
              selectedTime={event.duration}
              isDuration={true}
              hasDefault={true}
              onTimeChange={onSelectValidateAndUpdate('duration')}
            ></SelectTimeBox>
          </div>
        </div>
        <div className="row form2">
          <label htmlFor="totalPrice" className="col-form-label col-md-4">
            {`${t('TRIP_EVENT_FIELD_PRICE')}: `}
          </label>
          <div className="col-md-8">
            <PriceFields
              price={totalPriceValue}
              currencyCode={event.totalPriceCurrencyCode}
              setPrice={setPriceValue}
              setCurrency={setPrice('totalPriceCurrencyCode')}
            />
          </div>
        </div>
        <div className="row form2">
          <label htmlFor="tips" className="col-form-label col-md-4">
            {`${t('TRIP_EVENT_FIELD_TIPS')}: `}
          </label>
          <div className="col-md-12">
            <textarea
              className="form-control"
              rows="5"
              placeholder={t('TRIP_EVENT_FIELD_TIPS_PLACEHOLDER')}
              value={event.tips}
              onChange={ev => updateCurrentEventField('tips', ev.target.value)}
            ></textarea>
          </div>
        </div>
      </div>
      <div className="text-center">{children}</div>
    </>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TripEventForm);
