import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import NavigationService from '../services/navigation';
import { getTripResume } from '../actions/trips';
import { convertTimestampToDate } from '../utils/dateUtils';
import SideNav from '../components/SideNav';
import TripResumeContent from '../components/TripResumeContent';
import TripActionsButtons from '../containers/TripActionsButtons';
import TripTitleSubtitle from '../components/TripTitleSubtitle';
import CONSTANTS from '../constants/constants';
import TooltipIcon from '../components/TooltipIcon';
import TripResumeViewItinerary from '../containers/TripResumeViewItinerary';
import TripResumeViewOverview from '../containers/TripResumeViewOverview';
import { Tab, Tabs } from 'react-bootstrap';
const DEFAULT_TAB_NAME = 'overview';

const TripResumeViewPage = ({ currentUser, trip, inProgress, getTripResume }) => {
  const { t } = useTranslation();
  let { tripId } = useParams();
  const [isOpen, setIsOpen] = useState(null); // Three possible values: null, true and false
  const [key, setKey] = useState('');
  const isExpertUser = currentUser ? currentUser.isExpert : null;

  // Wrap with useCallback to avoid change on every render. It returns a memoized version of the callback that only changes if one of the provided dependencies change.
  const fetchTripResume = useCallback(async () => {
    await getTripResume(tripId);
  }, [tripId, getTripResume]);

  useEffect(() => {
    fetchTripResume();
  }, [fetchTripResume]);

  useEffect(() => {
    setKey(DEFAULT_TAB_NAME)
  }, []);

  const onTabChange = k => {
    setKey(k);
  };

  const goToTripList = event => {
    event.stopPropagation();
    NavigationService.goToDashboard(CONSTANTS.DASHBOARD_TAB_NAMES.TRIP_PLANS);
  };

  const onOpenEvent = ev => {
    ev.preventDefault();
    setIsOpen(true);
  };
  const onCloseEvent = ev => {
    ev.preventDefault();
    setIsOpen(false);
  };

  if (!trip) {
    return null;
  }

  const { title, startDate, endDate } = trip;
  let subtitle = '';
  if (startDate && endDate) {
    const startTimeLabel = convertTimestampToDate(startDate);
    const endTimeLabel = convertTimestampToDate(endDate);
    subtitle = `${startTimeLabel} - ${endTimeLabel}`;
  }

  const tabData = [
    {
      heading: 'TRIP_PLAN_VIEW_TAB_OVERVIEW',
      route: 'overview',
      content: TripResumeViewOverview,
    }, {
      heading: 'TRIP_PLAN_VIEW_TAB_ITINERARY',
      route: 'itinerary',
      content: TripResumeViewItinerary,
    },
  ];

  return (
    <div className="container padding-10 viewport-height-minus-navbar">
      <div className="row padding-10 trip-canvas-top">
        <div className="col-6">
          <TripTitleSubtitle title={title} subtitle={subtitle} isEdition={false}>
            <div className="display-inline-flex cursor-pointer" onClick={onOpenEvent}>
              <TooltipIcon iconClass="fa fa-angle-double-right" textKey="BUTTON_MORE_INFO" icon={true} tooltip={true} />
            </div>
          </TripTitleSubtitle>
        </div>
        <div className="col-6">
          <button type="button" className="btn btn-primary" onClick={goToTripList}>
            {t('BUTTON_BACK')}
          </button>
          <TripActionsButtons trip={trip} isExpertUser={isExpertUser} iconVersion={true}></TripActionsButtons>
        </div>
      </div>

      <SideNav id="left-trip-resume-sidenav" isOpen={isOpen} reverseColor={true} left={true} padding={true} triggerCloseEvent={onCloseEvent}>
        <TripResumeContent trip={trip} isExpertUser={isExpertUser}></TripResumeContent>
      </SideNav>
      <div className="width-100 padding-10">
        <Tabs id="trip-tabs" activeKey={key} onSelect={onTabChange}>
          {tabData.map((tab, index) => {
            return (
              <Tab key={index} eventKey={tab.route} title={t(tab.heading)}>
                {key === tab.route ? <tab.content /> : null /* only render the active tab */}
              </Tab>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  trip: state.trips.currentTripResume,
  inProgress: state.trips.inProgress,
  currentUser: state.common.currentUser
});

const mapDispatchToProps = dispatch => ({
  getTripResume: tripResumeId => dispatch(getTripResume(tripResumeId))
});

export default connect(mapStateToProps, mapDispatchToProps)(TripResumeViewPage);
