import CONSTANTS from '../constants/constants';
import { isPositiveInteger } from './common';
import { convertTimestampToHourOfTheDay, convertTimestampToHourOfTheDayVerbose } from './dateUtils';
import { isValidMonetaryValue } from './currencyUtils';
import { calculateDirectionRoute } from './googleAPIUtils';

/* Returns full price in monetary value*/
export const calculateFullPriceInCents = (days = 0, expert = {}, tripRequestServices = {}, tripRequestDestinations = []) => {
  let totalPriceInCents = 0,
    services = expert.services || []; // fields stype, value and perDay

  const numberOfDestinations = tripRequestDestinations.length;

  const numberOfDays = parseInt(days, 10);

  if (isNaN(numberOfDays) || numberOfDays < 0) {
    return null;
  }

  services.forEach(function (service) {
    const stype = service.stype;

    if (tripRequestServices[stype]) {
      if (service.perDay) {
        totalPriceInCents += days * service.value;
      } else {
        // per destination
        totalPriceInCents += numberOfDestinations * service.value;
      }
    }
  });

  return totalPriceInCents;
};

export const calculateFullPrice = (days = 0, expert = {}, tripRequestServices = {}, tripRequestDestinations = []) => {
  const totalPriceInCents = calculateFullPriceInCents(days, expert, tripRequestServices, tripRequestDestinations);
  // updates full price for saving it in Trip Resume
  const totalPrice = (totalPriceInCents / 100).toFixed(2);
  return totalPrice;
};

// Checkes whether at least one of the trip services is chosen or exist
export const isSomeServiceChosen = tripServices => {
  const serviceConfigkeys = Object.keys(CONSTANTS.TRIP_SERVICES_CONFIG);
  const isServiceChosen = serviceConfigkeys.some(serviceConfigKey => {
    const serviceKey = CONSTANTS.TRIP_SERVICES_CONFIG[serviceConfigKey].MODEL_KEY;
    return tripServices[serviceKey];
  });
  return isServiceChosen;
};

export const validateTripRequestFormParams = tripRequest => {
  const { numberOfDays, destinations, startDate, services, tripType, tripBudget, expert } = tripRequest;
  let errorMessage = '';

  if ((destinations || []).length === 0) {
    return 'TRIP_REQUEST_VALIDATION_MISSING_DESTINATIONS';
  }
  if (!isPositiveInteger(numberOfDays)) {
    return 'TRIP_REQUEST_VALIDATION_MISSING_OR_INVALID_DAYS_NUMBER';
  }
  if (!startDate) {
    return 'TRIP_REQUEST_VALIDATION_MISSING_START_DATE';
  }
  if (!isSomeServiceChosen(services)) {
    return 'TRIP_REQUEST_VALIDATION_MISSING_SERVICES';
  }
  if (!tripType) {
    return 'TRIP_REQUEST_VALIDATION_MISSING_TRIP_TYPE';
  }
  if (!tripBudget) {
    return 'TRIP_REQUEST_VALIDATION_MISSING_TRIP_BUDGET';
  }

  // trip request expert might not be chosen at this step and the error cannot be thrown in this case
  if (expert && !isSelectedServicesOfferedByExpert(services, expert.services)) {
    return 'TRIP_REQUEST_VALIDATION_SERVICES_NOT_OFFERED_BY_EXPERT';
  }

  return errorMessage;
};

/* Checks that at least one of the selected services are offered by the chosen expert */
const isSelectedServicesOfferedByExpert = (tripRequestServices, expertServices) => {
  const checkedServiceTypes = Object.keys(tripRequestServices).filter(tripRequestServiceType => {
    return tripRequestServices[tripRequestServiceType];
  });

  const expertServiceTypes = expertServices.map(expertService => {
    return expertService.stype;
  });

  const validChosenExpertServices = expertServiceTypes.filter(expertServiceType => {
    return checkedServiceTypes.indexOf(expertServiceType) !== -1;
  });

  return validChosenExpertServices.length > 0;
};

/*
 * Gets a dayIndex from a tripPlan according to a dayId.
 * Returns -1 when the dayId provided is not in the tripPlan
 */
export const getDayIndex = (tripDays = [], dayId) => {
  const dayIndex = tripDays.map(tripDay => tripDay._id).indexOf(dayId);

  return dayIndex;
};

export const updateDirectionEvent = (tripEvent, directionEvent, isOrigin) => {
  const coordinateField = isOrigin ? 'destination' : 'origin';
  const { address, latitude, longitude } = tripEvent;

  directionEvent[coordinateField] = {
    address,
    latitude,
    longitude
  };
};

/* Point contains address, latitude and longitude info */
const getPointEvent = (point) => {
  const { address, latitude, longitude } = point
  return {
    address,
    latitude,
    longitude
  }
}

/* Parses an Google Direction object and calculates the Distance and Duration
 *
 *  return an object with distance and duration properties
 */
const convertDurationDistanceFromGoogleDirection = (googleDirection) => {
  let shortestDistanceMeters = Number.MAX_SAFE_INTEGER, durationSeconds;

  (googleDirection.routes || []).forEach((route) => {
    // retrieves values with the shortest route path
    (route.legs || []).forEach(leg => {
      if (leg.distance.value < shortestDistanceMeters) {
        shortestDistanceMeters = leg.distance.value;
        durationSeconds = leg.duration.value;
      }
    })
  })

  let distance;
  let duration;
  if (shortestDistanceMeters !== Number.MAX_SAFE_INTEGER) {
    distance = (shortestDistanceMeters / 1000).toFixed(2); // to KM
    duration = (durationSeconds * 1000); // to milisseconds
  }

  return {
    distance,
    duration
  }
}

/* Rounds a duration to the closest multiple of a time step */
const roundDuration = (duration) => {
  const roundedSteps = Math.round(duration / CONSTANTS.TIME.TIME_STEP)
  const roundedDuration = CONSTANTS.TIME.TIME_STEP * roundedSteps
  return roundedDuration
}

export const getDirectionEvent = async (originEvent, destinationEvent) => {
  let timestampStart;

  // sets start of event only when start of previous event is defined
  if (isPositiveInteger(originEvent.timestampStart)) {
    timestampStart = (originEvent.timestampStart || 0) + (originEvent.duration || 0);
    if (timestampStart > CONSTANTS.TIME.DAY) {
      timestampStart = CONSTANTS.TIME.DAY;
    }
  }

  const googleDirection = await calculateDirectionRoute(originEvent, destinationEvent);
  const { duration, distance } = convertDurationDistanceFromGoogleDirection(googleDirection)
  const roundedDuration = roundDuration(duration)

  const directionEvent = {
    kind: CONSTANTS.TRIP_EVENT_KINDS.DIRECTIONS,
    meansOfTransportation: CONSTANTS.GOOGLE_MAPS_TO_DIRECTIONS_MAP.DRIVING, // This is the default travel mode on google
    timestampStart,
    duration: roundedDuration,
    distance,
    origin: getPointEvent(originEvent),
    destination: getPointEvent(destinationEvent)
  };
  return directionEvent;
};

// used for checking Trip Plan Attraction Event validity.
function checkAttractionEventPayload(evento) {
  if (!evento.name) {
    return 'TRIP_EVENT_VALIDATION_MISSING_NAME';
  }
  if (!evento.eventType) {
    return 'TRIP_EVENT_VALIDATION_MISSING_ATTRACTION_TYPE';
  }
}

// used for checking Trip Plan Attraction Event validity.
function checkDirectionEventPayload(evento) {
  if (!evento.meansOfTransportation) {
    return 'TRIP_EVENT_VALIDATION_MISSING_DIRECTION_MEANS_OF_TRANSPORTATION';
  }
  if (evento.distance && typeof evento.distance === 'string') {
    evento.distance = evento.distance.replace('.', ','); // only comma values are parsed
    evento.distance = parseFloat(evento.distance);
  }
  if (evento.distance && typeof evento.distance !== 'number') {
    return 'TRIP_EVENT_VALIDATION_INVALID_DIRECTION_DISTANCE';
  }
}

// used for checking Trip Plan Accommodation Event validity.
function checkAccommodationEventPayload(evento) {
  if (!evento.name) {
    return 'TRIP_EVENT_VALIDATION_MISSING_NAME';
  }
  if (!evento.address) {
    return 'TRIP_EVENT_VALIDATION_MISSING_ADDRESS';
  }
  if (!evento.accommodationType) {
    return 'TRIP_EVENT_VALIDATION_MISSING_ACCOMMODATION_TYPE';
  }
  if (!evento.actionType) {
    return 'TRIP_EVENT_VALIDATION_MISSING_ACCOMMODATION_ACTION_TYPE';
  }
}

// used for checking Trip Plan Establishment Event validity.
function checkEstablishmentEventPayload(evento) {
  if (!evento.name) {
    return 'TRIP_EVENT_VALIDATION_MISSING_NAME';
  }
  if (!evento.address) {
    return 'TRIP_EVENT_VALIDATION_MISSING_ADDRESS';
  }
  if (!evento.establishmentType) {
    return 'TRIP_EVENT_VALIDATION_MISSING_ESTABLISHMENT_TYPE';
  }
}

// Return an error message if something is wrong with Trip Event Payload
export const checkEventPayload = evento => {
  const { totalPrice, totalPriceCurrencyCode, kind } = evento;
  let errorParams = '';

  // total price is not mandatory
  if (totalPrice) {
    // when Total Price exists, Currency Code is mandatory
    if (!totalPriceCurrencyCode) {
      return 'TRIP_EVENT_VALIDATION_MISSING_CURRENCY_CODE';
    }

    if (!isValidMonetaryValue(totalPrice)) {
      return 'TRIP_EVENT_VALIDATION_INVALID_PRICE';
    }
  }

  switch (kind) {
    case CONSTANTS.TRIP_EVENT_KINDS.DIRECTIONS:
      errorParams = checkDirectionEventPayload(evento);
      break;
    case CONSTANTS.TRIP_EVENT_KINDS.ACCOMMODATION:
      errorParams = checkAccommodationEventPayload(evento);
      break;
    case CONSTANTS.TRIP_EVENT_KINDS.ESTABLISHMENT:
      errorParams = checkEstablishmentEventPayload(evento);
      break;
    default:
      errorParams = checkAttractionEventPayload(evento);
      break;
  }

  return errorParams;
};

export const calculateNextStartTimestampBasedOnLastEvent = (dayEvents = []) => {
  let nextStartTimestamp;

  if (!dayEvents || dayEvents.length === 0) {
    return;
  }

  const lastEvent = dayEvents[dayEvents.length - 1];
  if (isPositiveInteger(lastEvent.timestampStart)) {
    nextStartTimestamp = (lastEvent.timestampStart || 0) + (lastEvent.duration || 0);
    if (nextStartTimestamp > CONSTANTS.TIME.DAY) {
      nextStartTimestamp = CONSTANTS.TIME.DAY;
    }
  }
  return nextStartTimestamp;
};

export const getTripEventDateLabel = (tripEvent, hourOnly) => {
  const hourOfTheDay = convertTimestampToHourOfTheDay(tripEvent.timestampStart) || '--:--';
  if (hourOnly) {
    return `${hourOfTheDay}`;
  }
  const duration = tripEvent.duration ? `(${convertTimestampToHourOfTheDayVerbose(tripEvent.duration)})` : '';
  return `${hourOfTheDay} ${duration}`;
};

export const parseMapPositionsFromTripEvents = events => {
  const mapPositions = events
    .filter(evnt => evnt.latitude !== undefined && evnt.longitude !== undefined)
    .map((evnt, index) => {
      const { latitude, longitude, _id: id } = evnt;
      return { latitude, longitude, textLabel: index + 1, id };
    });
  return mapPositions;
};

export const addMapIndexToTripEvents = events => {
  let mapIndex = 1;

  events.forEach(evnt => {
    if (evnt.latitude !== undefined && evnt.longitude !== undefined) {
      evnt.mapIndex = mapIndex++;
    }
  });
};

export const extractTripEventFromId = (tripPlan = {}, dayId, eventId) => {
  const { days = [] } = tripPlan;
  const [day] = days.filter(day => day._id === dayId);
  if (!day) {
    return null;
  }
  const { events = [] } = day;
  const [tripEvent] = events.filter(event => event._id === eventId);
  return tripEvent;
};

const defaultEvent = {
  kind: CONSTANTS.TRIP_EVENT_KINDS.ATTRACTION,
  duration: undefined,
  totalPrice: 0,
  totalPriceCurrencyCode: CONSTANTS.DEFAULT_CURRENCY_CODE,
  imageUrl: '',
  latitude: undefined,
  longitude: undefined,
  tips: '',
  name: '',
  address: '',
  eventType: '',
  accommodationType: '',
  actionType: '',
  establishmentType: ''
};

export const resetNewEvent = (day = {}) => {
  const newEvent = { ...defaultEvent };
  // lastly updates timestampStart with new next hour available
  if (day && day.events) {
    newEvent.timestampStart = calculateNextStartTimestampBasedOnLastEvent(day.events);
  }
  return newEvent;
};

export const initializeMapPositions = (dayEvents = []) => {
  const mapPositions = dayEvents
    .filter(evnt => evnt.mapIndex !== undefined)
    .map(evnt => {
      const { latitude, longitude, mapIndex, _id: id } = evnt;
      return { latitude, longitude, mapIndex, id };
    });

  return mapPositions;
};

export const isAfterPaymentStatus = tripStatus => {
  return (
    tripStatus === CONSTANTS.TRIP_STATUS.TRIP_PROCESSING ||
    tripStatus === CONSTANTS.TRIP_STATUS.TRIP_VALIDATED ||
    tripStatus === CONSTANTS.TRIP_STATUS.TRIP_REVIEW
  );
};

// the day currency code is determined by the first day that has a currency
export const getDayCurrencyCode = (dayEvents = []) => {
  let totalPriceCurrencyCode;

  for (let index = 0; index < dayEvents.length; index++) {
    if (dayEvents[index].totalPriceCurrencyCode) {
      totalPriceCurrencyCode = dayEvents[index].totalPriceCurrencyCode;
      break;
    }
  }
  return totalPriceCurrencyCode;
};
