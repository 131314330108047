import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import NavigationService from '../services/navigation';
import CONSTANTS from '../constants/constants';
import CustomToggle from './CustomToggle';

const HeaderAvatar = ({ currentUser, onClickLogout }) => {
  const { t } = useTranslation();

  return (
    <div className="navbar-avatar-container">
      <div className="navbar-avatar">
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" drop="left">
            <img id="simple-dropdown" src={currentUser.imageUrl || '/images/default-user-bk.png'} alt={currentUser.email || 'Avatar Image'} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey="1">Hi, {currentUser.firstname}</Dropdown.Item>

            <Dropdown.Item eventKey="2" onClick={() => NavigationService.goToDashboard()}>
              Manage Trips
            </Dropdown.Item>
            <Dropdown.Item eventKey="3" onClick={() => NavigationService.goToDashboard(CONSTANTS.DASHBOARD_TAB_NAMES.MANAGE_LEADS)}>
              Manage Leads
            </Dropdown.Item>
            <Dropdown.Item eventKey="4" onClick={() => NavigationService.goToDashboard(CONSTANTS.DASHBOARD_TAB_NAMES.REGISTER_EXPERT)}>
              Manage Expert Users
            </Dropdown.Item>
            <Dropdown.Item eventKey="5" onClick={() => NavigationService.goToDashboard(CONSTANTS.DASHBOARD_TAB_NAMES.MESSAGES)}>
              {t('DASHBOARD_TAB_TITLE_MESSAGES')}
            </Dropdown.Item>
            <Dropdown.Item eventKey="6" onClick={() => NavigationService.goToDashboard(CONSTANTS.DASHBOARD_TAB_NAMES.TRIP_PLANS)}>
              {t('HEADER_AVATAR_TRIP_PLANS')}
            </Dropdown.Item>
            <Dropdown.Item eventKey="7" onClick={() => NavigationService.goToDashboard(CONSTANTS.DASHBOARD_TAB_NAMES.USER_PAYMENTS)}>
              {t('DASHBOARD_TAB_TITLE_USER_PAYMENTS')}
            </Dropdown.Item>
            <Dropdown.Item eventKey="8" onClick={() => NavigationService.goToManageLocations()}>
              {t('HEADER_AVATAR_MANAGE_LOCATIONS')}
            </Dropdown.Item>
            <Dropdown.Item eventKey="9" onClick={() => NavigationService.goToManageDestinations()}>
              {t('HEADER_AVATAR_MANAGE_DESTINATIONS')}
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="10" onClick={onClickLogout}>
              {t('HEADER_AVATAR_LOGOUT')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default HeaderAvatar;
