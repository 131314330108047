import CONSTANTS from '../constants/constants';
import agent from '../agent';
import moment from 'moment';

export const getCurrentFormat = () => {
  const language = agent.getLanguage();
  const LOCALE_CONFIGS = CONSTANTS.LOCALE_CONFIGS; // date format according to language chosen
  const currentMomentFormat = LOCALE_CONFIGS[language].momentFormat;

  return currentMomentFormat;
};

export const formatDate = date => {
  const currentMomentFormat = getCurrentFormat();
  const formatDate = moment(date).format(currentMomentFormat);
  return formatDate;
};

export const formatDateCustom = (date, customFormat) => {
  const formatDate = moment(date).format(customFormat);
  return formatDate;
};

export const convertTimestampToDate = dateTimestamp => {
  let dateLabel;
  const date = new Date(dateTimestamp);

  dateLabel = date.getDate() < 10 ? '0' : '';
  dateLabel += date.getDate() + '/';
  dateLabel += date.getMonth() + 1 < 10 ? '0' : '';
  dateLabel += date.getMonth() + 1 + '/';
  dateLabel += date.getFullYear();

  return dateLabel;
};

export const convertMilissecondsToHour = dateTimestamp => {
  const dateLabel = ((dateTimestamp + 1) / CONSTANTS.TIME.HOUR).toFixed(1);
  return dateLabel;
};

export const convertTimestampToHourMinute = dateTimestamp => {
  const hours = Math.floor(dateTimestamp / CONSTANTS.TIME.HOUR);
  const minutes = Math.round((dateTimestamp % CONSTANTS.TIME.HOUR) / CONSTANTS.TIME.MINUTE);

  return {
    hours,
    minutes
  };
};

export const convertTimestampToHourOfTheDay = dateTimestamp => {
  let dateLabel;

  if (dateTimestamp === null || dateTimestamp === undefined) {
    return;
  }

  const time = convertTimestampToHourMinute(dateTimestamp);
  if (isNaN(time.hours) || isNaN(time.minutes)) {
    return;
  }

  dateLabel = time.hours < 10 ? '0' : '';
  dateLabel += time.hours + ':';
  dateLabel += time.minutes < 10 ? '0' : '';
  dateLabel += time.minutes;

  return dateLabel;
};

export const convertTimestampToHourOfTheDayVerbose = dateTimestamp => {
  let dateLabel = '';

  const time = convertTimestampToHourMinute(dateTimestamp);

  if (isNaN(time.hours) || isNaN(time.minutes)) {
    return '-';
  }

  if (time.hours === 0 && time.minutes === 0) {
    return '0';
  }
  if (time.hours !== 0) {
    dateLabel += time.hours + 'h';
  }
  if (time.minutes !== 0) {
    dateLabel += time.minutes + 'min';
  }

  return dateLabel;
};

/* Get Current Date in the format dd/mm/YYYY */
export const getShortDateNow = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!

  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }

  return `${dd}/${mm}/${yyyy}`;
};
