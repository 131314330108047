import { EXPERTS_GET_REQUEST, EXPERTS_GET_SUCCESS, EXPERTS_GET_FAILURE, EXPERTS_ADD_USER, EXPERTS_UPDATE_LIST } from '../constants/actionTypes';
const defaultState = {
  allExperts: [],
  inProgress: false,
  error: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case EXPERTS_GET_REQUEST:
      return { ...state, inProgress: true };
    case EXPERTS_GET_SUCCESS:
      return {
        ...state,
        inProgress: false,
        error: null,
        allExperts: action.payload ? action.payload : null
      };
    case EXPERTS_GET_FAILURE:
      return {
        ...state,
        inProgress: false,
        message: [],
        error: action.error ? action.error : null
      };
    case EXPERTS_ADD_USER:
      return {
        ...state,
        inProgress: false,
        error: null,
        allExperts: [...state.allExperts, action.payload]
      };
    case EXPERTS_UPDATE_LIST:
      return {
        ...state,
        inProgress: false,
        error: null,
        allExperts: action.payload
      };
    default:
      return state;
  }
};
