import React from 'react';
import { Button } from './Custom';
import { useTranslation } from 'react-i18next';

const Destination = ({ destination, goToTripRequest }) => {
  const { t } = useTranslation();

  return (
    <div className="width-100">
      <div className="row">
        <div className="col-md-6 text-center">
          <img className="dest-img" src={destination.imageUrl} alt={destination.name} />
        </div>
        <div className="container col-md-6 dest-text">
          <h3>{destination.name}</h3>
          <p>{destination.description}</p>
          <div className="btn-dest">
            <Button as="a" className="btn btn-primary" onClick={goToTripRequest(destination)}>
              {t('BUTTON_SELECT_DESTINATION')}
            </Button>
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default Destination;
